import React, { useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import VendorDashboard from '../Components/Vendor/VendorDashboard/VendorDashboard';
import VendorHome from '../Components/Vendor/VendorHome/VendorHome';
import BulkshareCampaign from '../Components/Vendor/BulkshareCampaign/BulkshareCampaign';
import VendorLogin from '../Components/Vendor/VendorLogin/VendorLogin';
import VendorSignup from '../Components/Vendor/VendorSignup/VendorSignup';
import BulkShareList from '../Components/Vendor/BulkShareList/BulkShareList';
import SpinAndWinCreateCampaign from '../Components/Vendor/SpinAndWinCreateCampaign/SpinAndWinCreateCampaign';
import SpinAndWinList from '../Components/Vendor/SpinAndWinList/SpinAndWinList';
import ScratchCardList from '../Components/Vendor/ScratchCardList/ScratchCardList';
import ScratchCardCreate from '../Components/Vendor/ScratchCardCreate/ScratchCardCreate';
import AddProducts from '../Components/Vendor/AddProduct/AddProducts';
import AddStore from '../Components/Vendor/AddStore/AddStore';
import VendorStore from '../Components/Vendor/VendorStore/VendorStore';
import Products from '../Components/Vendor/Products/Products';
import Settings from '../Components/Vendor/Settings/Settings';
import ListOrders from '../Components/Vendor/ListOrders/ListOrders';
import AddOrders from '../Components/Vendor/AddOrders/AddOrders';
import ViewOrders from '../Components/Vendor/ViewOrders/ViewOrders';
import CustomerList from '../Components/Vendor/CustomerList/CustomerList';
import AddCustomers from '../Components/Vendor/AddCustomers/AddCustomers';
import ViewCustomers from '../Components/Vendor/ViewCustomers/ViewCustomers';
import Billing from '../Components/Vendor/Billing/Billing';
import Collections from '../Components/Vendor/Collections/Collections';
import BulkShareSetting from '../Components/Vendor/BulkShareSetting/BulkShareSetting';
import SpinAndWinRedeem from '../Components/Vendor/BulkShareSetting/SpinAndWinRedeem';
import ScratchCardRedeem from '../Components/Vendor/BulkShareSetting/ScratchCardRedeem';
import SpinAndWinVendorView from '../Components/Vendor/SpinAndWinVendorView/SpinAndWinVendorView';
import CouponsCreate from '../Components/Vendor/CouponsCreate/CouponsCreate';


function VendorRoutes() {


    var token = localStorage.getItem('vtoken')
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);

    //Check Token is Present or Not
    useEffect(() => {
        const checkToken = async () => {
            console.log({ "vendortoken": !!token })
            setLoggedIn(!!token);
            setTokenCheckComplete(true);
        };

        checkToken();
        // eslint-disable-next-line
    }, [token]);


    //If Token is not present Redirect to Login
    const PrivateRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? element : <Navigate to="/vendor/login" />;
        } else {
            return null;
        }
    };


    const LoginRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? <Navigate to="/vendor/home" /> : element;
        } else {
            return null;
        }
    };


    return (
        <Routes>

            <Route path="/login" element={<LoginRoute element={<VendorLogin />} />} />
            <Route path="/signup" element={<LoginRoute element={<VendorSignup />} />} />


            <Route path="/spin-and-win-campaign/:refid" element={<PrivateRoute element={<SpinAndWinVendorView />} />} />
            <Route path="/" element={<PrivateRoute element={<VendorDashboard />} />}>

                <Route path="/" element={<Navigate to="/vendor/home" />} />
                <Route path="home" element={<VendorHome />} />
                <Route path="bulksharelist" element={<BulkShareList />} />
                <Route path="bulkshare" element={<BulkshareCampaign />} />
                <Route path="spinandwincampaign" element={<SpinAndWinCreateCampaign />} />
                <Route path="spinandwinlist" element={<SpinAndWinList />} />
                <Route path="scratchcardlist" element={<ScratchCardList />} />
                <Route path="scratchcard" element={<ScratchCardCreate />} />
                <Route path='products' element={<Products />} />
                <Route path='addproduct' element={<AddProducts />} />
                <Route path="addstore" element={<AddStore />} />
                <Route path="viewstore" element={<VendorStore />} />
                <Route path='settings' element={<Settings />} />
                <Route path="listorders" element={<ListOrders />} />
                <Route path="addorders" element={<AddOrders />} />
                <Route path="vieworders" element={<ViewOrders />} />
                <Route path="listcustomers" element={<CustomerList />} />
                <Route path="addcustomers" element={<AddCustomers />} />
                <Route path="viewcustomers" element={<ViewCustomers />} />
                <Route path='bill' element={<Billing />} />
                <Route path='collections' element={<Collections />} />
                <Route path='bulkshare-coupon-redeem' element={<BulkShareSetting />} />
                <Route path='spin-and-win-coupon-redeem' element={<SpinAndWinRedeem />} />
                <Route path='scratch-card-coupon-redeem' element={<ScratchCardRedeem />} />
                <Route path='coupons' element={<CouponsCreate />} />

            </Route>

        </Routes>
    )
}

export default VendorRoutes