import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Products.css";
import ProductEmpty from "../../Images/Product-empty.png";
import Product from "../../Images/Rectangle 2470.png";
import api from "../ApiConfigration/ApiConfigration";
import toast from "react-hot-toast";
import InfiniteScroll from "react-infinite-scroll-component";
import loader2 from "../../Images/loader.gif"
import { useDispatch, useSelector } from 'react-redux';
import { SetRemoveProductDetails, setProductList, setProductListDetails, setProductSearch } from '../../../Redux/vendorReducer';

export default function Products() {

  const dispatch = useDispatch();
  const imageBaseUrl = process.env.REACT_APP_API_AWS_BASE_URL
  const navigate = useNavigate();
  const [products, setProduts] = useState([1]);
  const [search, setSearch] = useState("")
  // const [productData, setProductData] = useState([])
  // const [page, setPage] = useState(0);
  const limit = 10;
  // const [hasmore, setHasMore] = useState(true)
  const [isempty, setIsEmpty] = useState(false)
  var userData = useSelector(state => state?.vendorData?.vendor);
  var productData = useSelector(state => state?.vendorData?.productList);
  var page = useSelector(state => state?.vendorData?.productListDetails?.page);
  var hasmore = useSelector(state => state?.vendorData?.productListDetails?.hasmore);
  var noDataImageFlag = useSelector(state => state?.vendorData?.productListDetails?.noDataImageFlag);
  var searchdata = useSelector(state => state?.vendorData?.productSearch?.search);
  const ViewAddProductPage = () => {
    navigate("/vendor/addproduct");
  };





  async function getProductData() {
    setIsEmpty(false)


    await api.get(`/product/getallproducts?offset=${page ? page : 0}&imit=${limit}${search ? "&search=" + search : ""}`).then((res) => {
      if (res) {
        console.log(res?.data?.message?.message)
        // setProductData(res?.data?.message?.message)


        if (res?.data?.message?.message?.length < limit) {

          // setHasMore(false);
          dispatch(setProductListDetails({ hasmore: false, noDataImageFlag: true }))

        } else {

          // setPage(page + limit);
          // setHasMore(true);
          dispatch(setProductListDetails({ hasmore: true, page: page + limit, noDataImageFlag: true }))

        }

        // setProductData((prevData) => [...prevData, ...res?.data?.message?.message])
        const newData = res?.data?.message?.message;

        if (newData) {
          // Check for duplicates
          const existingIds = productData?.map(item => item?.id);
          const firstItemId = newData[0]?.id;
          const lastItemId = newData[newData.length - 1]?.id;

          if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
            // If no duplicates, add new items to existing data
            dispatch(setProductList([...productData, ...newData]));
          }
        }

        // if (newData) {

        //   const existingIds = productList.map(item => item.id);
        //   const firstItemId = newData[0]?.id;
        //   const lastItemId = newData[newData.length - 1]?.id;

        //   if (existingIds.includes(firstItemId) || existingIds.includes(lastItemId)) {
        //     // If any of the new items already exist in the existing data, don't add them
        //     return productList;
        //   } else {
        //     // Add new items to existing data
        //     return [...productList, ...newData];
        //   }

        // }

      } else {
        console.log("Error fetching data")
      }
    }).catch((err) => {
      toast.error(err.message)
    })
  }



  // useEffect(() => {
  //   if (productData?.length === 0) {
  //     setIsEmpty(true)
  //   } else {
  //     setIsEmpty(false)

  //   }
  // }, [productData])


  useEffect(() => {
    if (hasmore) {
      getProductData()
    }

  }, [])

  const redirecttopoductedit = (i) => {

    navigate(`/vendor/addproduct?id=${i}&edit=false`)

  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const searchProductHandle = async () => {
    dispatch(SetRemoveProductDetails({}));
    productData = []
    page = 0
    dispatch(setProductSearch({ search: search }))
    await new Promise(resolve => setTimeout(resolve, 200));
    getProductData()

  }




  return (
    <div style={{ gap: "32px" }} className="d-flex w-100 flex-column ">
      <div className="d-flex align-items-center">
        <h1 className="bulkshare111 pt-1">Products</h1>
        <button onClick={ViewAddProductPage} className="bulkshare112 ms-3">
          Add
        </button>
      </div>
      <div className="product-show-area">
        {/* filter */}
        <div style={{ gap: "8px" }} className="d-flex flex-column w-100">
          <div className="product-filter">
            <div className="input-outer-box">
              <input
                className="product-filter-search"
                type="text"
                value={search}
                onChange={handleSearchChange}
                placeholder="Search"
              />
              <svg
                style={{ cursor: "pointer" }}
                className="filter-search-svg"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                onClick={searchProductHandle}
              >
                <path
                  d="M11 20.7498C5.62 20.7498 1.25 16.3798 1.25 10.9998C1.25 5.61976 5.62 1.24976 11 1.24976C16.38 1.24976 20.75 5.61976 20.75 10.9998C20.75 16.3798 16.38 20.7498 11 20.7498ZM11 2.74976C6.45 2.74976 2.75 6.44976 2.75 10.9998C2.75 15.5498 6.45 19.2498 11 19.2498C15.55 19.2498 19.25 15.5498 19.25 10.9998C19.25 6.44976 15.55 2.74976 11 2.74976Z"
                  fill="#828785"
                />
                <path
                  d="M20.1601 22.7898C20.0801 22.7898 20.0001 22.7798 19.9301 22.7698C19.4601 22.7098 18.6101 22.3898 18.1301 20.9598C17.8801 20.2098 17.9701 19.4598 18.3801 18.8898C18.7901 18.3198 19.4801 17.9998 20.2701 17.9998C21.2901 17.9998 22.0901 18.3898 22.4501 19.0798C22.8101 19.7698 22.7101 20.6498 22.1401 21.4998C21.4301 22.5698 20.6601 22.7898 20.1601 22.7898ZM19.5601 20.4898C19.7301 21.0098 19.9701 21.2698 20.1301 21.2898C20.2901 21.3098 20.5901 21.1198 20.9001 20.6698C21.1901 20.2398 21.2101 19.9298 21.1401 19.7898C21.0701 19.6498 20.7901 19.4998 20.2701 19.4998C19.9601 19.4998 19.7301 19.5998 19.6001 19.7698C19.4801 19.9398 19.4601 20.1998 19.5601 20.4898Z"
                  fill="#828785"
                />
              </svg>
            </div>
            <button className="product-filter-btn">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
              >
                <path
                  d="M1 0.999756H17"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M5 4.99976H13"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M7 8.99976H11"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
              </svg>
              <p className="p-0 m-0">Filter</p>
            </button>
          </div>
          {searchdata ? <div className="mx-2">Showing search result for "{searchdata}"</div> : null}
          {/* <div className="filter-items">
            <div className="filter-item item-green">
              <p className="p-0 m-0">collection: sandwich</p>
              <svg
                className="mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <path
                  d="M0.7475 7.5784C0.5575 7.5784 0.3675 7.5084 0.2175 7.3584C-0.0725 7.0684 -0.0725 6.5884 0.2175 6.2984L5.8775 0.638398C6.1675 0.348398 6.6475 0.348398 6.9375 0.638398C7.2275 0.928398 7.2275 1.4084 6.9375 1.6984L1.2775 7.3584C1.1375 7.5084 0.9375 7.5784 0.7475 7.5784Z"
                  fill="#292D32"
                />
                <path
                  d="M6.4075 7.5784C6.2175 7.5784 6.0275 7.5084 5.8775 7.3584L0.2175 1.6984C-0.0725 1.4084 -0.0725 0.928398 0.2175 0.638398C0.5075 0.348398 0.987499 0.348398 1.2775 0.638398L6.9375 6.2984C7.2275 6.5884 7.2275 7.0684 6.9375 7.3584C6.7875 7.5084 6.5975 7.5784 6.4075 7.5784Z"
                  fill="#292D32"
                />
              </svg>
            </div>
            <div className="filter-item item-orenge">
              <p className="p-0 m-0">collection: sandwich</p>
              <svg
                className="mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
              >
                <path
                  d="M0.7475 7.5784C0.5575 7.5784 0.3675 7.5084 0.2175 7.3584C-0.0725 7.0684 -0.0725 6.5884 0.2175 6.2984L5.8775 0.638398C6.1675 0.348398 6.6475 0.348398 6.9375 0.638398C7.2275 0.928398 7.2275 1.4084 6.9375 1.6984L1.2775 7.3584C1.1375 7.5084 0.9375 7.5784 0.7475 7.5784Z"
                  fill="#292D32"
                />
                <path
                  d="M6.4075 7.5784C6.2175 7.5784 6.0275 7.5084 5.8775 7.3584L0.2175 1.6984C-0.0725 1.4084 -0.0725 0.928398 0.2175 0.638398C0.5075 0.348398 0.987499 0.348398 1.2775 0.638398L6.9375 6.2984C7.2275 6.5884 7.2275 7.0684 6.9375 7.3584C6.7875 7.5084 6.5975 7.5784 6.4075 7.5784Z"
                  fill="#292D32"
                />
              </svg>
            </div>
          </div> */}
        </div>
        {/* filter-end */}

        <>
          <div
            style={{ paddingTop: "32px" }}
            className="d-flex flex-column w-100"
          >
            <div className="products-item-head">
              <input className="input-checkbox-green" type="checkbox" />
              <p className="p-0 m-0">Showing {productData?.length} products</p>
            </div>
            {/* items */}
            <InfiniteScroll
              dataLength={productData?.length}
              next={getProductData}
              hasMore={hasmore}
              loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader2} alt="" /></div>}
            >
              {productData.map((item) => {
                return (
                  <div onClick={() => { redirecttopoductedit(item.id) }} style={{ cursor: "pointer" }} className="products-item">
                    <input className="input-checkbox-green" type="checkbox" />
                    <img src={imageBaseUrl + item?.productImage[0]} alt="" />
                    <div className="products-item-detials">
                      <div className="d-flex justify-content-between w-100">
                        <div style={{ gap: "16px" }} className="d-flex">
                          <p style={{ textTransform: "capitalize" }} className="products-item-name p-0 m-0 d-flex align-items-center">
                            {item?.productName?.toLowerCase()}
                          </p>
                          {(item?.collections?.slice(0, 3))?.map((itm, k) =>
                          (<p key={k}
                            className={`filter-item item-${k % 2 === 0 ? 'orenge' : 'green'} m-0`}>
                            {itm}
                          </p>)
                          )}

                        </div>
                        <div className="d-flex products-item-status">
                          <p className="m-0 p-0">{item?.availability}</p>
                          {item?.availability === "available" ?
                            <small className="products-item-status-green m-0 p-0"></small> :
                            <small className="products-item-status-red m-0 p-0"></small>
                          }
                        </div>
                      </div>
                      <div
                        style={{ gap: "8px" }}
                        className="d-flex products-item-price"
                      >
                        <p className="p-0 m-0">{userData?.currency?userData?.currency:null} {item?.productvarients[0]?.price ? item?.productvarients[0]?.price : item?.price ? item?.price : 0}</p>
                        <p className="p-0 m-0">-</p>
                        <p className="p-0 m-0">{item?.productvarients?.length ? item?.productvarients?.length : 0} varients</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </>
        {noDataImageFlag && productData?.length === 0 ? (
          <>
            {/* Empty */}
            <div
              style={{ paddingTop: "80px", gap: "18px", height: "330px" }}
              className="d-flex flex-column w-100 "
            >
              <div className="w-100 d-flex justify-content-center">
                <img src={ProductEmpty} alt="" />
              </div>
              <div className="d-flex justify-content-center w-100">
                <div className="d-flex flex-column">
                  <h6 className="product-empty-bold-text p-0 m-0">
                    No products found
                  </h6>
                  <p className="product-empty-text">
                    Try changing the filters or search term
                  </p>
                </div>
              </div>

            </div>
          </>
        ) : null}
        {/* empty end */}
      </div>
    </div>
  );
}
