import React from 'react'
import './Billing.css'
export default function Billing() {
  return (
    <div className='row'>
        <div  className='col-12 '>
            <div className='d-flex'>
                <h1 className='bulkshare111'>Billing</h1>
            </div>
            <div className='billing-box mt-4'>
                <div className='d-flex justify-content-between w-100'>
                    <h1 className='subscription-text '>Subscription</h1>
                    <div className='d-flex'>
                        <button className='transtirent-btn btn-text-orenge'>Subscribe</button>
                        <button className='transtirent-btn btn-text-green'>Get Support</button>
                    </div>
                </div>
                {/* head */}
                <div className='subscription-head bill-border-bottom'>
               
                        <span className='bill-item-left'>Plan</span>
                        <span className='bill-item-right'>Basic</span>
                   
                </div>
                <div className='subscription-head '>
               
               <span className='bill-item-left'>Status</span>
               <span className='bill-item-right'>No Subscription</span>
          
       </div>

            </div>
        </div>
    </div>
  )
}
