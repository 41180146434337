import React, { useEffect, useState } from 'react';
import "./VendorSidebar.css"
import logosmall from "../../Images/logo 1.png"
import logo from "../../Images/logo1.png"
import dashboardicon from "../../Images/home-2.png"
import storeicon from "../../Images/shop.png";
import productsicon from "../../Images/icons=Products.png"
import settingicon from "../../Images/Vector.png"
import ordersicon from "../../Images/icons=Orders.png"
import scratchicon from "../../Images/ticket-star.png"
import spinwinicon from "../../Images/icons=Spin & Win.png"
import bulkshareicon from "../../Images/icons=Bulk Purchase.png";
import customericon from "../../Images/icons=Customers.png";
import logouticon from "../../Images/logout.png";
import helpicon from "../../Images/message-question.png";
import { useNavigate, useParams } from 'react-router-dom';
import profilepic from "../../Images/Rectangle 2468.png"
import collection from '../../Images/folder.svg'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setVendor } from '../../../Redux/vendorReducer';

function VendorSidebar() {

  const dispatch = useDispatch();
  var navigate = useNavigate();
  var baseurl = process.env.REACT_APP_API_URL_VENDOR;
  var token = localStorage.getItem("vtoken")
  // var [userData, setUserData] = useState([])
  const currentURL = window.location.href;
  const [highlight, setHightlight] = useState("")
  var userData = useSelector(state => state?.vendorData?.vendor);



  useEffect(() => {
    const endpoint = currentURL.split("vendor/")[1];
    setHightlight(endpoint);
  }, [currentURL])

  var config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }


  useEffect(() => {
    async function getprofileap() {
      await axios.get(`${baseurl}/auth/getprofile`, config)
        .then((res) => {
          // console.log(res?.data?.message?.data)
          const vendorData = res?.data?.message?.data
          dispatch(setVendor(vendorData))
          // setUserData(res?.data?.message?.data)
        })
        .catch((err) => {
          console.log(err?.response?.data?.errors?.body[0])

        })
    }

    getprofileap()

    // eslint-disable-next-line
  }, [token])




  const Dashboardpage = () => {
    navigate("/vendor")
  }
  const BulkshareCampaignpage = () => {
    navigate("/vendor/bulksharelist")
  }
  const Logoutuser = () => {
    localStorage.removeItem("vtoken")
    localStorage.removeItem("vrtoken")
    window.location.replace("/vendor/login")

  }
  const CreateSpinAndWin = () => {
    navigate("/vendor/spinandwinlist")
  }

  const CreateScratchCard = () => {
    navigate("/vendor/scratchcardlist")
  }

  const ViewStore = () => {
    navigate("/vendor/viewstore")
  }
  const ViewProducts = () => {
    navigate('/vendor/products')
  }
  const ViewSettings = () => {
    navigate('/vendor/settings')
  }

  const listorders = () => {
    navigate("/vendor/listorders")
  }

  const CustomerList = () => {
    navigate("/vendor/listcustomers")
  }
  const ViewCollections = () => {
    navigate('/vendor/collections')
  }

  const ViewCoupons = () => {
    navigate('/vendor/coupons')

  }



  return (
    <div className='vendorsidebar222'>
      <div className='vendorsidebarhome '>

        <div  >

          <div className='mt-4 adjustimagelogo1'> <img src={logosmall} alt="" /></div>
          <div className='mt-4 adjustimagelogo'> <img src={logo} alt="" /></div>
          <div className='vendorlistadjust'>

            <div onClick={Dashboardpage} className={`indiconlistvendor ${highlight === "home" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={dashboardicon} alt="" /></span><span className='dboardtestlist'>Dashboard</span></div>
            <div onClick={ViewStore} className={`indiconlistvendor ${highlight === "viewstore" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={storeicon} alt="" /></span><span className='dboardtestlist'>Your store</span></div>
            <div onClick={ViewProducts} className={`indiconlistvendor ${highlight === "products" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={productsicon} alt="" /></span><span className='dboardtestlist'>Products</span></div>
            <div onClick={ViewCollections} className={`indiconlistvendor ${highlight === "collections" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={collection} alt="" /></span><span className='dboardtestlist'>Collections</span></div>
            <div onClick={listorders} className={`indiconlistvendor ${highlight === "listorders" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={ordersicon} alt="" /></span><span className='dboardtestlist'>Orders</span></div>
            <div onClick={CreateScratchCard} className={`indiconlistvendor ${highlight === "scratchcardlist" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={scratchicon} alt="" /></span><span className='dboardtestlist'>Scratch card</span></div>
            <div onClick={CreateSpinAndWin} className={`indiconlistvendor ${highlight === "spinandwinlist" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={spinwinicon} alt="" /></span><span className='dboardtestlist'>Spin & Win</span></div>
            <div onClick={BulkshareCampaignpage} className={`indiconlistvendor ${highlight === "bulksharelist" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={bulkshareicon} alt="" /></span><span className='dboardtestlist'>Bulk Share</span></div>
            <div onClick={CustomerList} className={`indiconlistvendor ${highlight === "listcustomers" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={customericon} alt="" /></span><span className='dboardtestlist'>Customers</span></div>
            <div onClick={ViewCoupons} className={`indiconlistvendor ${highlight === "coupons" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={scratchicon} alt="" /></span><span className='dboardtestlist'>Coupons</span></div>
            <div onClick={ViewSettings} className={`indiconlistvendor ${highlight === "settings" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={settingicon} alt="" /></span><span className='dboardtestlist'>Settings</span></div>


            <div className='mt-2 '>
              <div className='indiconlistvendor'><span><img className="mb-1 thrytuinv" src={helpicon} alt="" /></span><span className='dboardtestlist'>Help</span></div>
              <div onClick={Logoutuser} className='indiconlistvendor'><span><img className="mb-1 thrytuinv" src={logouticon} alt="" /></span><span className='dboardtestlist'>Logout</span></div>

            </div>

          </div>


          <div className=' d-flex userlogotextclr'>
            <span > <img className="profilepicuser4" src={profilepic} alt="" /></span>
            <span className='textitemprofile'>
              <div style={{ textTransform: "capitalize" }}>{userData?.userName?.toLowerCase()}</div>
              <div style={{ textTransform: "capitalize", overflow: "hidden", height: "23px" }}>{userData?.storeName?.toLowerCase()}</div>
            </span>

          </div>



        </div>


      </div>
    </div>
  )
}

export default VendorSidebar