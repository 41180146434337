import React, { useEffect, useState } from 'react'
import './Login.css'
import logo from "../../Images/logo1.png"
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


function Login() {

  const location = useLocation();
  const navigate = useNavigate();

  const [redirect, setRedirect] = useState("");
  const [username, setUserName] = useState("")
  const [bypass, setsetBypass] = useState("")
  const [redirectcampaign, setRedirectCampaign] = useState("")


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect');
    const username = searchParams.get('username');
    var bypass2 = searchParams.get('bypass');
    var redirectcampaign2 = searchParams.get('redirectcampaign');
    setsetBypass(bypass2)
    setRedirect(redirect);
    setUserName(username)
    setRedirectCampaign(redirectcampaign2)
  }, [location.search]);


  var baseurl = process.env.REACT_APP_API_URL_CUSTOMER;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("")
  const [countryCode, setCountryCode] = useState('+91');
  const [disableLogin, setDisablelogin] = useState(false)


  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value.replace(/\D/g, '');
    setPhoneNumber(inputPhoneNumber);
    setPhoneNumberError('');
  };


  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value)
  };

  const isValidPhoneNumber = (phoneNumber) => {
    try {
      const parsedPhoneNumber = phoneUtil.parse(phoneNumber);
      var isCorrect = phoneUtil.isValidNumber(parsedPhoneNumber);
      console.log(isCorrect)
      return isCorrect
    } catch (e) {
      toast.error(e.message)
      return false;
    }
  };

  const loginUser = async (e) => {
    e.preventDefault();

    if(disableLogin === true){
      return;
    }

    if (!phoneNumber) {
      setPhoneNumberError('Please enter your Phone Number.');
      return;
    }

    if (phoneNumber) {
      var isCorrect = isValidPhoneNumber(`${countryCode}${phoneNumber}`)
      if (!isCorrect) {
        setPhoneNumberError('Phone Number is Invalid');
        return
      }
      ;
    }

    setDisablelogin(true)

    await axios.post(`${baseurl}/auth/login`, {
      phoneNumber: phoneNumber,
      phone_code: countryCode

    })
      .then(async (res) => {
        console.log(res)
        console.log(res?.data?.message?.code)
        setDisablelogin(false)
        if (res?.data?.message?.code === "Success") {
          toast.success('OTP Sent to your phone number')

          const delayTime = 1000;
          await new Promise((resolve) => setTimeout(resolve, delayTime));
          var url = `/customer/verify?phone=${phoneNumber}`;

          if (redirect) {
            url += `&redirect=${redirect}`;
          }
          if (username) {
            url += `&username=${username}`;
          }
          if (bypass) {
            url += `&bypass=${bypass}`;
          }
          if (redirectcampaign) {
            url += `&redirectcampaign=${redirectcampaign}`;
          }

          navigate(url);

        } else {
          toast.error("Error try again")
          setDisablelogin(false)
        }

      })
      .catch((err) => {
        toast.error(err?.response?.data?.errors?.body[0])
        setDisablelogin(false)

      });

  }



  return (
    <form>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className='loginhomebody'>


        <div className='loginbody'>
          <div className=' d-grid gap-2'>
            <img className='logoimg' src={logo} alt="" />
          </div>
          <div className='loginbox'>
            <div className='innercont'>
              <h3 className='loginheading'>Welcome,</h3>
              <p className='logindesc'>Enter your phone number to continue</p>


              <div className='d-grid gap-2'>
                <div className="input-group loginnum">
                  <div className="input-group-prepend drop-select-css">
                    <select
                      className="custom-select numselect"
                      value={countryCode}
                      onChange={(e) => handleCountryCodeChange(e)}>
                      <option selected>+91</option>
                      <option>+973</option>


                    </select>
                  </div>
                  <input className=' form-control inputboxq' type="number" pattern="[0-9]{10}" placeholder="Enter Phone number" value={phoneNumber} name="phoneNumber" onChange={(e) => handlePhoneNumberChange(e)} />
                </div>
                {phoneNumberError && <div className="text-danger text-start">{phoneNumberError}</div>}

              </div>

              <div className={`submitbtn d-grid gap-2 ${disableLogin?"cursorstyledisable":null}`}>
                <div onClick={loginUser} className={`submitt ${disableLogin?"cursorstyledisable":null}`}><div>Claim Now</div></div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </form>

  )
}

export default Login