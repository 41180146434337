import React, { useEffect, useState } from 'react';
import "./CustomerSiderbar.css"
import logo from "../../Images/logo1.png"
import { useNavigate } from 'react-router-dom';


function CustomerSidebar({ pageData }) {

    const navigate = useNavigate()

    const [data, setData] = useState({})

    useEffect(() => {
        setData(pageData)

    }, [pageData])


    const redirecttopage = () => {
        navigate(`/customer/claimedoffers?id=${data?.id}`)
    }


    return (
        <div className='customersiderbar001 d-flex flex-column'>


            <div className="offcanvas-header mt-4">
                <h5 className="offcanvas-title customersiderbar003" id="offcanvasWithBothOptionsLabel">{data?.storeName ? data?.storeName : null} </h5>
                <button type="button" className="btn text-reset customersiderbar002" data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa-solid fa-xmark"></i></button>
            </div>
            <div className="offcanvas-body flex-grow-1">

                <div className='mt-3'>
                    {/* <div className='customersiderbar004'>Breakfast</div>
                    <div className='customersiderbar004'>Burgers</div>
                    <div className='customersiderbar004'>Beverages</div> */}
                    {data?.storeName ?<div className='d-flex mt-5'>
                        <div className='customersiderbar005 '>
                            <span className='mx-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                <path d="M0 20L1.40583 14.8642C0.538333 13.3608 0.0825 11.6567 0.0833333 9.90917C0.0858333 4.44583 4.53167 0 9.99417 0C12.645 0.000833333 15.1333 1.03333 17.005 2.90667C18.8758 4.78 19.9058 7.27 19.905 9.91833C19.9025 15.3825 15.4567 19.8283 9.99417 19.8283C8.33583 19.8275 6.70167 19.4117 5.25417 18.6217L0 20ZM5.4975 16.8275C6.89417 17.6567 8.2275 18.1533 9.99083 18.1542C14.5308 18.1542 18.2292 14.4592 18.2317 9.91667C18.2333 5.365 14.5525 1.675 9.9975 1.67333C5.45417 1.67333 1.75833 5.36833 1.75667 9.91C1.75583 11.7642 2.29917 13.1525 3.21167 14.605L2.37917 17.645L5.4975 16.8275ZM14.9867 12.2742C14.925 12.1708 14.76 12.1092 14.5117 11.985C14.2642 11.8608 13.0467 11.2617 12.8192 11.1792C12.5925 11.0967 12.4275 11.055 12.2617 11.3033C12.0967 11.5508 11.6217 12.1092 11.4775 12.2742C11.3333 12.4392 11.1883 12.46 10.9408 12.3358C10.6933 12.2117 9.895 11.9508 8.94917 11.1067C8.21333 10.45 7.71583 9.63917 7.57167 9.39083C7.4275 9.14333 7.55667 9.00917 7.68 8.88583C7.79167 8.775 7.9275 8.59667 8.05167 8.45167C8.1775 8.30833 8.21833 8.205 8.30167 8.03917C8.38417 7.87417 8.34333 7.72917 8.28083 7.605C8.21833 7.48167 7.72333 6.2625 7.5175 5.76667C7.31583 5.28417 7.11167 5.34917 6.96 5.34167L6.485 5.33333C6.32 5.33333 6.05167 5.395 5.825 5.64333C5.59833 5.89167 4.95833 6.49 4.95833 7.70917C4.95833 8.92833 5.84583 10.1058 5.96917 10.2708C6.09333 10.4358 7.715 12.9375 10.1992 14.01C10.79 14.265 11.2517 14.4175 11.6108 14.5317C12.2042 14.72 12.7442 14.6933 13.1708 14.63C13.6467 14.5592 14.6358 14.0308 14.8425 13.4525C15.0492 12.8733 15.0492 12.3775 14.9867 12.2742Z" fill="#25D366" />
                            </svg></span>
                            <span className='mt-2'>Chat with us</span>
                        </div>
                        <div className='customersiderbar005 ms-2'>
                            <span className='mx-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                <path d="M14.5834 2.08325C13.2027 2.08325 12.0834 3.20254 12.0834 4.58325C12.0834 4.80364 12.1119 5.01739 12.1655 5.22097C12.1064 5.23317 12.0482 5.25419 11.9925 5.28456L9.69533 6.53753L6.77333 8.20726C6.74346 8.22433 6.71554 8.24348 6.68964 8.26444C6.3168 8.04345 5.88159 7.91658 5.41675 7.91658C4.03604 7.91658 2.91675 9.03584 2.91675 10.4166C2.91675 11.7973 4.03604 12.9166 5.41675 12.9166C6.14514 12.9166 6.80077 12.6051 7.25771 12.108L9.6965 13.4629L12.1586 14.8058C12.1095 15.0013 12.0834 15.2059 12.0834 15.4166C12.0834 16.7973 13.2027 17.9166 14.5834 17.9166C15.9642 17.9166 17.0834 16.7973 17.0834 15.4166C17.0834 14.0358 15.9642 12.9166 14.5834 12.9166C13.8631 12.9166 13.2138 13.2213 12.7577 13.7088L10.3015 12.3691L7.847 11.0054C7.89259 10.8166 7.91675 10.6194 7.91675 10.4166C7.91675 9.96684 7.79799 9.54484 7.59012 9.18025L10.3048 7.62897L12.591 6.38194C12.6469 6.35146 12.6962 6.31369 12.7386 6.27047C13.1957 6.76999 13.853 7.08325 14.5834 7.08325C15.9642 7.08325 17.0834 5.96396 17.0834 4.58325C17.0834 3.20254 15.9642 2.08325 14.5834 2.08325Z" fill="#62C4A4" />
                            </svg></span>
                            <span className='mt-2'>
                                Share with friends
                            </span>
                        </div>
                    </div>:null}
                </div>
            </div>

            <div class="offcanvas-footer pb-4 customersiderbar009">
               {data?.id ?<div onClick={redirecttopage} className=' mb-4 customersiderbar010'><p>View my Offers</p></div>:null}
                <div className='customersiderbar006 customersiderbar007'>
                    <select style={{ boxShadow: 'none' }} className="form-select customersiderbar006 customersiderbar008 px-2" aria-label="Default select example">
                        <option className='optionselect333' selected>English</option>
                        {/* <option className='optionselect333' value="1">Hindi</option>
                        <option className='optionselect333' value="2">Malayalam</option>
                        <option className='optionselect333' value="3">Spanish</option> */}
                    </select>
                </div>

                <div className='mt-5 ms-1 customersiderbar011'><img src={logo} alt="" /></div>
            </div>




        </div>
    )
}

export default CustomerSidebar