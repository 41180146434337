import React, { useEffect, useState } from 'react'
import logosmall from "../../Images/logo 1.png"
import logo from "../../Images/logo1.png"
import dashboardicon from "../../Images/home-2.png"
import logouticon from "../../Images/logout.png";
import helpicon from "../../Images/message-question.png";
import profilepic from "../../Images/Rectangle 2468.png"
import { useNavigate } from 'react-router-dom';
import ordersicon from "../../Images/icons=Orders.png"
import customericon from "../../Images/icons=Customers.png";

function SideBarSuperAdmin() {

    const navigate = useNavigate();
    const currentURL = window.location.href;

    const [highlight, setHightlight] = useState("")




    useEffect(() => {
        const endpoint = currentURL.split("superadmin/")[1];
        setHightlight(endpoint);
    }, [currentURL])


    const Logoutuser = () => {
        localStorage.removeItem("satoken")
        localStorage.removeItem("sartoken")
        window.location.replace("/superadmin/login")

    }

    const Dashboardpage = () => {
        navigate("/superadmin")
    }

    const AdminList = () => {
        navigate("/superadmin/vendorlist")
    }

    const VendorRequests = () => {
        navigate("/superadmin/vendor-request")
    }

    const AddVendor = () => {
        navigate("/superadmin/addvendors")
    }

    const RejectedVendors = ()=>{
        navigate("/superadmin/rejectedvendors")

    }



    return (
        <div className='vendorsidebar222'>
            <div className='vendorsidebarhome '>


                <div className='mt-4 adjustimagelogo1'> <img src={logosmall} alt="" /></div>
                <div className='mt-4 adjustimagelogo'> <img src={logo} alt="" /></div>
                <div style={{ height: "92%" }} className=' d-flex flex-column '>

                    <div className='vendorlistadjust'>

                        <div onClick={Dashboardpage} className={`indiconlistvendor ${highlight === "home" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={dashboardicon} alt="" /></span><span className='dboardtestlist'>Dashboard</span></div>
                        <div onClick={AdminList} className={`indiconlistvendor ${highlight === "vendorlist" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={customericon} alt="" /></span><span className='dboardtestlist'>Vendors</span></div>
                        <div onClick={VendorRequests} className={`indiconlistvendor ${highlight === "vendor-request" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={ordersicon} alt="" /></span><span className='dboardtestlist'>Requests</span></div>
                        <div onClick={AddVendor} className={`indiconlistvendor ${highlight === "addvendors" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={ordersicon} alt="" /></span><span className='dboardtestlist'>Add Vendor</span></div>
                        <div onClick={RejectedVendors} className={`indiconlistvendor ${highlight === "rejectedvendors" ? "hightLighted" : null}`}><span><img className="mb-1 thrytuinv" src={ordersicon} alt="" /></span><span className='dboardtestlist'>Rejected Vendors</span></div>



                    </div>

                    <div className='mt-auto'>
                        <div className='mb-4 '>
                            <div onClick={Logoutuser} className='indiconlistvendor'><span><img className="mb-1 thrytuinv" src={logouticon} alt="" /></span><span className='dboardtestlist'>Logout</span></div>

                        </div>
                        <div className=' d-flex userlogotextclr '>
                            <span > <img className="profilepicuser4" src={profilepic} alt="" /></span>
                            <span className='textitemprofile'>
                                <div style={{ textTransform: "capitalize" }}>SuperAdmin</div>
                                <div style={{ textTransform: "capitalize" }}>Offerpedia</div>

                            </span>

                        </div>
                    </div>

                </div>

            </div>






        </div>
    )
}

export default SideBarSuperAdmin