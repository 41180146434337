import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import LoginSuperAdmin from '../Components/SuperAdmin/LoginSuperAdmin/LoginSuperAdmin';
import DashboardSuperAdmin from '../Components/SuperAdmin/DashboardSuperAdmin/DashboardSuperAdmin';
import HomeSuperAdmin from '../Components/SuperAdmin/HomeSuperAdmin/HomeSuperAdmin';
import AdminList from '../Components/SuperAdmin/AdminList/AdminList';
import VendorRequest from '../Components/SuperAdmin/VendorRequest/VendorRequest';
import AddVendors from '../Components/SuperAdmin/AddVendors/AddVendors';
import RejectedVendors from '../Components/SuperAdmin/RejectedVendors/RejectedVendors';
import ViewVendorById from '../Components/SuperAdmin/ViewVendorById/ViewVendorById';


function SuperAdminRoute() {


    var token = localStorage.getItem('satoken')
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);


    useEffect(() => {
        const checkToken = async () => {
            console.log({ "superadmintoken": !!token })
            setLoggedIn(!!token);
            setTokenCheckComplete(true);
        };

        checkToken();
        // eslint-disable-next-line
    }, [token]);



    //If Token is not present Redirect to Login
    const PrivateRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? element : <Navigate to={`/superadmin/login?bypass=${window.location.pathname}`} />;
        } else {
            return null;
        }
    };


    const LoginRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? <Navigate to="/superadmin" /> : element;
        } else {
            return null;
        }
    };


    return (
        <Routes>

            <Route path="/login" element={<LoginRoute element={<LoginSuperAdmin />} />} />

            <Route path="/" element={<PrivateRoute element={<DashboardSuperAdmin />} />}>

                <Route path="/" element={<Navigate to="/superadmin/home" />} />
                <Route path="home" element={<HomeSuperAdmin />} />
                <Route path="vendorlist" element={<AdminList />} />
                <Route path="vendor-request" element={<VendorRequest />} />
                <Route path="addvendors" element={<AddVendors />} />
                <Route path="rejectedvendors" element={<RejectedVendors />} />
                <Route path="view-vendor-by-id" element={<ViewVendorById />} />






            </Route>
        </Routes>
    )
}

export default SuperAdminRoute