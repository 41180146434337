import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import nodataImage from "../../Images/Product-empty.png";
import loader from "../../Images/loader.gif";
import api from '../ApiConfigration/ApiConfigration';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SetRemoveRejectedVendors, setPendingVendorList, setPendingVendorListDetails } from '../../../Redux/superAdminReducer';
import toast, { Toaster } from 'react-hot-toast';

function VendorRequest() {

  let navigate = useNavigate()
  const dispatch = useDispatch();
  const limit = 10;
  const [istopPage, setIsTopPage] = useState(false);
  const [isClickedButton, setIsClickedButton] = useState([])
  const [isRevokeClickedButton, setIsRevokeClickedButton] = useState([])
  var page = useSelector(state => state?.superAdminData?.pendingVendorListDetails?.page);
  var hasmore = useSelector(state => state?.superAdminData?.pendingVendorListDetails?.hasmore);
  var vendorlistdata = useSelector(state => state?.superAdminData?.pendingVendorList);
  var noDataImageFlag = useSelector(state => state?.superAdminData?.pendingVendorListDetails?.noDataImageFlag);


  useEffect(() => {
    setIsTopPage(true)
    if (istopPage) {
      if (hasmore) {
        getVendorList()
      }
    }
  }, [istopPage])

  const getVendorList = async () => {
    await api.get(`/vendor/getpendingvendor-requestlist?offset=${page}&limit=${limit}`).then((res) => {
      if (res) {

        if (res?.data?.message?.message?.length < limit) {

          dispatch(setPendingVendorListDetails({ hasmore: false, noDataImageFlag: true }));


        } else {

          dispatch(setPendingVendorListDetails({ page: page + limit, hasmore: true, noDataImageFlag: true }));

        }


        const newData = res?.data?.message?.message;

        if (newData) {
          // Check for duplicates
          const existingIds = vendorlistdata?.map(item => item?.id);
          const firstItemId = newData[0]?.id;
          const lastItemId = newData[newData?.length - 1]?.id;

          if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
            // If no duplicates, add new items to existing data
            dispatch(setPendingVendorList([...vendorlistdata, ...newData]));
          }
        }


      } else {
        console.log("Error Fetching Data")
      }
    }).catch((err) => {
      console.log(err.message)
    })
  }



  const approveVendor = (id) => {
    setIsClickedButton(prevIds => [...prevIds, id]);

    api.get(`/vendor/approveVendor?id=${id}`).then((res) => {
      setIsClickedButton(prevIds => prevIds.filter(buttonId => buttonId !== id));

      if (res) {
        const updatedList = vendorlistdata.filter(vendor => vendor.id !== id);
        dispatch(setPendingVendorList(updatedList));
        toast.success("Vendor Approved")
      } else {
        toast.error("Error Approving Admin")
      }
    }).catch((err) => {
      setIsClickedButton(prevIds => prevIds.filter(buttonId => buttonId !== id));

      toast.error(err?.message)
    })

  }

  const rejectVendor = (id) => {
    setIsRevokeClickedButton(prevIds => [...prevIds, id]);

    api.get(`/vendor/revokevendorpermission?id=${id}`).then((res) => {
      setIsRevokeClickedButton(prevIds => prevIds.filter(buttonId => buttonId !== id));

      if (res) {
        const updatedList = vendorlistdata.filter(vendor => vendor.id !== id);
        dispatch(setPendingVendorList(updatedList));
        dispatch(SetRemoveRejectedVendors());
        toast.success("Vendor Rejected")
      } else {
        toast.error("Error Approving Admin")
      }
    }).catch((err) => {
      setIsRevokeClickedButton(prevIds => prevIds.filter(buttonId => buttonId !== id));

      toast.error(err?.message)
    })

  }


  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      <div className='d-flex justify-content-start'>
        <div className='bulkshare111 pt-1'>Vendor Requests</div>
      </div>

      <div className='listorder001'>

        <div className='d-flex'>
          <div className="search-container">
            <input type="text" placeholder="Search" className="search-input" />
            <button type="submit" class="search-button"><i class="search-icon fa fa-search"></i></button>
          </div>

          <div className='ms-4'>
            <button type='Submit' className='bulkshare112 listorder002'><div className='d-flex'><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
              <path d="M1 1H17" stroke="white" stroke-width="2" stroke-linecap="round" />
              <path d="M5 5H13" stroke="white" stroke-width="2" stroke-linecap="round" />
              <path d="M7 9H11" stroke="white" stroke-width="2" stroke-linecap="round" />
            </svg></span><span className='ms-2'>Filter</span></div></button>
          </div>


        </div>

        <div className='d-flex justify-content-between align-item-center mt-4 listorder008 customerlist001'>
          <div className='d-flex mt-1'>
            <div><input className='listorder003' type="checkbox" /></div>
            <div className='ms-3  '><p>Showing {vendorlistdata?.length} Vendor</p></div>
          </div>

        </div>
        <InfiniteScroll
          dataLength={vendorlistdata?.length}
          next={istopPage ? getVendorList : null}
          hasMore={hasmore}
          loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
        >

          {vendorlistdata.map((item, k) => (<div className='d-flex mt-4 listorder007'>
            <div><input className='listorder003' type="checkbox" /></div>
            <div className='ms-3 listorder010' >
              <div className='d-flex justify-content-between'>

                <div>
                  <div className='d-flex'>
                    <span className='listorder005 customerlist002 mx-1'>{item?.userName ? item?.userName : null}</span>
                    {item?.customer?.userName ? <span className='mx-1'>-</span> : null}
                    <span className='mx-1'>{item?.email ? item?.email : null}</span>
                  </div>
                  <div className='ms-1'>
                    Joined {moment(item?.createdAt).fromNow()}
                  </div>
                </div>
                <div className='d-flex'>


                  <div className='mx-2'>
                    {isRevokeClickedButton.includes(item?.id) ? (
                      <button class="btn btn-danger" type="button" disabled>
                        <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                        Rejecting...
                      </button>
                    ) : (
                      <button onClick={() => rejectVendor(item?.id)} className='btn btn-outline-danger px-4 ps-4'>
                          Reject   
                      </button>
                    )}
                  </div>

                  <div className='mx-2'>
                    {isClickedButton.includes(item?.id) ? (
                      <button class="btn btn-success" type="button" disabled>
                        <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                        Approving...
                      </button>
                    ) : (
                      <button onClick={() => approveVendor(item?.id)} className='btn btn-outline-success'>
                        Approve
                      </button>
                    )}
                  </div>
                </div>

              </div>




            </div>
          </div>))}

        </InfiniteScroll>

        {noDataImageFlag && vendorlistdata?.length === 0 ? <div
          style={{ paddingTop: "80px", gap: "18px", height: "330px" }}
          className="d-flex flex-column w-100 "
        >
          <div className="w-100 d-flex justify-content-center">
            <img src={nodataImage} alt="" />
          </div>
          <div className="d-flex justify-content-center w-100">
            <div className="d-flex flex-column">
              <h6 className="product-empty-bold-text p-0 m-0">
                No Vendor Request found
              </h6>
              <p className="product-empty-text">
                Try changing the filters or search term
              </p>
            </div>
          </div>

        </div> : null}

      </div>



    </div>
  )
}

export default VendorRequest