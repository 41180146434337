import React, { useEffect, useState } from 'react'
import "./BulkShareSetting.css"
import "../ListOrders/ListOrders.css"
import toast, { Toaster } from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import loader from "../../Images/loader.gif";
import api from '../ApiConfigration/ApiConfigration'
import moment from 'moment'
import noDataImage from "../../Images/Product-empty.png"


function SpinAndWinRedeem() {

  let navigate = useNavigate()
  const [claimData, setClaimData] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [isShowEmpty, setIsEmpty] = useState(false)
  const [page, setPage] = useState(0);
  const limit = 10;
  const [hasmore, setHasMore] = useState(true)
  const [istopPage, setIsTopPage] = useState(false)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const uid = queryParams.get('uid');


  useEffect(() => {
    setIsTopPage(true)
    if (istopPage === true) {
      if (uid) {
        GetIndividualUser()
      } else {
        getCouponClaimData()
      }
    }
  }, [istopPage])


  const GetIndividualUser = () => {
    api.get(`/campaigns/spinandwincouponlistbyid?id=${id}&uid=${uid}`).then((res) => {
      if (res) {
        setCampaignName(res?.data?.message?.message?.campaignDetails)
        setClaimData(res?.data?.message?.message?.coupons)
        setHasMore(false);
      }
    })
  }



  const getCouponClaimData = () => {

    api.get(`/campaigns/spinandwincouponlist?offset=${page}&imit=${limit}&id=${id}`).then((res) => {
      if (res) {
        setIsEmpty(true)
        setClaimData((prevData) => [...prevData, ...res?.data?.message?.message?.coupons])
        setCampaignName(res?.data?.message?.message?.campaignDetails)
        if (res?.data?.message?.message?.coupons?.length < limit) {
          setHasMore(false);
        } else {
          setPage(page + limit);
          setHasMore(true);
        }
      }
    }).catch((err) => {
      console.log(err?.message)
    })

  }

  const redeemCoupon = (itm) => {

    api.get(`/campaigns/claimbulksharecoupon?id=${itm?.id}`).then((res) => {
      if (res) {
        toast.success(res?.data?.message?.message)
        const updatedClaimData = claimData.map(item =>
          item.id === itm.id ? { ...item, redeemStatus: true } : item
        );
        setClaimData(updatedClaimData);
      } else {
        toast.error("Error")

      }
    }).catch((err) => {
      toast.error(err.messsage)
    })

  }

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div>
        <div className="d-flex">
          <div onClick={() => { navigate("/vendor/spinandwinlist") }} style={{ cursor: 'pointer' }} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z"
                stroke="#444444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397"
                stroke="#444444"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div className="d-flex ps-2 flex-column">
            <p className="top-small-heading p-0 m-0">Back to Spin And Win Listt</p>
            <div className="d-flex">
              <p className="bulkshare111 p-0 m-0">{campaignName}</p>
            </div>
          </div>
        </div>

        <div className='listorder001'>

          <div className='d-flex'>
            <div className="search-container">
              <input type="text" placeholder="Search..." className="search-input" />
              <button type="submit" class="search-button"><i class="search-icon fa fa-search"></i></button>
            </div>

            <div className='ms-4'>
              <button type='Submit' className='bulkshare112 listorder002'><div className='d-flex'><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                <path d="M1 1H17" stroke="white" stroke-width="2" stroke-linecap="round" />
                <path d="M5 5H13" stroke="white" stroke-width="2" stroke-linecap="round" />
                <path d="M7 9H11" stroke="white" stroke-width="2" stroke-linecap="round" />
              </svg></span><span className='ms-2'>Filter</span></div></button>
            </div>


          </div>

          <div className='d-flex mt-4 listorder008 '>
            <div><input className='listorder003' type="checkbox" /></div>
            <div className='ms-3 listorder004'><p>Showing {claimData?.length} items</p></div>
          </div>

          <InfiniteScroll
            dataLength={claimData?.length}
            next={istopPage ? getCouponClaimData : null}
            hasMore={hasmore}
            loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
          >
            {claimData?.map((itm, k) => (<div className='d-flex justify-content-between bulkshareSetting-002'>
              <div className='d-flex mt-4  '>
                <div><input className='listorder003' type="checkbox" /></div>
                <div className='ms-3 listorder010'  >
                  <div className='d-flex'>
                    <span style={{ textTransform: "capitalize" }} className='bulkshareSetting-005'>{itm?.users?.userName ? itm?.users?.userName?.toLowerCase() : null}</span>
                    {itm?.users?.userName ? <span className='mx-2 ms-2'> - </span> : null}
                    <span>{itm?.users?.phone_code ? itm?.users?.phone_code : "+91"}{itm?.users?.phoneNumber} </span>
                    <span className='mx-2 ms-2'> - </span>
                    <span style={{ textTransform: "uppercase" }} className='bulkshareSetting-004 '> {itm?.couponCode} </span>
                    <span className='mx-2 ms-2'> - </span>
                    <span style={{ textTransform: "uppercase" }} className='bulkshareSetting-004 '> {itm?.discountPercentage ? itm?.discountPercentage + "%" : null} </span>
                  </div>
                  <div className='d-flex mt-1 '>
                    <div className='bulkshareSetting-003 '>Claimed {moment(itm?.createdAt).fromNow()}</div>
                  </div>
                </div>
              </div>

              <div>
                {!itm?.redeemStatus ?
                  <div onClick={() => { redeemCoupon(itm) }} className='bulkshareSetting-001 mt-4'>Redeem</div> :
                  <div className=' mt-4 inactive2'>Redeemed</div>
                }
              </div>

            </div>))}
          </InfiniteScroll>

          {isShowEmpty && claimData?.length === 0 ? <div
            style={{ paddingTop: "80px", gap: "18px", height: "330px" }}
            className="d-flex flex-column w-100 "
          >
            <div className="w-100 d-flex justify-content-center">
              <img src={noDataImage} alt="" />
            </div>
            <div className="d-flex justify-content-center w-100">
              <div className="d-flex flex-column">
                <h6 className="product-empty-bold-text p-0 m-0">
                  No Data found
                </h6>
                <p className="product-empty-text">
                  Try changing the filters or search term
                </p>
              </div>
            </div>

          </div> : null}

        </div>
      </div>
    </div>
  )
}

export default SpinAndWinRedeem