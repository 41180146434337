import React, { useState } from 'react'
import "./CustomerHome.css"
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar'
import menu from "../../Images/Menu.png";
import profile from "../../Images/Profile.png";
import slider from "./Slider.json"

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import LocationAccess from './LocationAccess';


const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    loop: true,
    autoplay: true,
    smartSpeed: 300,
    responsive: {
        0: {
            items: 2,
        },
        400: {
            items: 2,
        },
        500: {
            items: 3,
        },
        800: {
            items: 4,
        },
        1100: {
            items: 6,
        }
    },
}



function CustomerHome() {


    const [locationpageview, setlocationpageview] = useState(false)


    const closepage = (i) => {
        setlocationpageview(!locationpageview)
    }



    return (
        <>
            {
                locationpageview && (
                    <LocationAccess closepage={closepage} />
                )
            }

            {!locationpageview && (
                <div className='claimofferhome'>

                    <div className='claimoffershare'>

                        <div className='navbarrr'>
                            <div className='d-flex'>


                                <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                                <div className='mt-4'>
                                    <div onClick={() => setlocationpageview(!locationpageview)} className='location_access'>
                                        <div className='ms-2 location_address'>Chengottukonam,Trivandrum,Kerala 695587, India</div>
                                        <div className='pt-1 ms-2'> <i class="fa-solid fa-angle-down "></i></div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='navheadbarr'>Claimed Offers</div> */}
                            <div><img src={profile} alt="" /></div>

                        </div>

                        {/* Slider to List Products */}

                        <div className='mt-5 px-5 ps-5 mx-2 ms-2'>
                            <div>
                                <h3 className='slider_heading'>
                                    What's on your mind?
                                </h3>
                            </div>
                            <OwlCarousel className='owl-theme' {...options}>
                                {slider.map((url, index) => (
                                    <div key={index} className='item'>

                                        <img style={{ width: "125px", height: "125px", borderRadius: "50%" }} src={url.url} alt={`Image ${index}`} />
                                    </div>
                                ))}
                            </OwlCarousel>

                        </div>

                        <div className='home_divider_line'>
                        </div>

                        {/* List Resturants */}

                        <div className='mt-5 pt-2 px-5 ps-5 mx-2 ms-2'>

                            <div>
                                <h3 className='slider_heading'>
                                    Top restaurant chains
                                </h3>
                            </div>

                            <div>


                                <div className='mb-5 hotel_card'>

                                    <img className='hotel_image' style={{ width: "200px", height: "125px", objectFit: "cover" }} src="https://media-assets.swiggy.com/swiggy/image/upload/fl_lossy,f_auto,q_auto,w_660/e0839ff574213e6f35b3899ebf1fc597" alt="" />
                                    <div className='hotel_details'>
                                        <div className='mt-3 hotel_name'>Pizza Hut</div>
                                        <div className='hotel_rating' >4.3</div>
                                        <div className='hotel_foods' >Indian, Korean, Chinese</div>
                                        <div className='hotel_location' >Trivandrum</div>
                                    </div>

                                </div>







                            </div>


                        </div>


                    </div>

                    <div className="offcanvas offcanvas-start cssidebar001" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                        <CustomerSidebar pageData={"sidebarData"} />
                    </div>

                </div>)}
        </>
    )
}

export default CustomerHome