import React, { useEffect, useState } from "react";
import "./Settings.css";
import BuyPremium from "../BuyPremium/BuyPremium";
import CurrencyList from "currency-list";
import timezones from "timezones-list";
import { Link } from "react-router-dom";
import arrowup from "../../Images/arrow-up.png";
import PasswordChange from "./PasswordChange";
import api from "../ApiConfigration/ApiConfigration";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { setRemoveVendor, setSettingDetails } from "../../../Redux/vendorReducer";
import axios from "axios";

var frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;


const timezone = timezones;

function Settings() {

  const dispatch = useDispatch();

  // console.log(timezones, "===");
  const currencys = Object.entries(CurrencyList.getAll("en_US"));
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    currency: "",
    currencyType: "",
    timezone: "",
    email: "",
    tax: "",
    address1: "",
    address2: "",
    landMark: "",
    postalCode: "",
  });
  const [changePassword, setPasswordChange] = useState(false);
  const [freezeButton, setFreezeButton] = useState(false)
  var settingDetails = useSelector(state => state?.vendorData?.settingDetails);

  const onInputHandle = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value.trim() });
  };

  useEffect(() => {
    setFormData(settingDetails)
  }, [settingDetails])


  useEffect(() => {

    async function getSettingsApi() {
      await api.get("/auth/vendorsettings").then((res) => {
        // setFormData(res?.data?.message?.data)
        dispatch(setSettingDetails({ settingDetails: res?.data?.message?.data }))
      }).catch((err) => {
        toast.error(err?.message)
      })
    }
    getSettingsApi()

  }, [])

  const updateSetting = async (e) => {
    e.preventDefault();
    setFreezeButton(true)
    await api.post("/auth/updatevendorsetting", formData).then((res) => {
      setFreezeButton(false)
      if (res) {
        toast.success("Settings Updated Successfully")
      } else {
        toast.error("Error Saving Settings")
      }
    }).catch((err) => {
      setFreezeButton(false)

      toast.error(err?.message)
    })

  }








  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      {changePassword ? <PasswordChange setShow={setPasswordChange} /> : ""}
      <div className="row">
        <div className="col-12 col-lg-8">
          <form onSubmit={
            updateSetting
          } style={{ gap: "32px" }} className="d-flex flex-column ">
            {/* Top heading */}
            <div className="d-flex justify-content-between">
              <div className="bulkshare111">Settings</div>
              <div>
                {freezeButton ?
                  <button class="btn btn-success" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                    Updating...
                  </button>
                  : <button type="Submit" className="bulkshare112">
                    Save
                  </button>}
              </div>
            </div>
            {/* LOGIN  */}
            <div className="setting-boxes">
              <h1 className="setting-box-heading">Login</h1>
              <div style={{ gap: "16px" }} className="d-flex flex-column w-100">
                {/* UserNmae */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Username
                  </label>
                  <input
                    onChange={onInputHandle}
                    value={formData?.userName}
                    name="userName"
                    type="text"
                    className="form-control setting-box-input"
                  />
                </div>
                {/* Password */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Password
                  </label>
                  <div className="settings-password-outer">
                    <svg
                      className="settings-password-eye"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M21.2714 9.68005C20.9814 9.22005 20.6714 8.79005 20.3514 8.39005C19.9814 7.92005 19.2814 7.88005 18.8614 8.30005L15.8614 11.3001C16.0814 11.9601 16.1214 12.7201 15.9214 13.5101C15.5714 14.9201 14.4314 16.0601 13.0214 16.4101C12.2314 16.6101 11.4714 16.5701 10.8114 16.3501C10.8114 16.3501 9.38141 17.7801 8.35141 18.8101C7.85141 19.3101 8.01141 20.1901 8.68141 20.4501C9.75141 20.8601 10.8614 21.0701 12.0014 21.0701C13.7814 21.0701 15.5114 20.5501 17.0914 19.5801C18.7014 18.5801 20.1514 17.1101 21.3214 15.24C22.2714 13.7301 22.2214 11.1901 21.2714 9.68005Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M14.0206 10.4799L9.98062 14.5199C9.47062 13.9999 9.14062 13.2799 9.14062 12.4999C9.14062 10.9299 10.4206 9.63989 12.0006 9.63989C12.7806 9.63989 13.5006 9.96989 14.0206 10.4799Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M18.25 6.24993L14.86 9.63993C14.13 8.89993 13.12 8.45993 12 8.45993C9.76 8.45993 7.96 10.2699 7.96 12.4999C7.96 13.6199 8.41 14.6299 9.14 15.3599L5.76 18.7499H5.75C4.64 17.8499 3.62 16.6999 2.75 15.3399C1.75 13.7699 1.75 11.2199 2.75 9.64993C3.91 7.82993 5.33 6.39993 6.91 5.41993C8.49 4.45993 10.22 3.92993 12 3.92993C14.23 3.92993 16.39 4.74993 18.25 6.24993Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M14.8581 12.5001C14.8581 14.0701 13.5781 15.3601 11.9981 15.3601C11.9381 15.3601 11.8881 15.3601 11.8281 15.3401L14.8381 12.3301C14.8581 12.3901 14.8581 12.4401 14.8581 12.5001Z"
                        fill="#2C2C2C"
                      />
                      <path
                        d="M21.7689 2.72988C21.4689 2.42988 20.9789 2.42988 20.6789 2.72988L2.22891 21.1899C1.92891 21.4899 1.92891 21.9799 2.22891 22.2799C2.37891 22.4199 2.56891 22.4999 2.76891 22.4999C2.96891 22.4999 3.15891 22.4199 3.30891 22.2699L21.7689 3.80988C22.0789 3.50988 22.0789 3.02988 21.7689 2.72988Z"
                        fill="#2C2C2C"
                      />
                    </svg>
                    <input
                      disabled
                      value={"***********"}
                      onChange={onInputHandle}
                      style={{ paddingLeft: "40px" }}
                      type="password"
                      name="password"
                      className="form-control setting-box-input"
                    />
                    <button
                      onClick={() => setPasswordChange(true)}
                      type="button"
                      className="password-change-btn"
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Account */}
            <div className="setting-boxes">
              <div
                style={{ gap: "16px" }}
                className="d-flex flex-column w-100 "
              >
                <h1 className="setting-box-heading"> Account</h1>
                {/* Currency */}
                <div className="">
                  <label htmlFor="Currency" className="setting-box-label">
                    Currency
                  </label>
                  <div className="dropdown d-flex flex-column  me-2">
                    <button
                      class="  setting-box-dropdown dropdown-toggle "
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <p style={{ height: "22px" }} className="p-0 m-0">
                        {formData?.currency == "" ? "Select" : formData?.currency}
                      </p>
                    </button>
                    <ul
                      style={{ height: "180px", overflowY: "scroll" }}
                      class="dropdown-menu dropdown-menu"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      {currencys?.map((item) => {
                        return (
                          <li
                            onClick={() => {
                              console.log(item[1]?.symbol);
                              setFormData({
                                ...formData,
                                currency: item[1]?.symbol,
                                currencyType: item[0]
                              });
                            }}

                            className="dropdown-item "
                          >
                            {item[0]}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* Timezone */}
                <div className="">
                  <label htmlFor="Currency" className="setting-box-label">
                    Timezone
                  </label>
                  <div className="dropdown d-flex flex-column  me-2">
                    <button
                      class="  setting-box-dropdown dropdown-toggle "
                      type="button"
                      id="dropdownMenuButton2"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <p style={{ height: "22px" }} className="p-0 m-0">
                        {formData?.timezone == "" ? "Select" : formData?.timezone}
                      </p>
                    </button>
                    <ul
                      class="dropdown-menu dropdown-menu"
                      aria-labelledby="dropdownMenuButton2"
                      style={{ height: "250px", overflowY: "auto" }}
                    >
                      {timezone?.map((item) => {
                        return <li onClick={() =>
                          setFormData({ ...formData, timezone: item })
                        } className="dropdown-item">{item?.label}</li>;
                      })}
                    </ul>
                  </div>
                </div>
                {/* Email */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Email
                  </label>
                  <input
                    disabled
                    value={formData?.email}
                    name="email"
                    onChange={onInputHandle}
                    type="email"
                    className="setting-box-input form-control"
                  />
                  <label className="setting-box-footer-label" htmlFor="">
                    Set your email for notifications and account recovery
                  </label>
                </div>
                {/* Tax */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Tax(%)
                  </label>
                  <input
                    value={formData?.tax}
                    name="tax"
                    onChange={onInputHandle}
                    type="number"
                    className="setting-box-input form-control"
                  />
                  <label className="setting-box-footer-label" htmlFor="">
                    Add sales tax in the order
                  </label>
                </div>
                {/*  */}
              </div>
            </div>
            {/*  */}
            <div className="setting-boxes">
              <div
                style={{ gap: "16px" }}
                className="d-flex flex-column w-100 "
              >
                <h1 className="setting-box-heading">Store location</h1>
                {/* Address */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Address
                  </label>
                  <input
                    disabled
                    value={formData?.address1}
                    name="address1"
                    onChange={onInputHandle}
                    type="text"
                    className="setting-box-input form-control"
                  />
                  <input
                    disabled
                    name="address2"
                    value={formData?.address2}
                    onChange={onInputHandle}
                    type="text"
                    className="setting-box-input form-control mt-1"
                  />
                </div>
                {/* land mark */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Landmark (optional)
                  </label>

                  <input
                    disabled
                    name="landMark"
                    value={formData?.landMark}
                    onChange={onInputHandle}
                    type="text"
                    className="setting-box-input form-control mt-1"
                  />
                </div>
                {/* Postal code */}
                <div className="d-flex flex-column w-100">
                  <label htmlFor="" className="setting-box-label">
                    Postal code
                  </label>
                  <input
                    disabled
                    value={formData?.postalCode}
                    name="postalCode"
                    onChange={onInputHandle}
                    type="text"
                    className="setting-box-input form-control mt-1"
                  />
                </div>
              </div>
            </div>
            {/* Generate Link */}
            <div className="bulkshare114">
              <div>
                <div class="mb-4">
                  <label for="exampleFormControlInput1" class="form-label">
                    Generate Link
                  </label>

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text copubutton221 copubutton224">
                        <button
                          type="button"
                          className="btn btn-link btn-sm copubutton224"
                          id="copyButton"
                        >
                          <i className="fas fa-link"></i>
                        </button>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control copubutton222"
                      value={`${frontendUrl}/customer/store/${formData?.url}`}
                      disabled
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-success copubutton223"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label for="exampleFormControlInput1" class="form-label">
                  Custom Link
                </label>
                <div className="bulkshare116">
                  <div>
                    Upgrade to <span className="bulkshare119">PRO</span>
                  </div>
                  <div className="bulkshare117">
                    <img className="bulkshare118" src={arrowup} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="col-12  col-lg-4 pt-3 ">
          <div className="d-flex flex-column pt-5 flex-grow-1">
            <BuyPremium />
          </div>

        </div>
      </div>
    </div>
  );
}

export default Settings;
