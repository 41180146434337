import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import "./AddProducts.css";
import axios from "axios";
import api from "../ApiConfigration/ApiConfigration";
import toast from "react-hot-toast";
import loader from "../../Images/loader.gif";
import { useDropzone } from 'react-dropzone'


export default function AddImageModal({ Images, SetImage, SetShow }) {


  var BASE_URL = process.env.REACT_APP_API_AWS_BASE_URL

  const imgRef = useRef(null);
  const [images, setImages] = useState([]);
  const [imageselected, setimageselection] = useState([])
  const [imageUplaoding, setImageUploading] = useState(false)
  const [ImageUplaodepercentage, setImageUplaodepercentage] = useState("")


  const onDrop = useCallback(async (acceptedFiles) => {
    try {
      setImageUploading(true);
      const totalImages = acceptedFiles.length;
      let totalProgress = 0;

      const uploadPromises = acceptedFiles?.map(async (file, index) => {
        try {
          const image = new Image();
          image.src = URL.createObjectURL(file);

          await new Promise((resolve, reject) => {
            image.onload = function () {
              if (image.width <= 600 || image.height <= 400) {
                reject(new Error(`Image dimensions of ${file.name} should be greater than 600x400`));
              } else {
                resolve();
              }
            };
            image.onerror = function () {
              reject(new Error(`Failed to load image ${file.name}.`));
            };
          });

          const fileNameWithoutExtension = file.name.split('.')[0];
          const signedUrlResponse = await api.get(`/auth/signedurl/file/${fileNameWithoutExtension}.jpeg?mime=image/jpeg`);
          const signedUrl = signedUrlResponse.data.url;

          const options = {
            headers: {
              'Content-Type': 'image/jpeg',
            },
            // onUploadProgress: progressEvent => {
            // const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            // setImageUplaodepercentage(`${file.name} - ${percentCompleted}% uploaded`);
            //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            //             uploadProgress[index] = percentCompleted; // Update upload progress for this image
            //             setImageUplaodepercentage(uploadProgress.join(', '));
            // }
            onUploadProgress: progressEvent => {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              totalProgress += percentCompleted; // Update total progress
              const averageProgress = Math.min(Math.round(totalProgress / totalImages), 100); // Calculate average progress capped at 100%
              setImageUplaodepercentage(averageProgress);
            }
          };

          // Upload image
          await axios.put(signedUrl, file, options);

          // Make file public
          await api.get(`/auth/makefilepublic?filename=${signedUrlResponse.data.pathName}`).catch((err) => {
            toast.error(err?.message)
          });


          setimageselection(prevImages => [...prevImages, BASE_URL + signedUrlResponse.data.pathName]);

          return BASE_URL + signedUrlResponse.data.pathName;
        } catch (error) {
          toast.error(error.message);
          return null; // Return null for failed uploads
        }
      });

      const uploadedImages = await Promise.all(uploadPromises);
      setImageUplaodepercentage('')
      setImageUploading(false);
      const successfulUploads = uploadedImages.filter(image => image !== null);
      if (successfulUploads.length > 0) {
        toast.success('Images uploaded successfully');
      } else {
        console.log('No images uploaded');
      }
    } catch (error) {
      setImageUploading(false);
      toast.error(error.message);
    }
  }, [imageselected]);



  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


  // const Addimage = async (event, type) => {
  //   event.preventDefault()
  //   console.log({ event, type })
  //   console.log(event?.dataTransfer?.files[0]?.name)
  //   try {
  //     console.log(event?.dataTransfer?.files[0]?.name)

  //     if (!event.target.files[0]) {
  //       toast.error("No image selected.");
  //       return;
  //     }

  //     setImageUploading(true)
  //     var dragImage = event?.dataTransfer?.files[0]?.name
  //     console.log({ dragImage: dragImage })

  //     var fullFileName = event?.target?.files[0]?.name ? event?.target?.files[0]?.name : dragImage;
  //     console.log(fullFileName)
  //     var fileNameWithoutExtension = fullFileName?.split('.')[0];

  //     console.log(fileNameWithoutExtension)
  //     const signedUrlResponse = await api.get(`/auth/signedurl/file/${fileNameWithoutExtension}.jpeg?mime=image/jpeg`);
  //     console.log({ signedUrlResponse: signedUrlResponse?.data?.url })
  //     const signedUrl = signedUrlResponse?.data?.url;

  //     const file = event?.target?.files[0]?.name ? event?.target?.files[0] : event?.dataTransfer.files[0];

  //     const image = new Image();
  //     image.src = URL.createObjectURL(file);

  //     image.onload = async function () {

  //       if (image.width < 1200 || image.height < 700) {
  //         toast.error("Image dimensions should be greater than 1200x700.");
  //         setImageUploading(false)
  //         return;
  //       }

  //       const options = {
  //         headers: {
  //           'Content-Type': "image/jpeg",
  //         },
  //       };
  //       await axios.put(signedUrl, file, options).then(async (res) => {
  //         if (res) {
  //           await api.get(`/auth/makefilepublic?filename=${signedUrlResponse.data.pathName}`)
  //             .then((res) => {

  //               setimageselection([...imageselected, BASE_URL + signedUrlResponse.data.pathName]);
  //               setImageUploading(false)
  //               toast.success('Image uploaded')
  //             })
  //             .catch((err) => {
  //               setImageUploading(false)
  //               toast.err("Error in Image Upload")
  //             })
  //         }
  //       }).catch((err) => {
  //         setImageUploading(false)
  //         toast.error(err.message)
  //       });
  //     }
  //     image.onerror = function () {
  //       setImageUploading(false)
  //       toast.error("Failed to load image.");
  //     };
  //   } catch (err) {
  //     toast.error(err.message)
  //     console.log(err.message);
  //     setImageUploading(false)

  //   }
  // };

  const saveImagestoCollection = async () => {
    setImages([...images, ...imageselected]);

  }

  useEffect(() => {
    SetImage(images)
  }, [images])
  // console.log(images);
  const onHandleSubmit = (e) => {
    e.preventDefault()
    SetShow(false)
  }
  return (
    <div className="image-modal-bg">
      <form onSubmit={onHandleSubmit} action="">
        <motion.div
          className="add-image-modal"
          initial={{ y: -90, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
        >
          <div className="add-image-head">

            <h1 className="bulkshare111">Product Images</h1>
            <div className="d-flex align-items-center">
              <button
                onClick={() => SetShow(false)}
                className="add-product-btn-text"
                type="button"
              >
                Cancel
              </button>
              <button onClick={saveImagestoCollection} className="bulkshare112" type="submit">
                Select
              </button>
            </div>
          </div>

          {!imageUplaoding ? <div
            {...getRootProps()}
            // onDrop={(e) => Addimage(e, "drop")}
            // onDragOver={(e) => e.preventDefault()}
            onClick={() => imgRef.current.click()}
            className="add-image-body "
          >
            <input
              // onChange={(e) => Addimage(e, "upload")}
              {...getInputProps()}
              accept="image/*"
              ref={imgRef}
              className="img-input"
              type="file"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                d="M34.9503 13.3332H30.0503C27.9337 13.3332 26.667 12.0665 26.667 9.94984V5.04984C26.667 2.93317 27.9337 1.6665 30.0503 1.6665H34.9503C37.067 1.6665 38.3337 2.93317 38.3337 5.04984V9.94984C38.3337 12.0665 37.067 13.3332 34.9503 13.3332ZM35.317 7.8165C35.117 7.6165 34.8503 7.5165 34.5837 7.5165C34.317 7.5165 34.0503 7.6165 33.8503 7.8165L33.5503 8.1165V4.38317C33.5503 3.79984 33.0837 3.33317 32.5003 3.33317C31.917 3.33317 31.4503 3.79984 31.4503 4.38317V8.1165L31.1503 7.8165C30.7503 7.4165 30.0837 7.4165 29.6837 7.8165C29.2837 8.2165 29.2837 8.88317 29.6837 9.28317L31.767 11.3665C31.8503 11.4498 31.967 11.5165 32.0837 11.5665C32.117 11.5832 32.1503 11.5832 32.1837 11.5998C32.267 11.6332 32.3503 11.6498 32.4503 11.6498C32.4837 11.6498 32.517 11.6498 32.5503 11.6498C32.667 11.6498 32.767 11.6332 32.8837 11.5832C32.9003 11.5832 32.9003 11.5832 32.917 11.5832C33.0337 11.5332 33.1337 11.4665 33.217 11.3832C33.2337 11.3665 33.2337 11.3665 33.2503 11.3665L35.3337 9.28317C35.7337 8.88317 35.7337 8.2165 35.317 7.8165Z"
                fill="#62C4A4"
              />
              <path
                d="M14.9999 17.3C17.1906 17.3 18.9665 15.5241 18.9665 13.3334C18.9665 11.1426 17.1906 9.3667 14.9999 9.3667C12.8091 9.3667 11.0332 11.1426 11.0332 13.3334C11.0332 15.5241 12.8091 17.3 14.9999 17.3Z"
                fill="#62C4A4"
              />
              <path
                d="M34.9497 13.3335H34.1663V21.0168L33.9497 20.8335C32.6497 19.7168 30.5497 19.7168 29.2497 20.8335L22.3163 26.7835C21.0163 27.9002 18.9163 27.9002 17.6163 26.7835L17.0497 26.3168C15.8663 25.2835 13.983 25.1835 12.6497 26.0835L6.41634 30.2668C6.04967 29.3335 5.83301 28.2502 5.83301 26.9835V13.0168C5.83301 8.31683 8.31634 5.8335 13.0163 5.8335H26.6663V5.05016C26.6663 4.3835 26.783 3.81683 27.0497 3.3335H13.0163C6.94967 3.3335 3.33301 6.95016 3.33301 13.0168V26.9835C3.33301 28.8002 3.64967 30.3835 4.26634 31.7168C5.69967 34.8835 8.76634 36.6668 13.0163 36.6668H26.983C33.0497 36.6668 36.6663 33.0502 36.6663 26.9835V12.9502C36.183 13.2168 35.6163 13.3335 34.9497 13.3335Z"
                fill="#62C4A4"
              />
            </svg>
            <button className="add-image-btn" type="button">Upload Image</button>
            <p>or drop images to upload</p>
            <p style={{ fontSize: "12px", color: "red" }}>*Upload images of resolution greater than 600*400 </p>
          </div> : <div className="add-image-body ">

            <img style={{ width: "140px", height: "100px" }} src={loader} alt="" />
            <p style={{ fontSize: "14px" }}>Uploading Please Wait....</p>
            <p style={{ fontSize: "14px" }}>{ImageUplaodepercentage === 100 ? "Processing Image..." : `Uploading Status; ${ImageUplaodepercentage}%`}</p>
          </div>}
          <div style={{ float: "left" }} className=" w-100 ">
            {imageselected.map((item) => {
              return (
                <img
                  style={{ float: "left", marginRight: "16px", marginBottom: '16px' }}
                  src={item}
                  className="add-product-img-size "
                  alt=""
                />
              );
            })}
          </div>
        </motion.div>
      </form>
    </div>
  );
}
