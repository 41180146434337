import React from 'react'
import "./CustomerDashboard.css"
import { Outlet } from 'react-router-dom'

function CustomerDashboard() {
  return (
    <div className='customerDashboard001'>
        <Outlet/>
    </div>
  )
}

export default CustomerDashboard