import React, { useEffect, useState } from 'react';
import "./AddStore.css"
import toast, { Toaster } from 'react-hot-toast';
import api from '../ApiConfigration/ApiConfigration';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux';
import { setStoreDetails } from '../../../Redux/vendorReducer';
import 'react-phone-number-input/style.css';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


function AddStore() {

    let BASE_URL = process.env.REACT_APP_API_AWS_BASE_URL
    let navigate = useNavigate();
    let dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const storeId = queryParams.get('storeid');

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagepath, setSelectedImagepath] = useState(null);
    const [isfreeze, setFreeze] = useState(false);
    // var storeData = useSelector(state => state?.vendorData?.storeDetails);

    const [activeButtons, setActiveButtons] = useState({
        Monday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Tuesday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Wednesday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Thursday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Friday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Saturday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
        Sunday: {
            status: false,
            workingHourStart: '',
            workingHourEnd: '',
            workingMinuteStart: '',
            workingMinuteEnd: ''
        },
    });


    const [formData, setFormData] = useState({
        storeName: '',
        whatsappNumber: '',
        contactNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        workingHourStart: '',
        workingHourEnd: '',
        workingMinuteStart: '',
        workingMinuteEnd: '',
        maplink: ''
    });


    const [formErrors, setFormErrors] = useState({
        storeName: false,
        whatsappNumber: false,
        contactNumber: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        zipcode: false,
        workingHourStart: false,
        workingHourEnd: false,
        workingMinuteStart: false,
        workingMinuteEnd: false
    });

    const [formErrorsMessages, setFormErrorsMessage] = useState({
        storeName: "",
        whatsappNumber: "",
        contactNumber: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipcode: "",
        workingHourStart: "",
        workingHourEnd: "",
        workingMinuteStart: "",
        workingMinuteEnd: ""
    });

    const handleDayToggle = (day) => {
        console.log(day)
        setActiveButtons(prevState => ({
            ...prevState,
            [day]: {
                ...prevState[day],
                status: !(prevState[day].status),
            },
        }));
    };


    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {

            try {
                const signedUrlResponse = await api.get(`/auth/signedurl/store/${event.target.name}.jpeg?mime=image/jpeg`);
                console.log({ signedUrlResponse: signedUrlResponse.data.url })
                const signedUrl = signedUrlResponse.data.url;

                const file = event.target.files[0];
                const options = {
                    headers: {
                        'Content-Type': "image/jpeg",
                    },
                };
                await axios.put(signedUrl, file, options).then(async (res) => {
                    if (res) {
                        await api.get(`/auth/makefilepublic?filename=${signedUrlResponse.data.pathName}`)
                            .then((res) => {
                                setSelectedImage(BASE_URL + signedUrlResponse.data.pathName)
                                setSelectedImagepath(signedUrlResponse.data.pathName)
                                toast.success('Image uploaded')
                            })
                            .catch((err) => {
                                toast.err("Error in Image Upload")
                            })
                    }
                }).catch((err) => {
                    toast.error(err.message)
                });


            } catch (error) {
                toast.err(error.message)

            }

        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setSelectedImagepath(null);
    };


    const handleInputChange = async (event) => {
        const { target } = event;
        const { name, value, type, checked } = target;


        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


        if (name === "workingHourStart" || "workingHourEnd" || "workingMinuteStart" || "workingMinuteEnd") {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                "workingHourStart": false,
            }));
        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

    };

    const handleInputChange2 = async (event, day, field) => {
        console.log(event)
        console.log(day)
        setActiveButtons((prevData) => ({
            ...prevData,
            [day]: {
                ...prevData[day],
                [field]: event.target.value
            }
        }));
    }


    const validateHours = (value) => {
        const intValue = parseInt(value);
        return intValue >= 0 && intValue <= 23;
    }

    const validateMinutes = (value) => {
        const intValue = parseInt(value);
        return intValue >= 0 && intValue <= 59;
    }

    const isValidPhoneNumber = (phoneNumber) => {
        try {
            const parsedPhoneNumber = phoneUtil.parse(phoneNumber);
            var isCorrect = phoneUtil.isValidNumber(parsedPhoneNumber);
            console.log(isCorrect)
            return isCorrect
        } catch (e) {
            toast.error(e.message)
            return false;
        }
    };

    const SaveStoreDetails = async (e) => {
        e.preventDefault();

        console.log({ formData: formData })

        if (!formData.storeName.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                storeName: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                storeName: "This field cannont be empty",
            }))
            return;
        }

        if (!formData.whatsappNumber) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                whatsappNumber: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                whatsappNumber: "This field is required",
            }))
            return;
        }

        if (formData?.whatsappNumber) {
            var isTrue = isValidPhoneNumber(`+${formData?.whatsappNumberCountryCode}${formData?.whatsappNumber}`)
            if (!isTrue) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: true,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: "Phone Number Error",
                }))
                return;
            }
        }


        if (!formData.contactNumber) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                contactNumber: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                contactNumber: "This field is required",
            }))
            return;
        }



        if (formData?.contactNumber) {
            var isTrue = isValidPhoneNumber(`+${formData?.contactNumberCountryCode}${formData?.contactNumber}`)
            console.log(isTrue)
            if (!isTrue) {
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: true,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: "Phone Number Error",
                }))
                return;
            }
        }

        if (!formData?.address1) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                address1: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                address1: "Address is Required",
            }))
            return;
        }
        if (!formData?.city) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                city: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                city: "City is Required",
            }))
            return;
        }

        // if (!formData?.zipcode) {
        //     setFormErrors((prevErrors) => ({
        //         ...prevErrors,
        //         zipcode: true,
        //     }))
        //     setFormErrorsMessage((prevErrors) => ({
        //         ...prevErrors,
        //         zipcode: "Pincode is Required",
        //     }))
        //     return;
        // }



        if (!formData.workingHourStart || !formData.workingHourEnd || !formData.workingMinuteStart || !formData.workingMinuteEnd) {

            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workingHourStart: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                workingHourStart: "Working time field cannont be empty",
            }))
            return;
        }

        if (!validateHours(formData.workingHourStart) || !validateMinutes(formData.workingMinuteStart) ||
            !validateHours(formData.workingHourEnd) || !validateMinutes(formData.workingMinuteEnd)) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                workingHourStart: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                workingHourStart: "Please enter valid values for hours (0-23) and minutes (0-59).",
            }))
            return
        }

        if (formData.workingHourStart && formData.workingHourEnd && formData.workingMinuteStart && formData.workingMinuteEnd) {

            const fromTime = parseInt(formData.workingHourStart) * 60 + parseInt(formData.workingMinuteStart);
            const toTime = parseInt(formData.workingHourEnd) * 60 + parseInt(formData.workingMinuteEnd);

            if (fromTime >= toTime) {

                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    workingHourStart: true,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    workingHourStart: "End time should be greater than start time",
                }))
                return false;
            }
        }


        for (let day in activeButtons) {
            activeButtons[day].workingHourStart = formData.workingHourStart;
            activeButtons[day].workingHourEnd = formData.workingHourEnd;
            activeButtons[day].workingMinuteStart = formData.workingMinuteStart;
            activeButtons[day].workingMinuteEnd = formData.workingMinuteEnd;
        }

        formData.storeImage = selectedImagepath
        formData.workDays = activeButtons

        console.log({ data: { formdata: formData } })
        setFreeze(true)
        await api.post("/store/createstore", formData).then((res) => {
            if (res?.data?.message?.code === "Success") {
                toast.success(res?.data?.message?.message)
                setSelectedImage(null)
                setSelectedImagepath(null)
                setActiveButtons({
                    Monday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Tuesday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Wednesday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Thursday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Friday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Saturday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                    Sunday: {
                        status: false,
                        workingHourStart: '',
                        workingHourEnd: '',
                        workingMinuteStart: '',
                        workingMinuteEnd: ''
                    },
                })
                setFormData({
                    storeName: '',
                    whatsappNumber: '',
                    contactNumber: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    workingHourStart: '',
                    workingHourEnd: '',
                    workingMinuteStart: '',
                    workingMinuteEnd: ''
                })

                navigate("/vendor/viewstore")

            } else {
                toast.error("Error in Creating Store! Try again.")

                setFreeze(false)
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
            setFreeze(false)
        })


    }

    const UpdateStoreDetails = async (e) => {
        e.preventDefault()

        var updatevalues = formData;
        updatevalues.storeImage = selectedImagepath
        updatevalues.workDays = activeButtons
        setFreeze(true)
        // console.log({undated:updatevalues})
        // return;
        await api.post(`/store/updatestorebyid?id=${storeId}`, updatevalues).then(async (res) => {
            if (res) {

                toast.success(res?.data?.message?.message)

                const delayTime = 1000;
                await new Promise((resolve) => setTimeout(resolve, delayTime));
                navigate("/vendor/viewstore")

            } else {
                toast.error("Error in Saving Store")
                setFreeze(false)

            }
        }).catch((err) => {
            setFreeze(false)
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
        })


    }

    // useEffect(() => {
    //     setFormData(storeData)
    // }, [storeData])

    useEffect(() => {

        if (storeId) {

            async function getstoredata() {

                await api.get(`/store/getStoreById?id=${storeId}`).then((res) => {
                    if (res) {
                        console.log(res?.data?.message?.message);
                        const vendorData = res?.data?.message?.message
                        // dispatch(setStoreDetails(vendorData))
                        setFormData(res?.data?.message?.message)
                        setActiveButtons(res?.data?.message?.message?.workDays)
                        setSelectedImage(BASE_URL + res?.data?.message?.message?.storeImage)
                        setSelectedImagepath(res?.data?.message?.message?.storeImage)

                    }
                })
                    .catch((err) => {

                        {
                            !err?.response?.data?.message ?
                                toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message) :
                                toast.error(err?.response?.data?.message)
                        }
                    })

            }
            getstoredata();
        }

    }, [])

    const handlePhoneNumberChange = (value, type) => {
        // console.log({ value, type })
        if (value) {
            value = formatPhoneNumberIntl(value)
            const phoneNumberParts = value.split(' ');
            const countryCode = phoneNumberParts[0].replace('+', ''); // Remove the '+' sign
            const phoneNumber = phoneNumberParts.slice(1).join('').replace(/[^0-9]/g, ''); // Extract digits only

            console.log({ phoneNumberParts, countryCode, phoneNumber })


            if (type === 'whatsapp') {
                setFormData({
                    ...formData,
                    whatsappNumber: phoneNumber,
                    whatsappNumberCountryCode: countryCode
                });

                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: false,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: "",
                }))
            } else if (type === 'contact') {
                setFormData({
                    ...formData,
                    contactNumber: phoneNumber,
                    contactNumberCountryCode: countryCode
                });

                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: false,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: "",
                }))
            }

        } else {



            if (type === 'whatsapp') {
                setFormData({
                    ...formData,
                    whatsappNumber: '',
                    whatsappNumberCountryCode: ''
                });
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: false,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    whatsappNumber: "",
                }))
            } else if (type === 'contact') {
                setFormData({
                    ...formData,
                    contactNumber: '',
                    contactNumberCountryCode: ''
                });
                setFormErrors((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: false,
                }))
                setFormErrorsMessage((prevErrors) => ({
                    ...prevErrors,
                    contactNumber: "",
                }))
            }

        }
    };



    return (
        <div>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <form onSubmit={!storeId ? SaveStoreDetails : UpdateStoreDetails}>
                <div className='d-flex justify-content-between'>
                    {!storeId ? <div className='createstore111'>Add Your Store Details</div> : <div >
                        <div className="d-flex">
                            <div style={{ cursor: 'pointer' }} onClick={() => { navigate("/vendor/viewstore") }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                >
                                    <path
                                        d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z"
                                        stroke="#444444"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397"
                                        stroke="#444444"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </div>
                            <div className="d-flex ps-2 flex-column">
                                <p className="top-small-heading p-0 m-0">Back to Store</p>
                                <div className="d-flex">
                                    <p className="bulkshare111 p-0 m-0">Edit Store</p>
                                </div>
                            </div>
                        </div>

                    </div>}
                    <div> {!storeId ? <span className='createstore125'>Get a unique store link for your business.</span> :
                        <span />}{!storeId ?
                            <>{!isfreeze ? <button type='Submit' className='createstore112'>Add Store</button> :
                                <button class="btn btn-success" type="button" disabled>
                                    <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                    Creating...
                                </button>}</> :
                            <>{isfreeze ? <button class="btn btn-success" type="button" disabled>
                                <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                                Updating...
                            </button> : <button type='Submit' className='createstore112'>Save</button>}</>}</div>
                </div>

                <div className='row mt-4'>
                    <div className='col-12 col-lg-6 createstore114' >


                        <div className='createstore115 d-flex justify-content-start'>

                            <div>

                                <div class="createstore122">
                                    {!selectedImage && (<><input type="file" id="fileInput" onChange={handleImageChange} style={{ display: 'none' }} />
                                        <label for="fileInput" class="plus-sign">+</label></>)}
                                    {selectedImage && (<>
                                        <img
                                            src={selectedImage}
                                            alt="Selected"
                                            style={{ maxWidth: '350px', maxHeight: '350px' }}
                                        />
                                        <button className='createstore132' onClick={handleRemoveImage}><i class="fa-regular fa-circle-xmark"></i></button>
                                    </>
                                    )}
                                </div>

                            </div>

                            <div className='ms-4 createstore124'>

                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label createstore126">Store Name</label>
                                    <input
                                        type="text"
                                        name='storeName'
                                        value={formData.storeName}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        id="exampleFormControlInput1" />
                                    {formErrors.storeName && <div className='text-danger pt-1'>{formErrorsMessages.storeName}</div>}

                                </div>

                                {/* <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label createstore126 ">Whatsapp</label>
                                    <input
                                        type="number"
                                        name='whatsappNumber'
                                        value={formData.whatsappNumber}
                                        onChange={handleInputChange}
                                        className="form-control no-spinner"
                                        id="exampleFormControlInput1" />
                                    {formErrors.whatsappNumber && <div className='text-danger pt-1'>{formErrorsMessages.whatsappNumber}</div>}

                                </div> */}

                                {/* <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label createstore126 ">Contact Number</label>
                                    <input type="number"
                                        name='contactNumber'
                                        value={formData.contactNumber}
                                        onChange={handleInputChange}
                                        className="form-control no-spinner"
                                        id="exampleFormControlInput1" />
                                    {formErrors.contactNumber && <div className='text-danger pt-1'>{formErrorsMessages.contactNumber}</div>}

                                </div> */}

                                <div className="mb-3">
                                    <label htmlFor="whatsappInput" className="form-label createstore126">Whatsapp</label>
                                    <PhoneInput
                                        international
                                        value={formData?.whatsappNumber ? `+${formData?.whatsappNumberCountryCode}${formData?.whatsappNumber}` : ''}
                                        onChange={(value) => handlePhoneNumberChange(value, 'whatsapp')}
                                        inputProps={{
                                            name: 'whatsappNumber',
                                            id: 'whatsappInput'
                                        }}
                                        className="form-control"
                                        style={{ border: 'none' }}

                                    />
                                    {formErrors?.whatsappNumber && <div className='text-danger pt-1'>{formErrorsMessages?.whatsappNumber}</div>}
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="contactInput" className="form-label createstore126">Contact Number</label>
                                    <PhoneInput
                                        international
                                        value={formData?.contactNumber ? `+${formData?.contactNumberCountryCode}${formData?.contactNumber}` : ''}
                                        onChange={(value) => handlePhoneNumberChange(value, 'contact')}
                                        inputProps={{
                                            name: 'contactNumber',
                                            id: 'contactInput',
                                        }}
                                        style={{ border: 'none' }}
                                        className="form-control"
                                    />
                                    {formErrors?.contactNumber && <div className='text-danger pt-1'>{formErrorsMessages?.contactNumber}</div>}
                                </div>



                            </div>

                        </div>

                        <div className='createstore117 mb-4'>

                            <div className='createstore118'>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label createstore126">Address</label>
                                    <input type="text"
                                        name='address1'
                                        value={formData.address1}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        id="exampleFormControlInput1" />
                                    {formErrors.address1 && <div className='text-danger pt-1'>{formErrorsMessages.address1}</div>}

                                    <input
                                        type="text"
                                        name='address2'
                                        value={formData.address2}
                                        onChange={handleInputChange}
                                        className="form-control mt-2"
                                        id="exampleFormControlInput1" />


                                </div>


                                <div className='d-flex'>

                                    <div className="m-2">
                                        <label for="exampleFormControlInput1" className="form-label createstore126">City</label>
                                        <input
                                            type="text"
                                            name='city'
                                            value={formData.city}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            id="exampleFormControlInput1" />
                                        {formErrors.city && <div className='text-danger pt-1'>{formErrorsMessages.city}</div>}

                                    </div>
                                    <div className="m-2">
                                        <label for="exampleFormControlInput1" className="form-label createstore126">State</label>
                                        <input type="select"
                                            name='state'
                                            value={formData.state}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            id="exampleFormControlInput1" />

                                    </div>
                                    <div className="m-2">
                                        <label for="exampleFormControlInput1" className="form-label createstore126">Zip Code</label>
                                        <input type="number"
                                            name='zipcode'
                                            value={formData.zipcode}
                                            onChange={handleInputChange}
                                            className="form-control no-spinner"
                                            id="exampleFormControlInput1" />
                                        {formErrors.zipcode && <div className='text-danger pt-1'>{formErrorsMessages.zipcode}</div>}

                                    </div>
                                </div>


                                <div className="mb-3 mt-3 p-1">
                                    <label for="exampleFormControlInput1" className="form-label createstore126">Map Link</label>
                                    <input type="text"
                                        name='maplink'
                                        value={formData.maplink}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        id="exampleFormControlInput1" />


                                </div>

                            </div>


                        </div>





                    </div>

                    <div className='col-12 col-lg-6 createstore113'>

                        <div className='createstore116'>


                            <p className='createstore126'>Set Working Hours</p>
                            {!storeId ? <span>
                                <div className='d-flex '>
                                    <div className='m-2 createstore131'><p>From</p></div>
                                    <div class="time-input m-2 d-flex">
                                        <input
                                            className='createstore130'
                                            name='workingHourStart'
                                            value={formData.workingHourStart}
                                            onChange={handleInputChange}
                                            type="number"
                                            id="hours"
                                            placeholder="HH"
                                        />
                                        <div className='mx-1'> </div>
                                        <input
                                            className='createstore130'
                                            type="number"
                                            id="minutes"
                                            name='workingMinuteStart'
                                            value={formData.workingMinuteStart}
                                            onChange={handleInputChange}
                                            placeholder="MM"
                                        />
                                    </div>
                                    <div className='m-2'>
                                        <p className='createstore131'>to</p>
                                    </div>
                                    <div class="time-input m-2 d-flex">
                                        <input
                                            className='createstore130'
                                            type="number"
                                            name='workingHourEnd'
                                            value={formData.workingHourEnd}
                                            onChange={handleInputChange}
                                            id="hours"
                                            placeholder="HH"
                                        />
                                        <div className='mx-1'> </div>
                                        <input
                                            className='createstore130'
                                            type="number"
                                            name='workingMinuteEnd'
                                            value={formData.workingMinuteEnd}
                                            onChange={handleInputChange}
                                            id="minutes"
                                            placeholder="MM"
                                        />
                                    </div>

                                </div>

                                {formErrors.workingHourStart && <div className='text-danger mb-2'>{formErrorsMessages.workingHourStart}</div>}
                            </span>

                                : <div />}
                            <div>

                                <table className="table table-borderless createstore120">
                                    <tr>
                                        <td className='createstore126'>Monday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Monday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Monday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Monday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}

                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons?.Monday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Monday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Monday'].status}
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons?.Monday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Monday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Monday'].status}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        name='workingHourEnd'
                                                        value={activeButtons?.Monday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Monday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Monday'].status}
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Monday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Monday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Monday'].status}
                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>


                                    <tr>
                                        <td className='createstore126'>Tuesday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Tuesday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Tuesday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Tuesday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons.Tuesday.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Tuesday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Tuesday'].status}
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons.Tuesday.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Tuesday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Tuesday'].status}

                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"

                                                        value={activeButtons.Tuesday.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Tuesday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Tuesday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"

                                                        value={activeButtons.Tuesday.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Tuesday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Tuesday'].status}

                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>

                                    <tr>
                                        <td className='createstore126'>Wednesday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Wednesday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Wednesday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Wednesday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'

                                                        value={activeButtons?.Wednesday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Wednesday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Wednesday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons?.Wednesday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Wednesday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Wednesday'].status}

                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"

                                                        value={activeButtons?.Wednesday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Wednesday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Wednesday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"

                                                        value={activeButtons?.Wednesday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Wednesday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Wednesday'].status}

                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>

                                    <tr>
                                        <td className='createstore126'>Thursday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Thursday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Thursday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Thursday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons?.Thursday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Thursday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Thursday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons?.Thursday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Thursday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Thursday'].status}

                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Thursday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Thursday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Thursday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Thursday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Tuesday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Thursday'].status}

                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>

                                    <tr>
                                        <td className='createstore126'>Friday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Friday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Friday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Friday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons?.Friday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Friday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Friday'].status}
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"

                                                        value={activeButtons?.Friday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Friday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Friday'].status}

                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Friday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Friday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Friday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Friday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Friday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Friday'].status}

                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>



                                    <tr>
                                        <td className='createstore126'>Saturday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Saturday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Saturday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Saturday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons?.Saturday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Saturday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Saturday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons?.Saturday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Saturday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Saturday'].status}

                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Saturday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Saturday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Saturday'].status}

                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Saturday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Saturday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Saturday'].status}

                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>


                                    <tr>
                                        <td className='createstore126'>Sunday</td>
                                        <td className='createstore119'>
                                            <div className='d-flex'>
                                                <svg
                                                    onClick={() => handleDayToggle('Sunday')}
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="32"
                                                    height="25"
                                                    viewBox="0 0 32 25"
                                                    fill="none"
                                                >
                                                    {activeButtons['Sunday'].status ? (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#26735A"
                                                            />
                                                            <rect x="16.8335" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    ) : (
                                                        <>
                                                            <path
                                                                d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                                                                fill="#828785"
                                                            />
                                                            <rect x="5.16382" y="7.5" width="10" height="10" rx="5" fill="white" />
                                                        </>
                                                    )}
                                                </svg>

                                                {activeButtons['Sunday'].status ? <span className='ms-2'>Open</span> : <span className='ms-2'>Close</span>}
                                            </div>
                                        </td>
                                        {storeId ? <td>

                                            <div className='d-flex mt-2 workhourssection'>
                                                <div class=" d-flex">
                                                    <input
                                                        className='createstore130'
                                                        value={activeButtons?.Sunday?.workingHourStart}
                                                        onChange={(e) => handleInputChange2(e, 'Sunday', "workingHourStart")}
                                                        type="number"
                                                        id="hours"
                                                        disabled={!activeButtons['Sunday'].status}
                                                        placeholder="HH"
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        id="minutes"
                                                        value={activeButtons?.Sunday?.workingMinuteStart}
                                                        onChange={(e) => handleInputChange2(e, 'Sunday', "workingMinuteStart")}
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Sunday'].status}
                                                    />
                                                </div>
                                                <div className=''>
                                                    <p className='createstore131 mx-2 ms-2'>to</p>
                                                </div>
                                                <div class="time-input d-flex">
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Sunday?.workingHourEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Sunday', "workingHourEnd")}
                                                        id="hours"
                                                        placeholder="HH"
                                                        disabled={!activeButtons['Sunday'].status}
                                                    />
                                                    <div className='mx-1'> </div>
                                                    <input
                                                        className='createstore130'
                                                        type="number"
                                                        value={activeButtons?.Sunday?.workingMinuteEnd}
                                                        onChange={(e) => handleInputChange2(e, 'Sunday', "workingMinuteEnd")}
                                                        id="minutes"
                                                        placeholder="MM"
                                                        disabled={!activeButtons['Sunday'].status}
                                                    />
                                                </div>

                                            </div>




                                        </td> : null}
                                    </tr>


                                </table>

                            </div>



                        </div>

                    </div>
                </div>
            </form >
        </div >
    )
}

export default AddStore