import axios from 'axios';
import toast from 'react-hot-toast';

var baseURL = process.env.REACT_APP_API_URL_VENDOR

const api = axios.create({
    baseURL: baseURL,
});


const refreshToken = async () => {
    try {

        var refreshToken = localStorage.getItem('vrtoken');
        const response = await axios.post(`${baseURL}/auth/getnewtokenvendor`, {
            "refreshToken": refreshToken
        });

        const newToken = response.data.accessToken;
        localStorage.setItem('vtoken', newToken);

        return newToken;
    } catch (err) {

        console.log(err?.response?.data?.errors?.body[1])
        var errordata = err?.response?.data?.errors?.body[1]
        if (errordata === "invalid token" || "No user found") {

            toast.error("Login again!")
            localStorage.removeItem("vtoken")
            localStorage.removeItem("vrtoken")
            window.location.replace("/vendor/login")

        }
        throw err;
    }
};


api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('vtoken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const newToken = await refreshToken();

                if (newToken) {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                }
            } catch (refreshError) {
                throw refreshError;
            }
        }
        return Promise.reject(error);
    }
);



export default api;
