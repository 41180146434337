import React, { useEffect, useState } from 'react';
import "./VendorStore.css"
import toast, { Toaster } from 'react-hot-toast';
import "../BulkshareCampaign/BulkshareCampaign.css"
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import "../ScratchCardList/ScratchCardList.css"
import { useDispatch, useSelector } from 'react-redux';
import { setStore } from '../../../Redux/vendorReducer';

function VendorStore() {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    let frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;
    let baseUrlDigitalOcean = process.env.REACT_APP_API_AWS_BASE_URL

    // const [storeData, setStoreData] = useState([])
    const [isShow, setShow] = useState(false)
    const [isActive, setIsActive] = useState(false)
    var storeData = useSelector(state => state?.vendorData?.store);

    useState(() => {
        if (storeData) {
            setTimeout(() => {
                setIsActive(true)
            }, 3000);
        }

    }, [storeData])

    const sharedetails = async () => {

        var urltocopy = `${frontendUrl}/customer/store/${storeData[0]?.storeURL}`
        try {
            const textarea = document.createElement('textarea');
            textarea.value = urltocopy;
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            document.execCommand('copy');
            document.body.removeChild(textarea);
            toast.success("Link copied to clipboard!")

        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    const editstore = () => {
        navigate(`/vendor/addstore?storeid=${storeData[0]?.id}`)
    }



    useEffect(() => {

        async function getstoredata() {

            await api.get("/store/listuserstore").then((res) => {
                if (res) {
                    const vendorData = res?.data?.message?.message
                    dispatch(setStore(vendorData))
                    // setStoreData(res?.data?.message?.message)
                    setShow(true)
                }
            }).catch((err) => {
                console.log(err?.message)
            })

        }

        getstoredata()

    }, [])


    function formatPhoneNumber(number) {
        const cleaned = ('' + number).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return number;
    }

    const redirecttocreate = () => {
        navigate("/vendor/addstore")
    }


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div>

                <div className='d-flex justify-content-between storelistlist012'>
                    <div className='bulksharelist011'>Your Store</div>
                    {storeData.length > 0 ? <div className='storelistlist006' onClick={editstore}>Edit</div> : <div />}
                </div>

                {storeData.length === 0 ? <div>

                    {isShow ? <div onClick={redirecttocreate} className='col-6 scratchcardlist001'>

                        <div className='scratchcardlist004 d-flex'>

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
                                    <path d="M21.586 2.66675H10.4127C5.55935 2.66675 2.66602 5.56008 2.66602 10.4134V21.5734C2.66602 26.4401 5.55935 29.3334 10.4127 29.3334H21.5727C26.426 29.3334 29.3193 26.4401 29.3193 21.5868V10.4134C29.3327 5.56008 26.4394 2.66675 21.586 2.66675ZM23.9993 17.0001H16.9993V24.0001C16.9993 24.5467 16.546 25.0001 15.9993 25.0001C15.4527 25.0001 14.9993 24.5467 14.9993 24.0001V17.0001H7.99935C7.45268 17.0001 6.99935 16.5467 6.99935 16.0001C6.99935 15.4534 7.45268 15.0001 7.99935 15.0001H14.9993V8.00008C14.9993 7.45341 15.4527 7.00008 15.9993 7.00008C16.546 7.00008 16.9993 7.45341 16.9993 8.00008V15.0001H23.9993C24.546 15.0001 24.9993 15.4534 24.9993 16.0001C24.9993 16.5467 24.546 17.0001 23.9993 17.0001Z" fill="#26735A" />
                                </svg>
                            </div>
                            <div style={{ marginTop: "10px" }} className='ps-3 mt-1'>
                                <p className='scratchcardlist005'>Create Store</p>
                            </div>

                        </div>

                    </div> : null}
                </div> : <div>

                    {storeData?.map((item, k) => (
                        <div className='storelistlist001 mb-3'>
                            <div>
                                <img className='storelistlist002' src={baseUrlDigitalOcean + item?.storeImage} alt="" />
                            </div>
                            <div className='mx-3 mt-1 storelistlist005'>
                                <div> <p style={{ textTransform: "capitalize" }} className='storelistlist006'>{item?.storeName ? item?.storeName?.toLowerCase() : null}</p></div>
                                <div> <p className='storelistlist006'>{item?.contactNumberCountryCode ? "+" + item?.contactNumberCountryCode + " " : null}{formatPhoneNumber(item?.contactNumber)}</p></div>
                                <div className='storelistlist005'>


                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text copubutton221 copubutton224">
                                                <button type="button" className="btn btn-link btn-sm copubutton224" id="copyButton">
                                                    <i className="fas fa-link"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control copubutton222"
                                            value={`${frontendUrl}/customer/store/${item?.storeURL}`}
                                            disabled
                                        />
                                        <div className="input-group-append">
                                            <button
                                                onClick={sharedetails}
                                                type="button"
                                                className="btn btn-success copubutton223"
                                            >
                                                <i className="fa-regular fa-copy mx-2"></i>
                                                Copy
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>))}

                    {isActive && storeData?.length > 0 ? <div style={{ width: "70%" }} className='alertpage-username-home mt-5 '>

                        <div className='displayName-section-home'>
                            <div className='username-home-field mt-2'>New Arrivals: Expand Your Possibilities! <span style={{ textTransform: "capitalize" }}></span></div>
                            <div onClick={() => { setIsActive(false) }} style={{ cursor: "pointer" }}><i class="fa-solid fa-xmark iconcrosshome"></i></div>
                        </div>
                        <div className='mt-2 user-description-home'>Boost your store's appeal and sales by adding your latest product! It's quick and easy. Expand your inventory, attract more customers, and watch your business grow. Don't wait – add it now!</div>
                        <div className='mt-3'>
                            <button onClick={() => { navigate("/vendor/addproduct") }} className='btn btn-success'>Add Products</button>
                        </div>


                    </div> : null}
                </div>

                }


            </div>

        </div>
    )
}

export default VendorStore