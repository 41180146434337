import React from 'react';
import "./HomePage.css"
import logo from "../Images/logo1.png"
import { useNavigate } from 'react-router-dom';

function HomePage() {

    const navigate = useNavigate()

    const vendorlogin =()=>{
        navigate("/vendor")

    };

    const customerlogin =()=>{
        navigate("/customer")
    }


  return (
    <div className='homepage001'>

        <div>
            <div className='mb-4 homepage004'><img className='homepage005' src={logo} alt="" /></div>
            <div className='homepage002'>
                <div><button onClick={vendorlogin} className='homepage003'>Vendor Login</button></div>
                <div><button onClick={customerlogin} className='homepage003'>Customer Login</button></div>
            </div>
        </div>

    </div>
  )
}

export default HomePage