import React, { useState, useRef, useEffect } from 'react';
import './Verify.css';
import logo from '../../Images/logo1.png';
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import OtpInput from 'react-otp-input';


function Verify() {

    const [otp, setOtp] = useState();
    const [timer, setTimer] = useState(30);
    const [timerActive, setTimerActive] = useState(true);
    const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    const navigate = useNavigate();
    var baseurl = process.env.REACT_APP_API_URL_CUSTOMER;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phone = searchParams.get('phone');
    const screenSize = window.innerWidth;
    const containerWidth = screenSize > 1200 ? "80%" : "300px";


    const [redirect, setRedirect] = useState("");
    const [username, setUserName] = useState("")
    const [bypass, setBypass] = useState("")
    const [redirectcampaign, setRedirectCampaign] = useState("")
    const [loginformdisable, setLoginformdisable] = useState(false)

    useEffect(() => {
        const redirect = searchParams.get('redirect');
        const username = searchParams.get('username');
        const bypass2 = searchParams.get('bypass');
        var redirectcampaign2 = searchParams.get('redirectcampaign');

        setBypass(bypass2);
        setRedirect(redirect);
        setUserName(username);
        setRedirectCampaign(redirectcampaign2)
    }, [location.search]);



    useEffect(() => {
        let interval;
        if (timerActive && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            setTimerActive(false);
        }

        return () => {
            clearInterval(interval);
        };
    }, [timer, timerActive]);


    const handleChange = (e, index) => {
        let value = e.target.value.slice(-1);
        if (value.match(/[0-9]/) || value === '') {
            const updatedOtp = [...otp];
            updatedOtp[index] = value;
            setOtp(updatedOtp);

            if (e.keyCode === 8 && index > 0 && value === '') {
                refs[index - 1].current.focus();
            }
            if (index < 5 && value !== '') {
                refs[index + 1].current.focus();
            }
        }
    };



    const handleReset = () => {
        setOtp(['', '', '', '', '', '']);
        setTimer(30);
        setTimerActive(true);
    };

    const loginUser = async (e) => {

        e.preventDefault();

        if (!otp) {
            toast.error("Enter OTP")
            return;
        }
        // const enteredOtp = otp.join('');
        // console.log(otp.length)
        if (otp?.length === 4) {

            if (loginformdisable === true) {
                return;
            }

            setLoginformdisable(true)

            await axios.post(`${baseurl}/auth/verify`, {
                phoneNumber: phone,
                otp: otp,
                userName: username

            })
                .then(async (res) => {
                    console.log(res)
                    console.log(res?.data?.message?.code)

                    if (res?.data?.message?.code === "success") {
                        toast.success('Login Success')
                        setOtp()

                        var token = res?.data?.message?.user?.token
                        var refreshtoken = res?.data?.message?.user?.refreshToken

                        localStorage.setItem("utoken", token)
                        localStorage.setItem("urtoken", refreshtoken)
                        setLoginformdisable(false)
                        const delayTime = 1000;
                        await new Promise((resolve) => setTimeout(resolve, delayTime));


                        if (redirect) {
                            if (res?.data?.message?.isUserName) {
                                window.location.replace(`/customer/${redirect}?autoclaim=true`)
                            } else {
                                window.location.replace(`/customer/${redirect}?isUserName=false`)
                            }
                        } else if (bypass) {
                            window.location.replace(bypass)
                        }
                        else if (redirectcampaign) {
                            window.location.replace(`/customer/${redirectcampaign}`)

                        }
                        else {
                            window.location.replace(`/customer`)
                        }
                    } else {
                        toast.error("Error try again")
                    }

                })
                .catch(async (err) => {
                    console.log(err)
                    toast.error(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.errors?.body[0])
                    setLoginformdisable(false)

                    const delayTime = 1000;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));

                    if (!phone) {
                        navigate("/customer/login")
                    }
                });

        } else {
            toast.error("Enter 4 digit OTP")
        }

    }

    return (
        <form onSubmit={loginUser}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="loginhomebody">
                <div className="loginbody">
                    <div className="d-grid gap-2">
                        <img className="logoimg" src={logo} alt="" />
                    </div>
                    <div className="loginbox">
                        <div className="innercont">
                            <h3 className="loginheading">Verification</h3>
                            <p className="logindesc">
                                A 6-Digit PIN has been sent to your phone, enter it below to continue.
                            </p>
                            <div className=" loginnum2 d-flex justify-content-center">
                                {/* {otp.map((value, index) => (
                                    <input
                                        key={index}
                                        className="form-control inputbox2 mx-1 text-center"
                                        type="number"
                                        maxLength={1}
                                        value={value}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleChange(e, index)}
                                        ref={refs[index]}
                                        required
                                    />
                                ))} */}

                                <OtpInput
                                    value={otp}

                                    onChange={setOtp}
                                    numInputs={4}
                                    inputType="tel"
                                    placeholder='1234'
                                    renderSeparator={<span style={{ color: "#60C2A2" }}>-</span>}
                                    containerStyle={{ display: "flex", justifyContent: "space-around", width: containerWidth }} // Adjust width as needed

                                    renderInput={(props, index) => (
                                        <input
                                            {...props}
                                            style={{
                                                width: "50px",
                                                height: "50px",
                                                fontSize: "16px",
                                                textAlign: "center",
                                                margin: "0 5px",
                                                border: "1px solid #1D1F1E",
                                                background: "#1D1F1E",
                                                color: "#60C2A2",


                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className='sergt'>
                                {timerActive ? (
                                    <p className="timer">{`00:${timer.toString().padStart(2, '0')}`}</p>
                                ) : (
                                    <span className="reset-btn" onClick={handleReset}>Resent Code</span>
                                )}
                            </div>



                            <div className="submitbtn2 d-grid gap-2">
                                <div onClick={loginUser} className=" submitt2 "><div>Continue</div></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default Verify;
