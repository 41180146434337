import React, { useEffect, useState } from 'react';
import "./ListOrders.css"
import "../BulkshareCampaign/BulkshareCampaign.css"
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import loader from "../../Images/loader.gif"
import api from '../ApiConfigration/ApiConfigration';
import { useDispatch, useSelector } from 'react-redux';
import { SetRemoveOrderDetails, setOrderList, setOrderListDetails, setOrderSearch } from '../../../Redux/vendorReducer';
import nodataImage from "../../Images/Product-empty.png"

function ListOrders() {

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const listorders = () => {
        navigate("/vendor/addorders")
    }
    const vieworders = (uid) => {
        navigate(`/vendor/vieworders?orderid=${uid}`)
    }

    // const [nodataimg, setNoDataImage] = useState(false)
    // const [orderlist, setOrderList] = useState([])
    const [isLoading, setLoader] = useState(true)
    const [search, setSearch] = useState("")

    // const [page, setPage] = useState(0);
    const limit = 10;
    // const [hasmore, setHasMore] = useState(true)
    var userData = useSelector(state => state?.vendorData?.vendor);
    var page = useSelector(state => state?.vendorData?.orderListDetails?.page);
    var hasmore = useSelector(state => state?.vendorData?.orderListDetails?.hasmore);
    var orderlist = useSelector(state => state?.vendorData?.orderList);
    var noDataImageFlag = useSelector(state => state?.vendorData?.orderListDetails?.noDataImageFlag);
    var searchdata = useSelector(state => state?.vendorData?.orderSearch?.search);

    useEffect(() => {
        if (hasmore) {
            getOrderList();
        }
    }, [hasmore])


    const getOrderList = () => {

        api.get(`/order/getallordersbyid?offset=${page}&limit=${limit}${search ? "&search=" + search : ""}`).then((res) => {
            if (res) {

                if (res?.data?.message?.message?.length < limit) {
                    // setHasMore(false);
                    dispatch(setOrderListDetails({ hasmore: false, noDataImageFlag: true }));
                } else {
                    // setPage(page + limit);
                    // setHasMore(true);
                    dispatch(setOrderListDetails({ hasmore: false, page: page + limit, noDataImageFlag: true }));

                }
                // setOrderList((prevData) => [...prevData, ...res?.data?.message?.message])

                const newData = res?.data?.message?.message;

                if (newData) {
                    // Check for duplicates
                    const existingIds = orderlist?.map(item => item?.id);
                    const firstItemId = newData[0]?.id;
                    const lastItemId = newData[newData?.length - 1]?.id;

                    if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
                        // If no duplicates, add new items to existing data
                        dispatch(setOrderList([...orderlist, ...newData]));
                    }
                }

            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    const handleSearchChange = (e) => {
        setSearch(e.target.value)
    }

    const searchProductHandle = async (e) => {
        e.preventDefault();
        dispatch(SetRemoveOrderDetails({}));
        orderlist = []
        page = 0
        dispatch(setOrderSearch({ search: search }))
        await new Promise(resolve => setTimeout(resolve, 200));
        getOrderList()

    }


    return (
        <div>

            <div className='d-flex justify-content-start'>
                <div className='bulkshare111 pt-1'>Orders</div>
                <div className='ms-4'><button onClick={listorders} type='Submit' className='bulkshare112'><i className="fa-solid fa-plus listorder111"></i> Add</button></div>
            </div>

            <div className='listorder001'>

                <div className='d-flex'>
                    <div className="search-container">
                        <form onSubmit={searchProductHandle}>

                            <input
                                type="text"
                                placeholder="Search Phone Number"
                                className="search-input"
                                value={search}
                                onChange={handleSearchChange}
                            />
                            <button type="submit" class="search-button"><i class="search-icon fa fa-search"></i></button>
                        </form>
                    </div>

                    <div className='ms-4'>
                        <button type='Submit' className='bulkshare112 listorder002'><div className='d-flex'><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                            <path d="M1 1H17" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M5 5H13" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M7 9H11" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg></span><span className='ms-2'>Filter</span></div></button>
                    </div>


                </div>
                {searchdata ? <div className="mx-2 mt-3">Showing search result for "{searchdata}"</div> : null}


                <div className='d-flex mt-4 listorder008'>
                    <div><input className='listorder003' type="checkbox" /></div>
                    <div className='ms-3 listorder004'><p>Showing {orderlist?.length} items</p></div>
                </div>


                <InfiniteScroll
                    dataLength={orderlist?.length}
                    next={getOrderList}
                    hasMore={hasmore}
                    loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
                >
                    <div>{orderlist?.map((itm, k) =>
                        <div className='d-flex mt-4 listorder007'>
                            <div><input className='listorder003' type="checkbox" /></div>
                            <div className='ms-3 listorder010' onClick={() => vieworders(itm?.id)} >
                                <div style={{ textTransform: "capitalize" }} className='d-flex'><span className='listorder005'>{itm?.name?.toLowerCase()}</span>
                                    <span style={{ textTransform: "capitalize" }} class="badge rounded-pill listorder009 ms-3">{itm?.orderStatus}</span>
                                </div>
                                <div className='d-flex mt-2'>
                                    <div style={{ textTransform: "capitalize" }} className='listorder006 '>{itm?.type}</div>
                                    <div className='listorder006 mx-1 ps-1'>  - </div>
                                    <div className='listorder006  mx-1'>{userData?.currency ? userData?.currency :null} {itm?.cartValue ? itm?.cartValue : 0}</div>
                                    <div className='listorder006  mx-1'> - </div>
                                    <div className='listorder006 mx-1'>{itm?.orderDate ? new Date(itm?.orderDate).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
                                        : null}</div>
                                </div>

                            </div>
                        </div>)}
                    </div>


                </InfiniteScroll>


                {noDataImageFlag && orderlist?.length === 0 ? <div
                    style={{ paddingTop: "80px", gap: "18px", height: "330px" }}
                    className="d-flex flex-column w-100 "
                >
                    <div className="w-100 d-flex justify-content-center">
                        <img src={nodataImage} alt="" />
                    </div>
                    <div className="d-flex justify-content-center w-100">
                        <div className="d-flex flex-column">
                            <h6 className="product-empty-bold-text p-0 m-0">
                                No Orders found
                            </h6>
                            <p className="product-empty-text">
                                Try changing the filters or search term
                            </p>
                        </div>
                    </div>

                </div> : null}





            </div>





        </div>
    )
}

export default ListOrders