import React, { useEffect, useState } from 'react';
import api from "../ApiConfigration/ApiConfigration";
import toast from 'react-hot-toast';


function RedeemPopup({ onClose, onRedeem, campaignData }) {
    const [contactNumber, setContactNumber] = useState('');
    const [userOtp, setUserOtp] = useState('');

    const [verify, setVerify] = useState(true)
    const [sendotp, setSendOtp] = useState(false)
    const [enterotp, setEnterOtp] = useState(false)
    const [claim, setSclaim] = useState(false)
    const [isDisable, setIsDisabled] = useState(false)
    const [data, setData] = useState({})

    useEffect(() => {
        setData(campaignData)
    }, [campaignData])


    const handleRedeem = async () => {

        if (!contactNumber.trim()) {
            toast.error("Phone Number Error")
        }

        var campaignData = data
        campaignData.phoneNumber = contactNumber.trim()

        console.log(campaignData)



        await api.post("/customer/claimcouponforuser", campaignData).then((res) => {
            if (res) {
                toast.success(res?.data?.message?.message)
                onRedeem(contactNumber);

            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
            if (err?.response?.data?.errors?.message[0] === "User Already Claimed") {
                onRedeem(contactNumber);

            }
        })
    };


    const handleVerifyRedeem = async () => {

        if (!contactNumber.trim()) {
            toast.error("Enter Phone Number")
            return;
        }

        if (contactNumber.trim().length !== 10) {
            toast.error("Phone Number is invalid")
            return;
        }

        setVerify(false)
        setIsDisabled(true)
        await api.get(`/customer/checkuseravailable?phoneNumber=${contactNumber.trim()}`).then((res) => {
            if (res) {
                if (res?.data?.message?.message) {
                    toast.success("User Found")
                    setSclaim(true)
                } else {
                    toast.error("User Not Found")
                    setSendOtp(true)
                }
            } else {
                toast.error("Error Try Again")
                setVerify(true)
                setIsDisabled(false)

            }
        }).catch((err) => {
            toast.error(err?.message)
            setIsDisabled(false)
            setVerify(true)

        })
    }

    const handleSendOtp = async () => {

        if (!contactNumber?.trim()) {
            toast.error("Phone Number Error")
            return;
        }


        await api.get(`/customer/generate-otp-vendor?phoneNumber=${contactNumber?.trim()}`).then((res) => {
            if (res) {
                toast.success(res?.data?.message?.message)
                setEnterOtp(true)
            } else {
                toast.error("Error sending OTP try again!")
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
        })



    }

    const handleVerifyOTP = async () => {

        if (!contactNumber?.trim()) {
            toast.error("Phone Number Error")
            return;
        }
        if (!userOtp) {
            toast.error("Enter OTP")
            return;
        }

        if (userOtp.trim().length !== 6) {
            toast.error("OTP should be 6 digit number")
            return;
        }

        await api.get(`/customer/verify-otp-vendor?phoneNumber=${contactNumber?.trim()}&otp=${userOtp}`).then((res) => {
            if (res) {
                toast.success(res?.data?.message?.message)
                setEnterOtp(false)
                setSendOtp(false)
                setSclaim(true)

            } else {
                toast.error("Error Try again")
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)

        })

    }

    return (
        <div className="redeem-popup-spin-and-win">
            <div className="redeem-popup-content-spin-and-win">
                <span className="close-spin-and-win" onClick={onClose}>&times;</span>
                <h2 className='mb-4' style={{ textAlign: "center" }}>Claim Prize</h2>

                <div className=" mt-2 mb-2 pb-2">
                    <label for="exampleFormControlInput1" className="form-label">Mobile Number</label>
                    <input type="number"
                        name='phoneNumber'
                        placeholder="Enter your contact number"
                        value={contactNumber}
                        disabled={isDisable}
                        onChange={(e) => setContactNumber(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInput1" />
                </div>

                {enterotp && (<div className=" mt-2 mb-2 pb-2">
                    <label for="exampleFormControlInput1" className="form-label"> OTP</label>
                    <input type="number"
                        name='otpnumber'
                        placeholder="Enter you 6-digit Number"
                        value={userOtp}
                        onChange={(e) => setUserOtp(e.target.value)}
                        className="form-control"
                        id="exampleFormControlInput1" />
                </div>)}
                {verify && (<div className=' text-center'>
                    <button className='spin-win-claim-button-vendor' onClick={handleVerifyRedeem}>Verify User</button>
                </div>)}
                <div className={sendotp && enterotp ? 'd-flex justify-content-between' : null}>

                    {sendotp && (<div className=' text-center'>
                        <button className='spin-win-claim-button-vendor' onClick={handleSendOtp}>Send Otp</button>
                    </div>)}
                    {enterotp && (<div className=' text-center'>
                        <button className='spin-win-claim-button-vendor' onClick={handleVerifyOTP}>Verify Otp</button>
                    </div>)}
                </div>

                {claim && (<div className=' text-center'>
                    <button className='spin-win-claim-button-vendor' onClick={handleRedeem}>Claim Coupon</button>
                </div>)}




            </div>
        </div>
    );
}

export default RedeemPopup;
