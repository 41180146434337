import React, { useEffect, useState } from 'react'
import "./CustomerViewProduct.css"
import back from "../../Images/Back.png";
import "../ClaimedOffers/ClaimedOffers.css"
import logo from "../../Images/logo1.png"
import CartFooterbar from './CartFooterbar';
import food from "../../Images/5d085e0b3f7ceb3454946e693f137ac2.jpg"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import toast, { Toaster } from 'react-hot-toast';

function CustomerViewProduct() {

    const token = localStorage.getItem("utoken")
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get('id');
    const [productData, setProductData] = useState();
    const awsUri = process.env.REACT_APP_API_AWS_BASE_URL
    const [cartData, SetCartData] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [isCartVisible, setCartVisibility] = useState(true)
    const [currentURL, setCurrentURL] = useState('');
    const [currency, setCurrency] = useState("");

    const updateQuantity = (newQuantity) => {
        console.log(newQuantity)
        setQuantity(Number(newQuantity))
    }

    useEffect(() => {
        setCurrentURL(window.location.pathname);
    }, []);


    useEffect(() => {

        api.get(`/store/getproductbyid?id=${productId}`).then((res) => {
            if (res) {
                setProductData(res?.data?.message?.message)
                setCurrency(res?.data?.message?.currency)
                console.log(res?.data?.message?.message)
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        })


    }, [])

    const getTimeQuestion = () => {
        const timeQuestion = productData?.options?.find((itm) => itm.type === "time");
        return { isTimeExist: Boolean(timeQuestion), timeQuestion };
    };
    const getDeliveryDate = () => {
        const dateQuestion = productData?.options?.find((itm) => itm.type === "date");
        return { isDateExists: Boolean(dateQuestion), dateQuestion };
    };

    const getDeliveryQuestion = () => {
        const deleiveryQuestion = productData?.options?.find((itm) => itm.type === "text");
        return { isDateExists: Boolean(deleiveryQuestion), deleiveryQuestion };
    };

    const getProductTopping = () => {
        const productTopping = productData?.options?.find((itm) => itm.type === "choice");
        return { isDateExists: Boolean(productTopping), productTopping };
    };

    const handleVariantSelection = (variant) => {
        SetCartData({ ...cartData, variant: variant.id });
        console.log({ cartData: cartData })
    };

    const handleChoiceSelection = (choice) => {
        const selectedChoices = cartData.choices || [];

        const existingIndex = selectedChoices.indexOf(choice.id);

        const maxChoice = Number(getProductTopping()?.productTopping?.maximumChoice);
        if (maxChoice && selectedChoices.length >= maxChoice && existingIndex === -1) {
            toast.error(`Maximum ${maxChoice} Toppings can be selected`);
            return;
        }

        if (existingIndex !== -1) {
            const updatedChoices = [...selectedChoices];
            updatedChoices.splice(existingIndex, 1);
            SetCartData({ ...cartData, choices: updatedChoices });
        } else {
            const updatedChoices = [...selectedChoices, choice.id];
            SetCartData({ ...cartData, choices: updatedChoices });
        }

        console.log({ cartData: cartData });
    };



    const isChoiceSelected = (choice) => {
        if (!cartData.choices) return false;
        const selectedChoice = cartData.choices;
        return selectedChoice && selectedChoice.includes(choice.id);
    };

    const handleChoiceSelection2 = (choice, type) => {
        const selectedChoices = cartData.choices || [];
        const existingIndex = selectedChoices.findIndex(c => c.type === type);
        if (existingIndex !== -1) {
            selectedChoices[existingIndex].response = choice;
        } else {
            selectedChoices.push({ type: type, response: choice });
        }

        SetCartData({ ...cartData, choices: selectedChoices });
    };


    useEffect(() => {

        SetCartData(prev => ({
            ...prev,
            quantity: quantity,
            isVarient: productData?.isVarient,
            productId: productData?.id,
            vendorId: productData?.vendorId,
        }));

    }, [productData, quantity])


    const handleAddToCart = async () => {


        //Validation for Quantity and ProductId
        if (!cartData?.quantity || !cartData?.productId || !cartData?.vendorId) {
            toast.error("Error adding Data to cart");
            return;
        }

        //Validation for Varient Selection
        if (cartData?.isVarient && (cartData?.variant === null || !cartData?.variant)) {
            toast.error("Select any variant")
            return;
        }


        //Validation for Choice Selection & Extras
        var isChoice = productData?.options?.find((itm) => itm.type === "choice")
        if (isChoice) {
            if (isChoice.questionType === "required") {
                if (cartData?.choices?.length <= 0) {
                    toast.error("Select topping to complete Order")
                    return;
                }
            }
            const minimumChoice = Number(isChoice?.minmumChoice);
            const choicesLength = cartData?.choices?.length ?? 0;

            if ((minimumChoice > choicesLength)) {
                toast.error(`Select ${minimumChoice} or more toppings`);
                return;
            }
        }


        if (!token) {
            toast.error("Login to continue")
            navigate(`/customer/login?bypass=${currentURL}?id=${productId}`)
            return;
        }

        setCartVisibility(false);
        //API 
        await api.post("/store/additemtocart", cartData).then((res) => {
            if (res) {
                setCartVisibility(true);
                toast.success(res?.data?.message?.message);
                navigate(`/customer/orderdetails?id=${productData?.vendorId}`)

            } else {
                toast.error("Error adding to Cart")
            }
        }).catch((err) => {
            setCartVisibility(true);
            console.log(err)
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)

        })
    };


    return (


        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='claimofferhome'>

                <div className='claimoffershare'>

                    <div className='navbarrr'>
                        <div onClick={() => { navigate(-1) }}><img className='customerviewproduct022' src={back} alt={back} /></div>
                        <div ></div>
                        <div> </div>
                    </div>
                    {
                        productData ? <div className='customerviewproduct023'>
                            <img className='customerviewproduct021' src={awsUri + productData?.productImage} alt="" />
                        </div> : null}




                    <div className='customerviewproduct001'>

                        <div style={{ cursor: "pointer" }} className='d-flex justify-content-between mb-3'>
                            <div className='customerviewproduct002'>{(productData?.productName)?.toLowerCase()}</div>
                            <div><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M15 6.66675C16.3807 6.66675 17.5 5.54746 17.5 4.16675C17.5 2.78604 16.3807 1.66675 15 1.66675C13.6193 1.66675 12.5 2.78604 12.5 4.16675C12.5 5.54746 13.6193 6.66675 15 6.66675Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M5 12.5C6.38071 12.5 7.5 11.3807 7.5 10C7.5 8.61929 6.38071 7.5 5 7.5C3.61929 7.5 2.5 8.61929 2.5 10C2.5 11.3807 3.61929 12.5 5 12.5Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15 18.3333C16.3807 18.3333 17.5 17.214 17.5 15.8333C17.5 14.4525 16.3807 13.3333 15 13.3333C13.6193 13.3333 12.5 14.4525 12.5 15.8333C12.5 17.214 13.6193 18.3333 15 18.3333Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.15833 11.2583L12.85 14.575" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.8417 5.42505L7.15833 8.74172" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></div>
                        </div>


                        <div className='customerviewproduct003'>
                            <p>
                                {productData?.productDescription}
                            </p>
                        </div>

                        {productData ? <div className='mb-3'>
                            {/* Varient Section */}
                            {productData?.isVarient ? <>{productData?.productvarients?.map((itm, k) => <div style={{ cursor: "pointer" }} className={`d-flex justify-content-between customerviewproduct004 ${cartData?.variant === itm?.id ? 'selecteditem' : ''}`} onClick={() => handleVariantSelection(itm)}>
                                <div style={{ cursor: "pointer" }} className='customerviewproduct005'>{itm?.name}</div>
                                <div><span className='customerviewproduct006 mx-2 ms-0'>{currency?currency:"BD"} {itm?.originalPrice ? itm?.originalPrice : "0"}</span><span className='customerviewproduct007'>{currency?currency:"BD"} {itm?.price ? itm?.price : "0"}</span></div>
                            </div>)}</> : null}


                            {/* Non Varient Section */}
                            {!productData?.isVarient ? <div className='d-flex justify-content-center customerviewproduct004 selecteditem'>
                                <div><span className='customerviewproduct006 mx-2 ms-0'>{currency?currency:"BD"} {productData?.originalPrice ? productData?.originalPrice : "0"}</span><span className='customerviewproduct007'>{currency?currency:"BD"} {productData?.price ? productData?.price : "0"}</span></div>
                            </div> : null}
                        </div> : null}
                        {getProductTopping()?.isDateExists && <>

                            <div className='mb-5 mt-3'>
                                <div className='customerviewproduct008'>{getProductTopping()?.productTopping?.question}</div>
                                <div className='customerviewproduct009'>Select {getProductTopping()?.productTopping?.minmumChoice + "~" + getProductTopping()?.productTopping?.maximumChoice}</div>
                            </div>
                            <div className='mt-4 mb-5'>

                                {productData?.productExtras?.map((itm, k) => <div className='d-flex justify-content-between mb-3'>
                                    <div className='d-flex '><span style={{ cursor: "pointer" }}>
                                        <input id='checkbox-product' checked={isChoiceSelected(itm)} onChange={() => handleChoiceSelection(itm)} style={{ cursor: "pointer" }} className='customerviewproduct010 mx-3 ms-0' type="checkbox" /></span>
                                        <span className='customerviewproduct011'>{itm?.value}</span></div>
                                    <div className='customerviewproduct012'>+ {currency?currency:"BD"} {itm?.price}</div>
                                </div>)}



                            </div>

                        </>}
                        {/* Delivery Question */}
                        {getDeliveryQuestion()?.deleiveryQuestion && <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label customerviewproduct013">{getDeliveryQuestion()?.deleiveryQuestion?.question}</label>
                            <input onChange={(e) => handleChoiceSelection2(e.target.value, 'text')} type="text" className="form-control customerviewproduct014" id="exampleFormControlInput1" />

                        </div>}
                        {/* Delivery Date */}

                        {getDeliveryDate()?.isDateExists && <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label customerviewproduct013">{getDeliveryDate()?.dateQuestion?.question}</label>
                            <input onChange={(e) => handleChoiceSelection2(e.target.value, 'date')} type="date" className="form-control customerviewproduct014" id="exampleFormControlInput1" />

                        </div>}
                        {/* Delivery Time */}

                        {getTimeQuestion()?.isTimeExist && <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label customerviewproduct013">{getTimeQuestion()?.timeQuestion?.question}</label>
                            <input onChange={(e) => handleChoiceSelection2(e.target.value, 'time')} type="text" className="form-control customerviewproduct014" id="exampleFormControlInput1" />

                        </div>}



                        <div className='customerviewproduct017'>
                            <div className='customerviewproduct015'>
                                <img src={logo} alt="" />
                            </div>
                        </div>

                        {isCartVisible ? <div className='customerviewproduct020' >
                            <div className='customerviewproduct016'>
                                <CartFooterbar
                                    updateQuantity={updateQuantity}
                                    handleAddToCart={handleAddToCart} />
                            </div>
                        </div> : null}


                    </div>





                </div>

            </div>



        </div>


    )
}

export default CustomerViewProduct