import { createSlice } from "@reduxjs/toolkit";


const vendorSlice = createSlice({
    name: "vendordata",

    initialState: {
        vendor: {},
        store: [],
        storeDetails: {},
        productList: [],
        productListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
        },
        productSearch: {
            search: ""
        },
        collectionList: [],
        collectionListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
            isloadingFlag: true
        },
        customerList: [],
        customerlistdetails: {
            isRecallCustomerApi: true,
            page: 0,
            hasmore: true,
            noDataImageFlag: false
        },
        couponList: [],
        couponListDetails: {
            page: 0,
            hasmore: true
        },
        bulkshareList: [],
        bulkshareListDetails: {
            page: 0,
            hasmore: true
        },
        scratchCardList: [],
        scratchCardListDetails: {
            page: 0,
            hasmore: true
        },
        spinandwinList: [],
        spinandwinListDetails: {
            page: 0,
            hasmore: true
        },
        orderList: [],
        orderListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
        },
        orderSearch: {
            search: ""
        },
        settingDetails: {},

        homeDetails: {
            graphData: [],
            graphType: "monthly",
            values: {},
            totalSum: ""

        }


    },

    reducers: {

        setVendor: (state, action) => {
            state.vendor = action.payload;
        },
        setRemoveVendor: (state, payload) => {
            state.vendor = {}
        },
        setStore: (state, action) => {
            state.store = action.payload;
        },
        setStoreDetails: (state, action) => {
            state.storeDetails = action.payload;
        },
        setProductList: (state, action) => {
            state.productList = action.payload;
        },
        setProductListDetails: (state, action) => {
            state.productListDetails.page = action.payload.page;
            state.productListDetails.hasmore = action.payload.hasmore;
            state.productListDetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        setProductSearch: (state, action) => {
            state.productSearch.search = action.payload.search;
        },
        setCollectionList: (state, action) => {
            state.collectionList = action.payload;
        },
        setCollectionListDetails: (state, action) => {
            state.collectionListDetails.page = action.payload.page;
            state.collectionListDetails.hasmore = action.payload.hasmore;
            state.collectionListDetails.noDataImageFlag = action.payload.noDataImageFlag;
            state.collectionListDetails.isloadingFlag = action.payload.isloadingFlag;
        },
        setCustomerList: (state, action) => {
            state.customerList = action.payload;
        },
        setCustomerListDetails: (state, action) => {
            state.customerlistdetails.isRecallCustomerApi = action.payload.isRecallCustomerApi;
            state.customerlistdetails.page = action.payload.page;
            state.customerlistdetails.hasmore = action.payload.hasmore
            state.customerlistdetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        setCouponList: (state, action) => {
            state.couponList = action.payload;
        },
        setcouponListDetails: (state, action) => {
            state.couponListDetails.isRecallCustomerApi = action.payload.isRecallCustomerApi;
            state.couponListDetails.page = action.payload.page;
            state.couponListDetails.hasmore = action.payload.hasmore
        },
        setBulkshareList: (state, action) => {
            state.bulkshareList = action.payload;
        },
        setBulkshareListDetails: (state, action) => {
            state.bulkshareListDetails.page = action.payload.page;
            state.bulkshareListDetails.hasmore = action.payload.hasmore
        },
        setScratchCardList: (state, action) => {
            state.scratchCardList = action.payload;
        },
        setScratchCardListDetails: (state, action) => {
            state.scratchCardListDetails.page = action.payload.page;
            state.scratchCardListDetails.hasmore = action.payload.hasmore
        },
        setSpinAndWinList: (state, action) => {
            state.spinandwinList = action.payload;
        },

        setSpinAndWinListDetails: (state, action) => {
            state.spinandwinListDetails.page = action.payload.page;
            state.spinandwinListDetails.hasmore = action.payload.hasmore
        },
        setOrderList: (state, action) => {
            state.orderList = action.payload;
        },
        setOrderListDetails: (state, action) => {
            state.orderListDetails.page = action.payload.page;
            state.orderListDetails.hasmore = action.payload.hasmore;
            state.orderListDetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        setOrderSearch: (state, action) => {
            state.orderSearch.search = action.payload.search;
        },
        SetRemoveBulkshare: (state, action) => {
            state.bulkshareList = []
            state.bulkshareListDetails.page = 0;
            state.bulkshareListDetails.hasmore = true;
        },
        SetRemoveSpinAndWin: (state, action) => {
            state.spinandwinList = []
            state.spinandwinListDetails.page = 0;
            state.spinandwinListDetails.hasmore = true;
        },
        SetRemoveScratchCard: (state, action) => {
            state.scratchCardList = []
            state.scratchCardListDetails.page = 0;
            state.scratchCardListDetails.hasmore = true;
        },
        SetRemoveCouponCodes: (state, action) => {
            state.couponList = []
            state.couponListDetails.page = 0;
            state.couponListDetails.hasmore = true;
        },
        SetRemoveCustomers: (state, action) => {
            state.customerList = []
            state.customerlistdetails.page = 0;
            state.customerlistdetails.hasmore = true;
            state.customerlistdetails.isRecallCustomerApi = true;
            state.customerlistdetails.noDataImageFlag = false;

        },
        SetRemoveProductDetails: (state, action) => {
            state.productList = []
            state.productListDetails.page = 0;
            state.productListDetails.hasmore = true;
            state.productListDetails.noDataImageFlag = false;

        },
        setRemoveProductSearch: (state, action) => {
            state.productSearch.search = "";
        },
        setSettingDetails: (state, action) => {
            state.settingDetails = action.payload.settingDetails;
        },

        setHomeDeatils: (state, action) => {
            state.homeDetails.graphData = action.payload.graphData ? action.payload.graphData : state.homeDetails.graphData
            state.homeDetails.values = action.payload.values ? action.payload.values : state.homeDetails.values
            state.homeDetails.graphType = action.payload.graphType ? action.payload.graphType : state.homeDetails.graphType
            state.homeDetails.totalSum = action.payload.totalSum ? action.payload.totalSum : state.homeDetails.totalSum
        },
        SetRemoveOrderDetails: (state, action) => {
            state.orderList = []
            state.orderListDetails.page = 0;
            state.orderListDetails.hasmore = true;
            state.orderListDetails.noDataImageFlag = false;

        },
        setRemoveOrderSearch: (state, action) => {
            state.orderSearch.search = "";
        },


    }


});


export const { setVendor, setStore, setStoreDetails, setProductList, setProductListDetails, setCollectionList, setCustomerList, setCustomerListDetails, setCouponList,
    setcouponListDetails, setBulkshareList, setBulkshareListDetails, setScratchCardList, setScratchCardListDetails, setSpinAndWinList,
    setSpinAndWinListDetails, setOrderList, setOrderListDetails, SetRemoveBulkshare, SetRemoveSpinAndWin, SetRemoveScratchCard, SetRemoveCouponCodes,
    SetRemoveCustomers, SetRemoveProductDetails, setCollectionListDetails, setSettingDetails, setRemoveVendor, setHomeDeatils, setProductSearch,
    setRemoveProductSearch, setRemoveOrderSearch, setOrderSearch, SetRemoveOrderDetails } =
    vendorSlice.actions;
const vendorReducer = vendorSlice.reducer;
export default vendorReducer;