import { createSlice } from "@reduxjs/toolkit";


const customerSlice = createSlice({
    name: "customerdata",

    initialState: {

        customer: {},
        homeCoupons: [],
        homeCouponDetails: {
            page: 0,
            hasmore: true
        },

    },

    reducers: {

        setCustomer: (state, action) => {
            state.customer = action.payload;
        },

        setHomeCoupons: (state, actions) => {
            state.homeCoupons = actions.payload
        },
        setHomeCouponDetails: (state, actions) => {
            state.homeCouponDetails.hasmore = actions.payload.hasmore
            state.homeCouponDetails.page = actions.payload.page

        },



    }


});



export const { setCustomer, setHomeCoupons , setHomeCouponDetails} =
    customerSlice.actions;
const customerReducer = customerSlice.reducer;
export default customerReducer;