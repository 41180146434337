import React, { useEffect, useState } from "react";
import "./AddProducts.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import food from "../../Images/Rectangle 2470.png";
import AddImageModal from "./AddImageModal";
import AddtoCollection from "./AddtoCollection";
import questionData from "./Questions.json"
import toast, { Toaster } from "react-hot-toast";
import api from "../ApiConfigration/ApiConfigration";
import { useDispatch } from "react-redux";
import { SetRemoveProductDetails } from "../../../Redux/vendorReducer";

export default function AddProducts() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const edit = queryParams.get('edit');

  const [iseditDisabled, setEditDisabled] = useState(false)
  const [isSaveHidden, setisSaveHidden] = useState(false)
  const [collectionBanner, setCollectionBanner] = useState(true)
  const [checkedItems, setCheckedItems] = useState([]);
  const [collectionData, setCollectionData] = useState([])
  const [formData, setFormData] = useState({
    variants: [],
    isVarient: false,
    productName: "",
    productDescription: "",
    price: 0,
    originalPrice: 0,
    productCount: 0,
    inventory: { Availability: "", SKU: "" },
    trackQuantity: false,
    options: [],
    productImage: [],
    productDimensions: { length: "", breadth: "", height: "", weight: "" },
  });

  const [formDataError, setFormDataError] = useState({
    variants: [],
    isVarient: false,
    productName: "",
    price: "",
    originalPrice: "",
    productCount: "",
    productDescription: "",
    inventory: { Availability: "", SKU: "" },
    trackQuantity: false,
    options: [],
    productImage: [],
    productDimensions: { length: "", breadth: "", height: "", weight: "" },
  });



  const [addImageModal, setAddImageModal] = useState(false);
  const [addCollectionModal, setAddCollectionModal] = useState(true);


  const DecriptionHandle = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormDataError({ ...formDataError, [name]: "" })
  };

  const AddVariants = () => {
    setFormData({
      ...formData,
      variants: [
        ...formData.variants,
        { name: "", price: "", originalPrice: "", productCount: 0 },
      ],
    });
    setFormDataError({
      ...formDataError,
      variants: [
        ...formDataError?.variants,
        { name: "", price: "", originalPrice: "", productCount: "" },
      ],
    });
  };
  const DeleteVariants = (index) => {
    let variant = [...formData.variants];
    variant = JSON.parse(JSON.stringify(variant));
    variant.splice(index, 1);
    setFormData({ ...formData, variants: [...variant] });
    setFormDataError((prevState) => {
      const updatedErrors = [...prevState.variants];
      updatedErrors.splice(index, 1);

      return {
        ...prevState,
        variants: updatedErrors,
      };
    });
  };
  const onVariantChange = ({ event, type, index }) => {
    console.log({ event: event, type: type, index: index })
    try {
      const { name, value } = event?.target;
      const variants1 = formData?.variants || [];
      variants1[index][name] = value;
      setFormData({ ...formData, variants: variants1 });

      const variants1error = formDataError?.variants || [];
      variants1error[index][name] = "";
      setFormDataError({ ...formDataError, variants: variants1error });
    } catch (err) {
      console.log(err)
      // toast.error(err?.message)
    }
  };
  const AddOptions = () => {
    setFormData({
      ...formData,
      options: [
        ...formData.options,
        { type: "", question: "", questionType: "", choice: [], isViewChoice: false },
      ],
    });
    setFormDataError({
      ...formDataError,
      variants: [
        ...formDataError.options,
        { type: "", question: "", questionType: "", choice: [], isViewChoice: "" },
      ],
    });
  };
  const DeleteOptions = (index) => {
    const option = [...formData.options];
    option.splice(index, 1);
    setFormData({ ...formData, options: option });
    setFormDataError((prevState) => {
      const updatedErrors = [...prevState.options];
      updatedErrors.splice(index, 1);

      return {
        ...prevState,
        options: updatedErrors,
      };
    });
  };
  const AddOptionalChoice = (index) => {
    const option = formData.options;
    option[index].choice = [...option[index].choice, { value: "", price: 0 }];
    setFormData({ ...formData, options: option });
  };
  const DeleteOptionalChoice = (index) => {
    const options = [...formData.options];
    const choice = options[index].choice;
    choice.splice(index, 1);
    options[index].choice = [...choice];
    setFormData({ ...formData, options: [...options] });
  };
  const onChangeOptions = ({ type, event, index, mainindex }) => {
    if (type == "type") {
      const options = [...formData.options];
      const matchingQuestion = questionData.find(question => question.type === event);
      var questionvalue = matchingQuestion ? matchingQuestion.question : null;
      if (event === "choice") {
        options[index].isViewChoice = true;

      } else {
        options[index].isViewChoice = false;
      }

      const checkDouble = formData.options.find(item => item.type === event)
      if (checkDouble) {
        toast.error("Already added this option")
        return
      }
      options[index].type = event;
      options[index].question = questionvalue



      setFormData({ ...formData, options: options });
    } else if (type == "choiceText") {
      const options = [...formData.options];
      options[mainindex].choice[index].value = event.target.value;
      setFormData({ ...formData, options: options });
    } else if (type == "choiceNumber") {
      const options = [...formData.options];
      options[mainindex].choice[index].price = event.target.value;
      setFormData({ ...formData, options: options });
    } else if (type == "min-choice") {
      const options = [...formData.options];
      options[index].minmumChoice = event.target.value;
      setFormData({ ...formData, options: options });
    } else if (type == "max-choice") {
      const options = [...formData.options];
      options[index].maximumChoice = event.target.value;
      setFormData({ ...formData, options: options });
    } else if (type == "question") {
      const options = [...formData.options];
      options[index].question = event.target.value;
      setFormData({ ...formData, options: options });
    } else if (type == "questionType") {
      const options = [...formData.options];
      options[index].questionType = event;
      setFormData({ ...formData, options: options });
    }
  };
  console.log(formData);
  const onChangeImages = (images) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      productImage: [...prevFormData.productImage, ...images],
    }));
    setFormDataError(prevState => ({
      ...prevState,
      productImage: "",
    }));
  };
  const onChangeTrackQuantiy = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, trackQuantity: checked });
  };

  const onChangeVarient = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, isVarient: checked });

  }
  const oninventoryHandle = ({ type, event, value }) => {
    if (type == "availability") {
      setFormData({
        ...formData,
        inventory: { ...formData.inventory, Availability: value },
      });
    } else if (type == "SKU") {
      setFormData({
        ...formData,
        inventory: { ...formData.inventory, SKU: event.target.value },
      });
    }
  };
  const onProductDimensions = (event) => {
    const { value, name } = event.target;
    setFormData({
      ...formData,
      productDimensions: {
        ...formData.productDimensions,
        [name]: value.trim(),
      },
    });
  };

  const createNewProduct = async (e) => {
    e.preventDefault();

    if (!formData.productName) {
      setFormDataError(prevState => ({
        ...prevState,
        productName: "Product name cannot be empty",
      }));
      return;
    }

    if (formData?.productImage?.length <= 0) {
      setFormDataError(prevState => ({
        ...prevState,
        productImage: "Product Image cannot be empty",
      }));
      return;
    }

    for (let i = 0; i < formData.variants.length; i++) {
      const variant = formData.variants[i];

      if (formData?.isVarient) {
        if (!variant.name) {
          setFormDataError(prevState => ({
            ...prevState,
            variants: [
              ...prevState.variants.slice(0, i),
              {
                ...prevState.variants[i],
                name: `Product name cannot be empty`,
              },
              ...prevState.variants.slice(i + 1),
            ],
          }));

          return;
        }
        if (!variant.price || variant.price === 0 || variant.price === "0") {
          setFormDataError(prevState => ({
            ...prevState,
            variants: [
              ...prevState.variants.slice(0, i),
              {
                ...prevState.variants[i],
                price: `Product Price cannot be empty`,
              },
              ...prevState.variants.slice(i + 1),
            ],
          }));

          return;
        }
        // if (!variant.originalPrice || variant.originalPrice === 0 || variant.originalPrice === "0") {
        //   setFormDataError(prevState => ({
        //     ...prevState,
        //     variants: [
        //       ...prevState.variants.slice(0, i),
        //       {
        //         ...prevState.variants[i],
        //         originalPrice: `Product Original Price cannot be empty`,
        //       },
        //       ...prevState.variants.slice(i + 1),
        //     ],
        //   }));

        //   return;
        // }



        if (formData?.trackQuantity) {
          if (!variant?.productCount || variant?.productCount === 0 || variant?.productCount === "0") {
            setFormDataError(prevState => ({
              ...prevState,
              variants: [
                ...prevState.variants.slice(0, i),
                {
                  ...prevState.variants[i],
                  productCount: `Product Quantity cannot be empty`,
                },
                ...prevState.variants.slice(i + 1),
              ],
            }));

            return;
          }

        }
      }
    }

    if (!formData.isVarient) {
      if (!formData.price || formData.price == 0) {
        setFormDataError(prevState => ({
          ...prevState,
          price: "Price cannot be empty",
        }));
        return;
      }
      // if (!formData.originalPrice || formData.originalPrice == 0) {
      //   setFormDataError(prevState => ({
      //     ...prevState,
      //     originalPrice: "Original price cannot be empty",
      //   }));
      //   return;
      // }

    }


    setisSaveHidden(true)
    api.post("/product/addproducts", { formData, checkedItems }).then((res) => {
      if (res) {
        dispatch(SetRemoveProductDetails())
        toast.success(res?.data?.message?.message)
        navigate("/vendor/products")
      } else {
        toast.error("error")
        setisSaveHidden(false)

      }
    }).catch((err) => {
      setisSaveHidden(false)
      toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err.message)
    })


  }

  useEffect(() => {

    api.get("/product/getallcollections").then((res) => {
      if (res) {
        // console.log(res?.data?.message?.message)
        setCollectionData(res?.data?.message?.message)
      }
    }).catch((err) => {
      console.log(err.message)
    })

  }, [])

  const onRemoveImage = (index) => {
    setFormData((prevFormData) => {
      const updatedImages = [...prevFormData.productImage];
      updatedImages.splice(index, 1);
      return {
        ...prevFormData,
        productImage: updatedImages,
      };
    });
  }

  const handleCheckboxChange = (itemId) => {
    // Check if the item is already in the array
    if (checkedItems.includes(itemId)) {
      // If it is, remove it
      setCheckedItems(checkedItems.filter(id => id !== itemId));
    } else {
      // If it's not, add it
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  useEffect(() => {

    if (id) {

      async function getDataById() {
        try {
          await api.get(`product/getproductdetailsbyid?id=${id}`).then((res) => {
            if (res) {
              setFormData(res?.data?.message?.message?.finalresponse)
              setCheckedItems(res?.data?.message?.message?.collectionDetails ? res?.data?.message?.message?.collectionDetails : [])
            }
          }).catch((err) => {
            toast.error("Error Fetching Data")
          })
        }
        catch (err) {
          toast.error(err?.message)
        }
      }

      getDataById()
    }

    if (id && (edit === "false" || !edit)) {
      setEditDisabled(true)
    }




  }, [id, edit])


  return (
    <div >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      {addImageModal ? (
        <AddImageModal SetImage={onChangeImages} SetShow={setAddImageModal} />
      ) : (
        ""
      )}
      <form onSubmit={createNewProduct}>
        {/* Top heading */}
        <div className="d-flex justify-content-between">
          {/* <div className='bulkshare111'>d</div> */}
          <div className="d-flex">
            <div style={{ cursor: "pointer" }} onClick={() => { navigate("/vendor/products") }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z"
                  stroke="#444444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397"
                  stroke="#444444"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="d-flex ps-2 flex-column">
              <p className="top-small-heading p-0 m-0">Back to product list</p>
              <div className="d-flex">
                <p className="bulkshare111 p-0 m-0">Add new product</p>
              </div>
            </div>
          </div>

          <div style={{ gap: "10px" }} className="d-flex align-items-center">
            <button className="add-product-btn-text" type="button">
              Duplicate
            </button>
            <button className="add-product-btn-text" type="button">
              View
            </button>
            {edit === "true" ? null : <>{iseditDisabled ? null : <>{!isSaveHidden ? <button type="Submit" className="bulkshare112">
              Save
            </button> : <button class="btn btn-success" type="button" disabled>
              <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
              Saving...
            </button>}</>}</>}
          </div>
        </div>
        {/* ------------Form -----------------*/}
        <div>
           <div className="row">
          {/* ----left section -----*/}
          <div className="col-12 col-lg-6 py-4 ">
            {/* Product Name and Deccription */}
            <div>

            </div>
            <div className="add-product-box d-flex flex-column gap-16">
              <h1 className="add-product-box-heading">Description</h1>
              {/* product Name */}
              <div className="d-flex flex-column w-100">
                <label
                  className="add-product-box-input-label"
                  htmlFor="Product Name"
                >
                  Product Name
                </label>
                <input
                  disabled={iseditDisabled}
                  value={formData?.productName}
                  onChange={DecriptionHandle}
                  name="productName"
                  type="text"
                  className="add-product-box-input form-control"
                />
                {formDataError.productName && <div className='text-danger pt-1'>{formDataError.productName}</div>}
              </div>
              {/* -- */}
              {/*-----  Product Description ------- */}
              <div className="d-flex flex-column w-100">
                <label
                  className="add-product-box-input-label"
                  htmlFor="Product Description"
                >
                  Product Description
                </label>
                <textarea
                  value={formData?.productDescription}
                  disabled={iseditDisabled}
                  name="productDescription"
                  onChange={DecriptionHandle}
                  type="text"
                  rows={3}
                  className="add-product-box-input form-control"
                ></textarea>
              </div>
            </div>

            {/*----------- Variants ----------------------*/}
            <div className="add-product-box">
              <div className="d-flex ">
                <h1 className="add-product-box-heading">Variants</h1>
                <div className="ps-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10.0003 18.8334C14.6027 18.8334 18.3337 15.1025 18.3337 10.5001C18.3337 5.89771 14.6027 2.16675 10.0003 2.16675C5.39795 2.16675 1.66699 5.89771 1.66699 10.5001C1.66699 15.1025 5.39795 18.8334 10.0003 18.8334Z"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5752 7.99999C7.77112 7.44304 8.15782 6.97341 8.66682 6.67426C9.17583 6.37512 9.77427 6.26577 10.3562 6.36558C10.9381 6.46539 11.4659 6.76792 11.8461 7.21959C12.2263 7.67126 12.4344 8.24292 12.4335 8.83332C12.4335 10.5 9.93353 11.3333 9.93353 11.3333"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 14.6667H10.0083"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                {/* Variants Table */}
              </div>
              <div className="d-flex mt-2 mb-2 align-items-center">
                <div><input onChange={onChangeVarient}
                  checked={formData?.isVarient}
                  disabled={iseditDisabled}

                  className="input-checkbox-green "
                  type="checkbox" /></div>
                <div className="add-product-box-input-label ps-2 ">Add Varient</div>
              </div>
              {formData?.isVarient ? (<div className="d-flex flex-column w-100">
                {formData.variants[0] ? (
                  <div className="d-flex variants-table-head">
                    <div className="w-50">
                      <p>Name</p>
                    </div>
                    <div className="w-50 d-flex">
                      <p className="w-50">Price</p>
                      <p className="w-50">Orginal Price</p>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* rows */}
                {formData.variants.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className="d-flex w-100 align-items-center py-1 flex-grow-1"
                      >
                        <svg
                          width="20"
                          height="29"
                          viewBox="0 0 20 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="6" cy="6.5" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="6.5" r="2" fill="#BBC3BC" />
                          <circle cx="6" cy="14.5" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="14.5" r="2" fill="#BBC3BC" />
                          <circle cx="6" cy="22.5" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="22.5" r="2" fill="#BBC3BC" />
                        </svg>
                        <div className="d-flex flex-grow-1">
                          {/* Product Name */}
                          <div className="w-50 px-2">
                            <input
                              disabled={iseditDisabled}
                              onChange={(e) =>
                                onVariantChange({
                                  event: e,
                                  type: "name",
                                  index,
                                })
                              }
                              name="name"
                              value={item.name}
                              style={{ width: "90%" }}
                              type="text"
                              className="add-product-box-input form-control"
                              placeholder="Chicago Pizza"
                            />

                          </div>

                          <div className="w-50  d-flex">
                            <div className="w-50">
                              <input
                                disabled={iseditDisabled}
                                name="price"
                                min={"0"}
                                onChange={(event) =>
                                  onVariantChange({
                                    event,
                                    type: "price",
                                    index,
                                  })
                                }
                                style={{ width: "90%" }}
                                type="number"
                                value={id ? item?.price : null}
                                className="add-product-box-input form-control "
                                placeholder="0"
                              />
                            </div>
                            <div className="w-50">
                              <input
                                disabled={iseditDisabled}
                                name="originalPrice"
                                min={"0"}
                                onChange={(event) =>
                                  onVariantChange({
                                    event,
                                    type: "originalPrice",
                                    index,
                                  })
                                }
                                style={{ width: "90%" }}
                                type="number"
                                value={id ? item?.originalPrice : null}
                                className="add-product-box-input form-control "
                                placeholder="0"
                              />
                            </div>
                          </div>
                        </div>
                        {/* delete */}
                        {iseditDisabled ? null :
                          <svg
                            onClick={() => DeleteVariants(index)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                          >
                            <path
                              d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5ZM15.36 14.8C15.65 15.09 15.65 15.57 15.36 15.86C15.21 16.01 15.02 16.08 14.83 16.08C14.64 16.08 14.45 16.01 14.3 15.86L12 13.56L9.7 15.86C9.55 16.01 9.36 16.08 9.17 16.08C8.98 16.08 8.79 16.01 8.64 15.86C8.35 15.57 8.35 15.09 8.64 14.8L10.94 12.5L8.64 10.2C8.35 9.91 8.35 9.43 8.64 9.14C8.93 8.85 9.41 8.85 9.7 9.14L12 11.44L14.3 9.14C14.59 8.85 15.07 8.85 15.36 9.14C15.65 9.43 15.65 9.91 15.36 10.2L13.06 12.5L15.36 14.8Z"
                              fill="#C32D22"
                            />
                          </svg>}
                      </div>
                      {formDataError?.variants[index]?.name && <div className='text-danger pt-1'>{formDataError?.variants[index]?.name}</div>}
                      {formDataError?.variants[index]?.price && <div className='text-danger pt-1'>{formDataError?.variants[index]?.price}</div>}
                      {formDataError?.variants[index]?.originalPrice && <div className='text-danger pt-1'>{formDataError?.variants[index]?.originalPrice}</div>}

                    </>
                  );
                })}

                <div>
                  {iseditDisabled ? null : <button
                    onClick={() => AddVariants()}
                    className="green-add-btn"
                    type="button"
                  >
                    Add
                  </button>}
                </div>
              </div>) : (<div>

                <div className="d-flex variants-table-head">
                  <div className="w-50">
                    <p>Name</p>
                  </div>
                  <div className="w-50 d-flex">
                    <p className="w-50">Price</p>
                    <p className="w-50">Orginal Price</p>
                  </div>
                </div>


                <>
                  <div

                    className="d-flex w-100 align-items-center py-1 flex-grow-1"
                  >
                    <svg
                      width="20"
                      height="29"
                      viewBox="0 0 20 29"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="6" cy="6.5" r="2" fill="#BBC3BC" />
                      <circle cx="14" cy="6.5" r="2" fill="#BBC3BC" />
                      <circle cx="6" cy="14.5" r="2" fill="#BBC3BC" />
                      <circle cx="14" cy="14.5" r="2" fill="#BBC3BC" />
                      <circle cx="6" cy="22.5" r="2" fill="#BBC3BC" />
                      <circle cx="14" cy="22.5" r="2" fill="#BBC3BC" />
                    </svg>
                    <div className="d-flex flex-grow-1">
                      {/* Product Name */}
                      <div className="w-50 px-2">
                        <input
                          name="name"
                          style={{ width: "90%" }}
                          type="text"
                          className="add-product-box-input form-control"
                          placeholder="Chicago Pizza"
                          value={formData?.productName}
                          disabled
                        />
                      </div>
                      <div className="w-50  d-flex">
                        <div className="w-50">
                          <input
                            disabled={iseditDisabled}
                            name="price"
                            onChange={DecriptionHandle}
                            style={{ width: "90%" }}
                            type="number"
                            value={id ? formData?.price : null}
                            min={"0"}
                            className="add-product-box-input form-control "
                            placeholder="0"
                          />
                        </div>
                        <div className="w-50">
                          <input
                            disabled={iseditDisabled}
                            name="originalPrice"
                            style={{ width: "90%" }}
                            type="number"
                            min={"0"}
                            value={id ? formData?.originalPrice : null}
                            onChange={DecriptionHandle}
                            className="add-product-box-input form-control "
                            placeholder="0"
                          />
                        </div>
                      </div>

                    </div>


                    {/* delete */}

                  </div>
                  {formDataError.price && <div className='text-danger pt-1'>{formDataError.price}</div>}
                  {formDataError.originalPrice && <div className='text-danger pt-1'>{formDataError.originalPrice}</div>}
                </>


              </div>)}
              {/*  */}
            </div>
            {/*------------------ Options -----------------*/}
            <div style={{ gap: "16px" }} className="add-product-box">
              <div className="d-flex">
                <h1 className="add-product-box-heading">Options</h1>
                <div className="ps-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10.0003 18.8334C14.6027 18.8334 18.3337 15.1025 18.3337 10.5001C18.3337 5.89771 14.6027 2.16675 10.0003 2.16675C5.39795 2.16675 1.66699 5.89771 1.66699 10.5001C1.66699 15.1025 5.39795 18.8334 10.0003 18.8334Z"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5752 7.99999C7.77112 7.44304 8.15782 6.97341 8.66682 6.67426C9.17583 6.37512 9.77427 6.26577 10.3562 6.36558C10.9381 6.46539 11.4659 6.76792 11.8461 7.21959C12.2263 7.67126 12.4344 8.24292 12.4335 8.83332C12.4335 10.5 9.93353 11.3333 9.93353 11.3333"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 14.6667H10.0083"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              {formData.options.map((item, index) => {
                // if (index == 0) {
                //   return (
                //     <>
                //       <div
                //         key={item.name}
                //         className="d-flex flex-column gap-16 w-100"
                //       >
                //         <h1 className="add-product-box-option">OPTION 1</h1>
                //         <div className="d-flex flex-column w-100">
                //           <label
                //             htmlFor="type"
                //             className="pb-1 add-product-box-input-label"
                //           >
                //             Type
                //           </label>
                //           <div className="dropdown d-flex w-100">
                //             <button
                //               class="  add-product-box-dropdown  dropdown-toggle "
                //               type="button"
                //               id="dropdownMenuButton2"
                //               data-bs-toggle="dropdown"
                //               aria-expanded="false"
                //             >
                //               <p style={{ height: "19px" }} className="p-0 m-0">
                //                 {item.type ? item.type : "Select"}
                //               </p>
                //             </button>
                //             <ul
                //               class="dropdown-menu dropdown-menu"
                //               aria-labelledby="dropdownMenuButton2"
                //             >
                //               <li
                //                 onClick={() =>
                //                   onChangeOptions({
                //                     type: "type",
                //                     event: "choice",
                //                     index,
                //                   })
                //                 }
                //                 className="dropdown-item"
                //               >
                //                 Choice
                //               </li>
                //             </ul>
                //           </div>
                //         </div>

                //         <div className="d-flex flex-column">
                //           <label
                //             className="pb-1 add-product-box-input-label"
                //             htmlFor="qestion"
                //           >
                //             Question
                //           </label>
                //           <input
                //             onChange={(e) =>
                //               onChangeOptions({
                //                 type: "question",
                //                 event: e,
                //                 index,
                //               })
                //             }
                //             value={item.question}
                //             type="text"
                //             className="add-product-box-input form-control"
                //           />
                //         </div>

                //         <div className="d-flex flex-column">
                //           <label
                //             htmlFor=""
                //             className="pb-1 add-product-box-input-label"
                //           >
                //             Choices (Price is optional)
                //           </label>
                //           <div className="d-flex flex-column w-100 gap-16">
                //             {item.choice.map((choice, ind) => {
                //               return (
                //                 <div className="d-flex w-100 align-items-center flex-grow-1">
                //                   <svg
                //                     width="20"
                //                     height="29"
                //                     viewBox="0 0 20 29"
                //                     fill="none"
                //                     xmlns="http://www.w3.org/2000/svg"
                //                   >
                //                     <circle
                //                       cx="6"
                //                       cy="6.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                     <circle
                //                       cx="14"
                //                       cy="6.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                     <circle
                //                       cx="6"
                //                       cy="14.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                     <circle
                //                       cx="14"
                //                       cy="14.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                     <circle
                //                       cx="6"
                //                       cy="22.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                     <circle
                //                       cx="14"
                //                       cy="22.5"
                //                       r="2"
                //                       fill="#BBC3BC"
                //                     />
                //                   </svg>
                //                   <div className="d-flex flex-grow-1">
                //                     <div className="w-75 px-2">
                //                       <input
                //                         value={choice.value}
                //                         onChange={(e) =>
                //                           onChangeOptions({
                //                             type: "choiceText",
                //                             event: e,
                //                             index: ind,
                //                           })
                //                         }
                //                         style={{ width: "100%" }}
                //                         type="text"
                //                         className="add-product-box-input form-control"
                //                         placeholder="Chicago Pizza"
                //                       />
                //                     </div>
                //                     <div className="w-25  d-flex">
                //                       <div className="w-100">
                //                         <input
                //                           value={choice.price}
                //                           onChange={(e) =>
                //                             onChangeOptions({
                //                               type: "choiceNumber",
                //                               event: e,
                //                               index: ind,
                //                             })
                //                           }
                //                           style={{ width: "90%" }}
                //                           type="number"
                //                           className="add-product-box-input form-control "
                //                           placeholder=""
                //                         />
                //                       </div>
                //                     </div>
                //                   </div>
                //                   <svg
                //                     onClick={() => DeleteOptionalChoice(ind)}
                //                     xmlns="http://www.w3.org/2000/svg"
                //                     width="24"
                //                     height="25"
                //                     viewBox="0 0 24 25"
                //                     fill="none"
                //                   >
                //                     <path
                //                       d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5ZM15.36 14.8C15.65 15.09 15.65 15.57 15.36 15.86C15.21 16.01 15.02 16.08 14.83 16.08C14.64 16.08 14.45 16.01 14.3 15.86L12 13.56L9.7 15.86C9.55 16.01 9.36 16.08 9.17 16.08C8.98 16.08 8.79 16.01 8.64 15.86C8.35 15.57 8.35 15.09 8.64 14.8L10.94 12.5L8.64 10.2C8.35 9.91 8.35 9.43 8.64 9.14C8.93 8.85 9.41 8.85 9.7 9.14L12 11.44L14.3 9.14C14.59 8.85 15.07 8.85 15.36 9.14C15.65 9.43 15.65 9.91 15.36 10.2L13.06 12.5L15.36 14.8Z"
                //                       fill="#C32D22"
                //                     />
                //                   </svg>
                //                 </div>
                //               );
                //             })}

                //             <div>
                //               <button
                //                 onClick={() => AddOptionalChoice()}
                //                 className="green-add-btn"
                //                 type="button"
                //               >
                //                 Add
                //               </button>
                //             </div>

                //             <div className="d-flex flex-grow-1 pt-2 w-100">
                //               <div className="flex flex-grow-1 w-50 me-2">
                //                 <label
                //                   htmlFor=""
                //                   className="add-product-box-input-label"
                //                 >
                //                   Minimum choices
                //                 </label>
                //                 <input
                //                   onChange={(event) =>
                //                     onChangeOptions({
                //                       event,
                //                       index,
                //                       type: "min-choice",
                //                     })
                //                   }
                //                   value={item.minimumChoice}
                //                   type="number"
                //                   placeholder="0"
                //                   className="add-product-box-input w-100 form-control"
                //                 />
                //               </div>
                //               <div className="flex flex-grow-1 ms-2 w-50">
                //                 <label
                //                   htmlFor=""
                //                   className="add-product-box-input-label"
                //                 >
                //                   Maximum choices
                //                 </label>
                //                 <input
                //                   onChange={(event) =>
                //                     onChangeOptions({
                //                       event,
                //                       index,
                //                       type: "max-choice",
                //                     })
                //                   }
                //                   value={item.maximumChoice}
                //                   type="number"
                //                   placeholder="0"
                //                   className="add-product-box-input w-100 form-control"
                //                 />
                //               </div>
                //             </div>
                //           </div>
                //         </div>
                //       </div>
                //     </>
                //   );
                // } else
                return (
                  <>
                    <div className="d-flex flex-column gap-16 pt-2 w-100">
                      <div className="d-flex justify-cuntent-between w-100 justify-content-between align-items-center">
                        <h1 className="add-product-box-option">
                          Toppings
                        </h1>
                        {iseditDisabled ? null : <svg
                          onClick={() => DeleteOptions(index)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                        >
                          <path
                            d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5ZM15.36 14.8C15.65 15.09 15.65 15.57 15.36 15.86C15.21 16.01 15.02 16.08 14.83 16.08C14.64 16.08 14.45 16.01 14.3 15.86L12 13.56L9.7 15.86C9.55 16.01 9.36 16.08 9.17 16.08C8.98 16.08 8.79 16.01 8.64 15.86C8.35 15.57 8.35 15.09 8.64 14.8L10.94 12.5L8.64 10.2C8.35 9.91 8.35 9.43 8.64 9.14C8.93 8.85 9.41 8.85 9.7 9.14L12 11.44L14.3 9.14C14.59 8.85 15.07 8.85 15.36 9.14C15.65 9.43 15.65 9.91 15.36 10.2L13.06 12.5L15.36 14.8Z"
                            fill="#C32D22"
                          />
                        </svg>}
                      </div>

                      <div className="d-flex flex-column w-100">
                        <label
                          htmlFor="type"
                          className="pb-1 add-product-box-input-label"
                        >
                          Type
                        </label>
                        <div className="d-flex w-100">
                          <div className="dropdown d-flex w-50 me-2">
                            <button
                              disabled={iseditDisabled}

                              class="  add-product-box-dropdown dropdown-toggle "
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p
                                style={{ height: "19px", textTransform: "capitalize" }}
                                className="p-0 m-0"
                              >
                                {item.type ? item.type : "Select"}
                              </p>
                            </button>
                            <ul
                              class="dropdown-menu dropdown-menu"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              <li
                                onClick={() =>
                                  onChangeOptions({
                                    type: "type",
                                    event: "choice",
                                    index,
                                  })
                                }
                                className="dropdown-item "
                              >
                                Choice
                              </li>
                              {/* <li
                                onClick={() =>
                                  onChangeOptions({
                                    type: "type",
                                    event: "text",
                                    index,
                                  })
                                }
                                className="dropdown-item "
                              >
                                Text
                              </li>
                              <li
                                onClick={() =>
                                  onChangeOptions({
                                    type: "type",
                                    event: "date",
                                    index,
                                  })
                                }
                                className="dropdown-item "
                              >
                                Date
                              </li>
                              <li
                                onClick={() =>
                                  onChangeOptions({
                                    type: "type",
                                    event: "time",
                                    index,
                                  })
                                }
                                className="dropdown-item "
                              >
                                Time
                              </li> */}
                            </ul>
                          </div>
                          <div className="dropdown d-flex ms-2 w-50">
                            <button
                              disabled={iseditDisabled}

                              class="  add-product-box-dropdown dropdown-toggle "
                              type="button"
                              id="dropdownMenuButton2"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <p
                                style={{ height: "19px", textTransform: "capitalize" }}
                                className="p-0 m-0"
                              >
                                {item.questionType ? item.questionType : "Optional/Required"}
                              </p>
                            </button>
                            <ul
                              class="dropdown-menu dropdown-menu"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              <li onClick={() =>
                                onChangeOptions({
                                  type: "questionType",
                                  event: "optional",
                                  index,
                                })
                              }>
                                <div style={{ cursor: "pointer" }} class="dropdown-item " href="#">
                                  Optional
                                </div>
                              </li>
                              <li onClick={() =>
                                onChangeOptions({
                                  type: "questionType",
                                  event: "required",
                                  index,
                                })
                              }>
                                <div style={{ cursor: "pointer" }} class="dropdown-item " href="#">
                                  Required
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column w-100">
                        <label
                          htmlFor="type"
                          className="pb-1 add-product-box-input-label"
                        >
                          Question
                        </label>
                        <input
                          disabled
                          onChange={(e) =>
                            onChangeOptions({
                              type: "question",
                              event: e,
                              index,
                            })
                          }
                          value={item.question}
                          type="text"
                          className="add-product-box-input form-control"
                        />
                      </div>
                      {formData.options[index].isViewChoice ? <div className="d-flex flex-column">
                        <label
                          htmlFor=""
                          className="pb-1 add-product-box-input-label"
                        >
                          Choices (Price is optional)
                        </label>
                        <div className="d-flex flex-column w-100 gap-16">
                          {item.choice.map((choice, ind) => {
                            return (
                              <div className="d-flex w-100 align-items-center flex-grow-1">
                                <svg
                                  width="20"
                                  height="29"
                                  viewBox="0 0 20 29"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="6"
                                    cy="6.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                  <circle
                                    cx="14"
                                    cy="6.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                  <circle
                                    cx="6"
                                    cy="14.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                  <circle
                                    cx="14"
                                    cy="14.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                  <circle
                                    cx="6"
                                    cy="22.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                  <circle
                                    cx="14"
                                    cy="22.5"
                                    r="2"
                                    fill="#BBC3BC"
                                  />
                                </svg>
                                <div className="d-flex flex-grow-1">
                                  <div className="w-75 px-2">
                                    <input
                                      disabled={iseditDisabled}

                                      value={choice.value}
                                      onChange={(e) =>
                                        onChangeOptions({
                                          type: "choiceText",
                                          event: e,
                                          index: ind,
                                          mainindex: index
                                        })
                                      }
                                      style={{ width: "100%" }}
                                      type="text"
                                      className="add-product-box-input form-control"
                                      placeholder="Chicago Pizza"
                                    />
                                  </div>
                                  <div className="w-25  d-flex">
                                    <div className="w-100">
                                      <input
                                        disabled={iseditDisabled}

                                        value={choice.price}
                                        onChange={(e) =>
                                          onChangeOptions({
                                            type: "choiceNumber",
                                            event: e,
                                            index: ind,
                                            mainindex: index
                                          })
                                        }
                                        style={{ width: "90%" }}
                                        type="number"
                                        className="add-product-box-input form-control "
                                        placeholder="0"
                                        min={"0"}

                                      />
                                    </div>
                                  </div>
                                </div>
                                {iseditDisabled ? null : <svg
                                  onClick={() => DeleteOptionalChoice(ind)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="25"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                >
                                  <path
                                    d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5ZM15.36 14.8C15.65 15.09 15.65 15.57 15.36 15.86C15.21 16.01 15.02 16.08 14.83 16.08C14.64 16.08 14.45 16.01 14.3 15.86L12 13.56L9.7 15.86C9.55 16.01 9.36 16.08 9.17 16.08C8.98 16.08 8.79 16.01 8.64 15.86C8.35 15.57 8.35 15.09 8.64 14.8L10.94 12.5L8.64 10.2C8.35 9.91 8.35 9.43 8.64 9.14C8.93 8.85 9.41 8.85 9.7 9.14L12 11.44L14.3 9.14C14.59 8.85 15.07 8.85 15.36 9.14C15.65 9.43 15.65 9.91 15.36 10.2L13.06 12.5L15.36 14.8Z"
                                    fill="#C32D22"
                                  />
                                </svg>}
                              </div>
                            );
                          })}

                          {iseditDisabled ? null : <div>
                            <button
                              onClick={() => AddOptionalChoice(index)}
                              className="green-add-btn"
                              type="button"
                            >
                              Add
                            </button>
                          </div>}

                          <div className="d-flex flex-grow-1 pt-2 w-100">
                            <div className="flex flex-grow-1 w-50 me-2">
                              <label
                                htmlFor=""
                                className="add-product-box-input-label"
                              >
                                Minimum choices
                              </label>
                              <input
                                disabled={iseditDisabled}

                                onChange={(event) =>
                                  onChangeOptions({
                                    event,
                                    index,
                                    type: "min-choice",
                                  })
                                }
                                value={item.minmumChoice}
                                type="number"
                                placeholder="0"
                                min={"0"}
                                className="add-product-box-input w-100 form-control"
                              />
                            </div>
                            <div className="flex flex-grow-1 ms-2 w-50">
                              <label
                                htmlFor=""
                                className="add-product-box-input-label"
                              >
                                Maximum choices
                              </label>
                              <input
                                disabled={iseditDisabled}

                                onChange={(event) =>
                                  onChangeOptions({
                                    event,
                                    index,
                                    type: "max-choice",
                                  })
                                }
                                value={item.maximumChoice}
                                type="number"
                                placeholder="0"
                                min={"0"}
                                className="add-product-box-input w-100 form-control"
                              />
                            </div>
                          </div>
                        </div>
                      </div> : <div />}
                    </div>
                  </>
                );
              }
              )}

              {/* add button */}
              <div className="w-100">
                {iseditDisabled ? null : <button
                  onClick={() => AddOptions()}
                  className="green-add-btn"
                  type="button"
                >
                  Add Option
                </button>}
              </div>
            </div>
            {/* Collections */}
            <div className="d-flex add-product-box gap-16 w-100">
              <h1 className="add-product-box-heading">Collections</h1>
              <div className="d-flex flex-column w-100">
                {collectionData?.map((itm, k) => (<div className="d-flex align-items-center ">
                  <input
                    disabled={iseditDisabled}
                    checked={checkedItems.includes(itm.id)}
                    onChange={() => handleCheckboxChange(itm.id)}
                    style={{
                      width: "15px",
                      height: "15px",
                      accentColor: "#26735A",
                    }}
                    type="checkbox"
                    className="me-2 input-checkbox-green "
                  />
                  <label style={{ textTransform: "capitalize" }} className="check-box-text" htmlFor="">
                    {itm?.collectionName?.toLowerCase()}
                  </label>
                </div>))}

              </div>
              {collectionBanner ? <div className="d-flex gap-16 collection-green-border w-100">
                {/* icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0003 2.6665C8.65366 2.6665 2.66699 8.65317 2.66699 15.9998C2.66699 23.3465 8.65366 29.3332 16.0003 29.3332C23.347 29.3332 29.3337 23.3465 29.3337 15.9998C29.3337 8.65317 23.347 2.6665 16.0003 2.6665ZM15.0003 10.6665C15.0003 10.1198 15.4537 9.6665 16.0003 9.6665C16.547 9.6665 17.0003 10.1198 17.0003 10.6665V17.3332C17.0003 17.8798 16.547 18.3332 16.0003 18.3332C15.4537 18.3332 15.0003 17.8798 15.0003 17.3332V10.6665ZM17.227 21.8398C17.1603 22.0132 17.067 22.1465 16.947 22.2798C16.8137 22.3998 16.667 22.4932 16.507 22.5598C16.347 22.6265 16.1737 22.6665 16.0003 22.6665C15.827 22.6665 15.6537 22.6265 15.4937 22.5598C15.3337 22.4932 15.187 22.3998 15.0537 22.2798C14.9337 22.1465 14.8403 22.0132 14.7737 21.8398C14.707 21.6798 14.667 21.5065 14.667 21.3332C14.667 21.1598 14.707 20.9865 14.7737 20.8265C14.8403 20.6665 14.9337 20.5198 15.0537 20.3865C15.187 20.2665 15.3337 20.1732 15.4937 20.1065C15.8137 19.9732 16.187 19.9732 16.507 20.1065C16.667 20.1732 16.8137 20.2665 16.947 20.3865C17.067 20.5198 17.1603 20.6665 17.227 20.8265C17.2937 20.9865 17.3337 21.1598 17.3337 21.3332C17.3337 21.5065 17.2937 21.6798 17.227 21.8398Z"
                    fill="#26735A"
                  />
                </svg>
                {/* ----- */}
                <div
                  style={{ gap: "24px" }}
                  className="d-flex flex-column flex-grow-1"
                >
                  <div>
                    <h1 className="add-product-box-heading">
                      Organize products in your store
                    </h1>
                    <p className="p-0 m-0">
                      Assign products to collections to group products in the
                      same way you do with categories
                    </p>
                  </div>
                  <button
                    onClick={() => navigate('/vendor/collections')}
                    className="collection-green-btn"
                    type="button"
                  >
                    Go to collection page
                  </button>
                </div>
                {/* close icon */}
                <div style={{ cursor: "pointer" }} onClick={() => { setCollectionBanner(!collectionBanner) }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                  >
                    <path
                      d="M21.8019 13.2584L13.3166 21.7437C13.0267 22.0336 12.5458 22.0336 12.2559 21.7437C11.966 21.4538 11.966 20.973 12.2559 20.683L20.7412 12.1978C21.0311 11.9079 21.5119 11.9079 21.8019 12.1978C22.0918 12.4877 22.0918 12.9685 21.8019 13.2584Z"
                      fill="#444444"
                    />
                    <path
                      d="M21.8019 21.7436C21.5119 22.0336 21.0311 22.0336 20.7412 21.7436L12.2559 13.2584C11.966 12.9684 11.966 12.4876 12.2559 12.1977C12.5458 11.9078 13.0267 11.9078 13.3166 12.1977L21.8019 20.683C22.0918 20.9729 22.0918 21.4537 21.8019 21.7436Z"
                      fill="#444444"
                    />
                  </svg>
                </div>
                {/* ------ */}
              </div> : null}
            </div>
          </div>

          {/* Right side */}
          <div className="col-12 col-lg-6 py-4">
            {/* ADD product IMAGE */}
            <div style={{ gap: "16px" }} className="add-product-box">
              <div className="d-flex">
                <h1 className="add-product-box-heading">Product Images</h1>
                <div className="ps-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10.0003 18.8334C14.6027 18.8334 18.3337 15.1025 18.3337 10.5001C18.3337 5.89771 14.6027 2.16675 10.0003 2.16675C5.39795 2.16675 1.66699 5.89771 1.66699 10.5001C1.66699 15.1025 5.39795 18.8334 10.0003 18.8334Z"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5752 7.99999C7.77112 7.44304 8.15782 6.97341 8.66682 6.67426C9.17583 6.37512 9.77427 6.26577 10.3562 6.36558C10.9381 6.46539 11.4659 6.76792 11.8461 7.21959C12.2263 7.67126 12.4344 8.24292 12.4335 8.83332C12.4335 10.5 9.93353 11.3333 9.93353 11.3333"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 14.6667H10.0083"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {/* add images */}
              <div style={{ float: "left" }} className=" gap-16 ">
                {formData?.productImage.map((item, index) => {
                  return (
                    <div key={index} style={{ display: 'inline-block' }} className={`${addImageModal == true ? null : "image-icon-add-product1"} `}>
                      <img
                        style={{

                          float: 'left',
                          marginRight: '16px',
                          marginBottom: '16px',
                        }}
                        src={item}
                        className="add-product-img-size"
                        alt=""
                      />
                      <div

                      >
                        {iseditDisabled ? null : <>{!addImageModal ? <div style={{

                          position: 'absolute',
                          top: '10px',
                          right: '25px',
                          cursor: 'pointer',
                        }}
                          onClick={() => onRemoveImage(index)} className="image-icon-add-product" >
                          <div className="image-icon-add-product">
                            <i className="fa-solid fa-xmark fa-2xs"></i>
                          </div>
                        </div> : null}</>}

                      </div>
                    </div>

                  );
                })}
                {iseditDisabled ? null : <button
                  type="button"
                  onClick={() => setAddImageModal(true)}
                  style={{ float: "left", marginRight: "16px" }}
                  className="add-product-img-size "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                      fill="white"
                    />
                    <path
                      d="M12 5.5V18"
                      stroke="#60C2A2"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                    <path
                      d="M18 12L5.5 12"
                      stroke="#60C2A2"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>}
              </div>
              {formDataError.productImage && <div className='text-danger pt-1'>{formDataError.productImage}</div>}

            </div>
            {/* Inventory */}
            <div className="add-product-box">
              {/* Heading */}
              <div className="d-flex">
                <h1 className="add-product-box-heading">Inventory</h1>
                <div className="ps-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                  >
                    <path
                      d="M10.0003 18.8334C14.6027 18.8334 18.3337 15.1025 18.3337 10.5001C18.3337 5.89771 14.6027 2.16675 10.0003 2.16675C5.39795 2.16675 1.66699 5.89771 1.66699 10.5001C1.66699 15.1025 5.39795 18.8334 10.0003 18.8334Z"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5752 7.99999C7.77112 7.44304 8.15782 6.97341 8.66682 6.67426C9.17583 6.37512 9.77427 6.26577 10.3562 6.36558C10.9381 6.46539 11.4659 6.76792 11.8461 7.21959C12.2263 7.67126 12.4344 8.24292 12.4335 8.83332C12.4335 10.5 9.93353 11.3333 9.93353 11.3333"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 14.6667H10.0083"
                      stroke="#444444"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {/*  */}
              <div className="d-flex flex-column gap-16 w-100">
                <div className="d-flex flex-column w-100">
                  <div className="d-flex w-100">
                    <div
                      style={{
                        zIndex:
                          addImageModal == true
                            ? "-1"
                            : addCollectionModal
                              ? "0"
                              : "-1",
                      }}
                      className="dropdown d-flex flex-column w-50 me-2"
                    >
                      <label
                        htmlFor="type"
                        className="pb-1 add-product-box-input-label"
                      >
                        Availability
                      </label>
                      <button
                        disabled={iseditDisabled}

                        class="  add-product-box-dropdown dropdown-toggle "
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <p style={{ height: "19px", textTransform: "capitalize" }} className="p-0 m-0">
                          {formData?.inventory?.Availability == ""
                            ? "Select"
                            : formData?.inventory?.Availability}
                        </p>
                      </button>
                      <ul
                        class="dropdown-menu dropdown-menu"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        <li
                          onClick={() =>
                            oninventoryHandle({
                              type: "availability",
                              value: "available",
                            })
                          }
                          className="dropdown-item"
                        >
                          Available
                        </li>
                        <li
                          onClick={() =>
                            oninventoryHandle({
                              type: "availability",
                              value: "unavailable",
                            })
                          }
                          className="dropdown-item"
                        >
                          Unavailable
                        </li>
                      </ul>
                    </div>
                    <div className=" d-flex flex-column ms-2 w-50">
                      <label
                        htmlFor="type"
                        className="pb-1 add-product-box-input-label"
                      >
                        SKU (Optional)
                      </label>
                      <input
                        disabled={iseditDisabled}

                        value={formData?.inventory?.SKU}
                        onChange={(e) =>
                          oninventoryHandle({ type: "SKU", event: e })
                        }
                        type="text"
                        className="add-product-box-input form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center ">
                  <input
                    disabled={iseditDisabled}

                    checked={formData?.trackQuantity}
                    onChange={onChangeTrackQuantiy}
                    className="input-checkbox-green "
                    type="checkbox"
                  />
                  <label
                    htmlFor=""
                    className="add-product-box-input-label ps-2"
                  >
                    Track quantity
                  </label>
                </div>
                {formData.trackQuantity ? (
                  <>
                    {formData.isVarient ? (<>
                      {formData.variants.map((item, index) => {
                        return (
                          <div className="d-flex flex-column w-100">
                            <label
                              htmlFor="type"
                              className="pb-1 add-product-box-input-label"
                            >
                              {item.name}
                            </label>
                            <input
                              disabled={iseditDisabled}
                              placeholder="0"
                              min={"0"}
                              value={id ? item?.productCount : null}
                              name="productCount"
                              onChange={(e) =>
                                onVariantChange({ event: e, index: index })
                              }
                              type="number"
                              className="add-product-box-input form-control"
                            />
                            {formDataError?.variants[index]?.productCount && <div className='text-danger pt-1'>{formDataError?.variants[index]?.productCount}</div>}

                          </div>
                        );
                      })}</>) : (<>
                        <div className="d-flex flex-column w-100">
                          <label
                            htmlFor="type"
                            className="pb-1 add-product-box-input-label"
                          >
                            {formData.productName}
                          </label>
                          <input
                            disabled={iseditDisabled}
                            placeholder="0"
                            min={"0"}
                            value={id ? formData?.productCount : null}
                            name="productCount"
                            onChange={(e) => DecriptionHandle(e)}
                            type="number"
                            className="add-product-box-input form-control"
                          />
                          {formDataError?.productCount && <div className='text-danger pt-1'>{formDataError?.productCount}</div>}

                        </div>
                      </>)}
                  </>
                ) : (
                  ""
                )}
              </div>
              {/*  */}
            </div>
            {/* Product Dimensions */}
            <div className="gap-16 d-flex flex-column add-product-box">
              <h1 className="add-product-box-heading">Product Dimensions</h1>
              <div className="row w-100">
                {/*  */}
                <div className="col-3">
                  <label htmlFor="" className="add-product-box-input-label">
                    Length
                  </label>
                  <div className="add-product-box-input d-flex ">
                    <input
                      disabled={iseditDisabled}
                      placeholder="0"
                      min={"0"}
                      value={formData?.productDimensions.length}
                      name="length"
                      onChange={onProductDimensions}
                      className="input-transpirant "
                      type="number"
                    />
                    <p className="p-0 length-type m-0">cm</p>
                  </div>
                </div>
                {/*  */}
                <div className="col-3">
                  <label htmlFor="" className="add-product-box-input-label">
                    Breadth
                  </label>
                  <div className="add-product-box-input d-flex ">
                    <input
                      disabled={iseditDisabled}
                      placeholder="0"
                      min={"0"}
                      value={formData?.productDimensions.breadth}
                      onChange={onProductDimensions}
                      name="breadth"
                      className="input-transpirant "
                      type="number"
                    />
                    <p className="p-0 length-type m-0">cm</p>
                  </div>
                </div>
                {/*  */}
                <div className="col-3">
                  <label htmlFor="" className="add-product-box-input-label">
                    Height
                  </label>
                  <div className="add-product-box-input d-flex ">
                    <input
                      disabled={iseditDisabled}
                      placeholder="0"
                      min={"0"}
                      value={formData?.productDimensions.height}
                      onChange={onProductDimensions}
                      name="height"
                      className="input-transpirant "
                      type="number"
                    />
                    <p className="p-0 length-type m-0">cm</p>
                  </div>
                </div>
                {/*  */}
                <div className="col-3">
                  <label htmlFor="" className="add-product-box-input-label">
                    Weight
                  </label>
                  <div className="add-product-box-input d-flex ">
                    <input
                      disabled={iseditDisabled}
                      placeholder="0"
                      min={"0"}
                      value={formData?.productDimensions.weight}
                      onChange={onProductDimensions}
                      name="weight"
                      className="input-transpirant "
                      type="number"
                    />
                    <p className="p-0 length-type m-0">Kg</p>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }} className="mt-3 text-center d-flex justify-content-center">
                <div>
                  {edit === "true" ? null : <>{iseditDisabled ? null : <>{!isSaveHidden ? <button type="Submit" className="bulkshare112">
                    Save
                  </button> : <button class="btn btn-success" type="button" disabled>
                    <span class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                    Saving...
                  </button>}</>}</>}
                </div>

              </div>

            </div>
          </div>
        </div>
        </div>
       

      </form>
    </div>
  );
}
