import React, { useEffect, useState } from 'react'
import "../ClaimCouponsOffers/ClaimCouponsOffers.css"
import toast, { Toaster } from 'react-hot-toast'
import api from '../ApiConfigration/ApiConfigration'
import { useNavigate, useParams } from 'react-router-dom'


function ClaimCouponAfterCampaingn({ pageData }) {

    var navigate = useNavigate()
    const [userData, setUserData] = useState()
    const [dicount, setDiscount] = useState("")
    const { campaignid } = useParams();



    useEffect(() => {
        setUserData(pageData?.bulkshareData)
        setDiscount(pageData?.percentage)

    }, [pageData])

    const claimCoupon = () => {

        var coupon = {
            bulkShareClaimId: campaignid,
            discount: dicount
        }

        api.post("/campaign/claimcampaigncoupon", coupon).then((res) => {
            if (res) {
                toast.success(res?.data?.message?.message)
                window.location.replace(`/customer/offername?id=${res?.data?.message?.id}`)
            } else {
                toast.error("Error claiming coupon")
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)

            if (err?.response?.data?.errors?.code != "error") {
                window.location.replace(`/customer/offername?id=${err?.response?.data?.errors?.code}`)

            }
        })

    }



    return (
        <div>

            <form>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className='claimcouponoffersq'>

                    <h3 className='claimcouponheading'>Claim your offer</h3>
                    <h3 className='claimcouponsdescript'>{userData?.productName}</h3>


                    <div className='d-flex align-items-center mt-3 mb-4'>
                        <span><h3 className='heading-claim-item'>{dicount}%</h3></span> <span className='mx-2 heading-claim-item3'>OFF</span>

                    </div>

                    <div className='d-flex justify-content-between'>
                        <button type='button' className='claimoffersbutoonedc'>
                            Close
                        </button>
                        <button onClick={claimCoupon} type='button' className='claimoffersbutoonedc'>
                            Confirm
                        </button >
                    </div>

                </div>
            </form>



        </div>
    )
}

export default ClaimCouponAfterCampaingn