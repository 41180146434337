import React, { useEffect, useState, useRef } from 'react'
import "./SpinAndWinCreateCampaign.css"
import toast, { Toaster } from 'react-hot-toast'
import BuyPremium from '../BuyPremium/BuyPremium'
import arrowup from "../../Images/arrow-up.png"
import Spinner from '../../Customer/Spinner/Spinner'
import "../../Vendor/BulkshareCampaign/BulkshareCampaign.css"
import api from '../ApiConfigration/ApiConfigration'
import { useLocation, useNavigate } from 'react-router-dom'
import DynamicInputField from './DynamicInputField'
import Select from 'react-select';
import { useDispatch } from 'react-redux'
import { SetRemoveSpinAndWin } from '../../../Redux/vendorReducer'


function SpinAndWinCreateCampaign() {

    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch();
    var navigate = useNavigate();
    var frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const edit = queryParams.get('edit');


    const initialRow = {
        weightingFactor: '',
        chanceToWin: '',
        title: '',
        couponCode: '',
        discount: '',

    };

    const initialErrors = {
        weightingFactor: '',
        chanceToWin: '',
        title: '',
        couponCode: '',
        discount: '',

    };

    const placeHolder = [
        {
            weightingFactor: '1',
            chanceToWin: '25',
            title: '50% off',
            couponCode: 'OFFER50',
            discount: '50',

        },
        {
            weightingFactor: '0',
            chanceToWin: '0',
            title: 'Next Time',
            couponCode: '0',
            discount: '0',

        },
        {
            weightingFactor: '1',
            chanceToWin: '25',
            title: '10% off',
            couponCode: 'OFFER10',
            discount: '10',

        },
        {
            weightingFactor: '0',
            chanceToWin: '0',
            title: 'No Luck',
            couponCode: '0',
            discount: '0',

        },
        {
            weightingFactor: '1',
            chanceToWin: '25',
            title: '70% off',
            couponCode: 'OFFER70',
            discount: '70',

        },
        {
            weightingFactor: '0',
            chanceToWin: '0',
            title: 'So Close',
            couponCode: '0',
            discount: '0',

        },
        {
            weightingFactor: '1',
            chanceToWin: '25',
            title: '40% off',
            couponCode: 'OFFER40',
            discount: '40',

        },
        {
            weightingFactor: '0',
            chanceToWin: '0',
            title: 'Not Win',
            couponCode: '0',
            discount: '0',

        }]

    const [isSaveHidden, setIsSaveHidden] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    const [formData, setFormData] = useState({})
    const [campaignData, setCampaignData] = useState({})
    const [rows, setRows] = useState(Array.from({ length: 8 }, () => ({ ...initialRow })));
    const [errors, setErrors] = useState(Array.from({ length: 8 }, () => ({ ...initialErrors })));
    const [formError, setFormError] = useState('');
    const [formError2, setFormError2] = useState('');
    const [totalWeight, setTotalWeight] = useState(0);
    const [winChancePercentages, setWinChancePercentages] = useState([]);
    const [vendorCoupons, setVendorCoupons] = useState([])
    const [isdataloaded, setIsDataLoaded] = useState(false)

    useEffect(() => {
        console.log(rows, campaignData)
    }, [rows, campaignData])


    useEffect(() => {
        const getCoupons = async () => {

            api.get("/campaigns/getallcouponcodes").then((res) => {
                if (res) {
                    setVendorCoupons(res?.data?.message?.message)
                } else {
                    console.log("error in fetching vendor coupons")
                }
            }).catch((err) => {
                toast.error(err?.message)
            })

        }
        getCoupons()
    }, [])


    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);

        calculateWinChancePercentages()

        const updatedErrors = [...errors];
        updatedErrors[index][field] = '';
        setErrors(updatedErrors);
    };





    const calculateWinChancePercentages = () => {
        const sumWeight = rows.reduce((acc, row) => acc + Number(row.weightingFactor) || 0, 0);
        setTotalWeight(sumWeight);

        const percentages = rows.map((row) => {
            const weight = Number(row.weightingFactor) || 0;
            return sumWeight !== 0 ? ((weight / sumWeight) * 100).toFixed(2) : 0;
        });

        setWinChancePercentages(percentages);

        // Calculate and set chance to win in each row
        const updatedRows = rows.map((row, index) => {
            const winChance = percentages[index] || 0;
            return { ...row, chanceToWin: winChance };
        });

        setRows(updatedRows);
    };

    const isValid = () => {
        const isEmpty = rows.some((row, index) => {
            const emptyField = Object.keys(row).find((field) => row[field] === '');
            if (emptyField) {
                const updatedErrors = [...errors];
                updatedErrors[index][emptyField] = `*Required`;
                setErrors(updatedErrors);
            }
            return emptyField;
        });

        if (isEmpty) {
            setFormError('Please fill in all fields.');
            return false;
        }

        return errors.every((errorObject) => Object.values(errorObject).every((error) => error === ''));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormError('');

        console.log(campaignData)

        if (!campaignData.campaignName) {
            setFormError2({ ...formError2, campaignName: "Campaign name is required" })
            return;
        }

        if (!campaignData?.expiryDate) {
            setFormError2({ ...formError2, expiryDate: "Expiry date field is required" })
            return;
        }

        if (String(campaignData?.isCustomerView) !== "true" && String(campaignData?.isCustomerView) !== "false") {
            setFormError2({ ...formError2, isCustomerView: "View Type is Require" })
            return;
        }

        const today = new Date();
        const selectedDate = new Date(campaignData?.expiryDate);

        if (selectedDate <= today) {
            setFormError2((prevErrors) => ({
                ...prevErrors,
                expiryDate: "Please select an Expiry Date greater than today",
            }));
            return;
        }

        if (isValid()) {


            setIsSaveHidden(true)


            if (edit === "true") {

                await api.post("/campaigns/updatespinandwinbyid", { rows, campaignData }).then(async (res) => {

                    if (res) {


                        toast.success(res?.data?.message?.message)
                        const delayTime = 1000;
                        await new Promise((resolve) => setTimeout(resolve, delayTime));
                        navigate(`/vendor/spinandwincampaign?id=${id}`)


                    } else {
                        toast.error("Error creating campaign")
                        setIsSaveHidden(false)

                    }

                }).catch((err) => {
                    setIsSaveHidden(false)
                    toast.error(err?.message)
                })


            } else {

                await api.post("/campaigns/createspinandwin", { rows, campaignData }).then(async (res) => {
                    if (res) {
                        toast.success(res?.data?.message?.message)
                        const delayTime = 1000;
                        await new Promise((resolve) => setTimeout(resolve, delayTime));
                        dispatch(SetRemoveSpinAndWin());
                        navigate(`/vendor/spinandwincampaign?id=${res?.data?.message?.code}&edit=false`)


                    } else {
                        toast.error("Error creating campaign")
                        setIsSaveHidden(false)

                    }
                }).catch((err) => {
                    setIsSaveHidden(false)
                    toast.error(err?.message)
                })

            }
        } else {
            toast.error("All fields required")
        }
    };


    useEffect(() => {



        async function getSpinWinById() {

            await api.get(`/campaigns/getspinandwinbyid?id=${id}`).then((res) => {
                if (res) {
                    setRows(res?.data?.message?.message?.spinFactors)
                    setFormData(res?.data?.message?.message)
                    setCampaignData(res?.data?.message?.message)
                    setIsDataLoaded(true)
                }
            }).catch((err) => {
                console.log(err?.response?.data?.errors?.message[0])
                toast.error("Error fetching data")
            })
        }
        if (id && (edit === "false" || !edit)) {
            setDisabled(true)
        }

        if (id) {
            getSpinWinById()

        } else {
            setIsDataLoaded(true)
        }


    }, [id, edit])


    const sharedetails = async () => {

        var urltocopy = `${frontendUrl}/${formData?.isCustomerView == true ? "customer" : "vendor"}/spin-and-win-campaign/${formData?.uniqueCode}`
        try {
            const textarea = document.createElement('textarea');
            textarea.value = urltocopy;
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            document.execCommand('copy');
            document.body.removeChild(textarea);
            toast.success("Link copied to clipboard!")

        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }


    const setTemsAndConditionsChange = (data) => {
        setCampaignData({ ...campaignData, termsAndCondition: data });
    };

    const setCampaignName = (e) => {
        setCampaignData({ ...campaignData, campaignName: e.target.value });
        setFormData({ ...campaignData, campaignName: e.target.value })
        setFormError2({ ...formError2, campaignName: "" })
    }
    const setExpiryData = (e) => {
        setCampaignData({ ...campaignData, expiryDate: e.target.value });
        setFormData({ ...campaignData, expiryDate: e.target.value });
        setFormError2({ ...formError2, expiryDate: "" })
    }

    const setViewData = (e) => {
        setCampaignData({ ...campaignData, isCustomerView: e });
        setFormData({ ...formData, isCustomerView: e });
        setFormError2({ ...formError2, isCustomerView: "" })
    }

    const handleSelectChange = (selectedOption, index) => {
        if (selectedOption) {
            console.log(selectedOption)
            const updatedRows = [...rows];
            updatedRows[index].couponCode = selectedOption?.value?.code;
            updatedRows[index].discount = selectedOption?.value?.discount;

            setRows(updatedRows);

            const updatedErrors = [...errors];
            updatedErrors[index].couponCode = '';
            updatedErrors[index].discount = '';

            setErrors(updatedErrors);

        }
    };

    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='row'>
                <div className='col-12 col-lg-8' >
                    <form onSubmit={handleSubmit}>
                        {/* tope heading */}
                        <div className='d-flex justify-content-between'>
                            <div className='bulkshare111'>Spin and Win</div>
                            {
                                (!id && !edit) ? <div>{isSaveHidden ? null : <button type='Submit' className='bulkshare112'>Save</button>}</div> : null
                            }
                            {
                                edit === "true" ? <>{isSaveHidden ? null : <button type='Submit' className='bulkshare112'>Update</button>}</> : null

                            }
                        </div>

                        {/* form */}
                        <div className='bulkshare113'>


                            <div class="mb-5">
                                <label for="exampleFormControlInput1" class="form-label">Campaign Name</label>
                                <input type="text"
                                    name='campaignName'
                                    onChange={setCampaignName}
                                    value={formData?.campaignName}
                                    class="form-control"
                                    id="exampleFormControlInput1"
                                    disabled={isDisabled} />
                                {formError2?.campaignName ? <div className='text-danger mt-1'>{formError2?.campaignName}</div> : null}
                            </div>


                            <div className=' table_style-overflow'>
                                <div>
                                    <table className="table table-sm table-borderless table_style-overflow">
                                        <thead className="table112">
                                            <tr>
                                                <th scope="col">Weighting Factor</th>
                                                <th scope="col">Chance to win</th>
                                                <th scope="col">Title</th>
                                                <th scope="col">Coupon Code</th>
                                                <th scope="col">Discount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows.map((row, index) => (
                                                <tr key={index} className="mb-3">
                                                    <td >
                                                        <input
                                                            type="number"
                                                            name="weightingFactor"
                                                            placeHolder={placeHolder[index].weightingFactor}
                                                            className="form-control mb-3"
                                                            min="0"
                                                            value={row.weightingFactor}
                                                            disabled={isDisabled}
                                                            onChange={(e) => handleChange(index, 'weightingFactor', e.target.value)}
                                                        />
                                                        {errors[index]?.weightingFactor && (
                                                            <div className="text-danger">{errors[index]?.weightingFactor} </div>
                                                        )}

                                                    </td>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            name="chanceToWin"
                                                            className="form-control mb-3"
                                                            placeHolder={placeHolder[index].chanceToWin}
                                                            value={`${row.chanceToWin}` + "%"}
                                                            disabled={true}
                                                            onChange={(e) => handleChange(index, 'chanceToWin', e.target.value)}
                                                        />
                                                        {errors[index]?.chanceToWin && (
                                                            <div className="text-danger">{errors[index]?.chanceToWin} </div>
                                                        )}
                                                    </td>

                                                    <td>
                                                        <input
                                                            type="text"
                                                            name="title"
                                                            className="form-control mb-3"
                                                            placeHolder={placeHolder[index].title}
                                                            value={row.title}
                                                            disabled={isDisabled}
                                                            onChange={(e) => handleChange(index, 'title', e.target.value)}
                                                        />
                                                        {errors[index]?.title && (
                                                            <div className="text-danger">{errors[index]?.title}</div>
                                                        )}
                                                    </td>

                                                    {/* <td>
                                                        <input
                                                            type="select"
                                                            name="couponCode"
                                                            placeHolder={placeHolder[index].couponCode}
                                                            onFocus={() => showDropdown(index, 'dropdown')}
                                                            onBlur={() => hideDropdown(index, 'dropdown')}
                                                            className="form-control mb-3"
                                                            value={row.couponCode}
                                                            disabled={isDisabled}
                                                            onChange={(e) => handleChange(index, 'couponCode', e.target.value)}
                                                        />
                                                        {row.dropdown === true ? <div className='spinwindropdown_background'>
                                                            <div >
                                                                {vendorCoupons?.map((itm, k) =>
                                                                    <div onClick={() => handleChange(index, 'couponCode', itm?.couponCodes)} className='dropDown_items ps-3'>{itm?.couponCodes}</div>
                                                                )}
                                                            </div>
                                                        </div> : null}
                                                        {errors[index]?.couponCode && (
                                                            <div className="text-danger">{errors[index]?.couponCode}</div>
                                                        )}
                                                    </td> */}
                                                    <td>
                                                        {isdataloaded ? <div className='tablepostion_arrange_coupon'>
                                                            <Select
                                                                isDisabled={isDisabled}
                                                                defaultValue={
                                                                    row.couponCode ?
                                                                        { label: String(row?.discount) === "0" ? "NOCODE" : row?.couponCode, value: { code: String(row?.couponCode), discount: String(row?.discount) } }
                                                                        : null}
                                                                onChange={(i) => handleSelectChange(i, index)}
                                                                options={vendorCoupons} />
                                                        </div> : <input className="form-control mb-3"
                                                            disabled={true} />}

                                                        {errors[index]?.couponCode && (
                                                            <div className="text-danger">{errors[index]?.couponCode}</div>
                                                        )}

                                                    </td>

                                                    <td>
                                                        <input
                                                            type="number"
                                                            name="discount"
                                                            placeHolder={placeHolder[index].weightingFactor}
                                                            className="form-control mb-3"
                                                            value={row.discount}
                                                            min="0"
                                                            disabled={true}
                                                            onChange={(e) => handleChange(index, 'discount', e.target.value)}
                                                        />
                                                        {errors[index]?.discount && (
                                                            <div className="text-danger">{errors[index]?.discount}</div>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>

                            </div>

                            <div className=" mt-2 mb-3 pb-4">
                                <label for="exampleFormControlInput1" className="form-label">Expires on</label>
                                <input type="date" name='expiryDate'
                                    value={formData.expiryDate}
                                    onChange={setExpiryData} class="form-control" id="exampleFormControlInput1" disabled={isDisabled} />
                                {formError2.expiryDate && <div className='text-danger mt-1'>{formError2.expiryDate}</div>}

                            </div>

                            <div className=" mt-2 mb-3 pb-4">
                                <label for="exampleFormControlInput1" className="form-label">View Type</label>
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" disabled={isDisabled} onChange={() => setViewData(true)} value={true} checked={formData?.isCustomerView === true}
                                        />
                                        <label className="form-check-label" for="inlineRadio1">Customer</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" disabled={isDisabled} onChange={() => setViewData(false)} id="inlineRadio2" value={false} checked={formData?.isCustomerView === false}
                                        />
                                        <label className="form-check-label" for="inlineRadio2">Vendor</label>
                                    </div>
                                </div>
                                {formError2?.isCustomerView && <div className='text-danger mt-1'>{formError2?.isCustomerView}</div>}

                            </div>




                            <div style={{ gap: "16px" }} className="d-flex flex-column">
                                <h1 className="scratch-card-form-num-text">
                                    Terms & conditions
                                </h1>
                                <DynamicInputField
                                    initialData={isDisabled ? formData?.termsAndCondition : edit === "true" ? formData?.termsAndCondition : null}
                                    onInputDataChange={setTemsAndConditionsChange}
                                    isDisabled={isDisabled}
                                />
                            </div>

                        </div>

                        {/* Link generate */}
                        {!isDisabled ? null : <div className='bulkshare114'>

                            <div>
                                <div class="mb-4">
                                    <label for="exampleFormControlInput1" class="form-label">Generate Link</label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text copubutton221 copubutton224">
                                                <button type="button" className="btn btn-link btn-sm copubutton224" id="copyButton">
                                                    <i className="fas fa-link"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control copubutton222"
                                            value={`${frontendUrl}/${formData?.isCustomerView == true ? "customer" : "vendor"}/spin-and-win-campaign/${formData?.uniqueCode}`}

                                            disabled
                                        />
                                        <div className="input-group-append">
                                            <button
                                                onClick={sharedetails}
                                                type="button"
                                                className="btn btn-success copubutton223"
                                            >
                                                <i className="fa-regular fa-copy mx-2"></i>
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div>
                                <label for="exampleFormControlInput1" class="form-label">Custom Link</label>
                                <div className='bulkshare116'>

                                    <div>Upgrade to <span className='bulkshare119'>PRO</span></div>
                                    <div className='bulkshare117'><img className="bulkshare118" src={arrowup} alt="" /></div>

                                </div>

                            </div>

                        </div>}
                    </form>
                </div>

                <div className='col-12  col-lg-4'>

                    {/* headings */}
                    <div className='d-flex justify-content-between mb-4'>
                        <div>
                            <h3 className='bulkshare111'>Preview</h3>
                        </div>
                        <div>
                            <button className='bulkshare120'> <i class="fa-solid fa-eye"></i> Customer view</button>
                        </div>
                    </div>

                    {/* preview */}

                    <div className='spinnerv122 spinner2233' >
                        <div className='spinnerv129'>
                            <Spinner />
                        </div>

                    </div>


                    {/* buy premium  */}
                    <div>
                        <BuyPremium />
                    </div>

                    <div>  <Toaster
                        position="top-center"
                        reverseOrder={false}
                    /></div>

                </div>
            </div>

            <div>

            </div>


        </div>


    )
}

export default SpinAndWinCreateCampaign