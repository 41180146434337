import React, { useEffect, useState } from 'react'
import "./SpinAndWinList.css"
import "../BulkShareList/BulkShareList.css"
import { useNavigate } from 'react-router'
import InfiniteScroll from 'react-infinite-scroll-component'
import loader from "../../Images/loader.gif"
import api from '../ApiConfigration/ApiConfigration'
import "../ScratchCardList/ScratchCardList.css"
import toast, { Toaster } from 'react-hot-toast'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setSpinAndWinList, setSpinAndWinListDetails } from '../../../Redux/vendorReducer';

function SpinAndWinList() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    var frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;
    // const [spinandwin, setSpinAndWin] = useState([]);
    // const [page, setPage] = useState(0);
    const limit = 10;
    // const [hasmore, setHasMore] = useState(true)
    const [deleteCamapiagndata, setDeleteCampaignData] = useState()
    const [show, setShow] = useState(false);
    const [istopPage, setIsTopPage] = useState(false)
    var page = useSelector(state => state?.vendorData?.spinandwinListDetails?.page);
    var hasmore = useSelector(state => state?.vendorData?.spinandwinListDetails?.hasmore);
    var spinandwin = useSelector(state => state?.vendorData?.spinandwinList);

    const redirecttocreate = () => {
        navigate('/vendor/spinandwincampaign')
    }

    useEffect(() => {
        setIsTopPage(true)
        if (istopPage) {
            if (hasmore === true) {
                getsetSpinAndWinlist()
            }
        }
    }, [istopPage])

    const getsetSpinAndWinlist = async () => {
        await api.get(`/campaigns/spinandwinlist?offset=${page}&limit=${limit}`).then((res) => {
            if (res) {
                if (res?.data?.message?.message?.length < limit) {
                    // setHasMore(false);
                    dispatch(setSpinAndWinListDetails({ hasmore: false }))
                } else {
                    // setPage(page + limit);
                    // setHasMore(true);
                    dispatch(setSpinAndWinListDetails({ hasmore: true, page: page + limit }))

                }

                const newData = res?.data?.message?.message;

                if (newData) {
                    // Check for duplicates
                    const existingIds = spinandwin?.map(item => item?.id);
                    const firstItemId = newData[0]?.id;
                    const lastItemId = newData[newData?.length - 1]?.id;

                    if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
                        // If no duplicates, add new items to existing data
                        dispatch(setSpinAndWinList([...spinandwin, ...newData]));
                    }
                }


                // setSpinAndWin((prevData) => [...prevData, ...res?.data?.message?.message])
                // setSpinAndWin((prevData) => {
                //     const newData = res?.data?.message?.message;
                //     if (!newData) return prevData; // Return previous data if no new data

                //     const existingIds = prevData.map(item => item.id);
                //     const firstItemId = newData[0]?.id;
                //     const lastItemId = newData[newData.length - 1]?.id;

                //     if (existingIds.includes(firstItemId) || existingIds.includes(lastItemId)) {
                //         // If any of the new items already exist in the existing data, don't add them
                //         return prevData;
                //     } else {
                //         // Add new items to existing data
                //         return [...prevData, ...newData];
                //     }
                // });


            }
        }).catch((err) => {
            console.log(err.message)
        })

    }

    const deleteCampaign = async (id) => {
        await api.get(`/campaigns/deletespinandwinbyid?id=${id}`).then((res) => {
            if (res && res?.data?.message) {
                toast.success(res?.data?.message?.message)
                // setSpinAndWin(prevList => prevList.filter(campaign => campaign?.id !== id));
                if (spinandwin) {
                    var newData = spinandwin?.filter(campaign => campaign.id !== id)
                    dispatch(setSpinAndWinList(newData))
                }

            } else {
                toast.error("Error deleteing campaign")
            }
        }).catch((err) => {
            toast.error(err?.response?.data?.errors?.body[0])
        })
    }

    const sharedetails = async (id) => {

        var urltocopy = `${frontendUrl}/${id?.isCustomerView === true ? "customer" : "vendor"}/spin-and-win-campaign/${id?.uniqueCode}`
        try {
            const textarea = document.createElement('textarea');
            textarea.value = urltocopy;
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            document.execCommand('copy');
            document.body.removeChild(textarea);
            toast.success("Link copied to clipboard!")

        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    const redirecttoid = (id) => {
        navigate(`/vendor/spinandwincampaign?id=${id}&edit=false`)
    }
    const editCamapaign = (id) => [
        navigate(`/vendor/spinandwincampaign?id=${id}&edit=true`)

    ]

    const spinAndWinSetting = (id) => {
        navigate(`/vendor/spin-and-win-coupon-redeem?id=${id}`)
    }


    const handleClose = () => {
        setShow(false)
        setDeleteCampaignData()

    };
    const handleClose2 = () => {
        deleteCampaign(deleteCamapiagndata?.id)
        setShow(false)
        setDeleteCampaignData()
    };
    const handleShow = (i) => {
        // console.log(i)
        setDeleteCampaignData(i)
        setShow(true)
    };

    return (
        <div className='bulksharelist001'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />


            <div className='d-flex justify-content-between bulksharelist012'>
                <div className='bulksharelist011'>Spin and Win</div>
                <div style={{ display: 'none' }}><button onClick={redirecttocreate} className='bulksharelist010'>Create New</button></div>
            </div>

            <div className='row scratchcardlist003'>
                <div className='col-12 col-md-6'>
                    <div onClick={redirecttocreate} className=' scratchcardlist001'>

                        <div className='scratchcardlist004 d-flex m-1'>

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
                                    <path d="M21.586 2.66675H10.4127C5.55935 2.66675 2.66602 5.56008 2.66602 10.4134V21.5734C2.66602 26.4401 5.55935 29.3334 10.4127 29.3334H21.5727C26.426 29.3334 29.3193 26.4401 29.3193 21.5868V10.4134C29.3327 5.56008 26.4394 2.66675 21.586 2.66675ZM23.9993 17.0001H16.9993V24.0001C16.9993 24.5467 16.546 25.0001 15.9993 25.0001C15.4527 25.0001 14.9993 24.5467 14.9993 24.0001V17.0001H7.99935C7.45268 17.0001 6.99935 16.5467 6.99935 16.0001C6.99935 15.4534 7.45268 15.0001 7.99935 15.0001H14.9993V8.00008C14.9993 7.45341 15.4527 7.00008 15.9993 7.00008C16.546 7.00008 16.9993 7.45341 16.9993 8.00008V15.0001H23.9993C24.546 15.0001 24.9993 15.4534 24.9993 16.0001C24.9993 16.5467 24.546 17.0001 23.9993 17.0001Z" fill="#26735A" />
                                </svg>
                            </div>
                            <div className='ps-3'>
                                <p className='scratchcardlist005'>Create</p>
                                <p className='scratchcardlist006'>New Spin and Win</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>


            <InfiniteScroll
                dataLength={spinandwin?.length}
                next={istopPage ? getsetSpinAndWinlist : null}
                hasMore={hasmore}
                loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
            >
                <div className='scratchcardlist003'>

                    <div className='row'>


                        {spinandwin?.map((itm, k) => (
                            <div className='col-md-6 col-12 mt-3'>
                                <div className=' scratchcardlist012 '>

                                    <div className='scratchcardlist004 d-flex'>


                                        <div className='ps-1 scratchcardlist009'>
                                            <div className='d-flex scratchcardlist008'>
                                                <p onClick={() => { redirecttoid(itm?.id) }} className='scratchcardlist005 pt-1 scratchcardlist010'>{itm?.campaignName}</p>
                                                <div className='d-flex'>
                                                    <div onClick={() => { spinAndWinSetting(itm?.id) }} style={{ paddingTop: "2px" }} className='mx-3 ms-0 scratchcardlist010'>
                                                        <i style={{ fontSize: "20px", color: "#1C1C1C" }} className="fa-solid fa-ellipsis-vertical"></i>
                                                    </div>
                                                    <div onClick={() => { { editCamapaign(itm?.id) } }} className='mx-2 ms-0 scratchcardlist010'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                        <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#1C1C1C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M15.4102 4.1499C16.0802 6.5399 17.9502 8.4099 20.3502 9.0899" stroke="#1C1C1C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg></div>
                                                    <div className='mx-2 ms-0 scratchcardlist010'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                        <path d="M16.5 12.9V17.1C16.5 20.6 15.1 22 11.6 22H7.4C3.9 22 2.5 20.6 2.5 17.1V12.9C2.5 9.4 3.9 8 7.4 8H11.6C15.1 8 16.5 9.4 16.5 12.9Z" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M22.5 6.9V11.1C22.5 14.6 21.1 16 17.6 16H16.5V12.9C16.5 9.4 15.1 8 11.6 8H8.5V6.9C8.5 3.4 9.9 2 13.4 2H17.6C21.1 2 22.5 3.4 22.5 6.9Z" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg></div>
                                                    <div onClick={() => { { handleShow(itm) } }} className='scratchcardlist010'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                        <path d="M21.5 5.97998C18.17 5.64998 14.82 5.47998 11.48 5.47998C9.5 5.47998 7.52 5.57998 5.54 5.77998L3.5 5.97998" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M19.3504 9.13989L18.7004 19.2099C18.5904 20.7799 18.5004 21.9999 15.7104 21.9999H9.29039C6.50039 21.9999 6.41039 20.7799 6.30039 19.2099L5.65039 9.13989" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.8301 16.5H14.1601" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10 12.5H15" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg></div>

                                                </div>

                                            </div>


                                            <div>

                                                <div className='mt-1'>
                                                    <p onClick={() => { sharedetails(itm) }} className='scratchcardlist011 scratchcardlist010'>Copy Link</p>
                                                </div>
                                            </div>

                                        </div>


                                    </div>




                                </div>


                            </div>
                        ))}

                    </div>
                </div>

            </InfiniteScroll>

            <Modal show={show} onHide={handleClose} backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Campaign</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure want to delete {deleteCamapiagndata?.campaignName} Campaign</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleClose2}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}

export default SpinAndWinList