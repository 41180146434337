import React, { useEffect, useState } from 'react'
import "./ClaimCouponsOffers.css"
import api from '../ApiConfigration/ApiConfigration'
import { useNavigate, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

function ClaimCouponsOffers({ pageData }) {

    const navigate = useNavigate();
    const { user, refercode } = useParams();
    var token = localStorage.getItem("utoken")
    const [userName, setUserName] = useState("")
    const [userNameError, setuserNameError] = useState("")
    const [inputdisable, setInputDisable] = useState(false)
    const [userData, SetUserData] = useState({})
    const [statschange, setStatuschange] = useState(true)
    const [ClaimClicked, setClaimClicked] = useState(false)

    useEffect(() => {

        if (token) {
            SetUserData(pageData?.bulkshareData)

            api.get("/auth/getprofile").then((res) => {
                if (res) {
                    var userNamee = res?.data?.message?.data?.userName
                    if (userNamee) {
                        setUserName(userNamee)
                        setInputDisable(true)
                    }

                }
            }).catch((err) => {
                console.log(err.message)
            })
        }
    }, [pageData, statschange, token])

    const autosaveuserclaim = () => {
        setClaimClicked(true)
        api.post("/campaign/registercampaign", {
            "bulkShareId": userData?.bulkshareId,
            "parentCode": refercode
        }).then(async (res) => {

            toast.success(res?.data?.message?.message)

            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));

            if (res?.data?.message?.redirectid) {
                window.location.replace(`/customer/bulksharecampaign/${res?.data?.message?.redirectid}`)
            }

        }).catch(async (err) => {
            var error = err?.response?.data?.errors?.body[0]
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : error?.message)

            setClaimClicked(false)

            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));

            if (err?.response?.data?.errors?.code[0] !== "error") {

                window.location.replace(`/customer/bulksharecampaign/${err?.response?.data?.errors?.code[0]}`)

            }
        })
    }


    useEffect(() => {
        if (pageData?.autoclaimstatus && userData?.bulkshareId) {
            autosaveuserclaim()
        }
    }, [pageData?.autoclaimstatus, userData?.bulkshareId])


    const updateUserName = async (e) => {
        setUserName(e.target.value)
        setuserNameError("")
    }

    const saveUserNametoDB = async (e) => {
        e.preventDefault();
        setClaimClicked(true)
        if (token) {
            if (!inputdisable) {
                if (!userName) {
                    setuserNameError("Field cannot be empty")
                    return;
                }

                api.post("/auth/updateprofile", { userName: userName }).then((res) => {
                    if (res) {
                        if (res?.data?.message?.code === "success") {
                            setStatuschange(!statschange)
                            autosaveuserclaim()
                        }
                    }
                }).catch((err) => {
                    console.log(err.messaage)
                })
            } else {
                api.post("/campaign/registercampaign", {
                    "bulkShareId": userData?.bulkshareId,
                    "parentCode": refercode
                }).then(async (res) => {
                    setClaimClicked(false)
                    toast.success(res?.data?.message?.message)

                    const delayTime = 1000;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));

                    if (res?.data?.message?.redirectid) {
                        window.location.replace(`/customer/bulksharecampaign/${res?.data?.message?.redirectid}`)
                    }

                }).catch(async (err) => {
                    var error = err?.response?.data?.errors?.body[0]
                    toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : error?.message)

                    setClaimClicked(false)
                    const delayTime = 1000;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));

                    if (err?.response?.data?.errors?.code[0] !== "error") {

                        window.location.replace(`/customer/bulksharecampaign/${err?.response?.data?.errors?.code[0]}`)

                    }
                })
            }
        } else {

            if (!userName) {
                setuserNameError("Field cannot be empty")
                return;
            }

            navigate(`/customer/login?username=${userName}&redirect=${"bulkshare/" + user + "/" + refercode}`)
        }
    }



    return (
        <form onSubmit={saveUserNametoDB}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='claimcouponoffersq'>

                <h3 className='claimcouponheading'>Claim your offer</h3>
                <h3 className='claimcouponsdescript'>{userData?.productName}</h3>

                <p className='mb-0 pb-0 claimcouponsdescript2'>UserName</p>
                <div>
                    {
                        !inputdisable ? <input className='claimcouponiputbox' name="userName" onChange={(e) => { updateUserName(e) }} /> : null
                    }
                    {
                        !inputdisable ? null : <input className='claimcouponiputbox' placeholder='Enter your name' type="text" name="userName" onChange={(e) => { updateUserName(e) }} value={userName} disabled={inputdisable} />
                    }

                    {userNameError && <div className="text-danger text-start">{userNameError}</div>}
                </div>
                <div className='d-flex justify-content-end pt-1'>
                    <p className='peopleleftdiv'>{userData?.remaningquantity} more person left</p>
                </div>

                <div className='d-flex justify-content-between'>
                    <div onClick={ClaimClicked ? null : saveUserNametoDB} type='submit' className={`${ClaimClicked ? "claimoffersbutoonedc1" : "claimoffersbutoonedc"} submitt_btn_claim2`}>
                        Confirm
                    </div>
                    <div type='button' className='claimoffersbutoonedc submitt_btn_claim2'>
                        Share
                    </div >
                </div>

            </div>
        </form>
    )
}

export default ClaimCouponsOffers