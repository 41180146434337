import React, { useState } from 'react'
import "./Store.css"
import menu from "../../Images/Menu.png"
import mclog from "../../Images/2fa0d3cdf6f485415e7f53175e908fa0.png"
import "../ClaimedOffers/ClaimedOffers.css"
import { Collapse } from 'react-bootstrap'
import dish from "../../Images/5d085e0b3f7ceb3454946e693f137ac2.jpg"
import logo from "../../Images/logo1.png"
import api from '../ApiConfigration/ApiConfigration'
import { useNavigate, useParams } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'

function Store() {

    const navigate = useNavigate();
    const awsUri = process.env.REACT_APP_API_AWS_BASE_URL
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [storeData, setStoreData] = useState()
    const { storeuri } = useParams();
    const [openPanels, setOpenPanels] = useState({});


    const togglePanel = (panelId) => {
        onClickIndCollection(panelId)
        setOpenPanels((prevState) => ({
            ...prevState,
            [panelId]: !prevState[panelId],
        }));
        // console.log(storeData)
    };

    useState(() => {
        api.get(`/store/getstoredetails?id=${storeuri}`).then((res) => {
            if (res) {
                console.log(res?.data?.message?.message);
                setStoreData(res?.data?.message?.message);
            } else {
                toast.error("Error Fetching Data")
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        })

    }, [])


    const onClickIndCollection = (id) => {
        api.get(`/store/getproductlistbyid?id=${id}`).then((res) => {
            if (res && res.data && res.data.message && res.data.message.message) {
                const products = res.data.message.message;
                const updatedStoreData = { ...storeData };
                const collectionIndex = updatedStoreData.CollectionDetails.findIndex(collection => collection.id === id);
                if (collectionIndex !== -1) {
                    updatedStoreData.CollectionDetails[collectionIndex].products = products;
                    setStoreData(updatedStoreData);
                } else {
                    console.log("Collection not found");
                }
            } else {
                console.log("Error Fetching Data");
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        });
    };


    const redirecttoProduct = (uid) => {
        navigate(`/customer/product?id=${uid}`)
    }

    const rotateIcon = (isOpen) => {
        return isOpen ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' };
    };


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='claimofferhome'>

                <div className='claimoffershare'>

                    <div className='navbarrr'>
                        <div><img src={menu} alt={menu} /></div>

                        <div className='d-flex store003 mx-3'>
                            <div className='mx-4 mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none">
                                    <path d="M11.5 21.75C5.85 21.75 1.25 17.15 1.25 11.5C1.25 5.85 5.85 1.25 11.5 1.25C17.15 1.25 21.75 5.85 21.75 11.5C21.75 17.15 17.15 21.75 11.5 21.75ZM11.5 2.75C6.67 2.75 2.75 6.68 2.75 11.5C2.75 16.32 6.67 20.25 11.5 20.25C16.33 20.25 20.25 16.32 20.25 11.5C20.25 6.68 16.33 2.75 11.5 2.75Z" fill="#BBC3BC" />
                                    <path d="M21.9999 22.7499C21.8099 22.7499 21.6199 22.6799 21.4699 22.5299L19.4699 20.5299C19.1799 20.2399 19.1799 19.7599 19.4699 19.4699C19.7599 19.1799 20.2399 19.1799 20.5299 19.4699L22.5299 21.4699C22.8199 21.7599 22.8199 22.2399 22.5299 22.5299C22.3799 22.6799 22.1899 22.7499 21.9999 22.7499Z" fill="#BBC3BC" />
                                </svg>
                            </div>
                            <div><div className='store001'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M19.96 8.96002C19.29 8.22002 18.28 7.79002 16.88 7.64002V6.88002C16.88 5.51002 16.3 4.19002 15.28 3.27002C14.25 2.33002 12.91 1.89002 11.52 2.02002C9.12999 2.25002 7.11999 4.56002 7.11999 7.06002V7.64002C5.71999 7.79002 4.70999 8.22002 4.03999 8.96002C3.06999 10.04 3.09999 11.48 3.20999 12.48L3.90999 18.05C4.11999 20 4.90999 22 9.20999 22H14.79C19.09 22 19.88 20 20.09 18.06L20.79 12.47C20.9 11.48 20.92 10.04 19.96 8.96002ZM11.66 3.41002C12.66 3.32002 13.61 3.63002 14.35 4.30002C15.08 4.96002 15.49 5.90002 15.49 6.88002V7.58002H8.50999V7.06002C8.50999 5.28002 9.97999 3.57002 11.66 3.41002ZM8.41999 13.15H8.40999C7.85999 13.15 7.40999 12.7 7.40999 12.15C7.40999 11.6 7.85999 11.15 8.40999 11.15C8.96999 11.15 9.41999 11.6 9.41999 12.15C9.41999 12.7 8.96999 13.15 8.41999 13.15ZM15.42 13.15H15.41C14.86 13.15 14.41 12.7 14.41 12.15C14.41 11.6 14.86 11.15 15.41 11.15C15.97 11.15 16.42 11.6 16.42 12.15C16.42 12.7 15.97 13.15 15.42 13.15Z" fill="#62C4A4" />
                                </svg>
                            </div></div>

                        </div>
                    </div>

                    <div>
                        <div style={{ cursor: "pointer" }} className='store004'>
                            <p className='store005'>Invite friends and get 5% off</p>
                        </div>
                    </div>

                    {storeData ? <div className='store007'>
                        <div className='text-center'>
                            <img className='store006' src={awsUri + storeData?.storeData?.storeImage} alt="" />
                            <p className='store008 mt-2'>{(storeData?.storeData?.storeName)?.toLowerCase()}</p>
                        </div>
                    </div> : null}


                    {/* Category List */}
                    <div className='mt-5 store009'>
                        {storeData?.CollectionDetails?.map((itm, k) =>
                            <div className='mt-4'>
                                <div className='store010'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='store018'>{itm?.collectionName}</div>
                                        <div className='store021' onClick={() => { togglePanel(itm.id) }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" style={rotateIcon(openPanels[itm.id])}>
                                            <path d="M16.19 2.07788H7.81C4.17 2.07788 2 4.24788 2 7.88788V16.2579C2 19.9079 4.17 22.0779 7.81 22.0779H16.18C19.82 22.0779 21.99 19.9079 21.99 16.2679V7.88788C22 4.24788 19.83 2.07788 16.19 2.07788ZM16.06 11.2479L12.53 14.7779C12.38 14.9279 12.19 14.9979 12 14.9979C11.81 14.9979 11.62 14.9279 11.47 14.7779L7.94 11.2479C7.65 10.9579 7.65 10.4779 7.94 10.1879C8.23 9.89788 8.71 9.89788 9 10.1879L12 13.1879L15 10.1879C15.29 9.89788 15.77 9.89788 16.06 10.1879C16.35 10.4779 16.35 10.9479 16.06 11.2479Z" fill="#E0F3ED" />
                                        </svg></div>
                                    </div>
                                </div>
                                <div className='store011'>
                                    <Collapse in={openPanels[itm.id]}>
                                        <div className='store013' id={`collapse-${itm.id}`}>
                                            {itm?.products?.length === 0 ? <div style={{ fontWeight: "lighter" }} className='mb-2 pb-3 text-center'>No Products Available</div> : null}


                                            {/* Products List  */}
                                            {itm?.products?.map((item, k) =>
                                                <div style={{ cursor: "pointer" }} className='d-flex p-3 ps-0' onClick={() => redirecttoProduct(item?.products?.id)}>
                                                    <div>
                                                        <img className='store014' src={awsUri + item?.products?.productImage[0]} alt="" />
                                                    </div>
                                                    <div className='ms-3' >
                                                        <div className='store015 mb-2'>{item?.products?.productName}</div>
                                                        <div><span className='store016'>{storeData?.price ?storeData?.price:"BD"} {item?.products?.isVarient === true ? item?.products?.productvarients[0]?.originalPrice : item?.products?.Originalprice ? item?.products?.Originalprice : "0"}</span> <span className='store017'>{storeData?.price ?storeData?.price:"BD"} {item?.products?.isVarient === true ? item?.products?.productvarients[0]?.price : item?.products?.price}</span></div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </Collapse>

                                </div>
                            </div>

                        )}


                        {/* Contact Us */}
                        <div className='mt-5'>
                            <div className='store010'>
                                <div className='d-flex justify-content-between'>
                                    <div className='store018'><span className='mx-2 ms-0'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M11.05 15.0279L9.2 16.8779C8.81 17.2679 8.19 17.2679 7.79 16.8879C7.68 16.7779 7.57 16.6779 7.46 16.5679C6.43 15.5279 5.5 14.4379 4.67 13.2979C3.85 12.1579 3.19 11.0179 2.71 9.88788C2.24 8.74788 2 7.65788 2 6.61788C2 5.93788 2.12 5.28788 2.36 4.68788C2.6 4.07788 2.98 3.51788 3.51 3.01788C4.15 2.38788 4.85 2.07788 5.59 2.07788C5.87 2.07788 6.15 2.13788 6.4 2.25788C6.66 2.37788 6.89 2.55788 7.07 2.81788L9.39 6.08788C9.57 6.33788 9.7 6.56788 9.79 6.78788C9.88 6.99788 9.93 7.20788 9.93 7.39788C9.93 7.63788 9.86 7.87788 9.72 8.10788C9.59 8.33788 9.4 8.57788 9.16 8.81788L8.4 9.60788C8.29 9.71788 8.24 9.84788 8.24 10.0079C8.24 10.0879 8.25 10.1579 8.27 10.2379C8.3 10.3179 8.33 10.3779 8.35 10.4379C8.53 10.7679 8.84 11.1979 9.28 11.7179C9.73 12.2379 10.21 12.7679 10.73 13.2979C10.83 13.3979 10.94 13.4979 11.04 13.5979C11.44 13.9879 11.45 14.6279 11.05 15.0279Z" fill="#E0F3ED" />
                                        <path d="M21.9701 18.4079C21.9701 18.6879 21.9201 18.9779 21.8201 19.2579C21.7901 19.3379 21.7601 19.4179 21.7201 19.4979C21.5501 19.8579 21.3301 20.1979 21.0401 20.5179C20.5501 21.0579 20.0101 21.4479 19.4001 21.6979C19.3901 21.6979 19.3801 21.7079 19.3701 21.7079C18.7801 21.9479 18.1401 22.0779 17.4501 22.0779C16.4301 22.0779 15.3401 21.8379 14.1901 21.3479C13.0401 20.8579 11.8901 20.1979 10.7501 19.3679C10.3601 19.0779 9.9701 18.7879 9.6001 18.4779L12.8701 15.2079C13.1501 15.4179 13.4001 15.5779 13.6101 15.6879C13.6601 15.7079 13.7201 15.7379 13.7901 15.7679C13.8701 15.7979 13.9501 15.8079 14.0401 15.8079C14.2101 15.8079 14.3401 15.7479 14.4501 15.6379L15.2101 14.8879C15.4601 14.6379 15.7001 14.4479 15.9301 14.3279C16.1601 14.1879 16.3901 14.1179 16.6401 14.1179C16.8301 14.1179 17.0301 14.1579 17.2501 14.2479C17.4701 14.3379 17.7001 14.4679 17.9501 14.6379L21.2601 16.9879C21.5201 17.1679 21.7001 17.3779 21.8101 17.6279C21.9101 17.8779 21.9701 18.1279 21.9701 18.4079Z" fill="#E0F3ED" />
                                    </svg></span>Contact Us</div>
                                    <div className='store021' onClick={() => { setOpen3(!open3) }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M16.19 2.07788H7.81C4.17 2.07788 2 4.24788 2 7.88788V16.2579C2 19.9079 4.17 22.0779 7.81 22.0779H16.18C19.82 22.0779 21.99 19.9079 21.99 16.2679V7.88788C22 4.24788 19.83 2.07788 16.19 2.07788ZM16.06 11.2479L12.53 14.7779C12.38 14.9279 12.19 14.9979 12 14.9979C11.81 14.9979 11.62 14.9279 11.47 14.7779L7.94 11.2479C7.65 10.9579 7.65 10.4779 7.94 10.1879C8.23 9.89788 8.71 9.89788 9 10.1879L12 13.1879L15 10.1879C15.29 9.89788 15.77 9.89788 16.06 10.1879C16.35 10.4779 16.35 10.9479 16.06 11.2479Z" fill="#E0F3ED" />
                                    </svg></div>
                                </div>

                            </div>
                            <div className='store011'>
                                <Collapse in={open3}>
                                    <div className='store013' id="example-collapse-text">

                                        <div className='d-flex p-3 ps-0'>

                                            <div className='ms-3'>
                                                <div className='store015 mb-2'>Phone : <span className='phoneNumbberstore'>{storeData?.storeData?.contactNumber ? `+91-${storeData?.storeData?.contactNumber}` : null}</span></div>
                                            </div>
                                        </div>


                                    </div>
                                </Collapse>

                            </div>
                        </div>
                        {/* Referal */}
                        <div className='mt-4'>
                            <div className='store010'>
                                <div className='d-flex justify-content-between'>
                                    <div className='store018'><span className='mx-2 ms-0'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M17.53 7.84788C17.46 7.83788 17.39 7.83788 17.32 7.84788C15.77 7.79788 14.54 6.52788 14.54 4.96788C14.54 3.37788 15.83 2.07788 17.43 2.07788C19.02 2.07788 20.32 3.36788 20.32 4.96788C20.31 6.52788 19.08 7.79788 17.53 7.84788Z" fill="#E0F3ED" />
                                        <path d="M20.7901 14.7778C19.6701 15.5278 18.1001 15.8078 16.6501 15.6178C17.0301 14.7978 17.2301 13.8878 17.2401 12.9278C17.2401 11.9278 17.0201 10.9778 16.6001 10.1478C18.0801 9.9478 19.6501 10.2278 20.7801 10.9778C22.3601 12.0178 22.3601 13.7278 20.7901 14.7778Z" fill="#E0F3ED" />
                                        <path d="M6.43991 7.84788C6.50991 7.83788 6.57991 7.83788 6.64991 7.84788C8.19991 7.79788 9.42991 6.52788 9.42991 4.96788C9.42991 3.36788 8.13991 2.07788 6.53991 2.07788C4.94991 2.07788 3.65991 3.36788 3.65991 4.96788C3.65991 6.52788 4.88991 7.79788 6.43991 7.84788Z" fill="#E0F3ED" />
                                        <path d="M6.55012 12.928C6.55012 13.898 6.76012 14.818 7.14012 15.648C5.73012 15.798 4.26012 15.498 3.18012 14.788C1.60012 13.738 1.60012 12.028 3.18012 10.978C4.25012 10.258 5.76012 9.96798 7.18012 10.128C6.77012 10.968 6.55012 11.918 6.55012 12.928Z" fill="#E0F3ED" />
                                        <path d="M12.12 15.9479C12.04 15.9379 11.95 15.9379 11.86 15.9479C10.02 15.8879 8.55005 14.3779 8.55005 12.5179C8.56005 10.6179 10.09 9.07788 12 9.07788C13.9 9.07788 15.44 10.6179 15.44 12.5179C15.43 14.3779 13.97 15.8879 12.12 15.9479Z" fill="#E0F3ED" />
                                        <path d="M8.87005 18.0178C7.36005 19.0278 7.36005 20.6878 8.87005 21.6878C10.59 22.8378 13.41 22.8378 15.13 21.6878C16.64 20.6778 16.64 19.0178 15.13 18.0178C13.42 16.8678 10.6 16.8678 8.87005 18.0178Z" fill="#E0F3ED" />
                                    </svg></span>Referral Program</div>
                                    <div className='store021' onClick={() => { setOpen4(!open4) }}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <path d="M16.19 2.07788H7.81C4.17 2.07788 2 4.24788 2 7.88788V16.2579C2 19.9079 4.17 22.0779 7.81 22.0779H16.18C19.82 22.0779 21.99 19.9079 21.99 16.2679V7.88788C22 4.24788 19.83 2.07788 16.19 2.07788ZM16.06 11.2479L12.53 14.7779C12.38 14.9279 12.19 14.9979 12 14.9979C11.81 14.9979 11.62 14.9279 11.47 14.7779L7.94 11.2479C7.65 10.9579 7.65 10.4779 7.94 10.1879C8.23 9.89788 8.71 9.89788 9 10.1879L12 13.1879L15 10.1879C15.29 9.89788 15.77 9.89788 16.06 10.1879C16.35 10.4779 16.35 10.9479 16.06 11.2479Z" fill="#E0F3ED" />
                                    </svg></div>
                                </div>

                            </div>
                            <div className='store011'>
                                <Collapse in={open4}>
                                    <div className='store013' id="example-collapse-text">

                                        <div className='d-flex p-3 ps-0'>
                                            <div className='ms-3 mb-2 referal_text_data'>

                                                Stay tuned! We are working to implement this feature as soon as possible                                        </div>
                                        </div>


                                    </div>
                                </Collapse>

                            </div>
                        </div>


                    </div>



                    <div className='store019'>
                        <img className='store020' src={logo} alt="" />
                    </div>











                </div>




            </div>


        </div>
    )
}

export default Store