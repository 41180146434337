import React, { useEffect, useState } from 'react'
import "../CustomerList/CustomerList.css";
import "../ListOrders/ListOrders.css";
import "../BulkshareCampaign/BulkshareCampaign.css"
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import InfiniteScroll from 'react-infinite-scroll-component';
import loader from "../../Images/loader.gif";
import { useDispatch, useSelector } from 'react-redux';
import { SetRemoveCustomers, setCustomerList, setCustomerListDetails } from '../../../Redux/vendorReducer';
import nodataImage from "../../Images/Product-empty.png";

function CustomerList() {
    let navigate = useNavigate()

    const dispatch = useDispatch();
    const [customerdata, setCustomerdata] = useState([])
    // const [page, setPage] = useState(0);
    const limit = 10;
    // const [hasmore, setHasMore] = useState(true)
    const [istopPage, setIsTopPage] = useState(false);
    const [search, setSearch] = useState("")
    const [searchvalue, setSearchvalue] = useState("")
    const [showSearchText, setSearchText] = useState(false)
    var isCustomerRecall = useSelector(state => state?.vendorData?.customerlistdetails?.isRecallCustomerApi);
    var page = useSelector(state => state?.vendorData?.customerlistdetails?.page);
    var hasmore = useSelector(state => state?.vendorData?.customerlistdetails?.hasmore);
    var customerdataredux = useSelector(state => state?.vendorData?.customerList);
    var noDataImageFlag = useSelector(state => state?.vendorData?.customerlistdetails?.noDataImageFlag);

    const AddCustomers = () => {
        navigate("/vendor/addcustomers")
    }

    const viewcustomers = (id) => {
        navigate(`/vendor/viewcustomers?id=${id}`)
    }

    useEffect(() => {

        if (search === "") {
            setCustomerdata(customerdataredux)

        }

    }, [customerdataredux])

    useEffect(() => {
        setIsTopPage(true)
        if (istopPage) {
            if (hasmore) {
                getcustomerlist()
            }
        }
    }, [istopPage])

    const getcustomerlist = async () => {
        await api.get(`/customer/listallcustomers?offset=${page}&limit=${limit}`).then((res) => {
            if (res) {

                if (res?.data?.message?.message?.length < limit) {

                    dispatch(setCustomerListDetails({ hasmore: false, isRecallCustomerApi: false, noDataImageFlag: true }));
                    // dispatch(setRecallCustomerApi({ isRecallCustomerApi: false }));


                } else {

                    dispatch(setCustomerListDetails({ page: page + limit, hasmore: true, noDataImageFlag: true }));
                    // dispatch(setRecallCustomerApi({ hasmore: true }));

                }
                // setCustomerdata((prevData) => [...prevData, ...res?.data?.message?.message])
                // setCustomerdata((prevData) => {
                //     const newData = res?.data?.message?.message;
                //     if (!newData) return prevData;

                //     const existingIds = prevData.map(item => item.id);
                //     const firstItemId = newData[0]?.id;
                //     const lastItemId = newData[newData.length - 1]?.id;

                //     if (existingIds.includes(firstItemId) || existingIds.includes(lastItemId)) {
                //         return prevData;
                //     } else {
                //         // Add new items to existing data
                //         return [...prevData, ...newData];
                //     }
                // });

                const newData = res?.data?.message?.message;

                if (newData) {
                    // Check for duplicates
                    const existingIds = customerdata?.map(item => item?.id);
                    const firstItemId = newData[0]?.id;
                    const lastItemId = newData[newData?.length - 1]?.id;

                    if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
                        // If no duplicates, add new items to existing data
                        dispatch(setCustomerList([...customerdata, ...newData]));
                    }
                }


            } else {
                console.log("Error Fetching Data")
            }
        }).catch((err) => {
            console.log(err.message)
        })
    }

    function formatPhoneNumber(number) {
        const cleaned = ('' + number).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return number;
    }

    // console.log({ customerdata: customerdata })

    const handleChange = async (e) => {
        setSearch(e.target.value)

    }

    const searchCustomer = async (e) => {
        e.preventDefault()
        setSearchvalue(search)
        dispatch(SetRemoveCustomers())
        if (search) {
            await api.get(`/customer/searchCustomers?search=${search}`).then((res) => {
                if (res) {
                    setCustomerdata(res?.data?.message?.message)

                    setSearchText(true)
                }
            })
        } else {
            getcustomerlist()
            // setCustomerdata(customerdataredux)
            setSearchText(false)


        }
    }

    return (
        <div>


            <div className='d-flex justify-content-start'>
                <div className='bulkshare111 pt-1'>Customers</div>
                <div onClick={AddCustomers} className='ms-4'><button type='Submit' className='bulkshare112'><i className="fa-solid fa-plus listorder111"></i> Add</button></div>
            </div>

            <div className='listorder001'>
                <div className='d-flex'>

                    <div className="search-container">
                        <form onSubmit={searchCustomer}>

                            <input
                                type="text"
                                placeholder="Search customer name.."
                                className="search-input"
                                value={search}
                                onChange={handleChange}
                            />
                            <button type="submit" class="search-button"><i class="search-icon fa fa-search"></i></button>
                        </form>

                    </div>

                    <div className='ms-4'>
                        <button type='Submit' className='bulkshare112 listorder002'><div className='d-flex'><span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="10" viewBox="0 0 18 10" fill="none">
                            <path d="M1 1H17" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M5 5H13" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M7 9H11" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg></span><span className='ms-2'>Filter</span></div></button>
                    </div>



                </div>

                {
                    showSearchText ? <div className='mt-2 mx-1'> Showing search result for "{searchvalue}" </div> : null
                }

                <div className='d-flex justify-content-between align-item-center mt-4 listorder008 customerlist001'>
                    <div className='d-flex mt-1'>
                        <div><input className='listorder003' type="checkbox" /></div>
                        <div className='ms-3  '><p>Showing {customerdata?.length} Customers</p></div>
                    </div>
                    <div className=''>
                        <select className="form-select" aria-label="Default select example">
                            <option selected>Sort by Last Ordered</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
                <InfiniteScroll
                    dataLength={customerdata?.length}
                    next={istopPage ? getcustomerlist : null}
                    hasMore={searchvalue ? false : hasmore}
                    loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
                >

                    {customerdata.map((item, k) => (<div className='d-flex mt-4 listorder007'>
                        <div><input className='listorder003' type="checkbox" /></div>
                        <div onClick={() => viewcustomers(item?.id)} className='ms-3 listorder010' >
                            <div className='d-flex'>
                                <span className='listorder005 customerlist002 mx-1'>{item?.customer?.userName ? item?.customer?.userName : null}</span>
                                {item?.customer?.userName ? <span className='mx-1'>-</span> : null}
                                <span className='mx-1'>{item?.customer?.phone_code ? item?.customer?.phone_code : "+91"} {formatPhoneNumber(item?.customer?.phoneNumber)}</span>
                            </div>
                            {/* <div className='d-flex mt-1'>
                            <div className='listorder006 '>1 order</div>
                            <div className='listorder006 mx-1 ps-1'>  - </div>
                            <div className='listorder006  mx-1'>₹100</div>
                            <div className='listorder006  mx-1'> - </div>
                            <div className='listorder006 mx-1'>Ordered 4 days ago</div>
                        </div> */}

                        </div>
                    </div>))}

                </InfiniteScroll>

                {(searchvalue ? true : noDataImageFlag) && customerdata?.length === 0 ? <div
                    style={{ paddingTop: "80px", gap: "18px", height: "330px" }}
                    className="d-flex flex-column w-100 "
                >
                    <div className="w-100 d-flex justify-content-center">
                        <img src={nodataImage} alt="" />
                    </div>
                    <div className="d-flex justify-content-center w-100">
                        <div className="d-flex flex-column">
                            <h6 className="product-empty-bold-text p-0 m-0">
                                No Customers found
                            </h6>
                            <p className="product-empty-text">
                                Try changing the filters or search term
                            </p>
                        </div>
                    </div>

                </div> : null}

            </div>



        </div>
    )
}

export default CustomerList