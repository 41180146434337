import React, { useEffect, useState } from "react";
import "./Collections.css";
import img from "../../Images/Rectangle 2470.png";
import api from "../ApiConfigration/ApiConfigration";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { setCollectionList, setCollectionListDetails } from '../../../Redux/vendorReducer';
import loadingimage from "../../Images/loader.gif";
import noDataImage from "../../Images/Product-empty.png";

export default function Collections() {

  const dispatch = useDispatch();
  // const [collectionData, setCollectionData] = useState([])
  const [addCollection, setAddCollection] = useState(false)
  const [productData, setProductData] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([]);
  const baseImg = process.env.REACT_APP_API_AWS_BASE_URL
  const [inputValue, setInputValue] = useState('');
  const [inputValueerr, setInputValueerr] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  // const [isLoading, setIsloading] = useState(true)
  var collectionData = useSelector(state => state?.vendorData?.collectionList);
  var collectionListDetails = useSelector(state => state?.vendorData?.collectionListDetails);


  useEffect(() => {
    async function getCollectionData() {
      await api.get("/product/listcollections").then((res) => {
        // setCollectionData(res?.data?.message?.message)
        // setIsloading(false)
        // res?.data?.message?.message?.length =[]

        dispatch(setCollectionListDetails({ isloadingFlag: false }))
        dispatch(setCollectionList(res?.data?.message?.message))
      }).catch((err) => {
        dispatch(setCollectionListDetails({ isloadingFlag: false }))
        console.log(err?.message)
      })
    }
    async function getProductData() {
      await api.get("/product/productsforcollection").then((res) => {
        console.log(res?.data?.message?.message)
        setProductData(res?.data?.message?.message)
      }).catch((err) => {
        console.log(err.message)
      })
    }
    getCollectionData()
    getProductData()
  }, [addCollection])

  useEffect(() => {
    if (!collectionListDetails?.isloadingFlag) {
      if (collectionData?.length !== 0) {
        dispatch(setCollectionListDetails({ noDataImageFlag: false }))
      } else {
        dispatch(setCollectionListDetails({ noDataImageFlag: true }))

      }
    }
  }, [collectionData])


  const handleCheckboxChange = (selectedItem) => {
    setSelectedProducts(prevSelected => {
      const isSelected = prevSelected.some(item => item.id === selectedItem.id);

      if (isSelected) {
        return prevSelected.filter(item => item.id !== selectedItem.id);
      } else {
        return [...prevSelected, selectedItem];
      }
    });
  };

  const handleInputChange2 = (event) => {
    setInputValue(event.target.value);
    setInputValueerr("")
  };

  const saveCollectionData = async (e) => {
    e.preventDefault();

    if (!inputValue) {
      setInputValueerr("This Field Cannot be Empty")
      return;
    }

    var data = {
      collectionName: inputValue,
      productdata: selectedProducts
    }
    setIsSaveDisabled(true)
    await api.post("/product/createCollection", data).then((res) => {
      if (res) {
        setIsSaveDisabled(false)
        toast.success(res?.data?.message?.message)
        setSelectedProducts([])
        setInputValue("")
        setAddCollection(false)


      } else {
        setIsSaveDisabled(false)
        toast.error("Error in creating Collection")
      }
    }).catch((err) => {
      setIsSaveDisabled(false)
      toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
    })


  }



  return (
    <form onSubmit={saveCollectionData}>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="row">
        <div className="col-12">
          {/* Top Heading */}
          <div style={{ gap: "32px" }} className="w-100 d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column">
                <h1 className="top-heading-19 m-0 p-0">Collections</h1>
                <p className="top-heading-15 p-0 m-0">
                  Organize products by collection
                </p>
              </div>
              <button onClick={() => { setAddCollection(!addCollection) }} type="button" className="top-btn-green">
                {addCollection ? "Close" : "New Collection"}
              </button>
            </div>
            {/* Collections */}
            {addCollection ? <div className="d-flex">
              <div className="collection-box-icon">
                <svg
                  width="20"
                  height="29"
                  viewBox="0 0 20 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6.69434" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="6.69434" r="2" fill="#BBC3BC" />
                  <circle cx="6" cy="14.6943" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="14.6943" r="2" fill="#BBC3BC" />
                  <circle cx="6" cy="22.6943" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="22.6943" r="2" fill="#BBC3BC" />
                </svg>
              </div>
              <div className="collection-box">
                {/* collection Head */}

                <div className="collection-box-head">
                  <div className="collection-box-head-input-items">
                    <label htmlFor="" className="collection-box-label">
                      Collection name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={inputValue}
                      onChange={handleInputChange2} />
                    {inputValueerr && <div className='text-danger pt-1'>{inputValueerr}</div>}

                  </div>

                  <div
                    style={{ maxWidth: "277px" }}
                    className="collection-box-head-input-items"
                  >
                    <label htmlFor="" className="collection-box-label">
                      Add products
                    </label>

                    <div className="dropdown d-flex w-50 me-2">
                      <button
                        class="  add-product-box-dropdown dropdown-toggle "
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <p
                          style={{ height: "19px" }}
                          className="p-0 m-0"
                        >
                          Select
                        </p>
                      </button>
                      <ul
                        class="dropdown-menu dropdown-menu dropdown-height-class"
                        aria-labelledby="dropdownMenuButton2"
                      >
                        {productData?.map((itm, index) => (<li
                          onClick={(e) => {
                            e.stopPropagation();

                          }}
                          className="dropdown-item"
                        >
                          <input type="checkbox" className="mt-1 mx-2 ms-0"
                            checked={selectedProducts.some(selectedItem => selectedItem.id === itm.id)}
                            onChange={() => handleCheckboxChange(itm)} />
                          <span className="collectionlist002">{itm?.product}</span>
                        </li>))}

                      </ul>
                    </div>



                  </div>
                  <div className="collection-box-head-btns">
                    {/* Delete */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M21 6.67432C17.67 6.34432 14.32 6.17432 10.98 6.17432C9 6.17432 7.02 6.27432 5.04 6.47432L3 6.67432"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 5.66434L8.72 4.35434C8.88 3.40434 9 2.69434 10.69 2.69434H13.31C15 2.69434 15.13 3.44434 15.28 4.36434L15.5 5.66434"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.8504 9.83447L18.2004 19.9045C18.0904 21.4745 18.0004 22.6945 15.2104 22.6945H8.79039C6.00039 22.6945 5.91039 21.4745 5.80039 19.9045L5.15039 9.83447"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3301 17.1943H13.6601"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 13.1943H14.5"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* copy */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M16 13.5943V17.7943C16 21.2943 14.6 22.6943 11.1 22.6943H6.9C3.4 22.6943 2 21.2943 2 17.7943V13.5943C2 10.0943 3.4 8.69434 6.9 8.69434H11.1C14.6 8.69434 16 10.0943 16 13.5943Z"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 7.59434V11.7943C22 15.2943 20.6 16.6943 17.1 16.6943H16V13.5943C16 10.0943 14.6 8.69434 11.1 8.69434H8V7.59434C8 4.09434 9.4 2.69434 12.9 2.69434H17.1C20.6 2.69434 22 4.09434 22 7.59434Z"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* slide btn */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="25"
                      viewBox="0 0 32 25"
                      fill="none"
                    >
                      <path
                        d="M21.86 4.5542H10.14C5.65 4.5542 2 8.2042 2 12.6942C2 17.1842 5.65 20.8342 10.14 20.8342H21.86C26.35 20.8342 30 17.1842 30 12.6942C30 8.2042 26.35 4.5542 21.86 4.5542Z"
                        fill="#26735A"
                      />
                      <rect
                        x="16.834"
                        y="7.69434"
                        width="10"
                        height="10"
                        rx="5"
                        fill="white"
                      />
                    </svg>
                    {/* Save Butoon */}
                    {isSaveDisabled ? null : <button type="submit" className="collection-save-btn">Save</button>}
                  </div>
                </div>

                {/* collection body */}
                {selectedProducts?.map((item, k) => {
                  return (
                    <div className="collection-box-body-items">
                      <img
                        src={baseImg + item?.productImage[0]}
                        className="collection-box-body-items-img"
                        alt=""
                      />
                      <div className="d-flex">
                        <h1 className="collection-item-bold-text p-0 m-0">
                          {item?.product ? item?.product : "No Name"}
                        </h1>
                        <div
                          style={{ gap: "8px" }}
                          className="d-flex align-items-center ps-2"
                        >
                          {item?.inventory?.Availability === "Available" ? <span className="collection-green-active-btn p-0 m-0"></span> : <span className="collection-red-active-btn p-0 m-0"></span>}{" "}
                          <p className="p-0 m-0 collection-item-text">
                            {item?.inventory?.Availability ? item?.inventory?.Availability : "Unavailable"}
                          </p>

                        </div>
                      </div>
                      <div className="d-flex flex-grow-1 justify-content-end">
                        <svg
                          width="20"
                          height="29"
                          viewBox="0 0 20 29"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="6" cy="6.69434" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="6.69434" r="2" fill="#BBC3BC" />
                          <circle cx="6" cy="14.6943" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="14.6943" r="2" fill="#BBC3BC" />
                          <circle cx="6" cy="22.6943" r="2" fill="#BBC3BC" />
                          <circle cx="14" cy="22.6943" r="2" fill="#BBC3BC" />
                        </svg>
                      </div>
                      {/*  */}
                    </div>
                  );
                })}
              </div>
            </div> : null}
            {/*  */}

            {collectionListDetails?.isloadingFlag ? <div className="loader_collection">
              <img src={loadingimage} style={{ width: "80px" }} alt="" />
            </div> : null}

            {
              collectionListDetails?.noDataImageFlag ?
                <div
                  style={{ paddingTop: "80px", gap: "18px", height: "320px" }}
                  className="d-flex flex-column w-100 "
                >
                  <div className="w-100 d-flex justify-content-center">
                    <img src={noDataImage} alt="" />
                  </div>
                  <div className="d-flex justify-content-center w-100">
                    <div className="d-flex flex-column">
                      <p className="product-empty-text">
                        No Collections found
                      </p>
                    </div>
                  </div>

                </div>


                : null
            }


            {collectionData.map((item, k) => (<div className="d-flex">
              <div className="collection-box-icon">
                <svg
                  width="20"
                  height="29"
                  viewBox="0 0 20 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="6" cy="6.69434" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="6.69434" r="2" fill="#BBC3BC" />
                  <circle cx="6" cy="14.6943" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="14.6943" r="2" fill="#BBC3BC" />
                  <circle cx="6" cy="22.6943" r="2" fill="#BBC3BC" />
                  <circle cx="14" cy="22.6943" r="2" fill="#BBC3BC" />
                </svg>
              </div>
              <div className="collection-box">
                <div className="d-flex justify-content-between w-100">
                  <h1 className="top-heading-19 m-0 p-0">{item?.collectionName}</h1>
                  <div style={{ gap: "27px" }} className="d-flex">
                    {/* Edit */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M13.2603 4.29455L5.05034 12.9846C4.74034 13.3146 4.44034 13.9646 4.38034 14.4146L4.01034 17.6546C3.88034 18.8246 4.72034 19.6246 5.88034 19.4246L9.10034 18.8746C9.55034 18.7946 10.1803 18.4646 10.4903 18.1246L18.7003 9.43455C20.1203 7.93455 20.7603 6.22455 18.5503 4.13455C16.3503 2.06455 14.6803 2.79455 13.2603 4.29455Z"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.8896 5.74414C12.3196 8.50414 14.5596 10.6141 17.3396 10.8941"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3 22.6943H21"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* Delete */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M21 6.67432C17.67 6.34432 14.32 6.17432 10.98 6.17432C9 6.17432 7.02 6.27432 5.04 6.47432L3 6.67432"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8.5 5.66434L8.72 4.35434C8.88 3.40434 9 2.69434 10.69 2.69434H13.31C15 2.69434 15.13 3.44434 15.28 4.36434L15.5 5.66434"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M18.8504 9.83447L18.2004 19.9045C18.0904 21.4745 18.0004 22.6945 15.2104 22.6945H8.79039C6.00039 22.6945 5.91039 21.4745 5.80039 19.9045L5.15039 9.83447"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3301 17.1943H13.6601"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.5 13.1943H14.5"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* Copy */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M16 13.5943V17.7943C16 21.2943 14.6 22.6943 11.1 22.6943H6.9C3.4 22.6943 2 21.2943 2 17.7943V13.5943C2 10.0943 3.4 8.69434 6.9 8.69434H11.1C14.6 8.69434 16 10.0943 16 13.5943Z"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M22 7.59434V11.7943C22 15.2943 20.6 16.6943 17.1 16.6943H16V13.5943C16 10.0943 14.6 8.69434 11.1 8.69434H8V7.59434C8 4.09434 9.4 2.69434 12.9 2.69434H17.1C20.6 2.69434 22 4.09434 22 7.59434Z"
                        stroke="#0B150F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {/* slide Button */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="25"
                      viewBox="0 0 32 25"
                      fill="none"
                    >
                      <path
                        d="M21.86 4.5542H10.14C5.65 4.5542 2 8.2042 2 12.6942C2 17.1842 5.65 20.8342 10.14 20.8342H21.86C26.35 20.8342 30 17.1842 30 12.6942C30 8.2042 26.35 4.5542 21.86 4.5542Z"
                        fill="#26735A"
                      />
                      <rect
                        x="16.834"
                        y="7.69434"
                        width="10"
                        height="10"
                        rx="5"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                {/* bottonm text */}
                <div>
                  <p className="p-0 m-0 beverages-text ">
                    {item?.products?.map((itm, k) => (<span className="beverages-text-one">{itm.productName}{k < item.products.length - 1 && ", "}</span>))}

                  </p>
                </div>
              </div>
            </div>))}
          </div>
        </div>
      </div>
    </form>
  );
}
