import React, { useEffect, useState } from 'react';
import "./CustomerInvoice.css"
import "../ClaimedOffers/ClaimedOffers.css"
import api from '../ApiConfigration/ApiConfigration';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';


function CustomerInvoice() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get('id');
    const [invoice, setInvoice] = useState();
    const [storeData, setStoreData] = useState();
    const [isDisabled, setDisabled] = useState(false)
    const [currency , setCurrency] = useState("")

    useEffect(() => {
        api.get(`/store/orderinvoicebyid?id=${orderId}`).then((res) => {
            if (res) {
                setInvoice(res?.data?.message?.message?.res)
                setStoreData(res?.data?.message?.message?.storeData)
                setCurrency(res?.data?.message?.message?.currency)
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        })
    }, [])


    function formatDate(timestamp) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date = new Date(timestamp);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hour = date.getHours() % 12 || 12;
        const minute = date.getMinutes();
        const ampm = date.getHours() < 12 ? 'am' : 'pm';

        const suffix = day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th';

        const formattedDate = `${month} ${day}${suffix} ${year}, ${hour}:${minute.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }


    const downloadInvoice = () => {
        if (isDisabled) {
            toast.error("Please wait till current process finishes")
            return;
        }
        setDisabled(true)
        api.get(`/store/downloadinvoice?id=${orderId}`).then((res) => {
            if (res) {
                toast.success("Invoice Generated")
                var url = res?.data?.message?.url
                var myWindow = window.open(url, "_blank");

                setDisabled(false)

            } else {
                toast.error("Error Generating Invoice")
            }
        }).catch((err) => {
            setDisabled(false)
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        })
    }


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='claimofferhome'>
                <div className='claimoffershare'>


                    <div className='customerinvoice001'>

                        <div className='customerinvoice002'>INVOICE</div>

                        {storeData ? <div className='customerinvoice003'>
                            <div style={{textTransform:"capitalize"}} className='customerinvoice004 mt-1'>{storeData?.storeName?.toLowerCase()} - Total {invoice?.orderProducts?.length} item</div>
                            <div><div style={{ textTransform: "uppercase" }} className='customerinvoice005'>{invoice?.orderStatus}</div></div>
                        </div> : null}

                        {storeData ? <p className='customerinvoice006'>+91 {storeData?.contactNumber}</p> : null
                        }
                        <div className='customerinvoice007'>Customer</div>
                        {invoice ? <div className='mt-4'>
                            <p style={{ textTransform: "capitalize" }} className='customerinvoice008'>{invoice?.name}</p>
                            <p className='customerinvoice008'>+91 {invoice?.phoneNumber}</p>
                            <p className='customerinvoice008'>{formatDate(invoice?.createdAt)}</p>
                        </div> : null}

                        <div className='customerinvoice009'>Delivery</div>
                        {invoice ? <div className='mt-4 customerinvoice011'>
                            <p style={{ textTransform: "capitalize" }} className='customerinvoice010'>{invoice?.addressOne + " , " + invoice?.addressTwo}</p>
                            <p className='customerinvoice010'>{invoice?.pincode}</p>
                        </div> : null}


                        <div>
                            <table class="table table-borderless customerinvoice012">

                                <thead>
                                    <tr className='customerinvoice014'>
                                        <th><div className='customerinvoice013'></div></th>
                                        <th>Item</th>
                                        <th className='text-end'>{currency?currency:"BD"}</th>
                                    </tr>
                                </thead>
                                <tbody className='customerinvoice015'>
                                    {invoice?.orderProducts?.map((itm, k) => <tr>
                                        <td className='text-center'><div className='customerinvoice016'>{itm?.quantity}x</div></td>
                                        <td><div style={{ textTransform: "capitalize" }} className='customerinvoice016'>{itm?.isVarient ? itm.variants?.name : itm?.productdata?.productName}</div></td>
                                        <td className='text-end'><div className='customerinvoice016'>{itm?.isVarient ? itm.variants?.price : itm?.productdata?.price}</div></td>
                                    </tr>)}

                                </tbody>

                            </table>
                        </div>

                        <div className='d-flex justify-content-between mt-5 customerinvoice017'>
                            <div>Items total</div>
                            <div>{currency?currency:"BD"} {invoice?.cartValue ? invoice?.cartValue : 0.00}</div>
                        </div>


                        <div className='d-flex justify-content-between customerinvoice017 mt-4'>
                            <div>Total</div>
                            <div>{currency?currency:"BD"} {invoice?.cartValue ? invoice?.cartValue : 0.00}</div>
                        </div>


                        <div className='d-flex justify-content-between customerinvoice019'>
                            <div className='customerinvoice018 mx-2 ms-0'>Share</div>
                            <div onClick={() => { downloadInvoice() }} className='customerinvoice018 ms-2 mx-0'>{isDisabled?"Generating":"Download"}</div>
                        </div>




                    </div>










                </div>
            </div>

        </div>
    )
}

export default CustomerInvoice