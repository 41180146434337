import React, { useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Login from '../Components/Customer/Login/Login';
import Verify from '../Components/Customer/Verify/Verify';
import Bulkshare from '../Components/Customer/Bulkshare/Bulkshare';
import ClaimedOffers from '../Components/Customer/ClaimedOffers/ClaimedOffers';
import OfferName from '../Components/Customer/OfferName/OfferName';
import Store from '../Components/Customer/Store/Store';
import CustomerDashboard from '../Components/Customer/CustomerDashboard/CustomerDashboard';
import StoreWhatsappOrder from '../Components/Customer/StoreWhatsappOrder/StoreWhatsappOrder';
import CustomerInvoice from '../Components/Customer/CustomerInvoice/CustomerInvoice';
import OrderDetails from '../Components/Customer/OrderDetails/OrderDetails';
import CustomerViewProduct from '../Components/Customer/CustomerViewProduct/CustomerViewProduct';
import ScratchCardCampaign from '../Components/Customer/ScratchCardCampaign/ScratchCardCampaign';
import SpinAndWinCampaign from '../Components/Customer/SpinAndWinCampaign/SpinAndWinCampaign';
import CustomerHome from '../Components/Customer/CustomerHome/CustomerHome';
import CustomerTempHome from '../Components/Customer/CustomerTempHome/CustomerTempHome';

function CustomerRoutes() {


    var token = localStorage.getItem('utoken')
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isTokenCheckComplete, setTokenCheckComplete] = useState(false);


    useEffect(() => {
        const checkToken = async () => {
            console.log({ "customertoken": !!token })
            setLoggedIn(!!token);
            setTokenCheckComplete(true);
        };

        checkToken();
        // eslint-disable-next-line
    }, [token]);



    //If Token is not present Redirect to Login
    const PrivateRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? element : <Navigate to={`/customer/login?bypass=${window.location.pathname}`} />;
        } else {
            return null;
        }
    };


    const LoginRoute = ({ element }) => {
        if (isTokenCheckComplete) {
            return isLoggedIn ? <Navigate to="/customer" /> : element;
        } else {
            return null;
        }
    };


    return (
        <Routes>
            <Route path="/login" element={<LoginRoute element={<Login />} />} />
            <Route path="/verify" element={<LoginRoute element={<Verify />} />} />
            <Route path="/bulkshare/:user/:refercode" element={<Bulkshare />} />
            <Route path="spin-and-win-campaign/:refid" element={<SpinAndWinCampaign />} />
            <Route path="scratch-card-campaign/:refid" element={<ScratchCardCampaign />} />
            <Route path="store/:storeuri" element={<Store />} />
            <Route path="product" element={<CustomerViewProduct />} />

            <Route path="/" element={<PrivateRoute element={<CustomerDashboard />} />}>

                <Route path="/" element={<CustomerTempHome />} />
                <Route path="claimedoffers" element={<ClaimedOffers />} />
                <Route path="bulksharecampaign/:campaignid" element={<Bulkshare />} />
                <Route path="offername" element={<OfferName />} />
                <Route path="whatsapporder" element={<StoreWhatsappOrder />} />
                <Route path="invoice" element={<CustomerInvoice />} />
                <Route path="orderdetails" element={<OrderDetails />} />


            </Route>
        </Routes>
    )
}

export default CustomerRoutes