import React from 'react'
import "./DashboardSuperAdmin.css"
import { Outlet } from 'react-router-dom'
import logosearch from "../../Images/search-normal-home.png"
import VendorSidebar from '../../Vendor/VendorSidebar/VendorSidebar'
import SideBarSuperAdmin from '../SideBarSuperAdmin/SideBarSuperAdmin'


function DashboardSuperAdmin() {
  return (
    <div className='d-flex'>
      <div className='vendordashboard333'>

        <SideBarSuperAdmin />
      </div>
      <div className='vendordashboardhome'>

        <div className='d-flex justify-content-between vendor112'>
          <div style={{ position: "relative" }} className='vendor113'>
            <div className='search-container'>
              <input
                className='vendorhomesearch'
                type="text"
                placeholder='Search Vendors'
              />

              <button type='submit' className="search-button1"><img className='search-blue-logo' src={logosearch} alt="" /></button>

            </div>




          </div>
          {/* <div className='vendor114'><span className='hidecontent-dboard-vendor'>VIEW YOUR STORE</span><span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M17.44 15.3699C17.25 15.3699 17.06 15.2999 16.91 15.1499C16.62 14.8599 16.62 14.3799 16.91 14.0899L18.94 12.0599L16.91 10.0299C16.62 9.73994 16.62 9.25994 16.91 8.96994C17.2 8.67994 17.68 8.67994 17.97 8.96994L20.53 11.5299C20.82 11.8199 20.82 12.2999 20.53 12.5899L17.97 15.1499C17.82 15.2999 17.63 15.3699 17.44 15.3699Z" fill="#0B150F" />
                <path d="M19.93 12.8101H9.76001C9.35001 12.8101 9.01001 12.4701 9.01001 12.0601C9.01001 11.6501 9.35001 11.3101 9.76001 11.3101H19.93C20.34 11.3101 20.68 11.6501 20.68 12.0601C20.68 12.4701 20.34 12.8101 19.93 12.8101Z" fill="#0B150F" />
                <path d="M11.76 20.75C6.61001 20.75 3.01001 17.15 3.01001 12C3.01001 6.85 6.61001 3.25 11.76 3.25C12.17 3.25 12.51 3.59 12.51 4C12.51 4.41 12.17 4.75 11.76 4.75C7.49001 4.75 4.51001 7.73 4.51001 12C4.51001 16.27 7.49001 19.25 11.76 19.25C12.17 19.25 12.51 19.59 12.51 20C12.51 20.41 12.17 20.75 11.76 20.75Z" fill="#0B150F" />
            </svg></span></div> */}
        </div>
        <div className='vendor115'>
          <Outlet />
        </div>

      </div>


    </div>
  )
}

export default DashboardSuperAdmin