import React, { useEffect, useState } from 'react'
import "./VendorHome.css"
import BuyPremium from '../BuyPremium/BuyPremium'
import api from "../ApiConfigration/ApiConfigration"
import { useDispatch, useSelector } from 'react-redux'
import { setVendor, setHomeDeatils } from '../../../Redux/vendorReducer'
import { useNavigate } from 'react-router-dom';
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { px } from 'framer-motion'
import Dropdown from 'react-bootstrap/Dropdown';
import timezones from "timezones-list";
import CurrencyList from "currency-list";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);


function VendorHome() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = useState(true)
    // const [userData, setUserData] = useState({})
    var userData = useSelector(state => state?.vendorData?.vendor);
    var homeData = useSelector(state => state?.vendorData?.homeDetails);
    const currencys = Object.entries(CurrencyList.getAll("en_US"));

    const [createStoreBanner, setCreateStoreBanner] = useState(true)
    const [orderDetails, setOrtderDetails] = useState()
    const [graphData, setGraphData] = useState([])
    const [type, setType] = useState("monthly")
    const [totalSum, setTotalSum] = useState(0)

    const [ipAddress, setIpAddress] = useState('');
    const [country, setCountry] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [currencyType, setCurrencyType] = useState('');
    const [currency, setCurrency] = useState('');
    const [callProfile, setCallProfile] = useState(true)

    const handleItemClick = (item) => {
        dispatch(setHomeDeatils({ graphType: item }))

    };

    useEffect(() => {
        if (userData) {
            setCreateStoreBanner(userData?.isStoreActive)
        }
    }, [userData])


    useEffect(() => {
        async function getprofileap() {

            await api.get(`/auth/getprofile`)
                .then((res) => {
                    // console.log(res?.data?.message?.data)
                    // setUserData(res?.data?.message?.data)
                    const vendorData = res?.data?.message?.data
                    dispatch(setVendor(vendorData))
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        if (userData) {
            getprofileap()
        }

    }, [callProfile])


    const sampleData = graphData?.map((itm) => { return (itm?.totalSales) });

    const canvasData = {
        datasets: [
            {
                label: "Sales",
                borderColor: '#4fd6a9',
                pointRadius: 4,
                pointBackgroundColor: "white",
                fill: true,
                backgroundColor: '#62c4a360',
                lineTension: 0.4,
                data: sampleData,
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: true,
                },
                labels: graphData?.map((itm) => { return (itm?.value) }),
                ticks: {
                    color: "#444444",
                    font: {
                        family: "Nunito",
                        size: 12,
                    },
                },
            },
            y: {
                grid: {
                    display: true,
                },
                border: {
                    display: false,
                },
                min: 0,
                // max: 100,
                ticks: {
                    // stepSize: 100,
                    color: "#444444",
                    font: {
                        family: "Nunito",
                        size: 12,
                    },
                },
            },
        },
        maintainAspectRatio: true,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
        },
    };

    const graphStyle = {
        maxWidth: "540px",
        width: "100%",
        border: "1px solid #C4C4C4",
        borderRadius: "0.375rem",
        padding: "0.5rem",
    };

    useEffect(() => {


        async function getApiData() {

            await api.get("/home/orderstats").then((res) => {
                if (res) {
                    dispatch(setHomeDeatils({ values: res?.data?.message }))
                }
            }).catch((err) => {
                console.log(err?.message)
            })
        }

        getApiData();


    }, [])

    useEffect(() => {

        async function getGraphData() {
            api.get(`/home/graphdata?type=${type}`)
                .then((res) => {
                    if (res) {
                        // setGraphData(res?.data?.message?.message);
                        dispatch(setHomeDeatils({ graphData: res?.data?.message?.message?.formattedResult, totalSum: res?.data?.message?.message?.OrderValue }))
                    }
                })
                .catch((err) => {
                    console.log(err?.message)
                })
        }

        getGraphData()

    }, [type])

    useEffect(() => {
        if (homeData?.graphData) {
            setGraphData(homeData?.graphData)

        }
        if (homeData?.graphType) {
            setType(homeData?.graphType);

        } if (homeData?.values) {
            setOrtderDetails(homeData?.values)

        }
        if (homeData?.totalSum) {
            setTotalSum(homeData?.totalSum)
        }

    }, [homeData])




    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                if (!response.ok) {
                    throw new Error('Failed to fetch IP address');
                }
                const data = await response.json();
                setIpAddress(data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        if (userData.isActive && !userData.currency) {
            fetchIpAddress();
        }

    }, [userData]);

    useEffect(() => {

        const findTimezoneByCountryCode = (countryCode) => {

            for (const timezone of Object.values(timezones)) {
                if (timezone.tzCode.includes(countryCode)) {
                    setTimeZone(timezone.label)
                    return timezone.label;
                }
            }
            return null;
        };

        const findCurrencyType = (type) => {
            const foundCurrency = currencys.find(item => item[0] === type);

            if (foundCurrency) {
                const symbol = foundCurrency[1]?.symbol;
                setCurrency(symbol)
                setCurrencyType(foundCurrency[0]);
            } else {
                console.log("currency not found");
            }
        };

        async function getCountry() {
            try {
                const response = await fetch(`https://ipapi.co/${ipAddress}/json/`);
                if (!response.ok) {
                    throw new Error('Failed to fetch country data');
                }
                const data = await response.json();
                const timezone = findTimezoneByCountryCode(data?.timezone);
                const currencytypedata = findCurrencyType(data?.currency)
                setCountry(data);

            } catch (error) {
                console.error('Error:', error);
            }
        }

        if (ipAddress) {
            getCountry();
        }
    }, [ipAddress]);


    useEffect(() => {

        async function setCurrencyandtimezone() {
            await api.post("/auth/updatecurrency", { currency: currency, currencyType: currencyType, timezone: timeZone })
                .then((res) => {
                    if (res) {
                        setCallProfile(!callProfile)
                    }
                })
                .catch((err) => { console.log(err) })
        }

        if (userData.isActive && !userData.currency) {
            if (currency) {
                setCurrencyandtimezone()
            }

        }

    }, [currency, timeZone])




    return (
        <div>

            <div className='vendor-home-main'>
                <div className='vendor-home-main-001'>
                    {/* Vendor Welcome Message */}
                    {isActive ? <div className='alertpage-username-home'>

                        <div className='displayName-section-home'>
                            <div className='username-home-field'>Hi <span style={{ textTransform: "capitalize" }}>{userData?.userName}</span>{userData?.userName ? "," : null}</div>
                            <div onClick={() => { setIsActive(false) }} style={{ cursor: "pointer" }}><i class="fa-solid fa-xmark iconcrosshome"></i></div>
                        </div>
                        <div className='mt-1 user-description-home'>Let's make today a great day for your business.</div>


                    </div> : null}
                    {/* Vendor Welcome Message Ending*/}


                    {/* Create Store Message for New Users */}
                    {String(createStoreBanner) == "false" ? <div className='alertpage-username-home mt-4 pt-4'>

                        <div className='displayName-section-home'>
                            <div className='username-home-field mb-2'>Ready to boost your product's visibility?</div>
                            {/* <div onClick={() => { setCreateStoreBanner(true) }} style={{ cursor: "pointer" }}><i class="fa-solid fa-xmark iconcrosshome"></i></div> */}
                        </div>
                        <div className='mt-1 user-description-home'>
                            Create a store on Offerpedia! With our broad audience reach, your products will attract attention from potential customers
                            worldwide. Don't miss this chance to elevate your brand and increase sales.

                        </div>
                        <div className='mt-2'>
                            <button onClick={() => { navigate("/vendor/viewstore") }} className='btn btn-danger'>Create Store</button>
                        </div>


                    </div> : null}
                    {/* Create Store Message for New Users Ending*/}


                    {/* vendor Order Details Blocks */}
                    {String(createStoreBanner) == "true" ? <div>
                        <div className='home_dashboard_class '>

                            <div className='row justify-content-space-between'>

                                <div className='col-lg-4 col-12 ind_item_home_dashboard'>

                                    <div className='content_card_item_row'>

                                        <div className=' content-ind-home-block'>
                                            <div className='icon-img-home-content'><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M6.92001 8.50659C6.66667 8.50659 6.40001 8.39992 6.21334 8.21325C5.82667 7.82659 5.82667 7.18659 6.21334 6.79992L11.0533 1.95992C11.44 1.57326 12.08 1.57326 12.4667 1.95992C12.8533 2.34659 12.8533 2.98659 12.4667 3.37326L7.62667 8.21325C7.42667 8.39992 7.17334 8.50659 6.92001 8.50659Z" fill="#62C4A4" />
                                                <path d="M25.0801 8.50659C24.8267 8.50659 24.5734 8.41326 24.3734 8.21325L19.5334 3.37326C19.1467 2.98659 19.1467 2.34659 19.5334 1.95992C19.9201 1.57326 20.5601 1.57326 20.9467 1.95992L25.7867 6.79992C26.1734 7.18659 26.1734 7.82659 25.7867 8.21325C25.6001 8.39992 25.3334 8.50659 25.0801 8.50659Z" fill="#62C4A4" />
                                                <path d="M26.9467 14.1334C26.8534 14.1334 26.7601 14.1334 26.6667 14.1334H26.3601H5.33341C4.40008 14.1467 3.33341 14.1467 2.56008 13.3734C1.94675 12.7734 1.66675 11.84 1.66675 10.4667C1.66675 6.80005 4.34675 6.80005 5.62675 6.80005H26.3734C27.6534 6.80005 30.3334 6.80005 30.3334 10.4667C30.3334 11.8534 30.0534 12.7734 29.4401 13.3734C28.7467 14.0667 27.8134 14.1334 26.9467 14.1334ZM5.62675 12.1334H26.6801C27.2801 12.1467 27.8401 12.1467 28.0267 11.96C28.1201 11.8667 28.3201 11.5467 28.3201 10.4667C28.3201 8.96005 27.9467 8.80005 26.3601 8.80005H5.62675C4.04008 8.80005 3.66675 8.96005 3.66675 10.4667C3.66675 11.5467 3.88008 11.8667 3.96008 11.96C4.14675 12.1334 4.72008 12.1334 5.30675 12.1334H5.62675Z" fill="#62C4A4" />
                                                <path d="M13.0134 24.4001C12.4668 24.4001 12.0134 23.9467 12.0134 23.4001V18.6667C12.0134 18.1201 12.4668 17.6667 13.0134 17.6667C13.5601 17.6667 14.0134 18.1201 14.0134 18.6667V23.4001C14.0134 23.9601 13.5601 24.4001 13.0134 24.4001Z" fill="#62C4A4" />
                                                <path d="M19.1467 24.4001C18.6001 24.4001 18.1467 23.9467 18.1467 23.4001V18.6667C18.1467 18.1201 18.6001 17.6667 19.1467 17.6667C19.6934 17.6667 20.1467 18.1201 20.1467 18.6667V23.4001C20.1467 23.9601 19.6934 24.4001 19.1467 24.4001Z" fill="#62C4A4" />
                                                <path d="M19.8534 30.3333H11.8134C7.04008 30.3333 5.97341 27.4933 5.56008 25.0266L3.68008 13.4932C3.58675 12.9466 3.96008 12.4399 4.50675 12.3466C5.05341 12.2533 5.56008 12.6266 5.65341 13.1733L7.53341 24.6933C7.92008 27.0533 8.72008 28.3333 11.8134 28.3333H19.8534C23.2801 28.3333 23.6667 27.1333 24.1067 24.8133L26.3467 13.1466C26.4534 12.5999 26.9734 12.2399 27.5201 12.3599C28.0667 12.4666 28.4134 12.9866 28.3067 13.5332L26.0667 25.1999C25.5467 27.9066 24.6801 30.3333 19.8534 30.3333Z" fill="#62C4A4" />
                                            </svg>
                                            </div>
                                            <div className='ms-3'>
                                                <div className='text-heading-home-content'>Pending Orders</div>
                                                <div className='number-home_content_text'>{orderDetails?.pendingOrders ? orderDetails?.pendingOrders : 0}</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className='col-lg-4 col-12 ind_item_home_dashboard'>
                                    <div className='content_card_item_row'>

                                        <div className=' content-ind-home-block'>
                                            <div className='icon-img-home-content'><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M23.9997 10.5466C23.9597 10.5466 23.9331 10.5466 23.8931 10.5466H23.8264C21.3064 10.4666 19.4264 8.51993 19.4264 6.11993C19.4264 3.6666 21.4264 1.67993 23.8664 1.67993C26.3064 1.67993 28.3064 3.67993 28.3064 6.11993C28.2931 8.53327 26.4131 10.4799 24.0131 10.5599C24.0131 10.5466 24.0131 10.5466 23.9997 10.5466ZM23.8664 3.66661C22.5197 3.66661 21.4264 4.75994 21.4264 6.10661C21.4264 7.42661 22.4531 8.49328 23.7731 8.54661C23.7864 8.53328 23.8931 8.53328 24.0131 8.54661C25.3064 8.47994 26.3064 7.41327 26.3197 6.10661C26.3197 4.75994 25.2264 3.66661 23.8664 3.66661Z" fill="#62C4A4" />
                                                <path d="M24.0134 20.3735C23.4934 20.3735 22.9734 20.3334 22.4534 20.2401C21.9067 20.1468 21.5467 19.6268 21.64 19.0801C21.7334 18.5334 22.2534 18.1734 22.8 18.2668C24.44 18.5468 26.1734 18.2401 27.3334 17.4668C27.96 17.0535 28.2934 16.5334 28.2934 16.0134C28.2934 15.4934 27.9467 14.9868 27.3334 14.5734C26.1734 13.8001 24.4134 13.4934 22.76 13.7868C22.2134 13.8934 21.6934 13.5201 21.6 12.9734C21.5067 12.4268 21.8667 11.9068 22.4134 11.8135C24.5867 11.4268 26.84 11.8401 28.44 12.9068C29.6134 13.6934 30.2934 14.8134 30.2934 16.0134C30.2934 17.2001 29.6267 18.3335 28.44 19.1335C27.2267 19.9335 25.6534 20.3735 24.0134 20.3735Z" fill="#62C4A4" />
                                                <path d="M7.9599 10.5468C7.94656 10.5468 7.93323 10.5468 7.93323 10.5468C5.53323 10.4668 3.65323 8.52008 3.63989 6.12008C3.63989 3.66674 5.63989 1.66675 8.0799 1.66675C10.5199 1.66675 12.5199 3.66675 12.5199 6.10675C12.5199 8.52008 10.6399 10.4668 8.23989 10.5468L7.9599 9.54675L8.05323 10.5468C8.02656 10.5468 7.98656 10.5468 7.9599 10.5468ZM8.09323 8.54675C8.17323 8.54675 8.2399 8.54675 8.3199 8.56008C9.50656 8.50675 10.5466 7.44008 10.5466 6.12008C10.5466 4.77341 9.45323 3.68007 8.10656 3.68007C6.7599 3.68007 5.66656 4.77341 5.66656 6.12008C5.66656 7.42674 6.67989 8.48008 7.97323 8.56008C7.98656 8.54675 8.03989 8.54675 8.09323 8.54675Z" fill="#62C4A4" />
                                                <path d="M7.94675 20.3735C6.30675 20.3735 4.73341 19.9335 3.52008 19.1335C2.34675 18.3468 1.66675 17.2134 1.66675 16.0134C1.66675 14.8268 2.34675 13.6934 3.52008 12.9068C5.12008 11.8401 7.37341 11.4268 9.54675 11.8135C10.0934 11.9068 10.4534 12.4268 10.3601 12.9734C10.2667 13.5201 9.74675 13.8934 9.20008 13.7868C7.54675 13.4934 5.80008 13.8001 4.62675 14.5734C4.00008 14.9868 3.66675 15.4934 3.66675 16.0134C3.66675 16.5334 4.01342 17.0535 4.62675 17.4668C5.78675 18.2401 7.52008 18.5468 9.16008 18.2668C9.70675 18.1734 10.2267 18.5468 10.3201 19.0801C10.4134 19.6268 10.0534 20.1468 9.50675 20.2401C8.98675 20.3334 8.46675 20.3735 7.94675 20.3735Z" fill="#62C4A4" />
                                                <path d="M15.9998 20.5066C15.9598 20.5066 15.9332 20.5066 15.8932 20.5066H15.8265C13.3065 20.4266 11.4265 18.4799 11.4265 16.0799C11.4265 13.6266 13.4265 11.6399 15.8665 11.6399C18.3065 11.6399 20.3065 13.6399 20.3065 16.0799C20.2932 18.4932 18.4132 20.4399 16.0132 20.5199C16.0132 20.5066 16.0132 20.5066 15.9998 20.5066ZM15.8665 13.6266C14.5198 13.6266 13.4265 14.7199 13.4265 16.0666C13.4265 17.3866 14.4532 18.4532 15.7732 18.5066C15.7865 18.4932 15.8932 18.4932 16.0132 18.5066C17.3065 18.4399 18.3065 17.3732 18.3198 16.0666C18.3198 14.7332 17.2265 13.6266 15.8665 13.6266Z" fill="#62C4A4" />
                                                <path d="M15.9996 30.3467C14.3996 30.3467 12.7996 29.9334 11.5596 29.0934C10.3863 28.3067 9.7063 27.1867 9.7063 25.9867C9.7063 24.8001 10.373 23.6534 11.5596 22.8667C14.053 21.2134 17.9596 21.2134 20.4396 22.8667C21.613 23.6534 22.293 24.7734 22.293 25.9734C22.293 27.16 21.6263 28.3067 20.4396 29.0934C19.1996 29.9201 17.5996 30.3467 15.9996 30.3467ZM12.6663 24.5467C12.0396 24.9601 11.7063 25.4801 11.7063 26.0001C11.7063 26.5201 12.053 27.0267 12.6663 27.4401C14.4663 28.6534 17.5196 28.6534 19.3196 27.4401C19.9463 27.0267 20.2796 26.5067 20.2796 25.9867C20.2796 25.4667 19.933 24.9601 19.3196 24.5467C17.533 23.3334 14.4796 23.3467 12.6663 24.5467Z" fill="#62C4A4" />
                                            </svg>

                                            </div>
                                            <div className='ms-3'>
                                                <div className='text-heading-home-content'>Daily Visitors</div>
                                                <div className='number-home_content_text'>{orderDetails?.dailyVistiors ? orderDetails?.dailyVistiors : 0}</div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                                <div className='col-lg-4 col-12 ind_item_home_dashboard'>
                                    <div className='content_card_item_row'>

                                        <div className=' content-ind-home-block'>
                                            <div className='icon-img-home-content'><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M22.6666 30.3333H9.33329C4.74663 30.3333 1.66663 27.2533 1.66663 22.6666V15.9999C1.66663 11.8933 4.19996 8.91993 8.13329 8.4266C8.50663 8.37326 8.91996 8.33325 9.33329 8.33325H22.6666C22.9866 8.33325 23.4 8.34658 23.8266 8.41325C27.76 8.86658 30.3333 11.8533 30.3333 15.9999V22.6666C30.3333 27.2533 27.2533 30.3333 22.6666 30.3333ZM9.33329 10.3333C9.01329 10.3333 8.70663 10.3599 8.39996 10.3999C5.46663 10.7732 3.66663 12.9066 3.66663 15.9999V22.6666C3.66663 26.1066 5.89329 28.3333 9.33329 28.3333H22.6666C26.1066 28.3333 28.3333 26.1066 28.3333 22.6666V15.9999C28.3333 12.8799 26.5066 10.7333 23.5466 10.3866C23.2266 10.3333 22.9466 10.3333 22.6666 10.3333H9.33329Z" fill="#62C4A4" />
                                                <path d="M8.25344 10.4133C7.93344 10.4133 7.6401 10.2666 7.4401 9.99994C7.21344 9.69327 7.18677 9.29327 7.3601 8.95994C7.58677 8.5066 7.90677 8.0666 8.3201 7.6666L12.6534 3.31992C14.8668 1.11992 18.4668 1.11992 20.6801 3.31992L23.0134 5.67995C24.0001 6.65328 24.6001 7.95995 24.6668 9.34661C24.6801 9.65328 24.5601 9.94658 24.3334 10.1466C24.1068 10.3466 23.8001 10.4399 23.5068 10.3866C23.2401 10.3466 22.9601 10.3333 22.6668 10.3333H9.33344C9.01344 10.3333 8.70677 10.3599 8.4001 10.3999C8.3601 10.4133 8.30677 10.4133 8.25344 10.4133ZM10.4801 8.33327H22.4268C22.2534 7.87994 21.9734 7.46661 21.6001 7.09328L19.2534 4.71995C17.8268 3.30661 15.4934 3.30661 14.0534 4.71995L10.4801 8.33327Z" fill="#62C4A4" />
                                                <path d="M29.3333 23.0001H25.3333C23.3066 23.0001 21.6666 21.3601 21.6666 19.3334C21.6666 17.3067 23.3066 15.6667 25.3333 15.6667H29.3333C29.88 15.6667 30.3333 16.1201 30.3333 16.6667C30.3333 17.2134 29.88 17.6667 29.3333 17.6667H25.3333C24.4133 17.6667 23.6666 18.4134 23.6666 19.3334C23.6666 20.2534 24.4133 21.0001 25.3333 21.0001H29.3333C29.88 21.0001 30.3333 21.4534 30.3333 22.0001C30.3333 22.5467 29.88 23.0001 29.3333 23.0001Z" fill="#62C4A4" />
                                            </svg>

                                            </div>
                                            <div className='ms-3'>
                                                <div className='text-heading-home-content'>Average Order Value</div>
                                                <div className='number-home_content_text'>{orderDetails?.averageOrderValue ? (userData?.currency ? userData?.currency : null) + " " + (orderDetails?.averageOrderValue)?.toFixed(2)?.toLocaleString() : (userData?.currency ? userData?.currency : null) + " 0"}</div>
                                            </div>
                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>

                        {/* Vendor sales Chart Details */}

                        <div className='charts-details-home-vendor'>

                            <div className='d-flex justify-content-between mb-4'>
                                <div>
                                    <div className='graph-text-content-class'>Total sales</div>
                                    <div className='totalamount_text_class'>{totalSum ? (userData?.currency ? userData?.currency : null) + " " + (totalSum?.toLocaleString()) : (userData?.currency ? userData?.currency : null) + " 0"}</div>
                                </div>
                                <div>

                                    <Dropdown>
                                        <Dropdown.Toggle variant="outline-success" id="dropdown-basic" style={{ textTransform: "capitalize" }}>
                                            {type ? type : "Select"}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handleItemClick('monthly')}>Monthly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleItemClick('weekly')}>Weekly</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleItemClick('daily')}>Daily</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                </div>
                            </div>

                            <div>


                                <Line id="home" options={options} data={canvasData} />



                            </div>

                        </div>
                    </div> : null}



                </div>



                <div className='vendor-home-main-002'>
                    <div className='premium_icon_data'>
                        <BuyPremium />
                    </div>



                </div>




            </div>




        </div>
    )
}

export default VendorHome