import axios from 'axios';
import toast from 'react-hot-toast';

var baseURL = process.env.REACT_APP_API_URL_CUSTOMER

const api = axios.create({
    baseURL: baseURL,
});


const refreshToken = async () => {
    try {

        var refreshToken = localStorage.getItem('urtoken');
        const response = await axios.post(`${baseURL}/auth/getnewtokenuser`, {
            "refreshToken": refreshToken
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[1])
            var error = err?.response?.data?.errors?.body[1]
            if (error === "invalid token") {
                localStorage.removeItem("utoken")
                localStorage.removeItem("urtoken")
                window.location.replace("/customer/login")

            }

        });

        const newToken = response.data.accessToken;
        localStorage.setItem('utoken', newToken);

        return newToken;
    } catch (error) {
        console.log(error)

        throw error;
    }
};


api.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('utoken');

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const newToken = await refreshToken();

                if (newToken) {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                }
            } catch (refreshError) {
                throw refreshError;
            }
        }
        return Promise.reject(error);
    }
);



export default api;
