import React, { useEffect, useState } from 'react'
import "./ClaimedOffers.css"
import menu from "../../Images/Menu.png";
import profile from "../../Images/Profile.png"
import api from '../ApiConfigration/ApiConfigration';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar';
import ProfileDropDown from '../Bulkshare/ProfileDropDown';

function ClaimedOffers() {
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const vendorid = queryParams.get('id');
    var imgUrl = process.env.REACT_APP_API_AWS_BASE_URL



    const [data, setUserdata] = useState([])
    const [store, setStoreData] = useState({})
    const [sidebarData, setsideBarData] = useState({})

    useEffect(() => {
        async function getData() {
            await api.get(`/campaign/getallcouponsclaimed?id=${vendorid}`).then((res) => {
                if (res) {
                    setUserdata(res?.data?.message?.message?.coupons)
                    setStoreData(res?.data?.message?.message?.storeData)
                    setsideBarData({
                        "storeName": res?.data?.message?.message?.storeData?.storeName,
                        "id": res?.data?.message?.message?.storeData?.vendorId
                    })
                }
            }).catch((err) => { console.log(err.message) })
        }
        getData()
    }, [])

    const redirecttoofferpage = (id) => {
        navigate(`/customer/offername?id=${id}`)
    }


    return (
        <div className='claimofferhome'>

            <div className='claimoffershare'>

                <div className='navbarrr'>
                    <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                    <div className='navheadbarr'>Claimed Offers</div>
                    <div className='drop222'>
                        <div className='dropdown001 dropstart'><img style={{ cursor: 'pointer' }} src={profile} alt="" id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                            <ProfileDropDown />

                        </div>
                    </div>
                </div>

                <div className='truediv mt-5 container px-2'>
                    <div className='row  truediv'>

                        {data?.map((itm, k) => (
                            <div onClick={() => { redirecttoofferpage(itm.id) }} style={{ cursor: "pointer" }} className='col-3 col-md-2  controexol mx-1 ms-1 mb-2 mt-2'>
                                {itm?.redeemStatus === true ? <div className='redeemedtextcrossover '>
                                    Claimed
                                </div> : null}
                                <div className='d-flex justify-content-center '>

                                    <div className='imgdivoffer mt-4 mb-4 imagecontainercoupon'>
                                        <img className='imgdivoffer' src={imgUrl + store.storeImage} alt="" />
                                        {itm?.redeemStatus === true ? <div className='claimedoverlay'>

                                        </div> : null}

                                    </div>
                                </div>

                                <div className=' pb-3'>
                                    <h3 className={`claimheading text-center ${itm?.redeemStatus === true ? "textcolorclaimed" : null}`}>Flat {itm.discountPercentage}% off</h3>
                                    <p className={`claimdescript text-center ${itm?.redeemStatus === true ? "textcolorclaimed" : null}`}>from {store.storeName}</p>
                                </div>

                            </div>
                        ))}


                    </div>

                </div>
            </div>

            <div className="offcanvas offcanvas-start cssidebar001" data-bs-scroll="false" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <CustomerSidebar pageData={sidebarData} />
            </div>

        </div>
    )
}

export default ClaimedOffers