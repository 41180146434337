import React, { useEffect, useRef, useState } from "react";
import "./ScratchCard.css";
import logo from "../../Images/175.png";
import { motion } from 'framer-motion'
import scrach from "../../Images/Frame 4468.png";
import Confetti from 'react-confetti';
import api from "../ApiConfigration/ApiConfigration";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import scratchSound from "../../Audio/scratchCardSound.mp3"
import ScratchCard from 'react-scratchcard';
import useWindowSize from 'react-use/lib/useWindowSize'


const config = {
  angle: 90,
  spread: 360,
  startVelocity: 20,
  elementCount: 100,
  dragFriction: .15,
  duration: 2000,
  stagger: 0,
  width: "7px",
  height: "7px",
  perspective: "359px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};

export default function ScratchCard2({ campaignData, inputDataChange }) {

  var navigate = useNavigate()
  const { refid } = useParams();
  var token = localStorage.getItem("utoken")
  const [data, setData] = useState({})
  const canvasRef = useRef(null);
  const [scratchcardShow, setScratchCardShow] = useState(false);
  const [isShow, setIsShow] = useState(false)
  const [istrue, setIsTrue] = useState();
  const [isApiCalled, setApiCalled] = useState(false);
  const [isalert, setisAlert] = useState(false)
  const [isScratching, setIsScratching] = useState(false);
  const audioRef = useRef(null);
  const { width, height } = useWindowSize()
  const [redeemclicked, setRedeemClicked] = useState(false)


  useEffect(() => {
    inputDataChange(isApiCalled)
  }, [isApiCalled])


  useEffect(() => {
    setData(campaignData)
    setIsTrue(campaignData?.isWon)
    setIsShow(campaignData?.isScratched)
    if (campaignData?.isScratched === true) {
      setScratchCardShow(true)
    }

  }, [campaignData])


  // useEffect(() => {

  //   try {

  //     const canvas = canvasRef.current;
  //     const context = canvas.getContext("2d");
  //     const init = () => {
  //       const img = new Image();
  //       img.onload = () => {
  //         context.drawImage(img, 0, 0, 100, 100); // Draw the image at position (0, 0) with width and height as 100
  //       };

  //       img.src = scrach; // Replace with the actual path to your image

  //     };
  //     let isDragging = false;
  //     let mouseX = 0;
  //     let mouseY = 0;
  //     let scratchedPixels = 0;
  //     const threshold = 0.6;

  //     let events = {
  //       mouse: {
  //         down: "mousedown",
  //         move: "mousemove",
  //         up: "mouseup",
  //       },
  //       touch: {
  //         down: "touchstart",
  //         move: "touchmove",
  //         up: "touchend",
  //       },
  //     };
  //     let deviceType = "";
  //     const isTouchDevice = () => {
  //       try {
  //         document.createEvent("TouchEvent");
  //         deviceType = "touch";
  //         return true;
  //       } catch (e) {
  //         deviceType = "mouse";
  //         return false;
  //       }
  //     };

  //     let rectLeft = canvas.getBoundingClientRect().left;
  //     let recrTop = canvas.getBoundingClientRect().top;

  //     const getXY = (e) => {
  //       mouseX =
  //         (!isTouchDevice() ? e.pageX : e.touches[0].pageX) - rectLeft - 50;
  //       mouseY = (!isTouchDevice() ? e.pageY : e.touches[0].pageY) - recrTop - 72;
  //     };
  //     console.log(isTouchDevice());
  //     canvas.addEventListener(events[deviceType].down, (event) => {
  //       isDragging = true;
  //       getXY(event);
  //       scratch(mouseX, mouseY);
  //     });
  //     canvas.addEventListener(events[deviceType].move, (event) => {

  //       if (!isTouchDevice()) {
  //         event.preventDefault();
  //       }
  //       if (isDragging) {
  //         getXY(event);
  //         scratch(mouseX, mouseY);
  //       }
  //     });
  //     canvas.addEventListener(events[deviceType].up, () => {
  //       isDragging = false;
  //     });
  //     canvas.addEventListener("mouseleave", () => {
  //       isDragging = false;
  //     });
  //     const scratch = async (x, y) => {
  //       // console.log(({x,y}))
  //       setIsScratching(true);

  //       if (!token) {
  //         const delayTime = 1000;
  //         await new Promise((resolve) => setTimeout(resolve, delayTime));
  //         return;
  //       }
  //       if (!data?.camapaign?.beforeHeading) {
  //         return;
  //       }

  //       context.globalCompositeOperation = "destination-out";
  //       context.beginPath();
  //       context.arc(x, y, 12, 0, 3 * Math.PI);
  //       context.fill();
  //       scratchedPixels += Math.PI * Math.pow(12, 2);
  //       const totalArea = canvas.width * canvas.height;
  //       const percentageScratched = scratchedPixels / totalArea;

  //       if (percentageScratched >= threshold * 10) {

  //         if (!campaignData?.isScratched) {
  //           if (!isApiCalled) {
  //             setApiCalled(true);
  //             setIsShow(true)
  //             if (data?.camapaign?.id) {
  //               api.get(`/campaign/user-picked-scratch-card?id=${data?.camapaign?.id}`)
  //                 .catch((err) => { console.log(err?.message) })
  //             }
  //           }
  //         }
  //         clearCanvas();
  //       }
  //       setIsScratching(false);

  //     };
  //     const clearCanvas = () => {
  //       context.clearRect(0, 0, canvas.width, canvas.height);
  //       setScratchCardShow(true)
  //       setApiCalled(true)



  //     };
  //     window.onload = init();

  //     if (isShow) {
  //       context.clearRect(0, 0, canvas.width, canvas.height);
  //       setScratchCardShow(true)
  //     }
  //   } catch (err) {
  //     console.log(err.message)
  //   }
  // }, [isShow, token, isalert, data]);

  // const loginUser = async () => {
  //   toast.error("Login to scratch a card")
  //   const delayTime = 1000;
  //   await new Promise((resolve) => setTimeout(resolve, delayTime));

  //   navigate(`/customer/login?redirectcampaign=scratch-card-campaign/${refid}`)

  // }


  const campaignEnded = async () => {
    toast.error("Campaign Ended")
  }



  const settings = {
    width: 320,
    height: 330,
    image: scrach,
    finishPercent: 60,
    onComplete: () => {
      setApiCalled(true);
      setIsShow(true);
      setScratchCardShow(true)


      if (data?.camapaign?.id) {
        api.get(`/campaign/user-picked-scratch-card?id=${data?.camapaign?.id}`)
          .catch((err) => { console.log(err) })
      }

    },

  };

  const makeNoise = () => {
    audioRef.current.play();
  }


  const processData = (text) => {

    if (text.includes("@discount")) {
      text = text.replace("@discount", data?.product?.discounPercentage + "%");
    }

    return text;
  }

  return (


    <div className="scratch-card-body">

      <audio ref={audioRef} src={scratchSound} />

      {isShow
        ?
        null
        :
        <h1 style={{ marginBottom: "-60px" }} className="scratch-card-heading-text ">
          {data?.camapaign?.beforeHeading ? data?.camapaign?.beforeHeading : "Scratch to Win"}
        </h1>
      }


      <div>
      </div>

      <div className="scratch-card">

        <div className="mb-5">
        </div>

        {/* {scratchcardShow ? '' : <>{!token ? <canvas onClick={loginUser}
          className="scratch"
          ref={canvasRef}
          height={100}
          width={100}
        ></canvas> : <>{data?.isCampaignEnd ? <canvas onClick={campaignEnded}
          className="scratch"
          ref={canvasRef}
          height={100}
          width={100}
        ></canvas> : <canvas
          className="scratch"
          ref={canvasRef}
          height={100}
          width={100}
        ></canvas>}</>}</>} */}


        {isShow ?
          <div style={{ height: scratchcardShow ? '' : '320px' }} className={`scratch-product-card`}>
            {istrue ? <h1 className="text-card-scratch">{data?.camapaign?.afterHeading ? (data?.camapaign?.afterHeading).toLowerCase() : null}</h1> : null}
            <div style={{ width: "100%" }} >

              <Confetti
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                width={(width / 2) * 1.4}
                height={height}
                tweenDuration="5000"
                recycle={false}
                confettiSource={{ x: width / 2.8, y: height / 4, w: 7, h: 7 }}
              />

            </div>
            {istrue ? <div className="scratch-product-card-img">
              <img style={{ width: "150px" }} src={data?.product?.product?.productImage[0]} alt="" />
            </div> : null}
            {scratchcardShow ? <>{istrue ?
              <motion.div
                initial={{ y: -20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 50 }}
                className="scratch-card-sec-2">
                <div className="scratch-product-offerText">
                  <h1>{data?.product?.discounPercentage}%</h1>
                  <p>OFF</p>
                </div>
                <div className="scratch-product-offerText">
                  <h2>{data?.product?.product?.productName}</h2>
                </div>
                <div className="scratch-product-footer-text">
                  <p>{data?.camapaign?.afterSubHeading ? processData(data?.camapaign?.afterSubHeading) : null}</p>
                </div>
              </motion.div> : <div className="better-luck-card-head"><p className="better-luck-card">Better luck next time.</p></div>} </> : ''}

          </div>


          : <div onTouchMove={makeNoise} onMouseDown={makeNoise} ><ScratchCard {...settings}>

            <div style={{ height: scratchcardShow ? '' : '319px' }} className={`scratch-product-card`}>
              {istrue ? <h1 className="text-card-scratch">{data?.camapaign?.afterHeading ? (data?.camapaign?.afterHeading).toLowerCase() : null}</h1> : null}
              {scratchcardShow ? <div style={{ width: "100%" }} >

                <Confetti
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                  width={(width / 2) * 1.4}
                  height={height}
                  tweenDuration="5000"
                  recycle={false}
                  confettiSource={{ x: width / 2.8, y: height / 4, w: 10, h: 50 }}
                />

              </div> : null}
              {istrue ? <div className="scratch-product-card-img">
                <img style={{ width: "150px" }} src={data?.product?.product?.productImage[0]} alt="" />
              </div> : null}
              {scratchcardShow ? <>{istrue ?
                <motion.div
                  initial={{ y: -20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ type: "spring", stiffness: 50 }}
                  className="scratch-card-sec-2">
                  <div className="scratch-product-offerText">
                    <h1>{data?.product?.discounPercentage}%</h1>
                    <p>OFF</p>
                  </div>
                  <div className="scratch-product-offerText">
                    <h2>{data?.product?.product?.productName}</h2>
                  </div>
                  <div className="scratch-product-footer-text">
                    <p>{data?.camapaign?.afterSubHeading ? processData(data?.camapaign?.afterSubHeading) : null}</p>
                  </div>
                </motion.div> : <div className="better-luck-card-head"><p className="better-luck-card">Better luck next time.</p></div>} </> : ''}

            </div>
          </ScratchCard></div>}

        {isShow ? null : <div className="scratch-chance-text mt-2">
          <>{data?.camapaign?.beforeSubHeading ? <p>{data?.camapaign?.beforeSubHeading}</p> : <p> </p>}</>


        </div>}
      </div>
    </div>
  );
}
