import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function DynamicDropdown({ initialData, clickSubmit, errors, productlist, products, setProducts, isDisabled }) {
  const [items, setItems] = useState([]);
  const [productsname, setProductName] = useState([])
  const [formerror, setFormErrors] = useState([])
  const [isclickSubmit, setSubmit] = useState(false)


  useEffect(() => {
    if (initialData && initialData.length > 0) {
      setItems(initialData)
    }
  }, [initialData])



  useEffect(() => {
    setSubmit(clickSubmit)
  }, [clickSubmit])

  useEffect(() => {
    setFormErrors(errors.products)
    setProductName(productlist)
  }, [productlist, errors]);

  useEffect(() => {
    setItems(products);
  }, []);

  useEffect(() => {
    setProducts(items);
  }, [items]);

  const onDiscountChange = (e, index) => {
    const num = (e.target.value);
  
      const newProducts = [...items];
      newProducts[index].discount = num;
      setItems(newProducts);
      if (isclickSubmit) {
        const newErrors = [...formerror];
        newErrors[index].discount = "";
        setFormErrors(newErrors)
      
    }
  };

  const onProductChange = (index, id) => {
    const newProducts = [...items];
    newProducts[index].product = id?.productName;
    newProducts[index].id = id?.id;
    setItems(newProducts)
    if (isclickSubmit) {
      const newErrors = [...formerror];
      newErrors[index].product = "";
      setFormErrors(newErrors);
    }
  }

  const onRemoveDropDown = (index) => {
    const products = [...items]
    products.splice(index, 1)
    setItems([...products])
    const errors = [...formerror]
    errors.splice(index, 1)
    setFormErrors([...errors])


  }

  const addFormdata = () => {
    setFormErrors([...formerror, { product: '', discount: '' }])
    setItems([...items, { product: '', discount: "" }])

  }

  console.log(items);
  //   const onProductCount=(e)=>{
  //    if(0<Number(e.target.value)&&100>Number(e.target.value)){
  //        const arr=[]
  //        let num=Number(e.target.value)
  //           num-=items.length
  //           if(0<num){
  //             for(let i=0;i<num;i++){
  //                arr[i]={product:'',discount:0}
  //            }
  //            setItems([...items,...arr])
  //           }else{
  //             setItems(items.splice(0,Number(e.target.value)))
  //           }

  //    }
  // }
  return (
    <>
      <div className="d-flex flex-column">
        <label
          className="scratch-card-form-num-text"
          htmlFor="Number of product"
        >
          Number of scratch card
        </label>
        <input
          disabled
          value={items.length}
          className="scratch-card-form-input"
          min={0}
          max={100}
          type="number"
        />
      </div>

      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="d-flex flex-grow-1 product-name">
            <p className="p-0 m-0">Product</p>
          </div>
          <div className="d-flex w-25 product-name">
            <p className="p-0 m-0">Discount(%)</p>
          </div>
        </div>
        {items.map((item, index) => {
          return (
            <>
              <div className="d-flex flex-row">
                <div className="dropdown d-flex flex-grow-1 product-input-outer-box">
                  <button
                    class=" scratch-card-form-dropdown dropdown-toggle "
                    type="button"
                    disabled={isDisabled}
                    id="dropdownMenuButton2"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <p style={{ height: "19px" }} className="p-0 m-0">
                      {item?.product ? item?.product : "Select Product"}
                    </p>
                  </button>
                  <ul
                    class="dropdown-menu dropdown-menu"
                    aria-labelledby="dropdownMenuButton2"
                  >
                    {productsname?.length === 0 ? <div style={{ cursor: "pointer" }} class="dropdown-item " >
                      No Product available
                    </div> : <>{productsname?.map((itm, k) => (<li>
                      <div style={{ cursor: "pointer" }} class="dropdown-item " onClick={() => onProductChange(index, itm)}>
                        {itm?.productName ? itm?.productName : "No Name"}
                      </div>
                    </li>))}</>}
                  </ul>

                </div>
                <div className="d-flex align-items-center w-25 product-input-outer-box">
                  <input
                    onChange={(e) => onDiscountChange(e, index)}
                    style={{ minWidth: "60px" }}
                    min={0}
                    disabled={isDisabled}
                    value={item.discount}
                    max={100}
                    className="scratch-card-form-input form-control"
                    type="number"
                  />
                  {isDisabled ? null : <svg onClick={() => onRemoveDropDown(index)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                      fill="#C32D22"
                    />
                  </svg>}


                </div>


              </div>
              <div className="d-flex justify-content-between">
                {formerror[index]?.product ? <div className="text-danger ms-3">{formerror[index]?.product}</div> : <div />}
                {formerror[index]?.discount ? <div className="text-danger ms-3 float-right">{formerror[index]?.discount}</div> : null}
              </div>
            </>

          );
        })}
        {isDisabled ? null : <button onClick={() => addFormdata()} className="terms-button mx-3 mt-2" type="button">
          Add
        </button>}
      </div>
    </>
  );
}
