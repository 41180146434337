import React, { useEffect, useState } from 'react';
import "./ViewOrders.css"
import "../BulkshareCampaign/BulkshareCampaign.css"
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';

function ViewOrders() {

    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('orderid');


    const [orderData, setOrderData] = useState();
    const [orderStatus, setOrderStatus] = useState();
    const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
    const [internalNote, setInternalNote] = useState('');
    const [isShowEdit, setShowEdit] = useState(true)
    const [isDisabled, setIsDisabled] = useState(false)
    var userData = useSelector(state => state?.vendorData?.vendor);


    const handleStatusChange = (e) => {
        const selectedStatus = e.target.value;
        setSelectedOrderStatus(selectedStatus);

        api.post('/order/updateorderstatus', { id: uid, data: { orderStatus: selectedStatus } })
            .then((res) => {
                if (res) {
                    toast.success(res?.data?.message?.message)
                }
            }).catch((err) => {
                toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
            });
    };


    useEffect(() => {

        api.get(`/order/getindividualorderbyid?id=${uid}`).then((res) => {
            if (res) {
                setOrderData(res?.data?.message?.message)
                setSelectedOrderStatus(res?.data?.message?.message?.orderStatus)
                setInternalNote(res?.data?.message?.message?.InternalNote)
            } else {
                toast.error("No Data Found")
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.message[0])
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
        });

        api.get(`/store/updateOrderStatus`).then((res) => {
            if (res) {
                setOrderStatus(res?.data?.message?.message)
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.message[0])
            toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
        });

    }, [])


    const handleInternalNoteChange = (e) => {
        const note = e.target.value;
        setInternalNote(note);
    }


    const saveInternalNote = () => {

        api.post('/order/updateorderstatus', { id: uid, data: { "InternalNote": internalNote } })
            .then((res) => {
                if (res) {
                    toast.success(res?.data?.message?.message)
                    setShowEdit(true)
                }
            }).catch((err) => {
                toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err?.message)
            });
    }




    function formatDate(timestamp) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        const date = new Date(timestamp);
        const month = months[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        const hour = date.getHours() % 12 || 12;
        const minute = date.getMinutes();
        const ampm = date.getHours() < 12 ? 'am' : 'pm';

        const suffix = day === 1 ? 'st' : day === 2 ? 'nd' : day === 3 ? 'rd' : 'th';

        const formattedDate = `${month} ${day}${suffix} ${year}, ${hour}:${minute.toString().padStart(2, '0')} ${ampm}`;
        return formattedDate;
    }


    const downloadInvoice = async () => {
        setIsDisabled(true)
        await api.get(`/order/downloadinvoice?id=${uid}`).then((res) => {
            if (res) {
                toast.success("Invoice Generated")
                var url = res?.data?.message?.url
                var myWindow = window.open(url, "_blank");

                setIsDisabled(false)

            } else {
                toast.error("Error Generating Invoice")
            }
        }).catch((err) => {
            setIsDisabled(false)
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
        })
    }


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='vieworder022'>
                {/* <div className='bulkshare111'>d</div> */}
                <div className='d-flex'>
                    <div style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='d-flex ps-2 flex-column'>
                        <p className='top-small-heading p-0 m-0'>Back to the Order list</p>
                        <div className='d-flex'>
                            {orderData ? <p style={{ textTransform: "capitalize" }} className='bulkshare111 p-0 m-0'>N1 - {orderData?.customer?.userName ? orderData?.customer?.userName : orderData?.name}</p>
                                : null}
                        </div>
                    </div>
                </div>
                <div className='d-flex vieworder023'>
                    <div className='mx-3 mt-1 vieworder018'>Share</div>
                    {
                        isShowEdit ? <div onClick={() => setShowEdit(false)} className='mx-3 mt-1 vieworder019'>Edit</div> : null
                    }
                    <div className='mx-3 mt-1 vieworder020'>Print</div>
                    <div className='mx-3 mt-1 vieworder021'>Delete</div>
                    <div className='mx-3'><button type='Submit' className='bulkshare112'><i className="fa-brands fa-whatsapp vieworder017"></i><span className='vieworder023'>Message Order Details</span></button></div>
                </div>


            </div>


            <div className='row mt-5'>

                <div className='col-12 col-md-6 mb-4'>

                    <div className='vieworder001'>


                        <div className='d-flex justify-content-between'>
                            <div className='vieworder003'>N1 - Total {orderData?.orderProducts?.length} item</div>
                            <div><div style={{ textTransform: "uppercase" }} className='vieworder004'>{orderData?.orderStatus}</div></div>
                        </div>

                        <div className='mt-5 pt-2 vieworder009 pb-3'>
                            <div >
                                <p className='vieworder005 mb-3'>Customer</p>
                            </div>
                            <>{orderData ? <>
                                <div>
                                    <p style={{ textTransform: "capitalize" }} className='vieworder006 '>  {orderData?.name}</p>
                                </div>
                                <div>
                                    <p className='vieworder006'>+91 {orderData?.phoneNumber}</p>
                                </div>
                                <div>
                                    <p className='vieworder006'>{formatDate(orderData?.createdAt)}</p>
                                </div></> : null}</>
                        </div>

                        <div className='mt-4 vieworder009 pb-3'>
                            <div>
                                <p className='vieworder005'>Delivery</p>
                            </div>
                            {orderData ? <div>
                                <p style={{ textTransform: "capitalize" }} className='vieworder006'>{orderData?.addressOne + " , " + orderData?.addressTwo}</p>
                                <p className='vieworder006'>{orderData?.pincode}</p>
                            </div> : null}
                        </div>


                        <div className='mt-5 mb-4'>

                            <table class="table table-borderless">
                                <thead className='vieworder009 pb-3 mb-3'>
                                    <tr>
                                        <th className='vieworder010' scope="col"></th>
                                        <th className='vieworder010' scope="col"></th>
                                        <th className='vieworder010' scope="col"></th>
                                        <td className='vieworder010'>Item</td>
                                        <td className='text-end vieworder010'>{userData?.currency? userData?.currency: null}</td>

                                    </tr>
                                </thead>
                                <tbody>
                                    {orderData?.orderProducts?.map((itm, k) => <tr>
                                        <td></td>
                                        <td className='vieworder011'>{itm?.quantity}x</td>
                                        <td></td>
                                        <td style={{ textTransform: "capitalize" }} className='vieworder011'>{itm?.isVarient ? itm.variants?.name : itm?.products?.productName}</td>
                                        <td className='text-end vieworder011'>{itm?.isVarient ? itm.variants?.price : itm?.products?.price}</td>
                                    </tr>)}

                                </tbody>

                            </table>

                            <div className='d-flex justify-content-between mb-4 mt-2'>
                                <div className='vieworder012'>Items total</div>
                                <div className='vieworder012'>{userData?.currency? userData?.currency: null} {orderData?.cartValue ? orderData?.cartValue : 0.00}</div>
                            </div>

                            <div className='d-flex justify-content-between'>
                                <div className='vieworder013'>Total</div>
                                <div className='vieworder013'>{userData?.currency? userData?.currency: null} {orderData?.cartValue ? orderData?.cartValue : 0.00}</div>
                            </div>


                        </div>

                        <div className='d-flex justify-content-end mt-5'>
                            <div className='mx-3'><button className='vieworder014'>Share</button></div>
                            <div><button disabled={isDisabled} onClick={() => { downloadInvoice() }} className='vieworder014'>{isDisabled ? "Generating" : "Download"}</button></div>
                        </div>





                    </div>
                </div>

                <div className='col-12 col-md-6'>

                    <div className='vieworder002'>

                        <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label vieworder015">Status</label>

                            <select
                                style={{ textTransform: "capitalize" }}
                                className="form-select"
                                value={selectedOrderStatus}
                                disabled={isShowEdit}
                                onChange={handleStatusChange} >

                                {orderStatus?.map((itm, k) => <option style={{ textTransform: "capitalize" }} key={itm?.status} value={itm?.status}>{itm?.status}</option>)}

                            </select>
                        </div>

                        <div className="mb-4">
                            <label for="exampleFormControlInput1" className="form-label vieworder015">Internal Note</label>
                            <input type="text"
                                name='internalNote'
                                value={internalNote}
                                disabled={isShowEdit}
                                onChange={handleInternalNoteChange} className="form-control" id="exampleFormControlInput1" />
                            <p className='vieworder016'>Invisible to customers</p>

                        </div>
                        {
                            !isShowEdit ? <div className='d-flex justify-content-center'>
                                <button className='vieworder014 ' onClick={saveInternalNote}>Save</button>
                            </div> : null
                        }
                    </div>
                </div>




            </div>




        </div>
    )
}

export default ViewOrders