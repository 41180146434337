import React, { useState } from 'react'
import toast from 'react-hot-toast'

function CartFooterbar({ updateQuantity,handleAddToCart }) {
    const [quantity, setQuantity] = useState(1)


    const updateQuantityData = (type) => {

        if (type === "add") {
            setQuantity(quantity + 1)
            updateQuantity(quantity+1)

        } else {
            if (Number(quantity) === 1) {
                toast.error("Minimum Quantity is 1")
            } else {
                setQuantity(quantity - 1)
                
                updateQuantity(quantity+1)

            }
        }

    }




    return (


        <div className='cartfooterbar001'>

            <div className='d-flex cartfooterbar002'>

                <div onClick={() => { updateQuantityData("remove") }} style={{ cursor: "pointer" }}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M18 13.25H6C5.59 13.25 5.25 12.91 5.25 12.5C5.25 12.09 5.59 11.75 6 11.75H18C18.41 11.75 18.75 12.09 18.75 12.5C18.75 12.91 18.41 13.25 18 13.25Z" fill="white" />
                </svg></div>
                <div className='cartfooterbar004'>
                    {quantity?.toString().padStart(2, '0')}
                </div>
                <div onClick={() => { updateQuantityData("add") }} style={{ cursor: "pointer" }} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M18 13.25H6C5.59 13.25 5.25 12.91 5.25 12.5C5.25 12.09 5.59 11.75 6 11.75H18C18.41 11.75 18.75 12.09 18.75 12.5C18.75 12.91 18.41 13.25 18 13.25Z" fill="white" />
                        <path d="M12 19.25C11.59 19.25 11.25 18.91 11.25 18.5V6.5C11.25 6.09 11.59 5.75 12 5.75C12.41 5.75 12.75 6.09 12.75 6.5V18.5C12.75 18.91 12.41 19.25 12 19.25Z" fill="white" />
                    </svg>
                </div>

            </div>

            <div onClick={handleAddToCart} style={{ cursor: "pointer" }} className='d-flex cartfooterbar003'>
                <div><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M19.96 9.4599C19.29 8.7199 18.28 8.2899 16.88 8.1399V7.3799C16.88 6.0099 16.3 4.6899 15.28 3.7699C14.25 2.8299 12.91 2.3899 11.52 2.5199C9.12999 2.7499 7.11999 5.0599 7.11999 7.5599V8.1399C5.71999 8.2899 4.70999 8.7199 4.03999 9.4599C3.06999 10.5399 3.09999 11.9799 3.20999 12.9799L3.90999 18.5499C4.11999 20.4999 4.90999 22.4999 9.20999 22.4999H14.79C19.09 22.4999 19.88 20.4999 20.09 18.5599L20.79 12.9699C20.9 11.9799 20.92 10.5399 19.96 9.4599ZM11.66 3.9099C12.66 3.8199 13.61 4.1299 14.35 4.7999C15.08 5.4599 15.49 6.3999 15.49 7.3799V8.0799H8.50999V7.5599C8.50999 5.7799 9.97999 4.0699 11.66 3.9099ZM8.41999 13.6499H8.40999C7.85999 13.6499 7.40999 13.1999 7.40999 12.6499C7.40999 12.0999 7.85999 11.6499 8.40999 11.6499C8.96999 11.6499 9.41999 12.0999 9.41999 12.6499C9.41999 13.1999 8.96999 13.6499 8.41999 13.6499ZM15.42 13.6499H15.41C14.86 13.6499 14.41 13.1999 14.41 12.6499C14.41 12.0999 14.86 11.6499 15.41 11.6499C15.97 11.6499 16.42 12.0999 16.42 12.6499C16.42 13.1999 15.97 13.6499 15.42 13.6499Z" fill="#0B150F" />
                </svg></div>
                <div className='cartfooterbar005'>Add to cart</div>
            </div>


        </div>


    )
}

export default CartFooterbar