import React, { useState } from "react";
import "./Settings.jsx";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import api from "../ApiConfigration/ApiConfigration.jsx";
function PasswordChange({ setShow }) {
  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /\d/;
  const specialCharacterRegex = /[^a-zA-Z0-9]/;
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    conformPassword: "",
  });
  const [passwordError, setPasswordErr] = useState({
    lowercase: false,
    uppercase: false,
    number: false,
    specialCharacter: false,
    length: false,
  });
  const [freezeButton, setFreezeButton] = useState(false)
  const [formErrors, setFormErrors] = useState({
    oldPassword: false,
    newPassword: false,
    conformPassword: false,
  });
  const onhandleChange = (e) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: false });

    setFormData({ ...formData, [name]: value.trim() });
    if (name === 'conformPassword') {
      if (formData.newPassword !== value.trim()) {
        setFormErrors({ ...formErrors, [name]: true });
      }
    }
    if (name === "newPassword") {
      let password = value.trim();
      const error = { ...passwordError };
      if (!lowercaseRegex.test(password)) {
        error.lowercase = false;
      } else {
        error.lowercase = true;
      }
      if (!uppercaseRegex.test(password)) {
        error.uppercase = false;
      } else {
        error.uppercase = true;
      }
      if (!numberRegex.test(password)) {
        error.number = false;
      } else {
        error.number = true;
      }
      if (!specialCharacterRegex.test(password)) {
        error.specialCharacter = false;
      } else {
        error.specialCharacter = true;
      }
      if (password.length < 8) {
        error.length = false;
      } else {
        error.length = true;
      }
      console.log(error);
      setPasswordErr(error);
    }
  };

  const StrengthError = () => {
    for (const key in passwordError) {
      if (passwordError[key] === false) {
        return true;
      }
    }
    return false;
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = { ...formErrors };
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      if (formData[key] == "") {
        errors[key] = true;
        hasError = true;
      }
      if (key == 'conformPassword') {
        if (formData.newPassword !== formData.conformPassword) {
          errors[key] = true;
          hasError = true;
        }
      }

    });
    if (hasError) {
      setFormErrors(errors);
      return;
    }

    if (StrengthError() === true) {
      toast.error("Password Strength is low")
      return;
    }
    setFreezeButton(true)

    await api.post("/auth/updatepassword", formData).then((res) => {
      setFreezeButton(false)

      if (res) {
        toast.success("Successfully Updated Password")
      } else {
        toast.error("Error Updating Passowrd!")
      }
    }).catch((err) => {
      setFreezeButton(false)

      console.log(err)
      toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
    })

  };

  return (
    <div className="password-change-modal-bg">
      <form onSubmit={handleSubmit}>
        <motion.div
          style={{ gap: "16px" }}
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          className="password-change-modal"
        >
          <div className="d-flex justify-content-between w-100 pb-3 ">
            <h1 className="bulkshare111">Choose new password</h1>
            <div>
              <svg
                onClick={() => setShow(false)}
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
              >
                <path
                  d="M23.3921 12.0208L12.0784 23.3345C11.6918 23.7211 11.0507 23.7211 10.6641 23.3345C10.2776 22.948 10.2776 22.3069 10.6641 21.9203L21.9778 10.6066C22.3644 10.2201 23.0055 10.2201 23.3921 10.6066C23.7786 10.9932 23.7786 11.6343 23.3921 12.0208Z"
                  fill="#0B150F"
                />
                <path
                  d="M23.3921 23.3343C23.0055 23.7208 22.3644 23.7208 21.9778 23.3343L10.6641 12.0206C10.2776 11.634 10.2776 10.9929 10.6641 10.6064C11.0507 10.2198 11.6918 10.2198 12.0784 10.6064L23.3921 21.9201C23.7786 22.3066 23.7786 22.9477 23.3921 23.3343Z"
                  fill="#0B150F"
                />
              </svg>
            </div>
          </div>
          <div style={{ gap: "4px" }} className="d-flex flex-column w-100">
            <label htmlFor="" className="setting-box-label">
              Current password
            </label>
            <input
              name="oldPassword"
              onChange={(e) => onhandleChange(e)}
              type="text"
              className="setting-box-input form-control"
            />
            {formErrors?.oldPassword === true ? (
              <div style={{ lineHeight: "14px" }} className="text-danger ">
                Please enter old password
              </div>
            ) : null}
          </div>
          <div style={{ gap: "4px" }} className="d-flex flex-column w-100">
            <label htmlFor="" className="setting-box-label">
              New password
            </label>
            <input
              name="newPassword"
              onChange={(e) => onhandleChange(e)}
              type="password"
              className="setting-box-input form-control"
            />
            {formErrors?.newPassword === true && (
              <div style={{ lineHeight: "14px" }} className="text-danger ">
                Please enter new password
              </div>
            )}
          </div>
          <div style={{ gap: "4px" }} className="d-flex flex-column w-100">
            <label htmlFor="" className="setting-box-label">
              Confirm new password
            </label>
            <input
              name="conformPassword"
              onChange={onhandleChange}
              type="password"
              className="form-control setting-box-input"
            />
            {formErrors?.conformPassword === true && (
              <div style={{ lineHeight: "14px" }} className="text-danger ">
                {formData?.conformPassword == "" ? 'Please enter conform password' : 'password not match'}
              </div>
            )}
          </div>
          <div className="d-flex w-100">
            <div className="d-flex flex-column w-50">
              <div className="error-status-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  {passwordError.lowercase ? (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM13.9846 9.02409L9.25964 13.7491C9.14297 13.8658 8.98464 13.9324 8.81797 13.9324C8.6513 13.9324 8.49297 13.8658 8.3763 13.7491L6.01797 11.3908C5.7763 11.1491 5.7763 10.7491 6.01797 10.5074C6.25964 10.2658 6.65964 10.2658 6.9013 10.5074L8.81797 12.4241L13.1013 8.14076C13.343 7.89909 13.743 7.89909 13.9846 8.14076C14.2263 8.38242 14.2263 8.77409 13.9846 9.02409Z"
                      fill="#419556"
                    />
                  ) : (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM12.8013 12.8574C13.043 13.0991 13.043 13.4991 12.8013 13.7408C12.6763 13.8658 12.518 13.9241 12.3596 13.9241C12.2013 13.9241 12.043 13.8658 11.918 13.7408L10.0013 11.8241L8.08464 13.7408C7.95964 13.8658 7.8013 13.9241 7.64297 13.9241C7.48464 13.9241 7.3263 13.8658 7.2013 13.7408C6.95964 13.4991 6.95964 13.0991 7.2013 12.8574L9.11797 10.9408L7.2013 9.02409C6.95964 8.78242 6.95964 8.38242 7.2013 8.14076C7.44297 7.89909 7.84297 7.89909 8.08464 8.14076L10.0013 10.0574L11.918 8.14076C12.1596 7.89909 12.5596 7.89909 12.8013 8.14076C13.043 8.38242 13.043 8.78242 12.8013 9.02409L10.8846 10.9408L12.8013 12.8574Z"
                      fill="#C32D22"
                    />
                  )}
                </svg>
                <span>one lowercase character</span>
              </div>
              <div className="error-status-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  {passwordError.uppercase ? (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM13.9846 9.02409L9.25964 13.7491C9.14297 13.8658 8.98464 13.9324 8.81797 13.9324C8.6513 13.9324 8.49297 13.8658 8.3763 13.7491L6.01797 11.3908C5.7763 11.1491 5.7763 10.7491 6.01797 10.5074C6.25964 10.2658 6.65964 10.2658 6.9013 10.5074L8.81797 12.4241L13.1013 8.14076C13.343 7.89909 13.743 7.89909 13.9846 8.14076C14.2263 8.38242 14.2263 8.77409 13.9846 9.02409Z"
                      fill="#419556"
                    />
                  ) : (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM12.8013 12.8574C13.043 13.0991 13.043 13.4991 12.8013 13.7408C12.6763 13.8658 12.518 13.9241 12.3596 13.9241C12.2013 13.9241 12.043 13.8658 11.918 13.7408L10.0013 11.8241L8.08464 13.7408C7.95964 13.8658 7.8013 13.9241 7.64297 13.9241C7.48464 13.9241 7.3263 13.8658 7.2013 13.7408C6.95964 13.4991 6.95964 13.0991 7.2013 12.8574L9.11797 10.9408L7.2013 9.02409C6.95964 8.78242 6.95964 8.38242 7.2013 8.14076C7.44297 7.89909 7.84297 7.89909 8.08464 8.14076L10.0013 10.0574L11.918 8.14076C12.1596 7.89909 12.5596 7.89909 12.8013 8.14076C13.043 8.38242 13.043 8.78242 12.8013 9.02409L10.8846 10.9408L12.8013 12.8574Z"
                      fill="#C32D22"
                    />
                  )}
                </svg>
                <span>one uppercase character</span>
              </div>
              <div className="error-status-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  {passwordError.number ? (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM13.9846 9.02409L9.25964 13.7491C9.14297 13.8658 8.98464 13.9324 8.81797 13.9324C8.6513 13.9324 8.49297 13.8658 8.3763 13.7491L6.01797 11.3908C5.7763 11.1491 5.7763 10.7491 6.01797 10.5074C6.25964 10.2658 6.65964 10.2658 6.9013 10.5074L8.81797 12.4241L13.1013 8.14076C13.343 7.89909 13.743 7.89909 13.9846 8.14076C14.2263 8.38242 14.2263 8.77409 13.9846 9.02409Z"
                      fill="#419556"
                    />
                  ) : (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM12.8013 12.8574C13.043 13.0991 13.043 13.4991 12.8013 13.7408C12.6763 13.8658 12.518 13.9241 12.3596 13.9241C12.2013 13.9241 12.043 13.8658 11.918 13.7408L10.0013 11.8241L8.08464 13.7408C7.95964 13.8658 7.8013 13.9241 7.64297 13.9241C7.48464 13.9241 7.3263 13.8658 7.2013 13.7408C6.95964 13.4991 6.95964 13.0991 7.2013 12.8574L9.11797 10.9408L7.2013 9.02409C6.95964 8.78242 6.95964 8.38242 7.2013 8.14076C7.44297 7.89909 7.84297 7.89909 8.08464 8.14076L10.0013 10.0574L11.918 8.14076C12.1596 7.89909 12.5596 7.89909 12.8013 8.14076C13.043 8.38242 13.043 8.78242 12.8013 9.02409L10.8846 10.9408L12.8013 12.8574Z"
                      fill="#C32D22"
                    />
                  )}
                </svg>
                <span>one number</span>
              </div>
            </div>
            {/* right */}
            <div className="d-flex flex-column w-50">
              <div className="error-status-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  {passwordError.specialCharacter ? (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM13.9846 9.02409L9.25964 13.7491C9.14297 13.8658 8.98464 13.9324 8.81797 13.9324C8.6513 13.9324 8.49297 13.8658 8.3763 13.7491L6.01797 11.3908C5.7763 11.1491 5.7763 10.7491 6.01797 10.5074C6.25964 10.2658 6.65964 10.2658 6.9013 10.5074L8.81797 12.4241L13.1013 8.14076C13.343 7.89909 13.743 7.89909 13.9846 8.14076C14.2263 8.38242 14.2263 8.77409 13.9846 9.02409Z"
                      fill="#419556"
                    />
                  ) : (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM12.8013 12.8574C13.043 13.0991 13.043 13.4991 12.8013 13.7408C12.6763 13.8658 12.518 13.9241 12.3596 13.9241C12.2013 13.9241 12.043 13.8658 11.918 13.7408L10.0013 11.8241L8.08464 13.7408C7.95964 13.8658 7.8013 13.9241 7.64297 13.9241C7.48464 13.9241 7.3263 13.8658 7.2013 13.7408C6.95964 13.4991 6.95964 13.0991 7.2013 12.8574L9.11797 10.9408L7.2013 9.02409C6.95964 8.78242 6.95964 8.38242 7.2013 8.14076C7.44297 7.89909 7.84297 7.89909 8.08464 8.14076L10.0013 10.0574L11.918 8.14076C12.1596 7.89909 12.5596 7.89909 12.8013 8.14076C13.043 8.38242 13.043 8.78242 12.8013 9.02409L10.8846 10.9408L12.8013 12.8574Z"
                      fill="#C32D22"
                    />
                  )}
                </svg>
                <span>one special character</span>
              </div>
              <div className="error-status-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  {passwordError.length ? (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM13.9846 9.02409L9.25964 13.7491C9.14297 13.8658 8.98464 13.9324 8.81797 13.9324C8.6513 13.9324 8.49297 13.8658 8.3763 13.7491L6.01797 11.3908C5.7763 11.1491 5.7763 10.7491 6.01797 10.5074C6.25964 10.2658 6.65964 10.2658 6.9013 10.5074L8.81797 12.4241L13.1013 8.14076C13.343 7.89909 13.743 7.89909 13.9846 8.14076C14.2263 8.38242 14.2263 8.77409 13.9846 9.02409Z"
                      fill="#419556"
                    />
                  ) : (
                    <path
                      d="M10.0013 2.60742C5.40964 2.60742 1.66797 6.34909 1.66797 10.9408C1.66797 15.5324 5.40964 19.2741 10.0013 19.2741C14.593 19.2741 18.3346 15.5324 18.3346 10.9408C18.3346 6.34909 14.593 2.60742 10.0013 2.60742ZM12.8013 12.8574C13.043 13.0991 13.043 13.4991 12.8013 13.7408C12.6763 13.8658 12.518 13.9241 12.3596 13.9241C12.2013 13.9241 12.043 13.8658 11.918 13.7408L10.0013 11.8241L8.08464 13.7408C7.95964 13.8658 7.8013 13.9241 7.64297 13.9241C7.48464 13.9241 7.3263 13.8658 7.2013 13.7408C6.95964 13.4991 6.95964 13.0991 7.2013 12.8574L9.11797 10.9408L7.2013 9.02409C6.95964 8.78242 6.95964 8.38242 7.2013 8.14076C7.44297 7.89909 7.84297 7.89909 8.08464 8.14076L10.0013 10.0574L11.918 8.14076C12.1596 7.89909 12.5596 7.89909 12.8013 8.14076C13.043 8.38242 13.043 8.78242 12.8013 9.02409L10.8846 10.9408L12.8013 12.8574Z"
                      fill="#C32D22"
                    />
                  )}
                </svg>
                <span>8 character minimum</span>
              </div>
            </div>
          </div>
          <div className="d-flex w-100">
            {freezeButton ?
              <button disabled className="changePassword-submit-btn"><div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
              </div></button>
              : <button type="submit" className="changePassword-submit-btn">
                Change password
              </button>
            }
          </div>
        </motion.div>
      </form>
    </div>
  );
}

export default PasswordChange;
