import React, { useEffect, useState } from 'react'
import "./SpinAndWinCampaign.css"
import "../ScratchCardCampaign/ScratchCardCampaign.css"
import "../Bulkshare/Bulkshare.css"
import toast, { Toaster } from 'react-hot-toast'
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar';
import menu from "../../Images/Menu.png"
import Spinner from '../Spinner/Spinner'
import { useNavigate, useParams } from 'react-router-dom'
import api from '../ApiConfigration/ApiConfigration'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'


function SpinAndWinCampaign() {

    const navigate = useNavigate()
    const token = localStorage.getItem("utoken")
    const { refid } = useParams();
    const [campaignData, setCamapaignData] = useState({})
    const [isSpin, setIsSpin] = useState(false)
    const [isSpin2, setIsSpin2] = useState(false)
    const [isredeemable, setRedeemable] = useState(false)
    const [isUserSpin, setIsUserSpin] = useState({})
    const [isSpinStat, setIsSpinStat] = useState(false)
    const [showRedeemtimmer, setRedeemTime] = useState(false)
    const [spinCount, setSpinCount] = useState(0)
    const { width, height } = useWindowSize()
    const [redeemclicked, setRedeemClicked] = useState(false)

    const spintheWin = async () => {
        if (token) {
            if (campaignData?.isEnded) {
                toast.error("Campaign Ended")
                return;
            }
            setSpinCount(spinCount + 1)
            setIsSpin(true)


            await api.get(`/campaign/spin-and-win-user-click-spin?id=${campaignData?.id}`).catch((err) => { console.log(err.message) })
        } else {
            toast.error("Login to claim")
            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));
            navigate(`/customer/login?redirectcampaign=${"spin-and-win-campaign/" + refid}`)

        }
    }


    const handleRedeemData = () => {
        setTimeout(() => {
            setIsSpin2(true);
            setIsSpinStat(true)
            setRedeemTime(true)
        }, 5000);
    }

    // useEffect(() => {
    //     if (spinCount > 0) {
    //         if (campaignData) {
    //             setIsSpin(true)
    //         }
    //     }
    // }, [spinCount])


    useEffect(() => {

        function getCampaignData(data) {
            api.get(`/campaign/get-spin-and-win-${data}?id=${refid}`).then((res) => {
                if (res) {
                    setCamapaignData(res?.data?.message?.message)
                    setIsSpinStat(res?.data?.message?.message?.isSpin)
                    setIsSpin(res?.data?.message?.message?.isSpin)
                    setIsSpin2(res?.data?.message?.message?.isRedeemed ? false : true)
                    setRedeemTime(res?.data?.message?.message?.isSpin === true ? true : false)
                    if (String(res?.data?.message?.message?.item?.couponCode) !== "0") {
                        setRedeemable(true)
                    }

                } else {
                    console.log("Error Fetching Data")
                }
            }).catch((err) => {
                console.log(err)
                toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err.message)
            })

        }

        if (refid) {
            if (token) {
                getCampaignData("campaign2")

            } else {
                getCampaignData("campaign")

            }
        }


    }, [refid, token])


    //FetchClaimStatus

    useEffect(() => {
        if (token && campaignData && !campaignData?.isEnded) {
            api.get(`/campaign/spin-and-win-is-user-spin?spinid=${campaignData?.id}`).then((res) => {
                if (res) {
                    setIsUserSpin(res?.data?.message?.message)
                } else {
                    console.log("Error fetching Data")
                }
            }).catch((err) => {
                console.log(err.message)
            })

        }
    }, [token, campaignData])


    const claimPrize = async () => {
        if (token) {

            console.log(campaignData)
            var coupon = {
                id: campaignData?.id,
                coupon: campaignData?.item?.couponCode,
                vendorId: campaignData?.vendorId,
                discountPercentage: campaignData?.item?.discountPercentage,
                item: campaignData?.item
            }
            setRedeemClicked(true)
            api.post("/campaign/claim-spin-and-win-campaign", coupon).then((res) => {
                if (res) {
                    console.log(res?.data?.message?.message)
                    toast.success(res?.data?.message?.message)
                    setRedeemClicked(false)
                    setIsUserSpin({ isRedeemed: true })
                } else {
                    toast.error("Error Fetching Data")
                    setRedeemClicked(false)

                }
            }).catch((err) => {
                console.log(err?.response?.data?.errors?.body[0])
                toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
                setRedeemClicked(false)

            })

        } else {
            toast.error("Login to claim")
            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));
            navigate(`/customer/login?redirectcampaign=${"spin-and-win-campaign/" + refid}`)

        }
    }


    return (
        <div className='bulksharehome'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='topbulkshare'>


                {/* Navbar for scratchcard */}
                <div className='navbarr'>
                    <div className='d-flex'>
                        <div style={{ cursor: 'pointer' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                        <div className='ms-4 mt-4 scratch-card-campaign-000 capitalizer-heading'>{campaignData?.campaignName}</div>
                    </div>

                </div>


                <div className='scratch-card-campaign-001 mt-3 pt-3 mb-2 pb-2'>
                    <div className='scratch-card-campaign-002 d-flex justify-content-center'>
                        <div className='spin-and-win-campaign-001'>
                            {!isSpinStat ? <Spinner onData={campaignData} spinCount={spinCount} showReedem={handleRedeemData} /> :
                                <div>

                                    <div className='spin-and-win-winner-banner'>
                                        {/* <Confetti
                                        width={width/2}
                                        height={height}
                                        tweenDuration="5000"
                                        recycle={false}
                                        confettiSource={{ x: 50, y: 50, w: 50, h: 50 }}
                                    /> */}
                                        {String(campaignData?.item?.couponCode) !== "0" ?
                                            <div className='p-3'>
                                                <div style={{ width: "100%" }} >

                                                    <Confetti
                                                        style={{
                                                            position: 'absolute',
                                                            left: '50%',
                                                            top: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                        }}
                                                        width={(width/2)*1.4}
                                                        height={height}
                                                        tweenDuration="5000"
                                                        recycle={false}
                                                        confettiSource={{ x: width / 2.8, y: height / 4, w: 10, h: 50 }}
                                                    />

                                                </div>
                                                <div className='spin-banner-001'>Congratulations!</div>
                                                <div className='spin-banner-005'><span className='spin-banner-002'>{campaignData?.item?.discount ? campaignData?.item?.discount : 0}%</span><span className='spin-banner-003'>OFF</span></div>
                                                <div className='spin-banner-004'>Save up to {campaignData?.item?.title} on your next purchase.</div>
                                            </div> :
                                            <div className='banner-text-spin'>
                                                Better Luck Next Time
                                            </div>

                                        }

                                    </div>


                                </div>}
                        </div>
                    </div>
                </div>




                {isUserSpin?.isRedeemed
                    ?
                    <div className='claimbtnicon'>

                        <div className="scratch-card-claim-btn">Redeemed</div>
                    </div>
                    :
                    <div className='claimbtnicon'>
                        {!campaignData?.item ? null : <>{isSpin ? null : <div onClick={spintheWin} className="scratch-card-claim-btn">Spin</div>}</>}
                        {redeemclicked ? null : <>{isredeemable && showRedeemtimmer ? <>{isSpin2 && isSpin ? <div onClick={claimPrize} className="scratch-card-claim-btn">Redeem Now</div> : null}</> : null}</>}

                    </div>}




                {/* Terms and Conditions */}
                <div className='termsandpolicy001 termsandpolicy002'>

                    <h3 className='termsheading'>How this works</h3>
                    <ul className='termsorderlist'>
                        {campaignData?.termsAndCondition?.map((itm, k) => (
                            <>{itm?.value ? <li>{itm?.value}</li> : null}</>
                        ))}
                    </ul>
                </div>


                {/* Terms and Conditions */}
                <div className='termsandpolicy001 pb-3 mb-4'>

                    <h3 className='termsheading'>Terms and conditions</h3>
                    <ul className='termsorderlist'>
                        {campaignData?.termsAndCondition?.map((itm, k) => (
                            <>{itm?.value ? <li>{itm?.value}</li> : null}</>
                        ))}
                    </ul>
                </div>










            </div>

            <div className="offcanvas offcanvas-start cssidebar001" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <CustomerSidebar pageData={campaignData.sidebarData} />
            </div>




        </div>
    )
}

export default SpinAndWinCampaign