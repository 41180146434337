import React, { useEffect, useState } from 'react';
import './SpinAndWinVendorView.css';
import Spinner from '../../Customer/Spinner/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import menu from "../../Images/Back.png"
import api from '../ApiConfigration/ApiConfigration';
import { useNavigate, useParams } from 'react-router-dom';
import RedeemPopup from './Redeempopup';
function SpinAndWinVendorView() {


    const navigate = useNavigate()
    const { refid } = useParams();
    const [campaignData, setCamapaignData] = useState({})
    const [isSpin, setIsSpin] = useState(false)
    const [isSpin2, setIsSpin2] = useState(false)
    const [isredeemable, setRedeemable] = useState(false)
    const [isUserSpin, setIsUserSpin] = useState({})
    const [isSpinStat, setIsSpinStat] = useState(false)
    const [showRedeemtimmer, setRedeemTime] = useState(false)
    const [spinCount, setSpinCount] = useState(0)
    const [showRedeemPopup, setShowRedeemPopup] = useState(false);

    const claimPrize = () => {
        setShowRedeemPopup(true);
        document.body.style.overflow = 'hidden';

    };

    const handleCloseRedeemPopup = () => {
        setShowRedeemPopup(false);
    };

    const handleRedeem = (contactNumber) => {
        setShowRedeemPopup(false);
        setIsUserSpin({ ...isUserSpin, isRedeemed: true })

    };




    const token = localStorage.getItem("vtoken")


    useEffect(() => {

        function getCampaignData() {
            api.get(`/campaigns/spinandwinvendordata?id=${refid}`)
                .then((res) => {
                    if (res) {
                        setCamapaignData(res?.data?.message?.message)
                        setCamapaignData(res?.data?.message?.message)
                        setIsSpinStat(res?.data?.message?.message?.isSpin)
                        setIsSpin(res?.data?.message?.message?.isSpin)
                        setIsSpin2(res?.data?.message?.message?.isRedeemed ? false : true)
                        setRedeemTime(res?.data?.message?.message?.isSpin === true ? true : false)
                        if (res?.data?.message?.message?.item?.couponCode !== "0") {
                            setRedeemable(true)
                        }


                    } else {
                        console.log("Error")
                    }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.errors?.message[0] ? err?.response?.data?.errors?.message[0] : err.message)
                })
        }

        if (refid) {
            getCampaignData()
        }
    }, [refid])


    const spintheWin = async () => {
        if (token) {
            if (campaignData?.isEnded) {
                toast.error("Campaign Ended")
                return;
            }
            setSpinCount(spinCount + 1)
            setIsSpin(true)


            // await api.get(`/campaign/spin-and-win-user-click-spin?id=${campaignData?.id}`).catch((err) => { console.log(err.message) })
        } else {
            toast.error("Login to claim")
            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));
            navigate(`/customer/login?redirectcampaign=${"spin-and-win-campaign/" + refid}`)

        }
    }

    const handleRedeemData = () => {
        setTimeout(() => {
            setIsSpin2(true);
            setIsSpinStat(true)
            setRedeemTime(true)
        }, 5000);
    }



    return (
        <div className='bulk-share-vendor-view-dashnoard'>
            <div className='navbarr'>
                <div className='d-flex'>
                    <div onClick={() => { navigate("/vendor") }} style={{ cursor: 'pointer' }}  ><img src={menu} alt={menu} /></div>
                    <div className=' mt-4 scratch-card-campaign-000 capitalizer-heading campaigntext-color-spinwin'>{campaignData?.campaignName}</div>
                </div>

            </div>

            <div className='bulksharehome'>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />


                <div className='topbulksharevendor'>


                    {/* Navbar for scratchcard */}

                    <div className='scratch-card-campaign-001 mt-3 pt-3 mb-2 pb-2'>
                        <div className='scratch-card-campaign-002 d-flex justify-content-center'>
                            <div className='spin-and-win-campaign-001'>
                                {!isSpinStat ? <Spinner onData={campaignData} spinCount={spinCount} showReedem={handleRedeemData} /> :
                                    <div>

                                        <div className='spin-and-win-winner-banner'>

                                            {campaignData?.item?.couponCode !== "0" ?
                                                <div>
                                                    <div className='spin-banner-001'>Congratulations!</div>
                                                    <div className='spin-banner-005'><span className='spin-banner-002'>{campaignData?.item?.discount ? campaignData?.item?.discount : 0}%</span><span className='spin-banner-003'>OFF</span></div>
                                                    <div className='spin-banner-004'>Save up to {campaignData?.item?.title} on your next purchase.</div>
                                                </div> :
                                                <div className='banner-text-spin'>
                                                    Better Luck Next Time
                                                </div>

                                            }

                                        </div>


                                    </div>}
                            </div>
                        </div>
                    </div>




                    {isUserSpin?.isRedeemed
                        ?
                        <>
                            <div className='claimbtnicon'>
                                <div className="scratch-card-claim-btn">Redeemed</div>
                            </div>
                            <div className='claimbtnicon mt-4'>
                                <div onClick={() => { window.location.reload() }} className="scratch-card-claim-btn">Try Another</div>
                            </div>

                        </>
                        :
                        <div className='claimbtnicon'>
                            {!campaignData?.item ? null : <>{isSpin ? null : <div onClick={spintheWin} className="scratch-card-claim-btn">Spin</div>}</>}
                            {isredeemable && showRedeemtimmer ? <>{isSpin2 && isSpin ? <div onClick={claimPrize} className="scratch-card-claim-btn">Redeem Now</div> : null}</> : null}

                        </div>}




                    {/* Terms and Conditions */}
                    <div className='termsandpolicy001 termsandpolicy002'>

                        <h3 className='termsheading'>How this works</h3>
                        <ul className='termsorderlist'>
                            {campaignData?.termsAndCondition?.map((itm, k) => (
                                <>{itm?.value ? <li>{itm?.value}</li> : null}</>
                            ))}
                        </ul>
                    </div>


                    {/* Terms and Conditions */}
                    <div className='termsandpolicy001 pb-3 mb-4'>

                        <h3 className='termsheading'>Terms and conditions</h3>
                        <ul className='termsorderlist'>
                            {campaignData?.termsAndCondition?.map((itm, k) => (
                                <>{itm?.value ? <li>{itm?.value}</li> : null}</>
                            ))}
                        </ul>
                    </div>

                </div>

            </div>

            {showRedeemPopup && (
                <>
                    <div onClick={handleCloseRedeemPopup} className="backdrop-spin-and-win"></div>
                    <div className="popup-spin-and-win">
                        <RedeemPopup onClose={handleCloseRedeemPopup} onRedeem={handleRedeem} campaignData={campaignData} />
                    </div>
                </>

            )}

        </div>
    )
}

export default SpinAndWinVendorView