import React, { useEffect, useState } from "react";

function DynamicInputField({ initialData, onInputDataChange, isDisabled }) {

    const [inputFields, setInputFields] = useState([{ value: "" }]);


    useEffect(() => {
        if (initialData && initialData.length > 0) {
            setInputFields(initialData);
        }
    }, [initialData]);

    useEffect(() => {
        onInputDataChange(inputFields)
    }, [inputFields])

    function inputHandler() {
        setInputFields([...inputFields, { value: '' }])
    }

    function onInputChange(e, index) {
        const newFields = [...inputFields]
        newFields[index].value = e.target.value
        setInputFields(newFields)
    }

    function onRemoveField(index) {
        const newFields = [...inputFields]
        newFields.splice(index, 1)
        setInputFields(newFields)
    }


    

    return (
        <>
            <div className="d-flex flex-column">
                {inputFields.map((item, index) => {
                    return (
                        <div className="d-flex terms-item">
                            <svg
                                width="20"
                                height="28"
                                viewBox="0 0 20 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="6" cy="6" r="2" fill="#BBC3BC" />
                                <circle cx="14" cy="6" r="2" fill="#BBC3BC" />
                                <circle cx="6" cy="14" r="2" fill="#BBC3BC" />
                                <circle cx="14" cy="14" r="2" fill="#BBC3BC" />
                                <circle cx="6" cy="22" r="2" fill="#BBC3BC" />
                                <circle cx="14" cy="22" r="2" fill="#BBC3BC" />
                            </svg>
                            <input value={item.value} disabled={isDisabled} onChange={(e) => onInputChange(e, index)} type="text" className="scratch-card-form-input form-control" />
                            {isDisabled ? null : <svg onClick={() => onRemoveField(index)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                                    fill="#C32D22"
                                />
                            </svg>}
                        </div>
                    );
                })}
            </div>
            {isDisabled ? null : <button onClick={() => inputHandler()} className="terms-button" type="button">
                Add
            </button>}
        </>
    );
}

export default DynamicInputField;
