import React, { useEffect, useState } from 'react';
import "./ScratchCardCampaign.css"
import "../Bulkshare/Bulkshare.css"
import toast, { Toaster } from 'react-hot-toast';
import profile from "../../Images/Profile.png"
import menu from "../../Images/Menu.png"
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar';
import ScratchCard2 from '../ScratchCard/ScratchCard';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import scrach from "../../Images/Frame 4468.png";


function ScratchCardCampaign() {

    const navigate = useNavigate();
    var token = localStorage.getItem("utoken")
    const [campaignData, setCamapaignData] = useState()
    const { refid } = useParams();
    const [isReedemshow, setIsRedeemShow] = useState(false)
    const [isShowScratchCard, setIsShowScratchCard] = useState(false)
    const [isUserRedeemed, setIsUserRedeemed] = useState(false)
    const [redeemClicked, setRedeemClicked] = useState(false)
    const [customError, setCustomError] = useState("Please wait fetching data...")
    const inputDataChange = (data) => {
        setIsRedeemShow(data)
    }

    useEffect(() => {

        async function getCampaignData() {

            await api.get(`/campaign/scratch-card-campaign?id=${refid}`).then((res) => {
                if (res) {
                    setCamapaignData(res?.data?.message?.message)
                    setIsRedeemShow(res?.data?.message?.message?.isScratched)
                    setIsUserRedeemed(res?.data?.message?.message?.isRedeemed)
                    console.log(res?.data?.message?.message)
                } else {
                    console.log("Erroe Fetching Data")
                }

            }).catch((err) => {
                setCustomError(err?.response?.data?.errors?.body[0])
                toast.error(err?.response?.data?.errors?.body[0])
            })

        }

        getCampaignData()

    }, [refid])


    const claimCoupon = async () => {
        setRedeemClicked(true)
        await api.get(`/campaign/user-redeemed-scratch-card?id=${campaignData?.camapaign?.id}`).then((res) => {
            try {
                if (res) {
                    setRedeemClicked(false)
                    console.log(campaignData)
                    toast.success(res?.data?.message?.message)
                    setCamapaignData(prev => ({ ...prev, isRedeemed: true }));

                    if (isShowScratchCard === true) {
                        setIsShowScratchCard(false)
                        setCamapaignData(prev => ({ ...prev, isScratched: true, isRedeemed: true }))

                    }

                } else {
                    toast.error("Error claiming Coupon")
                    setRedeemClicked(false)

                }
            } catch (err) {
                toast.error(err.message)
                setRedeemClicked(false)

            }

        }).catch((err) => {
            toast.error(err?.message)
            setRedeemClicked(false)

        })
    }


    useEffect(() => {
        if (isShowScratchCard) {
            document.body.style.overflow = 'hidden'; // Freeze scrolling
        } else {
            document.body.style.overflow = ''; // Restore scrolling
        }
    }, [isShowScratchCard]);



    const permissionToScratch = async () => {
        if (!token) {
            toast.error("Login to scratch a card")
            const delayTime = 1000;
            await new Promise((resolve) => setTimeout(resolve, delayTime));
            navigate(`/customer/login?redirectcampaign=scratch-card-campaign/${refid}`)
            return;
        } else if (!campaignData) {
            toast.error(customError);
            return
        } else if (campaignData?.isCampaignEnd) {
            toast.error("Campaign Ended!");
        }
        else {
            setIsShowScratchCard(true)
        }

    }


    function closePopup() {
        const popup = document.getElementById('absoluteScratchCard');
        if (popup) {
            console.log(popup)
            popup.style.animation = 'popupAnimationReverse 0.5s ease-in-out forwards';
            setTimeout(() => {
                setIsShowScratchCard(false);
                if (isReedemshow === true) {
                    setCamapaignData(prev => ({ ...prev, isScratched: true, isRedeemed: false }))
                }
            }, 500);
        }
    }




    return (
        <div className='bulksharehome'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />



            {isShowScratchCard &&
                (

                    <div id='absoluteScratchCard' >

                        <div className='p-4 pt-4'>
                            <div className='d-flex justify-content-end'>
                                <div onClick={() => {
                                    closePopup()

                                }} className='scratchcardtext' style={{ fontSize: "21px", cursor: "pointer" }}>
                                    <i class="fa-solid fa-xmark"></i>
                                </div>
                            </div>

                            <div className={isReedemshow ? "nullscartachcard" : 'mt-4'}></div>
                            <div className='scratch-card-campaign-001'>
                                <div className='scratch-card-campaign-002 d-flex justify-content-center'>
                                    <ScratchCard2 campaignData={campaignData} inputDataChange={inputDataChange} />
                                </div>
                                {isReedemshow && campaignData?.isWon ? <div style={{ marginTop: "-10px" }} className='d-flex justify-content-center'>
                                    <div onClick={claimCoupon} className="scratch-card-claim-btn">Redeem Now</div>

                                </div> : null}

                            </div>

                        </div>


                    </div>

                )}

            <div className='topbulkshare'>


                {/* Navbar for scratchcard */}
                <div className='navbarr'>
                    <div className='d-flex'>
                        <div style={{ cursor: 'pointer' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                        <div className='ms-1 mt-4 scratch-card-campaign-000'>{campaignData?.camapaign?.campaignName ? campaignData?.camapaign?.campaignName : null}</div>
                    </div>

                </div>


                {campaignData ? <div className='scratch-card-campaign-001'>
                    <div className='scratch-card-campaign-002 d-flex justify-content-center '>
                        {campaignData?.isScratched ?
                            <ScratchCard2 campaignData={campaignData} inputDataChange={inputDataChange} />

                            : <div>
                                <img style={{ cursor: "pointer" }} className='mt-4' src={scrach} alt="" onClick={() => permissionToScratch()} />
                                <p style={{ fontSize: "16px" }} className='text-center mt-2 scratchcardtext'>Click on the Card to Scratch &#x1F446;</p>
                            </div>}

                    </div>
                </div> : <div className='scratch-card-campaign-001'>
                    <div className='scratch-card-campaign-002 d-flex justify-content-center '>
                        <div>
                            <img style={{ cursor: "pointer" }} className='mt-4' src={scrach} alt="" onClick={() => permissionToScratch()} />
                            <p style={{ fontSize: "16px" }} className='text-center mt-2 scratchcardtext'>Click on the Card to Scratch &#x1F446;</p>
                        </div>
                    </div>
                </div>}




                {redeemClicked ? null : <>{!campaignData ? null : <div className='claimbtnicon'>
                    {campaignData?.isRedeemed === false && (campaignData?.isWon && campaignData?.isScratched === true) ? <div onClick={claimCoupon} className="scratch-card-claim-btn">Redeem Now</div> : null}
                </div>}</>}
                {campaignData?.isRedeemed ? <div className='claimbtnicon'>
                    <div className="scratch-card-claim-btn">Redeemed</div>
                </div> : null}



                {/* Terms and Conditions */}
                {campaignData?.isScratched ? <div className='termsandpolicy001 termsandpolicy002'>

                    <h3 className='termsheading'>How this works</h3>
                    <ul className='termsorderlist'>
                        {campaignData?.camapaign?.termsAndCondition?.map((itm, k) => (
                            <li>{itm?.value}</li>
                        ))}
                    </ul>
                </div> : null}


                {/* Terms and Conditions */}
                {campaignData?.isScratched ? <div className='termsandpolicy001 pb-3 mb-4'>

                    <h3 className='termsheading'>Terms and conditions</h3>
                    <ul className='termsorderlist'>
                        {campaignData?.camapaign?.termsAndCondition?.map((itm, k) => (
                            <li>{itm?.value}</li>
                        ))}
                    </ul>
                </div> : null}










            </div>

            <div className="offcanvas offcanvas-start cssidebar001" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <CustomerSidebar pageData={campaignData?.sidebardata} />
            </div>




        </div>
    )
}

export default ScratchCardCampaign