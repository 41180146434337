import React, { useEffect, useState } from "react";
import "./Spinner.css";
import { motion, useAnimation } from "framer-motion";

export default function Spinner({ onData, spinCount, showReedem }) {


  const [campaignData, setCampaignData] = useState({})
  const controls = useAnimation();
  const [rotateStart, setRotateStart] = useState(false);
  const gap = 360 / 8;
  const colors = [
    { background: "#62C4A4", color: "#1D1F1E" },
    { background: "black", color: "white" },
    // { background: "red", color: "white" },
    // { background: "green", color: "white" }
  ];

  useEffect(() => {
    setCampaignData(onData)
  }, [onData])


  useEffect(() => {
    if (spinCount > 0) {
      startRotation(Number(campaignData?.item?.indexNumber))
    }
  }, [spinCount, campaignData]);


  const rotatetheSpinner = () => {
    console.log("This is a Spinner")
  }


  const stopRotation = (itmstp) => {
    // Stop the animation at a specific angle, for example, 180 degrees

    controls.start({
      rotate: [null, gap * itmstp * -1],
      transition: {
        type: 'spring',
        stiffness: 10,
        duration: 3,
        ease: "linear",
      },
    });
    setTimeout(() => {
      setRotateStart(false);
    }, 2000);

    showReedem(true);
  };
  const startRotation = (datastp) => {
    if (rotateStart == false) {
      setRotateStart(true);
      controls.start({
        rotate: [null, 360],
        transition: {
          type: 'spring',
          stiffness: 6,
          duration: 3,
          repeat: Infinity,
          ease: "linear",
        },
      });
      setTimeout(() => {
        stopRotation(datastp);
      }, 3000);
    }
  };

  return (
    // <div className="background">
    <div className="spinner-bg">
      <div className="spinner-arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="56"
          height="53"
          viewBox="0 0 56 53"
          fill="none"
        >
          <g filter="url(#filter0_d_210_3733)">
            <path
              d="M17.8652 17.8401L38.6498 17.8401L28.2575 35.8401L17.8652 17.8401Z"
              fill="#E84E27"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_210_3733"
              x="0.865234"
              y="0.840088"
              width="54.7852"
              height="52"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset />
              <feGaussianBlur stdDeviation="8.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.909804 0 0 0 0 0.305882 0 0 0 0 0.152941 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_210_3733"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_210_3733"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
      <motion.div animate={controls} className="spinner-circle">
        <div className="circle-blur"></div>
        <div onClick={() => { rotatetheSpinner() }} className="refresh-circle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21.9079 12.4112C21.9079 17.7414 17.582 22.0673 12.2518 22.0673C6.92163 22.0673 3.66753 16.6985 3.66753 16.6985M3.66753 16.6985H8.03208M3.66753 16.6985V21.5266M2.5957 12.4112C2.5957 7.08105 6.88301 2.75513 12.2518 2.75513C18.6924 2.75513 21.9079 8.12391 21.9079 8.12391M21.9079 8.12391V3.29587M21.9079 8.12391H17.6206"
              stroke="#1D1F1E"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        {(campaignData?.spinFactors ? campaignData?.spinFactors : [
          "5% off",
          "50% off",
          "Next time",
          "10% off",
          "No luck",
          "Free shiping",
          "Spin again",
          "So close",
        ])?.map((item, i) => (
          <div
            style={{
              transform: `rotate(${gap * i}deg)`,
              width: gap + "%",
              color: colors[i % colors.length].color,
              backgroundColor: colors[i % colors.length].background,
            }}
            className={`weel spinner-weels`}
          >
            <div style={{ transform: "rotate(-90deg)" }}>{campaignData?.spinFactors ? item.title : item}</div>
          </div>
        ))}
      </motion.div>
    </div>
    // </div>
  );
}
