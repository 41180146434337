import React, { useEffect, useState } from 'react'
import "./StoreWhatsappOrder.css"
import "../ClaimedOffers/ClaimedOffers.css"
import logo from "../../Images/logo1.png"
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import api from '../ApiConfigration/ApiConfigration';
import QRCode from 'react-qr-code';

function StoreWhatsappOrder() {

    const navigate = useNavigate();
    const [setupready, setSetUpready] = useState(false)
    const [whatsappStat, setWhatsappStat] = useState();
    const [phoneNumber, setPhoneNumber] = useState()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const storeId = queryParams.get('id');
    const [qrcodeValue, setQrcode] = useState()

    useEffect(() => {
        api.get(`/store/getstoredetailsforwhatsapp?id=${storeId}`).then((res) => {
            if (res) {
                // console.log(res?.data?.message?.message)
                setPhoneNumber(res?.data?.message?.message?.whatsappNumber)
                const message = `OrderId:${storeId}`
                var code = `https://wa.me/91${res?.data?.message?.message?.whatsappNumber}?text=${encodeURIComponent(message)}`
                setSetUpready(true);
                setQrcode(code)
            }
        }).catch((err) => {
            console.log(err?.response?.data?.errors?.body[0])
            toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
            setSetUpready(true)
        })
    })


    const SendMessage = () => {
        if (!setupready) {
            toast.error("Please till we fetch details")
            return;
        }
        if (!whatsappStat) {
            toast.error("Select any methods")
            return;
        }
        if (!phoneNumber && typeof (Number(phoneNumber)) !== Number) {
            toast.error("Error with Store Whatsapp Number")
            return;
        }
        if (storeId) {
            if (whatsappStat === "web") {
                const message = `OrderId:${storeId}`
                const whatsappWebApi = `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`
                window.location.replace(whatsappWebApi)
            } else if (whatsappStat === "desktop") {
                toast.error("Currently Unavailable try with Web")
                return;
            } else {
                toast.error("Select any methods")
                return;
            }

        } else {
            toast.error("OrderId is invalid")
        }


    }



    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='claimofferhome'>
                <div className='claimoffershare'>

                    <p className='wtsapp002'>To proceed on your phone, scan the QR code underneath</p>

                    <div className='wtsapp003 d-flex justify-content-center'>

                        {qrcodeValue ? <div className='qrcodewhatsapp'>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "200px", width: "200px" }}
                                value={qrcodeValue}
                                viewBox={`0 0 256 256`}
                            />
                        </div> : null}

                    </div>

                    <div className='mt-4 pt-2'>
                        <p className='wtsapp004'>OR</p>
                        <p className='wtsapp004 mt-4 pt-2'>Continue on desktop</p>
                    </div>


                    <div className='wtsapp006'>

                        <div onClick={() => setWhatsappStat("desktop")} className={`wtsapp005 ${whatsappStat === "desktop" ? "highlightwatsapp" : ""}`}>WhatsApp desktop app</div>

                        <div onClick={() => setWhatsappStat("web")} className={`wtsapp005 ${whatsappStat === "web" ? "highlightwatsapp" : ""}`}>WhatsApp desktop web</div>

                        {setupready ?
                            <div onClick={SendMessage} className='wtsapp007'>Continue</div>
                            : null}
                    </div>


                    <div className='wtsapp008'>
                        <img src={logo} alt="" />
                    </div>


                </div>
            </div>
        </div>
    )
}

export default StoreWhatsappOrder