import React, { useEffect, useState } from 'react'
import menu from "../../Images/Menu.png";
import profile from "../../Images/Profile.png"
import api from '../ApiConfigration/ApiConfigration';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProfileDropDown from '../Bulkshare/ProfileDropDown';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { setHomeCoupons, setHomeCouponDetails } from '../../../Redux/customerReducer';

function CustomerTempHome() {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const vendorid = queryParams.get('id');
    var imgUrl = process.env.REACT_APP_API_AWS_BASE_URL



    // const [data, setUserdata] = useState([])
    // const [store, setStoreData] = useState({})
    const [sidebarData, setsideBarData] = useState({})
    // const [page, setPage] = useState(0);
    const limit = 15;
    // const [hasmore, setHasMore] = useState(true)
    const [istopPage, setIsTopPage] = useState(false)
    var page = useSelector(state => state?.customerData?.homeCouponDetails?.page);
    var hasmore = useSelector(state => state?.customerData?.homeCouponDetails?.hasmore);
    var data = useSelector(state => state?.customerData?.homeCoupons);


    async function getData() {


        await api.get(`/home/getalluserclaimedcoupon?offset=${page}&limit=${limit}`).then((res) => {
            if (res) {
                if (res?.data?.message?.message?.length < limit) {
                    // setHasMore(false);
                    dispatch(setHomeCouponDetails({ hasmore: false }))
                } else {
                    // setPage(page + limit);
                    // setHasMore(true);
                    dispatch(setHomeCouponDetails({ hasmore: true, page: page + limit }))

                }

                const newData = res?.data?.message?.message;

                if (newData) {
                    // Check for duplicates
                    const existingIds = data?.map(item => item?.id);
                    const firstItemId = newData[0]?.id;
                    const lastItemId = newData[newData?.length - 1]?.id;

                    if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
                        // If no duplicates, add new items to existing data
                        dispatch(setHomeCoupons([...data, ...newData]));
                    }
                }

                // setUserdata((prevData) => [...prevData, ...res?.data?.message?.message])
                // setUserdata((prevData) => {
                //     const newData = res?.data?.message?.message;
                //     if (!newData) return prevData; // Return previous data if no new data

                //     const existingIds = prevData.map(item => item.id);
                //     const firstItemId = newData[0]?.id;
                //     const lastItemId = newData[newData.length - 1]?.id;

                //     if (existingIds.includes(firstItemId) || existingIds.includes(lastItemId)) {
                //         // If any of the new items already exist in the existing data, don't add them
                //         return prevData;
                //     } else {
                //         // Add new items to existing data
                //         return [...prevData, ...newData];
                //     }
                // });

            }
        }).catch((err) => { console.log(err.message) })
    }

    useEffect(() => {
        setIsTopPage(true)
        // setUserdata([])
        if (istopPage === true) {
            // setUserdata([])
            if (hasmore === true) {
                getData();
            }

        }
    }, [istopPage])

    const redirecttoofferpage = (id) => {
        navigate(`/customer/offername?id=${id}`)
    }



    return (
        <div className='claimofferhome'>

            <div className='claimoffershare'>

                <div className='navbarrr'>
                    <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                    <div className='navheadbarr'>Claimed Offers</div>
                    <div className='drop222'>
                        <div className='dropdown001 dropstart'><img style={{ cursor: 'pointer' }} src={profile} alt="" id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                            <ProfileDropDown />

                        </div>
                    </div>
                </div>


                <InfiniteScroll
                    dataLength={data?.length}
                    next={istopPage ? getData : null}
                    hasMore={hasmore}
                // loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
                >
                    <div className='truediv mt-5 container px-2'>
                        <div className='row  truediv'>

                            {data?.map((itm, k) => (
                                <div onClick={() => { redirecttoofferpage(itm.id) }} style={{ cursor: "pointer" }} className='col-3 col-md-2 col-sm-4 controexol mx-1 ms-1 mb-2 mt-2'>
                                    {itm?.redeemStatus === true ? <div className='redeemedtextcrossover '>
                                        Claimed
                                    </div> : null}
                                    <div className='d-flex justify-content-center '>

                                        <div className='imgdivoffer mt-4 mb-4 imagecontainercoupon'>
                                            <img className='imgdivoffer' src={imgUrl + itm.storeImage} alt="" />
                                            {itm?.redeemStatus === true ? <div className='claimedoverlay'>

                                            </div> : null}

                                        </div>
                                    </div>

                                    <div className=' pb-3'>
                                        <h3 className={`claimheading text-center ${itm?.redeemStatus === true ? "textcolorclaimed" : null}`}>Flat {itm.discountPercentage}% off</h3>
                                        <p className={`claimdescript text-center ${itm?.redeemStatus === true ? "textcolorclaimed" : null}`}>from {itm.storeName}</p>
                                    </div>

                                </div>
                            ))}


                        </div>

                    </div>
                </InfiniteScroll>
            </div>

            <div className="offcanvas offcanvas-start cssidebar001" data-bs-scroll="false" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <CustomerSidebar pageData={sidebarData} />
            </div>

        </div>
    )
}

export default CustomerTempHome