import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import loader from "../../Images/loader.gif"
import toast, { Toaster } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import "./CouponsCreate.css"
import { useDispatch, useSelector } from 'react-redux';
import { SetRemoveCouponCodes, setCouponList, setcouponListDetails } from '../../../Redux/vendorReducer';

function CouponsCreate() {

    const dispatch = useDispatch();
    const [editDisabled, setEditDisabled] = useState(false)
    const navigate = useNavigate()
    // const [page, setPage] = useState(0);
    const limit = 10;
    // const [hasmore, setHasMore] = useState(true)
    const [deleteCouponCode, setDeleteCouponCode] = useState()
    const [show, setShow] = useState(false);
    const [istopPage, setIsTopPage] = useState(false)
    // const [couponCodes, setCouponCodes] = useState([]);
    const [createShow, setCreateShow] = useState(false);
    const [hidesave, setHideSave] = useState(false);
    const [createdCoupon, setCreatedCoupon] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [formData, setFormData] = useState({
        couponCodeName: '',
        couponCodes: '',
        expiryDate: '',
        discount: ''
    });
    var page = useSelector(state => state?.vendorData?.couponListDetails?.page);
    var hasmore = useSelector(state => state?.vendorData?.couponListDetails?.hasmore);
    var couponCodes = useSelector(state => state?.vendorData?.couponList);


    const [formDataErrors, setFormDataErrors] = useState({
        couponCodeName: '',
        couponCodes: '',
        expiryDate: '',
        discount: ''
    });


    const handleChangeCoupons = (e) => {
        const { name, value } = e.target;

        const processedValue = name === "couponCodes" ? value.toUpperCase().trim() : value;

        setFormData(prevState => ({
            ...prevState,
            [name]: processedValue
        }));
        setFormDataErrors(prevState => ({
            ...prevState,
            [name]: ""
        }));
    };



    useEffect(() => {
        setIsTopPage(true)
        if (istopPage === true) {
            // setCouponCodes([])
            if (hasmore) {
                getCouponCodeList()
            }
        }
    }, [istopPage, createdCoupon])



    const getCouponCodeList = async () => {

        await api.get(`/common/getcouponcode?offset=${page}&limit=${limit}`).then((res) => {
            if (res) {
                if (res?.data?.message?.message?.length < limit) {
                    // setHasMore(false);
                    dispatch(setcouponListDetails({ hasmore: false }))
                } else {

                    // if (page === 0) {
                    // setCouponCodes([])

                    // }
                    // setPage(page + limit);
                    // setHasMore(true);
                    dispatch(setcouponListDetails({ hasmore: true, page: page + limit }))
                }



                const newData = res?.data?.message?.message;

                if (newData) {
                    // Check for duplicates
                    const existingIds = couponCodes?.map(item => item?.id);
                    const firstItemId = newData[0]?.id;
                    const lastItemId = newData[newData?.length - 1]?.id;

                    if (!(existingIds.includes(firstItemId) || existingIds.includes(lastItemId))) {
                        // If no duplicates, add new items to existing data
                        dispatch(setCouponList([...couponCodes, ...newData]));
                    }
                }


                // setCouponCodes((prevData) => [...prevData, ...res?.data?.message?.message])
                // setCouponCodes((prevData) => {
                //     const newData = res?.data?.message?.message;
                //     if (!newData) return prevData;

                //     const existingIds = prevData.map(item => item.id);
                //     const firstItemId = newData[0]?.id;
                //     const lastItemId = newData[newData.length - 1]?.id;

                //     if (existingIds.includes(firstItemId) || existingIds.includes(lastItemId)) {
                //         return prevData;
                //     } else {
                //         return [...prevData, ...newData];
                //     }
                // });


            }
        }).catch((err) => {
            console.log(err.message)
        })



    }



    const deleteCampaign = async (id) => {
        await api.get(`/common/deleteCouponCodebyid?id=${id}`).then((res) => {
            if (res && res?.data?.message?.message) {
                toast.success(res?.data?.message?.message)
                // setCouponCodes(prevList => prevList.filter(campaign => campaign.id !== id));
                if (couponCodes) {
                    var newData = couponCodes?.filter(campaign => campaign.id !== id)
                    dispatch(setCouponList(newData))
                }

            } else {
                toast.error("Error deleteing campaign")
            }
        }).catch((err) => {
            toast.error(err.message)
        })
    }


    const handleClose = () => {
        setShow(false)
        setDeleteCouponCode()

    };

    const handleClose2 = async () => {
        await deleteCampaign(deleteCouponCode?.id)
        setShow(false)
        setDeleteCouponCode()
    };

    const handleShow = (i) => {
        if (i.couponCodeType === "common") {
            setDeleteCouponCode(i)
            setShow(true)
        } else {
            toast.error("Campaign Auto-Generated Coupons Cannot be deleted")
        }

    };

    const updateCouponCode = (itm) => {
        setIsUpdate(true)
        setDeleteCouponCode(itm.id)
        setFormData(itm)
        setCreateShow(true)


    }



    const sharedetails = async (id) => {

        var urltocopy = id
        try {
            const textarea = document.createElement('textarea');
            textarea.value = urltocopy;
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            document.execCommand('copy');
            document.body.removeChild(textarea);
            toast.success("Coupon Code copied to clipboard!")

        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    // Create Coupons Popup

    const redirecttocreate = () => {
        setCreateShow(true)
    }

    const createHandleclose = () => {
        setCreateShow(false)
        setDeleteCouponCode()
        setFormData({})
        setFormDataErrors({})
        setIsUpdate(false)


    }



    const createCouponCodehandle = async () => {
        if (!formData.couponCodeName) {
            setFormDataErrors(prevState => ({
                ...prevState,
                couponCodeName: "Coupon Code Name is required"
            }));
            return;
        }

        if (!formData.couponCodes) {
            setFormDataErrors(prevState => ({
                ...prevState,
                couponCodes: "Coupon Code is required"
            }));
            return;
        }
        if (!formData.expiryDate) {
            setFormDataErrors(prevState => ({
                ...prevState,
                expiryDate: "Expiry Date is required"
            }));
            return;
        }

        if (formData?.couponCodeType && formData?.couponCodeType !== "common") {
            // toast.success("nehlected")
        } else {
            if (!formData.discount) {
                setFormDataErrors(prevState => ({
                    ...prevState,
                    discount: "Discount is required"
                }));
                return;
            }


            if (formData.discount < 0 || formData.discount > 100) {
                setFormDataErrors(prevState => ({
                    ...prevState,
                    discount: "Discount should be between 0 and 100"
                }));
                return;
            }
        }
        // toast.error("success")
        setHideSave(true)
        const currentDate = new Date();
        const selectedDate = new Date(formData.expiryDate);

        if (selectedDate <= currentDate) {
            setFormDataErrors(prevState => ({
                ...prevState,
                expiryDate: "Validity period should be greater than the current date."
            }));
            return;

        }
        setEditDisabled(true)

        if (isUpdate) {

            await api.post(`/common/updateCouponCodebyid?id=${deleteCouponCode}`, formData).then((res) => {
                if (res) {
                    toast.success(res?.data?.message?.message)
                    setCreateShow(false)
                    dispatch(SetRemoveCouponCodes())
                    setCreatedCoupon(!createdCoupon)
                    setDeleteCouponCode()
                    setFormData({})
                    setFormDataErrors({})
                    setEditDisabled(false)


                } else {
                    toast.error("Error Saving Code")
                    setHideSave(false)
                    setEditDisabled(false)


                }
            }).catch((err) => {
                toast.error(err?.response?.data?.errors?.message[0])
                setHideSave(false)
                setEditDisabled(false)


            })
            return;

        } else {

            await api.post("/common/createCouponCode", formData).then((res) => {
                if (res) {
                    toast.success(res?.data?.message?.message)
                    setCreateShow(false)
                    setCreatedCoupon(!createdCoupon)
                    setFormData({})
                    setFormDataErrors({})
                    setEditDisabled(false)
                    dispatch(SetRemoveCouponCodes())

                } else {
                    toast.error("Error Saving Code")
                    setHideSave(false)
                    setEditDisabled(false)


                }
            }).catch((err) => {
                toast.error(err?.response?.data?.errors?.message[0])
                setHideSave(false)
                setEditDisabled(false)


            })

        }



    };


    function formatDate(inputDateString) {
        const inputDate = new Date(inputDateString);

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = inputDate.getUTCDate();
        const month = months[inputDate.getUTCMonth()];
        const year = inputDate.getUTCFullYear();

        return `${day} ${month} ${year}`;
    }


    return (
        <div className='bulksharelist001'>

            <div className='d-flex justify-content-between bulksharelist012'>
                <div className='bulksharelist011'>Coupon Code</div>
                <div></div>
            </div>


            <div className='scratchcardlist002  mt-4'>
                <div className='row scratchcardlist003'>
                    <div className='col-12 col-md-6'>
                        <div onClick={redirecttocreate} className=' scratchcardlist001'>

                            <div className='scratchcardlist004 d-flex m-1'>

                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32" fill="none">
                                        <path d="M21.586 2.66675H10.4127C5.55935 2.66675 2.66602 5.56008 2.66602 10.4134V21.5734C2.66602 26.4401 5.55935 29.3334 10.4127 29.3334H21.5727C26.426 29.3334 29.3193 26.4401 29.3193 21.5868V10.4134C29.3327 5.56008 26.4394 2.66675 21.586 2.66675ZM23.9993 17.0001H16.9993V24.0001C16.9993 24.5467 16.546 25.0001 15.9993 25.0001C15.4527 25.0001 14.9993 24.5467 14.9993 24.0001V17.0001H7.99935C7.45268 17.0001 6.99935 16.5467 6.99935 16.0001C6.99935 15.4534 7.45268 15.0001 7.99935 15.0001H14.9993V8.00008C14.9993 7.45341 15.4527 7.00008 15.9993 7.00008C16.546 7.00008 16.9993 7.45341 16.9993 8.00008V15.0001H23.9993C24.546 15.0001 24.9993 15.4534 24.9993 16.0001C24.9993 16.5467 24.546 17.0001 23.9993 17.0001Z" fill="#26735A" />
                                    </svg>
                                </div>
                                <div className='ps-3'>
                                    <p className='scratchcardlist005'>Create</p>
                                    <p className='scratchcardlist006'>New Coupon Code</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={couponCodes?.length}
                    next={istopPage ? getCouponCodeList : null}
                    hasMore={hasmore}
                    loader={<div className="text-center mt-2"><img style={{ width: "80px" }} src={loader} alt="" /></div>}
                >
                    <div className='scratchcardlist003'>
                        <div className='row '>
                            {couponCodes?.map((itm, k) => (
                                <div className='col-md-6 col-12 mt-3 '>
                                    <div className=' scratchcardlist012 '>

                                        <div className='scratchcardlist004 d-flex'>


                                            <div className='ps-1 scratchcardlist009'>
                                                <div className='d-flex scratchcardlist008'>
                                                    <p className='scratchcardlist005 pt-1 scratchcardlist010'>{itm?.couponCodeName ? itm?.couponCodeName : null}</p>
                                                    <div className='d-flex'>

                                                        <div onClick={() => { updateCouponCode(itm) }} className='mx-2 ms-0 scratchcardlist010'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                            <path d="M11.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22H15.5C20.5 22 22.5 20 22.5 15V13" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M16.5399 3.02001L8.65988 10.9C8.35988 11.2 8.05988 11.79 7.99988 12.22L7.56988 15.23C7.40988 16.32 8.17988 17.08 9.26988 16.93L12.2799 16.5C12.6999 16.44 13.2899 16.14 13.5999 15.84L21.4799 7.96001C22.8399 6.60001 23.4799 5.02001 21.4799 3.02001C19.4799 1.02001 17.8999 1.66001 16.5399 3.02001Z" stroke="#1C1C1C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M15.4102 4.1499C16.0802 6.5399 17.9502 8.4099 20.3502 9.0899" stroke="#1C1C1C" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg></div>

                                                        {<div onClick={() => { handleShow(itm) }} className='scratchcardlist010'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                            <path d="M21.5 5.97998C18.17 5.64998 14.82 5.47998 11.48 5.47998C9.5 5.47998 7.52 5.57998 5.54 5.77998L3.5 5.97998" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M9 4.97L9.22 3.66C9.38 2.71 9.5 2 11.19 2H13.81C15.5 2 15.63 2.75 15.78 3.67L16 4.97" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M19.3504 9.13989L18.7004 19.2099C18.5904 20.7799 18.5004 21.9999 15.7104 21.9999H9.29039C6.50039 21.9999 6.41039 20.7799 6.30039 19.2099L5.65039 9.13989" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M10.8301 16.5H14.1601" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M10 12.5H15" stroke="#1C1C1C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg></div>}
                                                    </div>

                                                </div >
                                                <p className='scratchcardlist006 scratchcardlist010'>Valid till {itm?.expiryDate ? formatDate(itm?.expiryDate) : null}</p>

                                                <div className='d-flex mt-1'>
                                                    <div className='coupon_code_style'>
                                                        {itm?.couponCodes}
                                                    </div>

                                                    {itm?.couponCodeType === "common" ? <div className='  ms-4'>
                                                        <p onClick={() => { sharedetails(itm?.couponCodes) }} className='scratchcardlist011 scratchcardlist010'>Copy Code</p>
                                                    </div> : null}
                                                </div>

                                            </div>


                                        </div>




                                    </div>


                                </div>
                            ))}

                        </div>
                    </div>


                </InfiniteScroll>

                <Modal show={show} onHide={handleClose} backdrop="static" centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Coupon Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure want to delete <b>{deleteCouponCode?.couponCodes}</b> Code</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="danger" onClick={handleClose2}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>



                {/* Create Update */}

                <Modal dialogClassName="custom-modal-style-234" show={createShow} onHide={createHandleclose} backdrop="static" centered
                >
                    <div style={{ padding: "30px" }}>

                        <div className='d-flex justify-content-between'>
                            <div className='couponcodetitle'>{isUpdate ? "Update Coupon Code" : "Create New Coupon Code"}</div>

                            <div onClick={() => createHandleclose()} style={{ cursor: "pointer" }} className='pt-1'>
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.3921 12.0208L12.0784 23.3345C11.6918 23.7211 11.0507 23.7211 10.6641 23.3345C10.2776 22.948 10.2776 22.3069 10.6641 21.9203L21.9778 10.6066C22.3644 10.2201 23.0055 10.2201 23.3921 10.6066C23.7786 10.9932 23.7786 11.6343 23.3921 12.0208Z" fill="#0B150F" />
                                    <path d="M23.3921 23.3343C23.0055 23.7208 22.3644 23.7208 21.9778 23.3343L10.6641 12.0206C10.2776 11.634 10.2776 10.9929 10.6641 10.6064C11.0507 10.2198 11.6918 10.2198 12.0784 10.6064L23.3921 21.9201C23.7786 22.3066 23.7786 22.9477 23.3921 23.3343Z" fill="#0B150F" />
                                </svg>

                            </div>

                        </div>
                        <div className='mt-4'>

                            <div>
                                <div class="mb-3">
                                    <label style={{ fontWeight: "500" }} class="form-label">Coupon Code Name</label>
                                    <input placeholder='OfferPedia Coupon Code' type="text" class="form-control" name="couponCodeName" value={formData.couponCodeName} onChange={handleChangeCoupons} />
                                    <div className='text-danger mt-1'>{formDataErrors?.couponCodeName ? formDataErrors?.couponCodeName : null}</div>

                                </div>
                            </div>

                            <div>
                                <div class="mb-3">
                                    <label style={{ fontWeight: "500" }} class="form-label">Coupon Code</label>
                                    <input disabled={formData?.couponCodeType && formData?.couponCodeType !== "common"} placeholder='OFFER50' type="text" class="form-control" name="couponCodes" value={formData.couponCodes} onChange={handleChangeCoupons} />
                                    <div className='text-danger mt-1'>{formDataErrors?.couponCodes ? formDataErrors?.couponCodes : null}</div>
                                </div>
                            </div>

                            <div>
                                <div class="mb-3 pt-2">
                                    <label style={{ fontWeight: "500" }} class="form-label">Validity Period</label>
                                    <input type="date" class="form-control" name="expiryDate" value={formData.expiryDate} onChange={handleChangeCoupons} />
                                    <div className='text-danger mt-1'>{formDataErrors?.expiryDate ? formDataErrors?.expiryDate : null}</div>

                                </div>
                            </div>

                            <div>
                                <div class="mb-3 pt-2">
                                    <label style={{ fontWeight: "500" }} class="form-label">Offer (%)</label>
                                    <input disabled={formData?.couponCodeType && formData?.couponCodeType !== "common"} placeholder='50' min={"0"} type="number" class="form-control" name="discount" value={formData.discount} onChange={handleChangeCoupons} />
                                    <div className='text-danger mt-1'>{formDataErrors?.discount ? formDataErrors?.discount : null}</div>

                                </div>
                            </div>



                        </div>

                        {editDisabled ? null : <div className='d-flex justify-content-end mt-3 pt-3'>
                            <button disabled={editDisabled} className='bulkshare112' onClick={createCouponCodehandle}>
                                {isUpdate === true ? "Update" : "Create"}
                            </button>


                        </div>}


                    </div>
                </Modal>


            </div>

            <Toaster
                position="top-center"
                reverseOrder={false}
            />
        </div >
    )
}

export default CouponsCreate