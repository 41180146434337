import React, { useState } from 'react';
import "./AddCustomers.css"
import "../BulkshareCampaign/BulkshareCampaign.css"
import "../AddOrders/AddOrders.css"
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { SetRemoveCustomers } from '../../../Redux/vendorReducer';

function AddCustomers() {

    let dispatch = useDispatch()
    let navigate = useNavigate()

    const [formData, setFormData] = useState({
        customerName: "",
        contactNumber: "",
        address1: '',
        address2: '',
        pincode: "",
    });


    const [formErrors, setFormErrors] = useState({
        customerName: false,
        contactNumber: false,
        address1: false,
        address2: false,
        pincode: false,
    });

    const [formErrorsMessages, setFormErrorsMessage] = useState({
        customerName: "",
        contactNumber: "",
        address1: '',
        address2: '',
        pincode: "",
    });


    const handleInputChange = async (event) => {
        const { target } = event;
        const { name, value, type, checked } = target;


        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

    };



    const saveCustomerData = (e) => {

        e.preventDefault();

        if (!formData.customerName.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                customerName: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                customerName: "Customer Name cannont be empty",
            }))

            return false;
        }

        if (!formData.contactNumber.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                contactNumber: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                contactNumber: "Phone Number cannont be empty",
            }))

            return false
        }

        if (Number(formData.contactNumber.length) !== 10) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                contactNumber: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                contactNumber: "Number must be 10 digit Number",
            }))
            return false;
        }

        var customerDate = {
            userName: formData.customerName,
            phoneNumber: formData.contactNumber,
            address1: formData.address1,
            address2: formData.address2,
            pincode: formData.pincode

        }

        api.post("/customer/createCustomer", customerDate).then((res) => {
            if (res) {
                toast.success(res?.data?.message?.message);
                setFormData({
                    customerName: "",
                    contactNumber: "",
                    address1: '',
                    address2: '',
                    pincode: "",
                })
                dispatch(SetRemoveCustomers());
            } else {
                toast.error("Error in Saving Customer")
            }
        }).catch((err) => {
            console.log(err)
            toast.error(err?.response.data?.errors?.message[0] ? err?.response.data?.errors?.message[0] : err.message)
        })


    }


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <form onSubmit={saveCustomerData}>

                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                        <div style={{ cursor: 'pointer' }} onClick={() => { navigate("/vendor/listcustomers") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className='d-flex ps-2 flex-column'>
                            <p className='top-small-heading p-0 m-0'>Back to the Customer list</p>
                            <div className='d-flex'>
                                <p className='bulkshare111 p-0 m-0'>New Customer</p>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3 mt-1 addorder010'>Delete</div>
                        <div className='mx-3 mt-1 addorder011'>Cancel</div>
                        <div className='mx-3'><button type='Submit' className='bulkshare112'>Save</button></div>
                    </div>


                </div>


                <div className='row'>

                    <div className='col-12 col-md-6 '>
                        <div className='addcustomer001'>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002">Name</label>
                                <input type="text"
                                    name='customerName'
                                    value={formData.customerName}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                />
                                {formErrors.customerName && <div className='text-danger pt-1'>{formErrorsMessages.customerName}</div>}

                            </div>

                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002">Contact Number</label>
                                <input type="number"
                                    name='contactNumber'
                                    value={formData.contactNumber}
                                    onChange={handleInputChange}
                                    className="form-control no-spinner"
                                    id="exampleFormControlInput1" />
                                {formErrors.contactNumber && <div className='text-danger pt-1'>{formErrorsMessages.contactNumber}</div>}

                            </div>

                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002">Address</label>
                                <input type="text"
                                    name='address1'
                                    value={formData.address1}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="exampleFormControlInput1" />
                                <input type="text"
                                    name='address2'
                                    value={formData.address2}
                                    onChange={handleInputChange}
                                    className="form-control mt-2"
                                    id="exampleFormControlInput1" />
                                {formErrors.address1 && <div className='text-danger pt-1'>{formErrorsMessages.address1}</div>}

                            </div>

                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002 ">Pincode</label>
                                <input
                                    type="number"
                                    name='pincode'
                                    value={formData.pincode}
                                    onChange={handleInputChange}
                                    className="form-control no-spinner"
                                    id="exampleFormControlInput1" />
                                {formErrors.pincode && <div className='text-danger pt-1'>{formErrorsMessages.pincode}</div>}

                            </div>




                        </div>

                    </div>


                </div>
            </form>


        </div>
    )
}

export default AddCustomers