import React, { useState } from 'react'
import "./VendorSignup.css";
import "../VendorLogin/VendorLogin.css";
import logo from "../../Images/logo1.png"
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';

function VendorSignup() {

    const navigate = useNavigate();
    var baseurl = process.env.REACT_APP_API_URL_VENDOR;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRePassword] = useState('');
    const [username, setUsername] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repasswordError, setRePasswordError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [isFreezed, setIsFreezed] = useState(false)


    const handleEmailChange = (e) => {
        setEmail((e.target.value).trim().toLowerCase());
        setEmailError('');
    };

    const handleUserNameChange = (e) => {
        setUsername((e.target.value));
        setUsernameError('');
    };

    const handlePasswordChange = (e) => {
        setPassword((e.target.value).trim());
        setPasswordError('');
    };

    const handleReEnterPasswordChange = (e) => {
        setRePassword((e.target.value).trim());
        setRePasswordError('');
    };



    const signupUser = async (e) => {

        e.preventDefault()
        var usernameRegex = /^[a-zA-Z\s]+$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;



        // Validate UserName
        if (!(username.trim())) {
            setUsernameError('Please enter your Username.');
            return;
        }

        if (!(username.match(usernameRegex))) {
            setUsernameError('Username should contain only alphabets and spaces.');
            return;
        }

        // Validate Email
        if (!(email.trim())) {
            setEmailError('Please enter your email.');
            return;
        }
        if (!email.match(emailRegex)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        //Validate Password
        if (!password) {
            setPasswordError('Please enter your Password.');
            return;
        }
        if (!password.match(passwordRegex)) {
            setPasswordError('Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
            return;
        }

        if (!repassword) {
            setRePasswordError('Please Re-enter your Password.');
            return;
        }

        if (password !== repassword) {
            setRePasswordError("Password Mis-match");
            return;
        }

        setIsFreezed(true)

        //Saving User Details to Backend
        await axios.post(`${baseurl}/auth/signup`, {
            userName: username,
            password: password,
            email: email
        })
            .then(async (res) => {
                // console.log(res)
                // console.log(res?.data?.message?.code)
                setIsFreezed(false)
                if (res?.data?.message?.code === "success") {
                    toast.success('Vendor Created Successfully')
                    setEmail("")
                    setPassword("")
                    setUsername("")
                    setRePassword("")

                    const delayTime = 200;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));
                    toast.success('Check your mail for confirmation')

                    // navigate("/vendor/login")


                } else {
                    toast.error("Error during Signup")
                }


            })
            .catch((err) => {
                // console.log(err)
                setIsFreezed(false)
                toast.error(err?.response?.data?.errors?.body[0])
            });



    }


    const loginuser = () => {
        navigate("/vendor/login")
    }


    return (
        <div className='vendorsignup112'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className='vendorsignup113'>
                <div className='mb-4 d-flex justify-content-center'><img className='vendorsignup120' src={logo} alt="" /></div>
                <div >
                    <form onSubmit={signupUser}>
                        <div className=" vendorsignup118">

                            {/* <div class="text-center vendorsignup121">Vendor signup</div> */}
                            <div className='mt-4'>
                                <label for="exampleFormControlInput1" class="form-label">User Name</label>
                                <input type="text" className="form-control formbgcolor1" value={username} name="username" onChange={(e) => handleUserNameChange(e)} id="exampleFormControlInput2" />
                                {usernameError && <div className="text-danger mt-1">{usernameError}</div>}

                            </div>

                            <div className='mt-4'>
                                <label for="exampleFormControlInput1" class="form-label">Email address</label>
                                <input type="text" className="form-control formbgcolor1" value={email} name="email" onChange={(e) => handleEmailChange(e)} id="exampleFormControlInput1" />
                                {emailError && <div className="text-danger mt-1">{emailError}</div>}

                            </div>

                            <div className="mt-4">
                                <label for="exampleFormControlInput1" class="form-label">Password</label>
                                <input type="password" className="form-control formbgcolor1" value={password} name="email" onChange={(e) => handlePasswordChange(e)} id="exampleFormControlInput1" />
                                {passwordError && <div className="text-danger mt-1">{passwordError}</div>}

                            </div>

                            <div className="mt-4">
                                <label for="exampleFormControlInput1" class="form-label">Re-Enter Password</label>
                                <input type="password" className="form-control formbgcolor1" value={repassword} name="email" onChange={(e) => handleReEnterPasswordChange(e)} id="exampleFormControlInput1" />
                                {repasswordError && <div className="text-danger mt-1">{repasswordError}</div>}

                            </div>

                            <div className="mt-5 d-grid">
                                {!isFreezed ? <button className='vendorsignup117'>Signup</button> :
                                    <div className='d-flex justify-content-center'>
                                        <div class="spinner-border text-success" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className='mt-3 d-flex justify-content-end vendorlogin121'>
                                <p><span className='mx-1'>Already have account?  </span>  <span onClick={loginuser} className='vendorlogin122'>Login</span></p>
                            </div>


                        </div>
                    </form>
                </div>
            </div>



        </div>
    )
}

export default VendorSignup