import React, { useEffect, useState } from 'react'
import './PeopleClaimedTimeline.css'

function PeopleClaimedTimeline({ userClaimedlist }) {
    // const names = ["Leo", "Antony", "Harold", "Saho", "Rahul", "Satya"];
    const [names, setUserName] = useState([])

    useEffect(() => {
        setUserName(userClaimedlist)
    }, [userClaimedlist])

    return (
        <div className="timeline">
            {names?.map((name, index) => (
                <div key={index} className="timeline-item">
                    {index % 2 === 0 ? (
                        <>
                            <div className="timeline-dot  ertertert4" />
                            <div className="timeline-content left">
                                <h2 className='textlinebargrapg' style={{ overflow: "hidden" }}>{name?.toLowerCase()}</h2>
                            </div>
                        </>

                    ) : (
                        <>
                            <div className="timeline-dot ertertert4" />
                            <div className="timeline-content right">
                                <h2 className='textlinebargrapg frtyujhg'>{name?.toLowerCase()}</h2>
                            </div>
                        </>
                    )}
                </div>
            ))}
        </div>
    );
}

export default PeopleClaimedTimeline