import './App.css';
import {
  Route,
  Routes,
}
  from 'react-router-dom';
import VendorRoutes from './Routes/VendorRoutes';
import CustomerRoutes from './Routes/CustomerRoutes';
import HomePage from './Components/HomePage/HomePage';
import Spinner from './Components/Customer/Spinner/Spinner';
import ScratchCard from './Components/Customer/ScratchCard/ScratchCard';
import SuperAdminRoute from './Routes/SuperAdminRoutes';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Routes>
        <Route path='/spinner' element={<Spinner />} />
        <Route path='/scratch-card' element={<ScratchCard />} />
        {/* Customer */}
        <Route path='/customer/*' element={<CustomerRoutes />} />

        {/* Vendor */}
        <Route path="/vendor/*" element={<VendorRoutes />} />

        {/* SuperAdmin */}
        <Route path="/superadmin/*" element={<SuperAdminRoute />} />

        <Route path="/" element={<HomePage />} />



      </Routes>
    </>
  );
}

export default App;
