import React from 'react'
import "./Bulkshare.css"

function ProfileDropDown() {

    const token = localStorage.getItem("utoken");

    const logout = () => {
        localStorage.removeItem("utoken")
        localStorage.removeItem("urtoken")
        window.location.replace("/customer/login")
    }

    const loginUser = ()=> {
        window.location.replace("/customer/login")

    }


    return (
        <div className='dropdown-menu dropdown0017'>
           {token? <ul className="  dropdown0012 " >
                {/* <li><a className="dropdown-item dropdown0013 pb-3" > <i className="fa-solid fa-user mx-2"></i> Profile</a></li> */}
                <li onClick={logout}><div className="dropdown-item dropdown0013 dropdown0014" > <i className="fa-solid fa-right-from-bracket mx-2"></i>Logout</div></li>
            </ul>:
            <ul className="  dropdown0012 " >
            <li onClick={loginUser}><div className="dropdown-item dropdown0013 dropdown0014 " > <i class="fa-solid fa-user-plus mx-2"></i>Login</div></li>

        </ul>}
        </div>
    )
}

export default ProfileDropDown