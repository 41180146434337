import React, { useEffect, useState } from 'react'
import "./ViewVendorById.css"
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import api from '../ApiConfigration/ApiConfigration';
import nodataimage from "../../Images/Product-empty.png"
import loderImage from "../../Images/loader.gif"
import { useDispatch, useSelector } from 'react-redux';
import { SetRemoveRejectedVendors, setVendorList } from '../../../Redux/superAdminReducer';


function ViewVendorById() {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [vendorData, setvendorData] = useState()
    const [campaignData, setCamapaignData] = useState([])
    const [isloading, setIsLoading] = useState(true)
    const [orderData, setOrderDataa] = useState([])
    const [freezeButton, setFreezeButton] = useState(false)
    var vendorlistdata = useSelector(state => state?.superAdminData?.vendorList);



    useEffect(() => {
        if (id) {

            async function fetchData() {
                api.get(`/vendor/getvendorbyid?id=${id}`).then((res) => {
                    if (res) {
                        setvendorData(res?.data?.message?.message)
                    } else {
                        toast.error("Error Fetching Data")
                    }
                }).catch((err) => {
                    toast.error(err?.message)
                });

                api.get(`/vendor/getvendorordersbyid?id=${id}`).then((data) => {
                    setIsLoading(false);

                    if (data) {
                        var finalData = data?.data?.message?.message?.campaignData
                        setCamapaignData(finalData)
                        setOrderDataa(data?.data?.message?.message?.order)
                    } else {
                        toast.error("Error Fetching Data")
                    }
                }).catch((err) => {
                    setIsLoading(false);

                    toast.error(err?.message)
                });
            }

            fetchData()
        }
    }, [id])



    function formatDate(inputDateString) {
        const inputDate = new Date(inputDateString);

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = inputDate.getUTCDate();
        const month = months[inputDate.getUTCMonth()];
        const year = inputDate.getUTCFullYear();

        return `${day} ${month} ${year}`;
    }

    function camelToNormal(camelCase) {
        const words = camelCase.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
        const normalCase = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return normalCase;
    }


    const revokeVendor = async () => {
        setFreezeButton(true)
        await api.get(`/vendor/revokevendorpermission?id=${id}`)
            .then((res) => {
                setFreezeButton(false)
                if (res) {
                    toast.success("Revoked Vendor permission")
                    const updatedList = vendorlistdata.filter(vendor => vendor.id !== id);
                    dispatch(setVendorList(updatedList));
                    dispatch(SetRemoveRejectedVendors());
                    navigate(-1)
                } else {
                    toast.error("Error Updating Status")
                }
            }).catch((err) => {
                setFreezeButton(false)
                toast.error(err?.message)
            })
    }


    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        navigate(-1);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='d-flex ps-2 flex-column'>
                        <p className='top-small-heading p-0 m-0'>Back to the Vendor list</p>
                        <div className='d-flex'>
                            <p style={{ "textTransform": "capitalize" }} className='bulkshare111 p-0 m-0'>{vendorData?.StoreName ? (vendorData?.StoreName).toLowerCase() : null}</p>

                        </div>
                    </div>
                </div>
                <div className='d-flex'>

                    <div>{!freezeButton ? <button type='button ' onClick={revokeVendor} className=' btn btn-danger'> Revoke</button> :
                        <button disabled className=' btn btn-danger'>
                            <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                            Updating..</button>}</div>
                </div>


            </div>


            <div className='row mt-5'>



                <div className='col-12 col-md-6'>


                    <div className='viewcustomer003 d-flex justify-content-around mb-5 pb-5'>

                        <div className='text-center'>
                            <div className='viewcustomer005'>Total Products</div>
                            <div className='viewcustomer006'>{vendorData?.orderDetails?.productCount ? vendorData?.orderDetails?.productCount : 0}</div>
                        </div>
                        <div className='text-center'>

                            <div className='viewcustomer005'>Delivered Orders</div>
                            <div className='viewcustomer006'>{vendorData?.orderDetails?.orderCount ? vendorData?.orderDetails?.orderCount : 0}</div>

                        </div>
                        <div className='text-center'>
                            <div className='viewcustomer005'>Total Sales</div>
                            <div className='viewcustomer006'>{vendorData?.orderDetails?.Ordersum ? "₹" + vendorData?.orderDetails?.Ordersum : "₹0"}</div>
                        </div>

                    </div>


                    <div className='viewcustomer004'>

                        <div className='viewcustomer007 mb-4'>Vendor Orders</div>

                        <div>

                            <table class="table table-borderless">
                                <thead className=' pb-3 mb-3 viewcustomer012'>
                                    <tr>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008' >Cost</td>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008' >Date</td>
                                        <td className='viewcustomer008'>Status</td>

                                    </tr>
                                </thead>


                                <tbody>

                                    {orderData?.map((itm, k) => <tr>

                                        <td className=''>{itm?.id ? itm?.id?.substring(0, 8) : null}</td>

                                        <td className='viewcustomer009'>

                                            {itm?.type ? camelToNormal(itm?.type) : null}


                                        </td>

                                        <td style={{ textTransform: "capitalize" }} className='viewcustomer009'>
                                            {itm?.cartValue ? itm?.cartValue?.toLowerCase() : null}
                                            {itm?.type === "bulkshare" ? "Bulk Share" : null}


                                        </td>
                                        <td className='viewcustomer009'><div className='mx-2'></div></td>

                                        <td className='viewcustomer009'>
                                            {itm?.createdAt ? formatDate(itm?.createdAt) : null}
                                        </td>
                                        <td className={`viewcustomer009`}>
                                            <div style={{ color: itm?.orderStatus === 'delivered' ? 'green' : 'red' }}>
                                                {itm?.orderStatus ? itm.orderStatus : null}

                                            </div>
                                        </td>

                                    </tr>)}









                                    {/* <tr>
                                <td className='viewcustomer009 viewcustomer010'>N1</td>
                                <td className='viewcustomer009'></td>
                                <td className='viewcustomer009'>₹900</td>
                                <td className='viewcustomer009'><div className='mx-2'></div></td>
                                <td className='viewcustomer009'>Jan 29 2023</td>
                                <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                            </tr>

                            <tr>
                                <td className='viewcustomer009 viewcustomer010'>N1</td>
                                <td className='viewcustomer009'></td>
                                <td className='viewcustomer009'>₹900</td>
                                <td className='viewcustomer009'><div className='mx-2'></div></td>
                                <td className='viewcustomer009'>Jan 29 2023</td>
                                <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                            </tr>


                            <tr>
                                <td className='viewcustomer009 viewcustomer010'>N1</td>
                                <td className='viewcustomer009'><div className='mx-3'></div></td>
                                <td className='viewcustomer009'>₹900</td>
                                <td className='viewcustomer009'><div className='mx-2'></div></td>
                                <td className='viewcustomer009'>Jan 29 2023</td>
                                <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                            </tr>

                            <tr>
                                <td className='viewcustomer009 viewcustomer010'>N1</td>
                                <td className='viewcustomer009'></td>
                                <td className='viewcustomer009'>₹900</td>
                                <td className='viewcustomer009'><div className='mx-2'></div></td>
                                <td className='viewcustomer009'>Jan 29 2023</td>
                                <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                            </tr> */}
                                </tbody>

                            </table>

                            {!isloading && orderData?.length === 0 ? <div className='text-center nodataicon-div'>
                                <img src={nodataimage} style={{ width: "50px", height: "50px" }} />
                                <div className='nodata_text'>No data found</div>
                            </div> : null}

                            {
                                isloading ? <div className='d-flex , justify-content-center mt-3'>
                                    <img src={loderImage} style={{ width: "80px" }} alt="" />
                                </div> : null
                            }



                        </div>


                    </div>




                </div>

                <div className='col-12 col-md-6'>

                    <div className='viewcustomer016'>

                        <div className='viewcustomer019'>Vendor Details</div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Store Name</div>
                            <div style={{ "textTransform": "capitalize" }} className='viewcustomer018 col-6'>{vendorData?.StoreName ? (vendorData?.StoreName).toLowerCase() : "Nil"}</div>
                            <div></div>
                        </div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Name</div>
                            <div style={{ "textTransform": "capitalize" }} className='viewcustomer018 col-6'>{vendorData?.userName ? (vendorData?.userName).toLowerCase() : "Nil"}</div>
                            <div></div>
                        </div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Phone</div>
                            <div className='viewcustomer018 col-6'>{vendorData?.countryCode ? ("+" + vendorData?.countryCode) : null} {vendorData?.contactNumber ? (vendorData?.contactNumber) : "Nil"}</div>
                            <div></div>
                        </div>
                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Whatsapp</div>
                            <div className='viewcustomer018 col-6'>{vendorData?.whatsappCountryCode ? ("+" + vendorData?.whatsappCountryCode) : null} {vendorData?.whatsappNumber ? (vendorData?.whatsappNumber) : "Nil"}</div>
                            <div></div>
                        </div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Address</div>
                            <div style={{ "textTransform": "capitalize" }} className='viewcustomer018 col-6'>
                                <div>{vendorData?.address1 ? (vendorData?.address1).toLowerCase() : null}{vendorData?.address2 ? " ," : null} {vendorData?.address2 ? (vendorData?.address2).toLowerCase() : null}</div>
                                <div>{vendorData?.city ? (vendorData?.city).toLowerCase() : null} {vendorData?.state ? "," : null} {vendorData?.state ? (vendorData?.state).toLowerCase() : null}</div>
                            </div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Notes</div>
                            <div className='viewcustomer018 col-6'></div>
                            <div></div>
                        </div>





                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Created</div>
                            <div className='viewcustomer018 col-6'>{vendorData?.createdAt ? formatDate(vendorData?.createdAt) : "Nil"}</div>
                            <div></div>
                        </div>





                    </div>

                    <div className='viewcustomer016 mt-4'>

                        <div className='viewcustomer019'>Vendor Campaigns</div>

                        <div>

                            <table class="table table-borderless">
                                <thead className=' pb-3 mb-3 viewcustomer012'>
                                    <tr>
                                        <td className='viewcustomer008'>Type</td>
                                        <td className='viewcustomer008' >Campaign Name</td>
                                        <td className='viewcustomer008' >Date</td>

                                    </tr>
                                </thead>
                                <tbody>

                                    {campaignData?.map((itm, k) => <tr>
                                        <td className='viewcustomer009'>

                                            {itm?.type ? camelToNormal(itm?.type) : null}


                                        </td>
                                        <td style={{ textTransform: "capitalize" }} className='viewcustomer009'>
                                            {itm?.campaignName ? itm?.campaignName?.toLowerCase() : null}
                                            {itm?.type === "bulkshare" ? "Bulk Share" : null}


                                        </td>
                                        <td className='viewcustomer009'>
                                            {itm?.createdAt ? formatDate(itm?.createdAt) : null}
                                        </td>
                                    </tr>)}

                                </tbody>

                            </table>

                            {
                                isloading ? <div className='d-flex , justify-content-center mt-3'>
                                    <img src={loderImage} style={{ width: "80px" }} alt="" />
                                </div> : null
                            }

                            {!isloading && campaignData?.length === 0 ? <div className='text-center nodataicon-div'>
                                <img src={nodataimage} style={{ width: "50px", height: "50px" }} />
                                <div className='nodata_text'>No data found</div>
                            </div> : null}

                        </div>
                    </div>

                </div>
            </div>




        </div>
    )
}

export default ViewVendorById