import React, { useEffect, useState } from "react";
import "./ProgressBar2.css";
import { AnimatePresence, motion } from "framer-motion";
import logo from "../../Images/175.png";
export default function ProgressBar2({
  setPercentage,
  maxDiscount,
  maxLimit,
  imageUrl,
  currentstatus,
}) {
  const [progressLength, setProgressLength] = useState([]);
  const [limit, setLimit] = useState(0);
  const MAX_Limit=Number(maxLimit)
  const CURRENT_STATUS=Number(currentstatus)
  const MAX_DISCOUNT=Number(maxDiscount)
  useEffect(() => {
    setLimit(MAX_DISCOUNT / MAX_Limit);
    const showPoints = 176 / (MAX_Limit);
    let arr = [];
    for (let i = 0; i < MAX_Limit+1; i++) {
      if (i === 0) {
        arr[i] = -88;
      } else {
        arr[i] = arr[i - 1] + showPoints;
      }
    }
    setProgressLength(arr);
    setPercentage(Math.ceil(CURRENT_STATUS*(MAX_DISCOUNT/MAX_Limit)))
  }, [maxDiscount, maxLimit,currentstatus]);

  
  return (
    <div className="progressbar">
      <div className="circle">
        <motion.div
          initial={{ rotate: "-90deg" }}
          animate={{ rotate: progressLength[CURRENT_STATUS] + "deg" }}
          transition={{delay:'0.2', duration: "2" }}
          className="bar"
        >
          {" "}
        </motion.div>

        <div className="inner-circle">
          {progressLength.map((item, i) => {
            return (
              <div
                style={{ transform: `rotate(${item}deg)` }}
                className="scale"
              >
                <AnimatePresence>
                {i <= CURRENT_STATUS ? (
                  <motion.svg
                  initial={{opacity:0}}
                  animate={{opacity:1}}
                  transition={{delay:(i*1*(2/CURRENT_STATUS)),duration:0.3}}
                    className="activepointsvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_148_58)">
                      <circle cx="15" cy="15" r="5" fill="#60C2A2" />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_148_58"
                        x="0"
                        y="0"
                        width="30"
                        height="30"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="5" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.376471 0 0 0 0 0.760784 0 0 0 0 0.635294 0 0 0 1 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_148_58"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_148_58"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </motion.svg>
                ) : (
                  <svg
                    className="pointsvg"
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle cx="5" cy="5" r="5" fill="#333333" />
                  </svg>

                )}</AnimatePresence>
                <p
                  style={{
                    transform: `rotate(${
                      item < 0 ? Math.abs(item) : item * -1
                    }deg)`,
                  }}
                  className="progressbar-text"
                >
                  {Math.ceil(i * limit)}%
                </p>
              </div>
            );
          })}
          <img
            className="product-image"
            style={{ width: "35%" }}
            src={imageUrl?imageUrl:logo}
            alt=""
          />
        </div>
      </div>
      <div className="half-hidden"></div>
    </div>
  );
}
