import React from 'react'
import "./BuyPremium.css"

function BuyPremium() {
    return (
        <div>
            <div className='buypremium001'>
                <h3 className='buypremium002'>Go Premium</h3>
                <p className='buypremium003'>Subscribe to premium plan and get access to advanced features</p>
                <div className='d-flex justify-content-center'>
                    <button className='buypremium004'>Upgrade Now</button>

                </div>
            </div>
        </div>
    )
}

export default BuyPremium