import React, { useEffect, useState } from 'react';
import "./BulkshareCampaign.css"

function DynamicInputFields({ initialData, onInputDataChange, disableEdit }) {

    const [inputFields, setInputFields] = useState([{ value: '' }]);


    useEffect(() => {
        onInputDataChange(inputFields);
        // eslint-disable-next-line
    }, [inputFields])

    useEffect(() => {
        // Set initial data when available
        if (initialData && initialData.length > 0) {
            console.log()

            var items = initialData?.map((item) => {
                let parsedItem = item;
                if (typeof item === 'string') {
                    parsedItem = JSON.parse(item);
                }
                parsedItem = parsedItem?.value || parsedItem;
                return parsedItem;
            });
            setInputFields(items?.map((value) => ({ value })));
        }
    }, [initialData]);




    const handleAddField = () => {
        setInputFields([...inputFields, { value: '' }]);
    };

    const handleRemoveField = (index) => {
        const newFields = [...inputFields];
        newFields.splice(index, 1);
        setInputFields(newFields);
    };

    const handleInputChange = (index, event) => {
        const newFields = [...inputFields];
        newFields[index].value = event.target.value;
        setInputFields(newFields);
    };

    return (
        <div className=" mt-2">
            <form >
                {inputFields.map((field, index) => (
                    <div key={index} className="mb-3">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={field.value}
                                onChange={(e) => handleInputChange(index, e)}
                                disabled={disableEdit}
                                required
                            />
                            {!disableEdit ? <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleRemoveField(index)}
                            >
                                Remove
                            </button> : null}
                        </div>
                    </div>
                ))}
                <div class="d-grid gap-2 mt-5">
                    {!disableEdit ? <button
                        type="button"
                        className="dynamicbutton111"
                        onClick={handleAddField}
                    >
                        Add
                    </button> : null}
                </div>

            </form>
        </div>
    );
}

export default DynamicInputFields;
