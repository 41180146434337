import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';

function AddVendors() {

    let navigate = useNavigate()

    const [buttonLoading, issetButtonLoading] = useState(false)
    const [formData, setFormData] = useState({
        userName: "",
        email: "",
        password: '',

    });


    const [formErrors, setFormErrors] = useState({
        userName: false,
        email: false,
        password: false,
    });

    const [formErrorsMessages, setFormErrorsMessage] = useState({
        userName: "",
        email: "",
        password: '',
    });


    const handleInputChange = async (event) => {
        const { target } = event;
        const { name, value, type, checked } = target;


        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));


        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

    };



    const saveVendorData = (e) => {

        e.preventDefault();

        if (!formData.userName.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                userName: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                userName: "Vendor Name cannont be empty",
            }))

            return false;
        }

        if (!formData.email.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                email: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                email: "Email cannont be empty",
            }))

            return false
        }

        if (!formData.password.trim()) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                password: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                password: "Password cannont be empty",
            }))

            return false
        }

        if ((formData.password.trim()).length < 8) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                password: true,
            }))
            setFormErrorsMessage((prevErrors) => ({
                ...prevErrors,
                password: "Password should be 8 character long",
            }))

            return false
        }


        var customerDate = {
            userName: formData.userName,
            email: formData.email,
            password: formData.password,

        }

        issetButtonLoading(true)
        api.post("/vendor/createvendor", customerDate).then((res) => {
            issetButtonLoading(false)

            if (res) {
                toast.success(res?.data?.message?.message);
                setFormData({
                    userName: "",
                    email: "",
                    password: '',

                })
            } else {
                toast.error("Error in Saving Vendor")
            }
        }).catch((err) => {
            issetButtonLoading(false)
            toast.error(err?.response.data?.errors?.body[0] ? err?.response.data?.errors?.body[0] : err.message)
        })


    }

    const generatePassword = () => {
        const symbols = '!@#$%^&*()_-+=<>?/[]{}|';
        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const numbers = '0123456789';

        const getRandomCharacter = (characters) => {
            return characters[Math.floor(Math.random() * characters.length)];
        };

        let newPassword = '';
        newPassword += getRandomCharacter(lowerCaseLetters);
        newPassword += getRandomCharacter(upperCaseLetters);
        newPassword += getRandomCharacter(numbers);
        newPassword += getRandomCharacter(symbols);
        for (let i = 4; i < 12; i++) {
            const characterType = Math.floor(Math.random() * 4);
            switch (characterType) {
                case 0:
                    newPassword += getRandomCharacter(lowerCaseLetters);
                    break;
                case 1:
                    newPassword += getRandomCharacter(upperCaseLetters);
                    break;
                case 2:
                    newPassword += getRandomCharacter(numbers);
                    break;
                case 3:
                    newPassword += getRandomCharacter(symbols);
                    break;
                default:
                    break;
            }
        }

        newPassword = newPassword.split('').sort(() => Math.random() - 0.5).join('');
        setFormData({ ...formData, password: newPassword });
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            password: false,
        }));
    };



    return (

        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <form onSubmit={saveVendorData}>

                <div className='d-flex justify-content-between'>
                    <div className='d-flex'>
                        <div style={{ cursor: 'pointer' }} onClick={() => { navigate(-1) }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className='d-flex ps-2 flex-column'>
                            <p className='top-small-heading p-0 m-0'>Back to home</p>
                            <div className='d-flex'>
                                <p className='bulkshare111 p-0 m-0'>New Vendor</p>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>

                        {buttonLoading ?
                            <button class="btn btn-success" type="button" disabled>
                                <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                                Saving...
                            </button>
                            : <div className='mx-3'><button type='Submit' className='bulkshare112'>Save</button></div>}
                    </div>


                </div>


                <div className='row'>

                    <div className='col-12 col-md-6 '>
                        <div className='addcustomer001'>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002">Vendor Name</label>
                                <input type="text"
                                    name='userName'
                                    value={formData.userName}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                />
                                {formErrors.userName && <div className='text-danger pt-1'>{formErrorsMessages.userName}</div>}

                            </div>

                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label addcustomers002">Email</label>
                                <input type="email"
                                    name='email'
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="form-control no-spinner"
                                    id="exampleFormControlInput1" />
                                {formErrors.email && <div className='text-danger pt-1'>{formErrorsMessages.email}</div>}

                            </div>

                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label addcustomers002">Password</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                    />
                                    <button className="btn btn-success" type="button" onClick={generatePassword}>Random Password</button>
                                </div>
                                {formErrors.password && <div className="text-danger pt-1">{formErrorsMessages.password}</div>}
                            </div>


                            <div className='d-flex justify-content-center mt-4 pt-4'>

                                {buttonLoading ?
                                    <button class="btn btn-success" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                                        Saving...
                                    </button>
                                    : <div className='mx-3'><button type='Submit' className=' btn btn-success'>Create Vendor</button></div>}
                            </div>

                        </div>

                    </div>


                </div>
            </form>


        </div>



    )
}

export default AddVendors