import React, { useEffect, useState } from 'react'
import "./BulkshareCampaign.css"
import DynamicInputFields from './DynamicInputFields'
import arrowup from "../../Images/arrow-up.png"
import BuyPremium from '../BuyPremium/BuyPremium'
import api from '../ApiConfigration/ApiConfigration'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressBar2 from '../../Customer/ProgressBar2/ProgressBar2'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { SetRemoveBulkshare } from '../../../Redux/vendorReducer'

function BulkshareCampaign() {

    var BASE_URL = process.env.REACT_APP_API_AWS_BASE_URL
    const frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL

    const dispatch = useDispatch();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const edit = queryParams.get('edit')



    const [disableForm, setDisableForm] = useState(false);
    const [uniqueid, setuniqueId] = useState('')
    const [imageUrl, setimageUrl] = useState("")
    const [productsname, setProductName] = useState([])
    const [isSaveHidden, setIsSaveHidden] = useState(false)

    useEffect(() => {
        async function getProductList() {

            await api.get("/campaigns/getproductlist").then((res) => {
                if (res) {
                    setProductName(res?.data?.message?.message)
                } else {
                    console.log("Error Fetching Data")
                }
            }).catch((err) => {
                console.log(err.message)
            })

        }
        getProductList()
    }, [])



    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        productName: '',
        productImage: '',
        productId: '',
        quantity: '',
        maxlevel: '',
        maxpercentage: '',
        expirytime: '',
        typeofDelivery: '',
        termsAndCondition: [],
    });


    const [formErrors, setFormErrors] = useState({
        productId: false,
        productName: false,
        productImage: false,
        quantity: false,
        maxlevel: false,
        maxpercentage: false,
        expirytime: false,
        typeofDelivery: false,
    });


    useEffect(() => {
        if (id && (!edit || edit === "false")) {
            setDisableForm(true);
        }
        if (id) {
            getbulksharedatabyid();

        }
        async function getbulksharedatabyid() {
            await api.get(`/campaigns/getbulksharebyid?id=${id}`).then((res) => {
                if (res) {
                    setimageUrl(BASE_URL + res?.data?.message?.data?.productImage[0])
                    setFormData(res?.data?.message?.data)
                    setuniqueId(res?.data?.message?.data?.uniqueCode)
                }
            }).catch((err) => {
                toast.error('Error Fetching Data')
            })
        }
    }, [id, edit]);




    const handleInputChange = async (event) => {
        const { target } = event;
        const { name, value, type, checked } = target;

        if (name === 'productImage' && type === 'file') {
            try {

                const signedUrlResponse = await api.get(`/auth/signedurl/file/${name}.jpeg?mime=image/jpeg`);
                console.log({ signedUrlResponse: signedUrlResponse.data.url })
                const signedUrl = signedUrlResponse.data.url;

                const file = event.target.files[0];
                const options = {
                    headers: {
                        'Content-Type': "image/jpeg",
                    },
                };
                await axios.put(signedUrl, file, options).then(async (res) => {
                    if (res) {
                        await api.get(`/auth/makefilepublic?filename=${signedUrlResponse.data.pathName}`)
                            .then((res) => {
                                setimageUrl(BASE_URL + signedUrlResponse.data.pathName)
                                toast.success('Image uploaded')
                            })
                            .catch((err) => {
                                toast.err("Error in Image Upload")
                            })
                    }
                }).catch((err) => {
                    toast.error(err.message)
                });

                setFormData((prevData) => ({
                    ...prevData,
                    [name]: signedUrlResponse.data.pathName,
                }));


            } catch (error) {
                toast.err(error.message)

            }
        } else {

            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value,
            }));

        }

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));
    };


    const handleInputData = (data) => {
        console.log(data);
        formData.termsAndCondition = data
    };


    const createBulkshare = async (e) => {

        e.preventDefault();

        const today = new Date();
        const selectedDate = new Date(formData.expirytime);

        if (selectedDate <= today) {
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                expirytime: true,
            }));
            return;
        }

        const errors = { ...formErrors };
        let hasErrors = false;

        Object.keys(formData).forEach((key) => {
            if (key !== 'termsAndCondition' && formData[key] === '') {
                errors[key] = true;
                hasErrors = true;
            }
        });

        if (hasErrors) {
            setFormErrors(errors);
            return;
        }
        setIsSaveHidden(true)

        if (edit === "true") {


            await api.post("/campaigns/updatebulksharebyid", formData).then(async (res) => {
                if (res) {
                    toast.success("Campaign Updated")
                    const delayTime = 1000;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));
                    navigate(`/vendor/bulkshare?id=${id}`)


                } else {
                    toast.error("Error try again")
                    setIsSaveHidden(false)
                }
            }).catch((err) => {
                toast.error(err.message)
                setIsSaveHidden(false)
            })


        } else {
            await api.post("/campaigns/bulkshare", formData).then(async (res) => {
                if (res) {
                    toast.success("Campaign Created")
                    var id = res?.data?.message?.id
                    const delayTime = 1000;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));
                    dispatch(SetRemoveBulkshare());
                    navigate(`/vendor/bulkshare?id=${id}`)


                } else {
                    toast.error("Error try again")
                    setIsSaveHidden(false)
                }
            }).catch((err) => {
                toast.error(err.message)
                setIsSaveHidden(false)

            })
        }

    }


    const sharedetails = async () => {

        var urltocopy = `${frontendUrl}/customer/bulkshare/v/${uniqueid}`
        try {
            const textarea = document.createElement('textarea');
            textarea.value = urltocopy;
            textarea.style.position = 'fixed';
            textarea.style.top = 0;
            textarea.style.left = 0;
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.setSelectionRange(0, textarea.value.length);
            document.execCommand('copy');
            document.body.removeChild(textarea); toast.success("Link copied to clipboard!")

        } catch (error) {
            console.error('Failed to copy:', error);
        }
    }

    const setPercentage = () => {
        console.log("Sucess")
    }


    const onProductChange = (e) => {
        // console.log(e)
        setFormData((prevData) => ({
            ...prevData,
            productId: e?.id,
            productName: e?.productName,
            productImage: e?.productImage[0],

        }));

        setimageUrl(e?.productImage[0])

        setFormErrors((prevErrors) => ({
            ...prevErrors,
            productId: false,
            productName: false,
            productImage: false,
        }));
    }

    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='row'>
                <div className='col-12 col-lg-8' >
                    <form onSubmit={createBulkshare}>
                        {/* tope heading */}
                        <div className='d-flex justify-content-between'>
                            <div className='bulkshare111'>Bulk Share</div>
                            {!edit || edit === "false" ? <div>{disableForm ? <div></div> : <>{isSaveHidden ? null : <button type='Submit' className='bulkshare112'>Save</button>}</>}</div> : null}
                            {edit === "true" ? <>{isSaveHidden ? null : <button type='Submit' className='bulkshare112'>Update</button>}</> : null}

                        </div>

                        {/* form */}
                        <div className='bulkshare113'>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Product</label>
                                <div className="dropdown d-flex  ">
                                    <button
                                        class=" scratch-card-form-dropdown dropdown-toggle "
                                        type="button"
                                        disabled={disableForm}
                                        id="dropdownMenuButton2"
                                        data-bs-toggle="dropdown"

                                    >
                                        <p style={{ height: "19px" }} className="p-0 m-0">
                                            {formData?.productName ? formData?.productName : "Select Product"}
                                        </p>
                                    </button>
                                    <ul
                                        class="dropdown-menu dropdown-menu dropdownheight-bulkshare"
                                        aria-labelledby="dropdownMenuButton2"
                                    >
                                        {productsname?.length === 0 ? <div style={{ cursor: 'pointer' }} class="dropdown-item " >
                                            No Product to choose
                                        </div> : <>
                                            {productsname?.map((itm, k) => (<li>
                                                <div style={{ cursor: 'pointer' }} class="dropdown-item " onClick={() => onProductChange(itm)}>
                                                    {itm?.productName ? itm?.productName : "No Name"}
                                                </div>
                                            </li>))}</>}
                                    </ul>

                                </div>
                                {/* <input type="text"
                                    name='productName'
                                    value={formData.productName}
                                    onChange={handleInputChange}
                                    class="form-control"
                                    id="exampleFormControlInput1"
                                    disabled={disableForm} /> */}

                                {formErrors.productId && <div className='text-danger pt-1'>Please select Product</div>}
                                {formErrors.productName && <div className='text-danger pt-1'>Please select Product having product name</div>}
                                {formErrors.productImage && <div className='text-danger pt-1'>Please select Product having product image</div>}

                            </div>

                            {/* {!disableForm ? <div class="mb-3">
                                <label for="formFile" class="form-label">Product Image</label>
                                <input class="form-control" name='productImage'
                                    // value={formData.productImage}
                                    onChange={handleInputChange} type="file" id="formFile"
                                    disabled={disableForm} />
                                {formErrors.productImage && <div className='text-danger mt-2'>Please upload Product Image</div>}

                            </div> : <div className='mb-3'>
                                <label for="formFile" class="form-label">Product Image</label>
                                <input class="form-control" name='productImage'
                                    value={formData.productImage}
                                    onChange={handleInputChange} type="string" id="formFile"
                                    disabled={disableForm} /></div>} */}

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Quantity</label>
                                <input type="number" name='quantity'
                                    value={formData.quantity}
                                    onChange={handleInputChange} class="form-control" id="exampleFormControlInput1" disabled={disableForm} />
                                {formErrors.quantity && <div className='text-danger mt-1'>Please enter Quantity</div>}

                            </div>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Maximum Person</label>
                                <input type="number" name='maxlevel'
                                    value={formData.maxlevel}
                                    onChange={handleInputChange} class="form-control" id="exampleFormControlInput1" disabled={disableForm} />
                                {formErrors.maxlevel && <div className='text-danger mt-1'>Please enter Maximum Person</div>}

                            </div>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Maximum Percentage</label>
                                <input type="number" name='maxpercentage'
                                    value={formData.maxpercentage}
                                    onChange={handleInputChange} class="form-control" id="exampleFormControlInput1" disabled={disableForm} />
                                {formErrors.maxpercentage && <div className='text-danger mt-1'>Please enter Maximum Percentage</div>}

                            </div>

                            <div class="mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Expires on</label>
                                <input type="date" name='expirytime'
                                    value={formData.expirytime}
                                    onChange={handleInputChange} class="form-control" id="exampleFormControlInput1" disabled={disableForm} />
                                {formErrors.expirytime && <div className='text-danger mt-1'>Please select Expiry Date greater than Today</div>}

                            </div>

                            <div className=''>
                                <div class="form-check form-check-inline">
                                    <input name='typeofDelivery'
                                        checked={formData.typeofDelivery === 'delivery'}
                                        value='delivery'
                                        onChange={handleInputChange} class="form-check-input" type="radio" id="inlineCheckbox1" disabled={disableForm} />
                                    <label class="form-check-label" for="inlineCheckbox1">Delivery</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input name='typeofDelivery'
                                        checked={formData.typeofDelivery === 'pickup'}
                                        onChange={handleInputChange} class="form-check-input" type="radio"
                                        value='pickup'
                                        id="inlineCheckbox2" disabled={disableForm} />
                                    <label class="form-check-label" for="inlineCheckbox2">Pickup</label>

                                </div>

                            </div>
                            {formErrors.typeofDelivery && <div className='text-danger mt-1'>Please select Type of Delivery</div>}



                            <div class="mb-3 mt-5">
                                <label for="exampleFormControlInput1" class="form-label">Terms & Conditions</label>
                                {/* <DynamicInputFields onInputDataChange={handleInputData} /> */}
                                <DynamicInputFields initialData={disableForm ? formData.termsAndCondition : edit === "true" ? formData.termsAndCondition : null} onInputDataChange={handleInputData} disableEdit={disableForm} />
                            </div>

                        </div>

                        {/* Link generate */}
                        {disableForm ? <div className='bulkshare114'>

                            <div>
                                <div id="generatelink" class="mb-4">
                                    <label for="exampleFormControlInput1" class="form-label">Generate Link</label>

                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text copubutton221 copubutton224">
                                                <button type="button" className="btn btn-link btn-sm copubutton224" id="copyButton">
                                                    <i className="fas fa-link"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <input
                                            type="text"
                                            className="form-control copubutton222"
                                            value={`${frontendUrl}/customer/bulkshare/v/${uniqueid}`}
                                            disabled
                                        />
                                        <div className="input-group-append">
                                            <button
                                                onClick={sharedetails}
                                                type="button"
                                                className="btn btn-success copubutton223"
                                            >
                                                <i className="fa-regular fa-copy mx-2"></i>
                                                Copy
                                            </button>
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div>
                                <label for="exampleFormControlInput1" class="form-label">Custom Link</label>
                                <div className='bulkshare116'>

                                    <div>Upgrade to <span className='bulkshare119'>PRO</span></div>
                                    <div className='bulkshare117'><img className="bulkshare118" src={arrowup} alt="" /></div>

                                </div>

                            </div>

                        </div> : <div className='mb-5 pb-5'></div>}
                    </form>
                </div>

                <div className='col-12  col-lg-4'>

                    {/* headings */}
                    <div className='d-flex justify-content-between mb-4'>
                        <div>
                            <h3 className='bulkshare111'>Preview</h3>
                        </div>
                        <div>
                            <button className='bulkshare120'> <i class="fa-solid fa-eye"></i> Customer view</button>
                        </div>
                    </div>

                    {/* preview */}

                    <div className='bulkshare122' >
                        <div className='bulkshare129'>
                            <ProgressBar2 setPercentage={setPercentage} currentstatus={2} imageUrl={imageUrl} maxDiscount={formData?.maxpercentage ? formData?.maxpercentage : 18} maxLimit={formData?.maxlevel ? formData?.maxlevel : 9} key={''} />
                        </div>

                    </div>


                    {/* buy premium  */}
                    <div>
                        <BuyPremium />
                    </div>

                    <div>  <Toaster
                        position="top-center"
                        reverseOrder={false}
                    /></div>

                </div>
            </div>

            <div>

            </div>





        </div>
    )
}

export default BulkshareCampaign