import React, { useEffect, useState } from "react";
import "./ScratchCardCreate";
import BuyPremium from "../BuyPremium/BuyPremium";
import toast, { Toaster } from "react-hot-toast";
import arrowup from "../../Images/arrow-up.png";
import "../../Vendor/BulkshareCampaign/BulkshareCampaign.css";
import ScratchCardImage from "../../Images/Frame 4468.png";
import ProductImage from "../../Images/175.png";
import "./ScratchCardCreate.css";
import api from "../ApiConfigration/ApiConfigration"
import DynamicInputField from "./DynamicInputField";
import DynamicDropdowns from "./DynamicDropdowns";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetRemoveScratchCard } from "../../../Redux/vendorReducer";

function ScratchCardCreate() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const edit = queryParams.get('edit'); 
  


  const [iseditDisable, setEditdisabled] = useState(false)
  const [isSaveHidden, setIsSaveHidden] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const [clickSubmit, setSubmit] = useState(false)
  const [productlist, setProductList] = useState([])
  const [activeButton, setActiveButton] = useState(false);
  const [isDiscount, setDiscount] = useState(true)
  const [formData, setFormData] = useState({
    productCount: 0,
    campaignName: "",
    products: [],
    expiryTime: "",
    beforeHeading: "",
    beforeSubHeading: "",
    afterHeading: "",
    afterSubHeading: "",
    termsAndCondition: [],
  });

  const [formErrors, setFormErrors] = useState({
    campaignName: false,
    expiryTime: false,
    beforeHeading: false,
    beforeSubHeading: false,
    afterHeading: false,
    afterSubHeading: false,
    products: []
  });

  var frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL;
  const onProductChange = (product) => {
    setFormData({ ...formData, products: product });
  };


  const setTemsAndConditionsChange = (data) => {
    setFormData({ ...formData, termsAndCondition: data });
  };

  const addDiscount = () => {
    setFormData(prevState => ({
      ...formData,
      afterSubHeading: prevState.afterSubHeading + ' @discount '
    }));
    setDiscount(false)
  }

  useEffect(() => {
    async function getProductList() {

      await api.get("/campaigns/getproductlist").then((res) => {
        if (res) {
          setProductList(res?.data?.message?.message)
        } else {
          console.log("Error Fetching Data")
        }
      }).catch((err) => {
        console.log(err.message)
      })

    }
    getProductList()
  }, [])

  const onInputHandle = (e) => {

    const { name, value } = e.target;

    if (name === "afterSubHeading") {
      if (value.includes("@discount")) {
        setDiscount(false)
      } else {
        setDiscount(true)
      }
    }
    setFormData({ ...formData, [name]: value });
    if (name === "expiryTime") {
      let today = new Date();
      let date = new Date(value);
      if (today >= date || date === "Invalid Date") {
        setFormErrors({ ...formErrors, expiryTime: true });
      } else {
        setFormErrors({ ...formErrors, expiryTime: false });
      }
    }
  };

  // console.log(formData);


  const handleSubmit = async (e) => {
    setSubmit(true)
    e.preventDefault();

    const errors = { ...formErrors };
    let hasError = false;


    console.log({ errors: errors })

    formData.products.forEach((product, index) => {
      if (product.product === "" || product.discount === undefined || product.discount < 0 || product.discount > 100 || product.discount == 0) {
        hasError = true;
        errors.products[index] = {
          product: product.product === "" ? "Product is required" : "",
          discount: (product.discount === undefined || product.discount == 0 || product.discount < 0 || product.discount > 100) ? "Enter  discount Value" : ""
        };
        ;
      } else {
        errors.products[index] = { product: "", discount: "" };
      }
    });


    Object.keys(formData).forEach((key) => {
      if (key === "products" || key === "termsAndCondition") {
      } else {
        if (formData[key] === "") {
          hasError = true;
          errors[key] = true;
        }
      }
    });

    if (hasError) {
      setFormErrors(errors)
      return;
    }

    setIsSaveHidden(true)

    if (edit === "true") {

      await api.post("/campaigns/updatescratchcardbyid", formData).then(async (res) => {
        if (res) {

          toast.success(res?.data?.message?.message)
          const delayTime = 1000;
          await new Promise((resolve) => setTimeout(resolve, delayTime));
          navigate(`/vendor/scratchcard?id=${id}`)

        } else {
          toast.success("Error Updating Scratch card")
          setIsSaveHidden(false)
        }
      }).catch((err) => {
        toast.error(err.message)
        setIsSaveHidden(false)

      })

    } else {

      await api.post("/campaigns/createscratchcard", formData).then(async (res) => {
        if (res) {
          toast.success(res?.data?.message?.message)

          const delayTime = 1000;
          await new Promise((resolve) => setTimeout(resolve, delayTime));
          dispatch(SetRemoveScratchCard())
          navigate(`/vendor/scratchcard?id=${res?.data?.message?.code}`)
          

        } else {
          toast.error("Error Creating Scratch card")
          setIsSaveHidden(false)
        }
      }).catch((err) => {
        toast.error(err.message)
        setIsSaveHidden(false)

      })
    }

  };

  const processData = (text) => {

    if (text.includes("@discount")) {
      text = text.replace("@discount", formData?.products[0]?.discount + "%");
    }

    return text;
  }

  const sharedetails = async () => {

    var urltocopy = `${frontendUrl}/customer/scratch-card-campaign/${formData?.uniqueCode}`
    try {
      const textarea = document.createElement('textarea');
      textarea.value = urltocopy;
      textarea.style.position = 'fixed';
      textarea.style.top = 0;
      textarea.style.left = 0;
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.setSelectionRange(0, textarea.value.length);
      document.execCommand('copy');
      document.body.removeChild(textarea);
      toast.success("Link copied to clipboard!")

    } catch (error) {
      console.error('Failed to copy:', error);
    }
  }

  useEffect(() => {
    if (id) {
      async function getDataById() {
        await api.get(`campaigns/getScratchcardbyid?id=${id}`).then((res) => {
          if (res) {
            setFormData(res?.data?.message?.message)
            setActiveButton(res?.data?.message?.message?.isTrue)
          }
        }).catch((err) => {
          toast.error("Error Fetching Data")
        })
      }

      getDataById()
    }

    if (id && (edit === "false" || !edit)) {
      setDisabled(true)
    }




  }, [id, edit])

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <div className="row">
        <div className="col-12 col-lg-8">
          <form onSubmit={handleSubmit}>
            {/* Top heading */}
            <div className="d-flex justify-content-between">
              {/* <div className='bulkshare111'>d</div> */}
              <div className="d-flex">
                <div onClick={() => { navigate("/vendor/scratchcardlist") }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                  >
                    <path
                      d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z"
                      stroke="#444444"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397"
                      stroke="#444444"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="d-flex ps-2 flex-column">
                  <p className="top-small-heading p-0 m-0">
                    Back to scratch card list
                  </p>
                  <div className="d-flex">
                    <p className="bulkshare111 p-0 m-0">
                      {formData?.campaignName ? formData?.campaignName : "Scratch card campaign"}
                    </p>
                    <div className="ps-2 d-flex align-items-center">
                      {activeButton ? (
                        <svg
                          onClick={() => setActiveButton(!activeButton)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="25"
                          viewBox="0 0 32 25"
                          fill="none"
                        >
                          <path
                            d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                            fill="#26735A"
                          />
                          <rect
                            x="16.8335"
                            y="7.5"
                            width="10"
                            height="10"
                            rx="5"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          onClick={() => setActiveButton(!activeButton)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="25"
                          viewBox="0 0 32 25"
                          fill="none"
                        >
                          <path
                            d="M21.86 4.36H10.14C5.65 4.36 2 8.01 2 12.5C2 16.99 5.65 20.64 10.14 20.64H21.86C26.35 20.64 30 16.99 30 12.5C30 8.01 26.35 4.36 21.86 4.36Z"
                            fill="#828785"
                          />
                          <rect
                            x="5.16382"
                            y="7.5"
                            width="10"
                            height="10"
                            rx="5"
                            fill="white"
                          />
                        </svg>
                      )}
                      <p className="p-0 m-0 button-green-text">Active</p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {(!isDisabled && !edit) ? <>{isSaveHidden ? null : <button type="Submit" className="bulkshare112">
                  Save
                </button>}</> : null}
                {
                  edit === "true" ? <>{isSaveHidden ? null : <button type="Submit" className="bulkshare112">
                    Update
                  </button>}</> : null
                }
              </div>
            </div>

            {/* Form */}
            {/* Product */}

            <div className="scratch-card-product-name">
              <div >
                <label className="scratch-card-form-num-text pb-1" for="exampleFormControlInput1" class="form-label">Campaign Name</label>
                <input type="text"
                  name='campaignName'
                  onChange={onInputHandle}
                  value={formData?.campaignName}
                  class="form-control"
                  id="exampleFormControlInput1"
                  disabled={isDisabled} />
                {formErrors.campaignName && (
                  <div
                    style={{ lineHeight: "14px" }}
                    className="text-danger mt-2"
                  >
                    {formData.campaignName === ""
                      ? "This Field is Required"
                      : ""}
                  </div>
                )}
              </div>
            </div>



            <div
              style={{ gap: "16px" }}
              className="product-expires d-flex flex-column"
            >
              {/* Product */}
              <DynamicDropdowns
                initialData={isDisabled ? formData?.products : edit === "true" ? formData?.products : null}
                clickSubmit={clickSubmit}
                errors={formErrors}
                productlist={productlist}
                products={formData.products}
                setProducts={onProductChange}
                isDisabled={isDisabled}
              />
            </div>
            {/* Expires On */}
            <div className="product-expires d-flex flex-column">
              <label
                htmlFor="expires"
                className="py-1 scratch-card-form-num-text"
              >
                Expires on
              </label>
              <input
                value={formData.expiryTime}
                name="expiryTime"
                disabled={isDisabled}
                onChange={onInputHandle}
                type="date"
                className="scratch-card-form-input form-control"
              />
              {formErrors.expiryTime && (
                <div
                  style={{ lineHeight: "14px" }}
                  className="text-danger mt-2"
                >
                  {formData.expiryTime === ""
                    ? "Please enter date"
                    : "Please enter valid date"}
                </div>
              )}
            </div>
            {/* Before */}
            <div
              style={{ gap: "16px" }}
              className="product-expires d-flex flex-column"
            >
              <h1 className="before-after-text">BEFORE</h1>
              <div className="d-flex flex-column">
                <label
                  htmlFor="Heading"
                  className="scratch-card-form-num-text pb-1"
                >
                  Heading
                </label>
                <input
                  value={formData.beforeHeading}
                  name="beforeHeading"
                  disabled={isDisabled}
                  onChange={onInputHandle}
                  type="text"
                  className="scratch-card-form-input form-control"
                />
                {formErrors.beforeHeading && (
                  <div
                    style={{ lineHeight: "14px" }}
                    className="text-danger mt-2"
                  >
                    {formData.beforeHeading === ""
                      ? "This Field is Required"
                      : ""}
                  </div>
                )}              </div>
              <div className="d-flex flex-column">
                <label
                  htmlFor="Sub-Heading"
                  className="scratch-card-form-num-text pb-1"
                >
                  Sub Heading
                </label>
                <input
                  value={formData.beforeSubHeading}
                  name="beforeSubHeading"
                  disabled={isDisabled}
                  onChange={onInputHandle}
                  type="text"
                  className="scratch-card-form-input form-control"
                />
                {formErrors.beforeSubHeading && (
                  <div
                    style={{ lineHeight: "14px" }}
                    className="text-danger mt-2"
                  >
                    {formData.beforeSubHeading === ""
                      ? "This Field is Required"
                      : ""}
                  </div>
                )}
              </div>
            </div>

            {/* After */}

            <div
              style={{ gap: "32px" }}
              className="product-expires d-flex flex-column"
            >
              <div style={{ gap: "16px" }} className="d-flex flex-column">
                <h1 className="before-after-text">AFTER</h1>
                <div className="d-flex flex-column">
                  <label
                    htmlFor="Heading"
                    className="scratch-card-form-num-text pb-1"
                  >
                    Heading
                  </label>
                  <input
                    value={formData.afterHeading}
                    name="afterHeading"
                    onChange={onInputHandle}
                    disabled={isDisabled}
                    type="text"
                    className="scratch-card-form-input form-control"
                  />
                  {formErrors.afterHeading && (
                    <div
                      style={{ lineHeight: "14px" }}
                      className="text-danger mt-2"
                    >
                      {formData.afterHeading === ""
                        ? "This Field is Required"
                        : ""}
                    </div>
                  )}
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex justify-content-between">
                    <label
                      htmlFor="Sub-Heading"
                      className="scratch-card-form-num-text pb-1"
                    >
                      Sub Heading
                    </label>
                    <div className="d-flex">
                      {isDiscount ? <p style={{ cursor: "pointer" }} onClick={addDiscount} className="green-border-text">@discount</p> : null
                      }
                      <p style={{ display: "none" }} className="green-border-text">@product</p>
                    </div>
                  </div>

                  <input
                    value={formData.afterSubHeading}
                    name="afterSubHeading"
                    onChange={onInputHandle}
                    disabled={isDisabled}
                    type="text"
                    className="scratch-card-form-input form-control"
                  />
                  {formErrors.afterSubHeading && (
                    <div
                      style={{ lineHeight: "14px" }}
                      className="text-danger mt-2"
                    >
                      {formData.afterSubHeading === ""
                        ? "This Field is Required"
                        : ""}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ gap: "16px" }} className="d-flex flex-column">
                <h1 className="scratch-card-form-num-text">
                  Terms & conditions
                </h1>
                <DynamicInputField
                  initialData={isDisabled ? formData?.termsAndCondition : edit === "true" ? formData?.termsAndCondition : null}
                  onInputDataChange={setTemsAndConditionsChange}
                  isDisabled={isDisabled}
                />
              </div>
            </div>

            {/* Link generate */}
            {isDisabled ? <div className="bulkshare114">
              <div>
                <div class="mb-4">
                  <label for="exampleFormControlInput1" class="form-label">
                    Generate Link
                  </label>

                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text copubutton221 copubutton224">
                        <button
                          type="button"
                          className="btn btn-link btn-sm copubutton224"
                          id="copyButton"
                        >
                          <i className="fas fa-link"></i>
                        </button>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control copubutton222"
                      value={`${frontendUrl}/customer/scratch-card-campaign/${formData?.uniqueCode}`}
                      disabled
                    />
                    <div className="input-group-append">
                      <button
                        onClick={sharedetails}
                        type="button"
                        className="btn btn-success copubutton223"
                      >
                        Copy
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label for="exampleFormControlInput1" class="form-label">
                  Custom Link
                </label>
                <div className="bulkshare116">
                  <div>
                    Upgrade to <span className="bulkshare119">PRO</span>
                  </div>
                  <div className="bulkshare117">
                    <img className="bulkshare118" src={arrowup} alt="" />
                  </div>
                </div>
              </div>
            </div> : null}
          </form>
        </div>

        <div className="col-12  col-lg-4 ">
          {/* headings */}
          <div className="d-flex justify-content-between mb-4">
            <div>
              <h3 className="bulkshare111">Preview</h3>
            </div>
            <div>
              <button className="bulkshare120">
                {" "}
                <i class="fa-solid fa-eye"></i> Customer view
              </button>
            </div>
          </div>

          {/* Preview  Customer Component*/}

          <div style={{ height: "fit-content" }} className="bulkshare122">
            <div className="bulkshare129 preview-area">
              {/*scratch card  */}
              <div className="scratch-card-preview">
                <h1 style={{ textTransform: "capitalize" }} className="scratch-card-before-heading">
                  {formData?.beforeHeading ? formData?.beforeHeading?.toLowerCase() : "Scratch here to reveal your price"}
                </h1>
                <img
                  className="scratch-card-image img-fluid"
                  src={ScratchCardImage}
                  alt=""
                />
                <div className="scratch-card-before-sub-headig">
                  <p style={{ textTransform: "capitalize" }}> {formData?.beforeSubHeading ? formData?.beforeSubHeading?.toLowerCase() : "Chance to win upto 30% Off"} </p>
                </div>
              </div>
              {/* preview */}
              <div className="scratch-preview">
                <h1 style={{ textTransform: "capitalize" }}>{formData?.afterHeading ? formData?.afterHeading?.toLowerCase() : "Congratulations!"}</h1>
                <div className="scratch-preview-img">
                  <img src={ProductImage} alt="" />
                </div>
                <div className="scratch-preview-footer">
                  <div className="scratch-preview-boxes">
                    <h1>{formData?.products[0]?.discount ? formData?.products[0]?.discount : "10"}%</h1>
                    <p>OFF</p>
                  </div>
                  <div className="scratch-preview-boxes">
                    <h2 style={{ textTransform: "capitalize" }}>{formData?.products[0]?.product ? (formData?.products[0]?.product)?.toLowerCase() : "Fajitha Sandwitch"}</h2>
                  </div>
                  <div className="scratch-preview-footer-text">
                    <p style={{ textTransform: "capitalize" }}>{formData?.afterSubHeading ? processData(formData?.afterSubHeading) : "Save up to 10% on your next purchase."}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Buy premium  */}

          <div className="d-flex flex-column flex-grow-1">
            <BuyPremium />
          </div>

          <div>
            <Toaster position="top-center" reverseOrder={false} />
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}

export default ScratchCardCreate;
