import React, { useEffect, useState } from 'react';
import "./ViewCustomers.css"
import "../BulkshareCampaign/BulkshareCampaign.css";
import "../AddOrders/AddOrders.css"
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import toast from 'react-hot-toast';
import nodataimage from "../../Images/Product-empty.png"
import loderImage from "../../Images/loader.gif"

function ViewCustomers() {

    let navigate = useNavigate();



    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [customerData, setCustomerData] = useState()
    const [campaignData, setCamapaignData] = useState([])
    const [isloading, setIsLoading] = useState(true)


    useEffect(() => {
        if (id) {

            async function fetchData() {
                await api.get(`/customer/getcustemerdatabyid?id=${id}`).then((res) => {
                    if (res) {
                        setCustomerData(res?.data?.message?.message)
                    } else {
                        toast.error("Error Fetching Data")
                    }
                }).catch((err) => {
                    toast.error(err?.message)
                });

                api.get(`/customer/getcustomercampaignlistbyid?id=${id}`).then((data) => {
                    if (data) {
                        setIsLoading(false);
                        var finalData = data?.data?.message?.message?.splice(0, 5)
                        setCamapaignData(finalData)
                    } else {
                        toast.error("Error Fetching Data")
                    }
                }).catch((err) => {
                    toast.error(err?.message)
                });
            }

            fetchData()
        }
    }, [id])



    function formatDate(inputDateString) {
        const inputDate = new Date(inputDateString);

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = inputDate.getUTCDate();
        const month = months[inputDate.getUTCMonth()];
        const year = inputDate.getUTCFullYear();

        return `${day} ${month} ${year}`;
    }




    return (
        <div>

            <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                    <div style={{ cursor: "pointer" }} onClick={() => {
                        navigate(-1);
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div className='d-flex ps-2 flex-column'>
                        <p className='top-small-heading p-0 m-0'>Back to the Customer list</p>
                        <div className='d-flex'>
                            <p style={{ "textTransform": "capitalize" }} className='bulkshare111 p-0 m-0'>{customerData?.name ? ((customerData?.name).toLowerCase()).toLowerCase() : null}</p>

                        </div>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='mx-3 mt-1 addorder011'>Create an order</div>
                    <div className='mx-3 mt-1 addorder011'>Edit</div>
                    <div className='mx-3 mt-1 addorder010'>Delete</div>
                    <div className='mx-3'><button type='Submit' className='bulkshare112'><i class="fa-brands fa-whatsapp" ></i> Message</button></div>
                </div>


            </div>


            <div className='row mt-5'>



                <div className='col-12 col-md-6'>


                    <div className='viewcustomer003 d-flex justify-content-around mb-5 pb-5'>

                        <div className='text-center'>
                            <div className='viewcustomer005'>Last Order</div>
                            <div className='viewcustomer006'>n/a</div>
                        </div>
                        <div className='text-center'>

                            <div className='viewcustomer005'>Total Spent to date</div>
                            <div className='viewcustomer006'>₹0</div>

                        </div>
                        <div className='text-center'>
                            <div className='viewcustomer005'>Average order value</div>
                            <div className='viewcustomer006'>₹0</div>
                        </div>

                    </div>


                    <div className='viewcustomer004'>

                        <div className='viewcustomer007'>Orders</div>

                        <div>

                            <table class="table table-borderless">
                                <thead className=' pb-3 mb-3 viewcustomer012'>
                                    <tr>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008' >Cost</td>
                                        <td className='viewcustomer008'></td>
                                        <td className='viewcustomer008' >Date</td>
                                        <td className='viewcustomer008'>Status</td>

                                    </tr>
                                </thead>


                                <tbody>









                                    {/* <tr>
                                        <td className='viewcustomer009 viewcustomer010'>N1</td>
                                        <td className='viewcustomer009'></td>
                                        <td className='viewcustomer009'>₹900</td>
                                        <td className='viewcustomer009'><div className='mx-2'></div></td>
                                        <td className='viewcustomer009'>Jan 29 2023</td>
                                        <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                                    </tr>

                                    <tr>
                                        <td className='viewcustomer009 viewcustomer010'>N1</td>
                                        <td className='viewcustomer009'></td>
                                        <td className='viewcustomer009'>₹900</td>
                                        <td className='viewcustomer009'><div className='mx-2'></div></td>
                                        <td className='viewcustomer009'>Jan 29 2023</td>
                                        <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                                    </tr>


                                    <tr>
                                        <td className='viewcustomer009 viewcustomer010'>N1</td>
                                        <td className='viewcustomer009'><div className='mx-3'></div></td>
                                        <td className='viewcustomer009'>₹900</td>
                                        <td className='viewcustomer009'><div className='mx-2'></div></td>
                                        <td className='viewcustomer009'>Jan 29 2023</td>
                                        <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                                    </tr>

                                    <tr>
                                        <td className='viewcustomer009 viewcustomer010'>N1</td>
                                        <td className='viewcustomer009'></td>
                                        <td className='viewcustomer009'>₹900</td>
                                        <td className='viewcustomer009'><div className='mx-2'></div></td>
                                        <td className='viewcustomer009'>Jan 29 2023</td>
                                        <td className='viewcustomer009'><span className='viewcustomer015'>Paid</span></td>
                                    </tr> */}
                                </tbody>

                            </table>

                            <div className='text-center nodataicon-div'>
                                <img src={nodataimage} style={{ width: "50px", height: "50px" }} />
                                <div className='nodata_text'>No data found</div>
                            </div>


                        </div>


                    </div>




                </div>

                <div className='col-12 col-md-6'>

                    <div className='viewcustomer016'>

                        <div className='viewcustomer019'>Customer</div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Name</div>
                            <div style={{ "textTransform": "capitalize" }} className='viewcustomer018 col-6'>{customerData?.name ? (customerData?.name).toLowerCase() : "Nil"}</div>
                            <div></div>
                        </div>

                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Phone</div>
                            <div className='viewcustomer018 col-6'>{customerData?.phoneCode ? (customerData?.phoneCode) : null} {customerData?.phoneNumber ? (customerData?.phoneNumber) : "Nil"}</div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Address</div>
                            <div className='viewcustomer018 col-6'>
                                <div>{customerData?.addressOne ? (customerData?.addressOne).toLowerCase() : "Nil"}</div>
                                <div>{customerData?.pincode ? (customerData?.pincode).toLowerCase() : null}</div>
                            </div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Notes</div>
                            <div className='viewcustomer018 col-6'></div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Tag</div>
                            <div className='viewcustomer018 col-6'><span className='viewcustomer021'>tag_01</span> <span className='viewcustomer022'>tag_01</span></div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Referral code</div>
                            <div className='viewcustomer018 col-6'></div>
                            <div></div>
                        </div>


                        <div className='row viewcustomer020'>
                            <div className='viewcustomer017 col-6'>Created</div>
                            <div className='viewcustomer018 col-6'>{customerData?.createAt ? formatDate(customerData?.createAt) : "Nil"}</div>
                            <div></div>
                        </div>





                    </div>

                    <div className='viewcustomer016 mt-4'>

                        <div className='viewcustomer019'>Campaigns</div>

                        <div>

                            <table class="table table-borderless">
                                <thead className=' pb-3 mb-3 viewcustomer012'>
                                    <tr>
                                        <td className='viewcustomer008'>Type</td>
                                        <td className='viewcustomer008' >Campaign Name</td>
                                        <td className='viewcustomer008' >Date</td>

                                    </tr>
                                </thead>
                                <tbody>

                                    {campaignData?.map((itm, k) => <tr>
                                        <td className='viewcustomer009'>

                                            {itm?.spinAndWinId ? "Spin And Win" : null}
                                            {itm?.ScratchCardId ? "Scratch Card" : null}
                                            {itm?.bulkShareId ? "BulkShare" : null}

                                        </td>
                                        <td className='viewcustomer009'>
                                            {itm?.spinAndWinId ? itm?.spinwin?.campaignName : null}
                                            {itm?.ScratchCardId ? itm?.scratchCard?.campaignName : null}
                                            {itm?.bulkShareId ? itm?.bulkshare?.product?.productName : null}

                                        </td>
                                        <td className='viewcustomer009'>
                                            {itm?.createdAt ? formatDate(itm?.createdAt) : null}
                                        </td>
                                    </tr>)}

                                </tbody>

                            </table>

                            {
                                isloading ? <div className='d-flex , justify-content-center mt-3'>
                                    <img src={loderImage} style={{ width: "80px" }} alt="" />
                                </div> : null
                            }

                            {!isloading && campaignData?.length === 0 ?  <div className='text-center nodataicon-div'>
                                <img src={nodataimage} style={{ width: "50px", height: "50px" }} />
                                <div className='nodata_text'>No data found</div>
                            </div>:null}

                        </div>
                    </div>

                </div>
            </div>




        </div>
    )
}

export default ViewCustomers