import { configureStore } from "@reduxjs/toolkit";
import vendorReducer from "./vendorReducer"
import customerReducer from "./customerReducer";
import superAdminReducer from "./superAdminReducer";

export const store = configureStore({

  reducer: {
    vendorData: vendorReducer,
    customerData: customerReducer,
    superAdminData: superAdminReducer,

  },

});