import React, { useEffect, useState } from 'react';
import "./AddOrders.css";
import { useNavigate } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';

function AddOrders() {

    let navigate = useNavigate()

    const [totalcost, setTotalCost] = useState(0)
    const [totalMRP, setTotalMRP] = useState(0)
    const [customerlist, setCustomerList] = useState([])


    const [orderData, setOrderData] = useState({
        CustomerId: "",
        delivery: {
            deliveryOption: "",
            address1: "",
            address2: "",
            postalCode: "",
            schedule: false,
        },
        discount: false,
    })

    const [orderErrors, setOrderErrors] = useState({
        CustomerId: false,
        delivery: {
            deliveryOption: false,
            address1: false,
            address2: false,
            postalCode: false,
            schedule: false,
        },
        discount: false,
    })

    const [orderErrorsMessages, setOrderErrorsMessages] = useState({
        CustomerId: "",
        delivery: {
            deliveryOption: "",
            address1: "",
            address2: "",
            postalCode: "",
            schedule: "",
        },
        discount: "",
    })


    const [formData, setFormData] = useState({
        variants: [],

    });


    const AddVariants = () => {
        setFormData({
            ...formData,
            variants: [
                ...formData.variants,
                { name: "", price: 0, originalPrice: 0 },
            ],
        });
    };
    const DeleteVariants = (index) => {
        let variant = [...formData.variants];
        variant = JSON.parse(JSON.stringify(variant));
        variant.splice(index, 1);
        setFormData({ ...formData, variants: [...variant] });
    };
    const onVariantChange = ({ event, type, index }) => {
        if (type === "name") {
            const variants = formData.variants;
            variants[index].name = event.target.value;
            setFormData({ ...formData, variants: variants });
        } else if (type === "price") {
            const variants = formData.variants;
            variants[index].price = Number(event.target.value);
            setFormData({ ...formData, variants: variants });
        } else if (type === "originalprice") {
            const variants = formData.variants;
            variants[index].originalPrice = Number(event.target.value);
            setFormData({ ...formData, variants: variants });
        }
    };


    const handleInputChange = async (event) => {
        const { target } = event;
        const { name, value, type, checked } = target;

        setOrderData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));


        setOrderErrors((prevErrors) => ({
            ...prevErrors,
            [name]: false,
        }));

        console.log({ orderData: orderData })

    };


    const handleDeliveryInputChange = (event) => {
        const { name, value, type, checked } = event.target;

        setOrderData(prevData => ({
            ...prevData,
            delivery: {
                ...prevData.delivery,
                [name]: type === 'checkbox' ? checked : value,
            },
        }));

        setOrderErrorsMessages((prevErrors) => ({
            ...prevErrors,
            delivery: {
                ...prevErrors.delivery,
                [name]: false,
            },
        }));


    };


    const calculateTotalPrices = (variants) => {
        let totalPrice = 0;
        let totalOriginalPrice = 0;

        variants.forEach(variant => {
            totalPrice += variant.price;
            totalOriginalPrice += variant.originalPrice;
        });

        return {
            totalPrice,
            totalOriginalPrice
        };
    };


    useEffect(() => {
        const { totalPrice, totalOriginalPrice } = calculateTotalPrices(formData.variants);
        setTotalCost(totalPrice)
        setTotalMRP(totalOriginalPrice)
    }, [formData])



    useEffect(() => {
        api.get("/order/getallcustomers").then((res) => {
            if (res) {
                setCustomerList(res?.data?.message?.message)
            } else {
                console.log("err")
            }
        }).catch((err) => {
            console.log(err.message)
        })
    })

    const saveorderDetails = (e) => {

        e.preventDefault();

        if (!orderData.CustomerId) {
            setOrderErrors((prevErrors) => ({
                ...prevErrors,
                CustomerId: true,
            }))
            setOrderErrorsMessages((prevErrors) => ({
                ...prevErrors,
                CustomerId: "Select a Customer to create order",
            }))
            return false;

        }

        if (!orderData.delivery.address1) {

            setOrderErrors((prevErrors) => ({
                ...prevErrors,
                delivery: {
                    ...prevErrors.delivery,
                    address1: true
                },
            }))
            setOrderErrorsMessages((prevErrors) => ({
                ...prevErrors,
                delivery: {
                    ...prevErrors.delivery,
                    address1: "Enter Delivery Address"
                },
            }))
            return false;

        }

        if (!orderData.delivery.postalCode) {

            setOrderErrors((prevErrors) => ({
                ...prevErrors,
                delivery: {
                    ...prevErrors.delivery,
                    postalCode: true
                },
            }))
            setOrderErrorsMessages((prevErrors) => ({
                ...prevErrors,
                delivery: {
                    ...prevErrors.delivery,
                    postalCode: "Enter Postal Code"
                },
            }))
            return false;

        }

        console.log({ data: { orderDetails: orderData, products: formData } })
    }



    return (
        <div>

            <form onSubmit={saveorderDetails} >

                <div className='d-flex justify-content-between'>
                    {/* <div className='bulkshare111'>d</div> */}
                    <div className='d-flex'>
                        <div style={{ cursor: 'pointer' }} onClick={() => { navigate("/vendor/listorders") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M25.9999 38.9998H13.9999C9.09859 38.9998 5.92779 38.0134 3.95709 36.0427C1.98639 34.072 1 30.9012 1 25.9999V13.9999C1 9.09859 1.98639 5.92779 3.95709 3.95709C5.92779 1.98639 9.09859 1 13.9999 1H25.9999C30.9012 1 34.072 1.98639 36.0427 3.95709C38.0134 5.92779 38.9998 9.09859 38.9998 13.9999V25.9999C38.9998 30.9012 38.0134 34.072 36.0427 36.0427C34.072 38.0134 30.9012 38.9998 25.9999 38.9998Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M22.5204 27.0596L15.4805 19.9997L22.5204 12.9397" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className='d-flex ps-2 flex-column'>
                            <p className='top-small-heading p-0 m-0'>Back to the Order list</p>
                            <div className='d-flex'>
                                <p className='bulkshare111 p-0 m-0'>N6 - New Order</p>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <div className='mx-3 mt-1 addorder010'>Delete</div>
                        <div className='mx-3 mt-1 addorder011'>Cancel</div>
                        <div className='mx-3'><button type='Submit' className='bulkshare112'>Save</button></div>
                    </div>


                </div>

                <div className='row mt-5'>


                    <div className='col-md-6 col-12'>

                        <div className='addorder001'>
                            <p className='addorder007'>Customer</p>
                            <div className='addorder008'>
                                <select
                                    className="form-select"
                                    name="CustomerId"
                                    onChange={handleInputChange}
                                    value={orderData.CustomerId}
                                    aria-label="Default select example">
                                    <option selected >Select a customer</option>
                                    {customerlist.map(customer => (
                                        <option key={customer.id} value={customer.id}>
                                            {customer.userName ? customer.userName : "Default User"}
                                        </option>
                                    ))}
                                </select>
                                {orderErrors.CustomerId && <div className='text-danger pt-1'>{orderErrorsMessages.CustomerId}</div>}

                            </div>


                        </div>

                        <div>


                            <div className="add-product-box">
                                <div className="d-flex ">
                                    <h1 className="addorder006 mb-3">PRODUCTS</h1>
                                    <div className="ps-2">

                                    </div>
                                    {/* Variants Table */}
                                </div>
                                <div className="d-flex flex-column w-100">
                                    {formData.variants[0] ? (
                                        <div className="d-flex variants-table-head">
                                            <div className="w-50">
                                                <p>Name</p>
                                            </div>
                                            <div className="w-50 d-flex">
                                                <p className="w-50">Price</p>
                                                <p className="w-50">Orginal Price</p>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* rows */}
                                    {formData.variants.map((item, index) => {
                                        return (
                                            <>
                                                <div
                                                    key={index}
                                                    className="d-flex w-100 align-items-center py-1 flex-grow-1"
                                                >
                                                    <svg
                                                        width="20"
                                                        height="29"
                                                        viewBox="0 0 20 29"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle cx="6" cy="6.5" r="2" fill="#BBC3BC" />
                                                        <circle cx="14" cy="6.5" r="2" fill="#BBC3BC" />
                                                        <circle cx="6" cy="14.5" r="2" fill="#BBC3BC" />
                                                        <circle cx="14" cy="14.5" r="2" fill="#BBC3BC" />
                                                        <circle cx="6" cy="22.5" r="2" fill="#BBC3BC" />
                                                        <circle cx="14" cy="22.5" r="2" fill="#BBC3BC" />
                                                    </svg>
                                                    <div className="d-flex flex-grow-1">
                                                        {/* Product Name */}
                                                        <div className="w-50 px-2">
                                                            <input
                                                                onChange={(e) =>
                                                                    onVariantChange({
                                                                        event: e,
                                                                        type: "name",
                                                                        index,
                                                                    })
                                                                }
                                                                value={item.name}
                                                                style={{ width: "90%" }}
                                                                type="text"
                                                                className="add-product-box-input "

                                                            />
                                                        </div>
                                                        <div className="w-50  d-flex">
                                                            <div className="w-50">
                                                                <input
                                                                    onChange={(event) =>
                                                                        onVariantChange({
                                                                            event,
                                                                            type: "price",
                                                                            index,
                                                                        })
                                                                    }
                                                                    style={{ width: "90%" }}
                                                                    type="number"
                                                                    value={item.price}
                                                                    className="add-product-box-input  "
                                                                    placeholder="0"
                                                                />
                                                            </div>
                                                            <div className="w-50">
                                                                <input
                                                                    onChange={(event) =>
                                                                        onVariantChange({
                                                                            event,
                                                                            type: "originalprice",
                                                                            index,
                                                                        })
                                                                    }
                                                                    style={{ width: "90%" }}
                                                                    type="number"
                                                                    value={item.originalPrice}
                                                                    className="add-product-box-input  "
                                                                    placeholder="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* delete */}
                                                    <svg
                                                        onClick={() => DeleteVariants(index)}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="25"
                                                        viewBox="0 0 24 25"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M16.19 2.5H7.81C4.17 2.5 2 4.67 2 8.31V16.68C2 20.33 4.17 22.5 7.81 22.5H16.18C19.82 22.5 21.99 20.33 21.99 16.69V8.31C22 4.67 19.83 2.5 16.19 2.5ZM15.36 14.8C15.65 15.09 15.65 15.57 15.36 15.86C15.21 16.01 15.02 16.08 14.83 16.08C14.64 16.08 14.45 16.01 14.3 15.86L12 13.56L9.7 15.86C9.55 16.01 9.36 16.08 9.17 16.08C8.98 16.08 8.79 16.01 8.64 15.86C8.35 15.57 8.35 15.09 8.64 14.8L10.94 12.5L8.64 10.2C8.35 9.91 8.35 9.43 8.64 9.14C8.93 8.85 9.41 8.85 9.7 9.14L12 11.44L14.3 9.14C14.59 8.85 15.07 8.85 15.36 9.14C15.65 9.43 15.65 9.91 15.36 10.2L13.06 12.5L15.36 14.8Z"
                                                            fill="#C32D22"
                                                        />
                                                    </svg>
                                                </div>
                                            </>
                                        );
                                    })}

                                    <div>
                                        <button
                                            onClick={() => AddVariants()}
                                            className="green-add-btn"
                                            type="button"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                                {/*  */}
                            </div>





                        </div>


                        <div className='addorder005 mb-3'>

                            <div className='d-flex '>
                                <div className='addorder007 mt-2 '>DISCOUNT</div>
                                <div className='mx-4'>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input addorder14"
                                            name="discount"
                                            onChange={handleInputChange}
                                            value={orderData.discount}
                                            type="checkbox"
                                            id="flexSwitchCheckDefault" />
                                    </div>
                                </div>
                            </div>

                        </div>



                    </div>





                    <div className='col-md-6 col-12'>

                        <div className='addorder003'>

                            <p className='addorder006'>SUMMARY</p>

                            <div className='d-flex justify-content-between addorder009'>
                                <div className='addorder009'>Item Total</div>
                                <div>₹{totalMRP}</div>
                            </div>

                            <div className='d-flex justify-content-between mt-3 addorder009'>
                                <div>Discount</div>
                                <div>₹{totalMRP - totalcost}</div>
                            </div>


                            <div className='d-flex justify-content-between mt-3 addorder009'>
                                <div>Delievery</div>
                                <div>₹0</div>
                            </div>


                            <div className='d-flex justify-content-between mt-3 addorder009'>
                                <div className='addorder015'>Total</div>
                                <div className='addorder015'>₹{totalcost}</div>
                            </div>



                        </div>



                        <div className='addorder004'>

                            <div className='mb-3 addorder006'>Delivery</div>

                            <div className="mb-4">
                                <select
                                    className="form-select"
                                    name="deliveryOption"
                                    value={orderData.delivery.deliveryOption}
                                    onChange={handleDeliveryInputChange}
                                    aria-label="Default select example">
                                    <option value="0" selected>Delievery</option>
                                    <option value="1">Pickup</option>

                                </select>
                            </div>

                            <div className="mb-4">
                                <label for="exampleFormControlInput1" className="form-label addorder007">Address</label>
                                <input
                                    type="text"
                                    name='address1'
                                    value={orderData.delivery.address1}
                                    onChange={handleDeliveryInputChange}
                                    className="form-control"
                                    id="exampleFormControlInput1" />
                                <input
                                    type="text"
                                    name='address2'
                                    value={orderData.delivery.address2}
                                    onChange={handleDeliveryInputChange}
                                    className="form-control mt-3"
                                    id="exampleFormControlInput1" />

                                {orderErrors.delivery.address1 && <div className='text-danger pt-1'>{orderErrorsMessages.delivery.address1}</div>}

                            </div>

                            <div className="mb-4">
                                <label for="exampleFormControlInput1" className="form-label addorder007">Postal Code</label>
                                <input
                                    type="number"
                                    name='postalCode'
                                    value={orderData.delivery.postalCode}
                                    onChange={handleDeliveryInputChange}
                                    className="form-control no-spinner"
                                    id="exampleFormControlInput1" />
                                {orderErrors.delivery.postalCode && <div className='text-danger pt-1'>{orderErrorsMessages.delivery.postalCode}</div>}

                            </div>

                            <div className='mb-4'>

                                <div className='d-flex'>
                                    <div className='addorder007 mt-2'>Schedule</div>
                                    <div>
                                        <div className='mx-2 addorder14'>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="form-check-input"
                                                    name='schedule'
                                                    value={orderData.delivery.schedule}
                                                    onChange={handleDeliveryInputChange}
                                                    type="checkbox"
                                                    id="flexSwitchCheckDefault" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>


                    </div>







                </div>


            </form>

        </div>
    )
}

export default AddOrders