import React, { useState } from 'react';
import "./LoginSuperAdmin.css"
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from "../../Images/logo1.png"

function LoginSuperAdmin() {

    const navigate = useNavigate();
    var baseurl = process.env.REACT_APP_API_URL_SUPERADMIN;


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isFreezed, setIsFreezed] = useState(false)


    const handleEmailChange = (e) => {
        setEmail((e.target.value).trim());
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword((e.target.value).trim());
        setPasswordError('');
    };



    const loginuser = async (e) => {
        e.preventDefault()

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!(email.trim())) {
            setEmailError('Please enter your email.');
            return;
        }
        if (!email.match(emailRegex)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        if (!password) {
            setPasswordError('Please enter your Password.');
            return;
        }
        setIsFreezed(true)
        //Saving User Details to Backend
        await axios.post(`${baseurl}/auth/login`, {
            password: password,
            email: email
        })
            .then(async (res) => {
               
                if (res?.data?.message?.code === "success") {
                    toast.success('Login Success')
                  
                    setIsFreezed(false)

                    var token = res?.data?.message?.user?.token
                    var refreshtoken = res?.data?.message?.user?.refreshToken

                    localStorage.setItem("satoken", token)
                    localStorage.setItem("sartoken", refreshtoken)

                    const delayTime = 500;
                    await new Promise((resolve) => setTimeout(resolve, delayTime));

                    window.location.replace('/superadmin/home')


                } else {
                    setIsFreezed(false)
                    toast.error("Error during Login")
                }

            })
            .catch((err) => {
                setIsFreezed(false)
                toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
            });



    }


    return (

        <div className='vendorlogin112'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='vendorlogin113'>

                <div className='mb-4 d-flex justify-content-center'><img className='vendorlogin120' src={logo} alt="" /></div>
                <div >
                    <form onSubmit={loginuser}>
                        <div className=" vendorlogin118">

                            {/* <div class="text-center vendorlogin121">Vendor Login</div> */}


                            <div className='mt-4'>
                                <label for="exampleFormControlInput1" class="form-label">Email address</label>
                                <input type="text" className="form-control formbgcolor" value={email} name="email" onChange={(e) => handleEmailChange(e)} id="exampleFormControlInput1" />
                                {emailError && <div className="text-danger mt-1">{emailError}</div>}

                            </div>

                            <div className="mt-4">
                                <label for="exampleFormControlInput1" class="form-label">Password</label>
                                <input type="password" className="form-control formbgcolor" value={password} name="email" onChange={(e) => handlePasswordChange(e)} id="exampleFormControlInput1" />
                                {passwordError && <div className="text-danger mt-1">{passwordError}</div>}

                            </div>

                            <div className="mt-5 d-grid">
                                {!isFreezed ? <button onClick={loginuser} className='vendorlogin117'>
                                    Login
                                </button>
                                    : <div className='d-flex justify-content-center'>
                                        <div class="spinner-border text-success" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </div>}

                            </div>

                            <div className='mt-3 d-flex justify-content-end vendorlogin121'>

                            </div>


                        </div>
                    </form>
                </div>
            </div>



        </div>
    )
}

export default LoginSuperAdmin