import { createSlice } from "@reduxjs/toolkit";


const superAdminSlice = createSlice({
    name: "superadmindata",

    initialState: {

        vendorList: [],
        vendorListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
        },
        pendingVendorList: [],
        pendingVendorListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
        },
        rejectedVendorList: [],
        rejectedVendorListDetails: {
            page: 0,
            hasmore: true,
            noDataImageFlag: false,
        },

    },

    reducers: {


        setVendorList: (state, action) => {
            state.vendorList = action.payload;
        },
        setVendorListDetails: (state, action) => {
            state.vendorListDetails.page = action.payload.page;
            state.vendorListDetails.hasmore = action.payload.hasmore;
            state.vendorListDetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        setPendingVendorList: (state, action) => {
            state.pendingVendorList = action.payload;
        },
        setPendingVendorListDetails: (state, action) => {
            state.pendingVendorListDetails.page = action.payload.page;
            state.pendingVendorListDetails.hasmore = action.payload.hasmore;
            state.pendingVendorListDetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        setRejectedVendorList: (state, action) => {
            state.rejectedVendorList = action.payload;
        },
        setRejectedVendorListDetails: (state, action) => {
            state.rejectedVendorListDetails.page = action.payload.page;
            state.rejectedVendorListDetails.hasmore = action.payload.hasmore;
            state.rejectedVendorListDetails.noDataImageFlag = action.payload.noDataImageFlag
        },
        SetRemoveRejectedVendors: (state, action) => {
            state.rejectedVendorList = []
            state.rejectedVendorListDetails.page = 0;
            state.rejectedVendorListDetails.hasmore = true;
            state.rejectedVendorListDetails.noDataImageFlag = false;

        },
        SetRemoveVendors: (state, action) => {
            state.vendorList = []
            state.vendorListDetails.page = 0;
            state.vendorListDetails.hasmore = true;
            state.vendorListDetails.noDataImageFlag = false;

        },

    }


});



export const { setVendorList, setVendorListDetails, setPendingVendorList, setPendingVendorListDetails, setRejectedVendorList,
    setRejectedVendorListDetails , SetRemoveRejectedVendors , SetRemoveVendors } =
    superAdminSlice.actions;
const superAdminReducer = superAdminSlice.reducer;
export default superAdminReducer;