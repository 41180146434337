import React, { useEffect, useRef, useState } from 'react'
import './Bulkshare.css'
import ProgressBar2 from '../ProgressBar2/ProgressBar2';
import menu from "../../Images/Menu.png";
import profile from "../../Images/Profile.png"
import PeopleClaimedTimeline from '../PeopleClaimedTimeline/PeopleClaimedTimeline';
import ClaimCouponsOffers from '../ClaimCouponsOffers/ClaimCouponsOffers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import api from '../ApiConfigration/ApiConfigration';
import Countdown from 'react-countdown';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import CustomerSidebar from '../CustomerSideBar/CustomerSidebar';
import ClaimCouponAfterCampaingn from './ClaimCouponAfterCampaingn';
import { isMobile } from 'react-device-detect';
import ProfileDropDown from './ProfileDropDown';



function Bulkshare() {

    var token = localStorage.getItem("utoken")

    const frontendUrl = process.env.REACT_APP_CUSTOMER_FRONTEND_URL
    var baseUrl = process.env.REACT_APP_API_URL_CUSTOMER
    var awsurl = process.env.REACT_APP_API_AWS_BASE_URL
    let navigate = useNavigate()

    const { campaignid, user, refercode } = useParams();
    const [bulkshareData, setbulkshareData] = useState({})
    const [referreduserlist, setreferreduserlist] = useState([])
    const [progresspending, setProgressPending] = useState(0)
    const [imageUrl, setImageUrl] = useState()
    const [isActive, setIsActive] = useState(false);
    const [userdiscount, setUserDiscount] = useState(0);
    const [sidebarData, setSidebarData] = useState({});
    const [percentage, setPercentage] = useState(0)


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const autoclaimstatus = queryParams.get('autoclaim');
    const isUserName = queryParams.get('isUserName');

    useEffect(() => {



    }, [isUserName])



    useEffect(() => {

        if (user && refercode) {

            getbulkshareDetails()
            async function getbulkshareDetails() {
                await axios.get(`${baseUrl}/campaign/getcampaigndetails?usertype=${user}&uniquecode=${refercode}`).then((res) => {
                    if (res) {
                        console.log(res?.data?.message)
                        var data = res?.data?.message?.data
                        var referreduserlist = res?.data?.message?.referredUser

                        var items = data?.termsAndCondition?.map((item) => {
                            let parsedItem = item;
                            if (typeof item === 'string') {
                                parsedItem = JSON.parse(item);
                            }
                            parsedItem = parsedItem?.value || parsedItem;
                            return parsedItem;
                        });

                        var datavalues = {
                            expirytime: data?.expirytime,
                            termsAndCondition: items,
                            remaningquantity: data?.remaningquantity,
                            bulkshareId: (user === "v") ? (data?.id) : (data?.bulkShareId),
                            referalCode: (user === "v") ? data?.uniqueCode : data?.referalCode,
                            maxpercentage: data?.maxpercentage,
                            maxlevel: data?.maxlevel,
                            currentstatus: referreduserlist?.length,
                            userName: data?.userName,
                            productName: data?.productName


                        }

                        setImageUrl(awsurl + data?.imageUrl)
                        setbulkshareData(datavalues)
                        setreferreduserlist(referreduserlist)
                        setSidebarData(res?.data?.message?.sidebardata)
                        CalculatePercentageprogressbar(data?.remaningquantity, data?.quantity)
                    }
                }).catch((err) => {
                    console.log(err?.response?.data?.errors?.body[0])
                })
            }
        }

        if (campaignid) {

            getbulkshareCampaignDetails()

            async function getbulkshareCampaignDetails() {

                await api.get(`/campaign/getCampaigndetailsbyid?claimid=${campaignid}`).then(async (res) => {
                    if (res) {
                        console.log(res?.data?.message)
                        var data = res?.data?.message?.data
                        var referreduserlist = res?.data?.message?.referredUser

                        var items = data?.termsAndCondition?.map((item) => {
                            let parsedItem = item;
                            if (typeof item === 'string') {
                                parsedItem = JSON.parse(item);
                            }
                            parsedItem = parsedItem?.value || parsedItem;
                            return parsedItem;
                        });

                        var datavalues = {
                            expirytime: data?.expirytime,
                            termsAndCondition: items,
                            remaningquantity: data?.remaningquantity,
                            bulkshareId: data?.bulkShareId,
                            referalCode: data?.referalCode,
                            maxpercentage: data?.maxpercentage,
                            maxlevel: data?.maxlevel,
                            currentstatus: referreduserlist.length,
                            userName: data?.userName,
                            productName: data?.productName


                        }
                        setImageUrl(awsurl + data?.imageUrl)
                        setbulkshareData(datavalues)
                        setreferreduserlist(referreduserlist)
                        CalculatePercentageprogressbar(data?.remaningquantity, data?.quantity)
                        setSidebarData(res?.data?.message?.sidebardata)

                    }
                }).catch((err) => {
                    console.log(err?.response?.data?.errors?.body[0])
                })
            }

        }
        // eslint-disable-next-line/
    }, [campaignid, user, refercode])


    useEffect(() => {
        if (bulkshareData) {
            var percentage = bulkshareData?.maxpercentage
            var level = bulkshareData?.maxlevel
            var ref = referreduserlist?.length;
            var individual = Number(percentage) / Number(level)
            var discountearned = Number(ref) * Math.ceil(individual)
            setUserDiscount(discountearned)
        }


    }, [bulkshareData])

    function isDateBeforeToday(date) {
        const targetDate = new Date(date);
        const today = new Date();
        return targetDate < today;
    }



    const CalculatePercentageprogressbar = (remaningp, totalp) => {
        var remaning = remaningp
        var total = totalp

        var pending = (Number(remaning) / Number(total)) * 100
        console.log({ pendingpendingpending: pending, remaning, total })
        setProgressPending(pending)
    }

    const sharedetails = async () => {

        if (isMobile) {
            if (!bulkshareData?.referalCode) {
                toast.error("Error try again")
                return
            }
            const text = "*Hello, check out this campaign and win exciting discounts:*";
            const link = `${frontendUrl}/customer/bulkshare/${(user === "v") ? "v" : "u"}/${bulkshareData?.referalCode}`;
            const whatsappURL = `whatsapp://send?text=${encodeURIComponent(text + " " + link)}`;
            window.location.href = (whatsappURL);

        } else {
            if (!bulkshareData?.referalCode) {
                toast.error("Error try again")
            }
            var urltocopy = `${frontendUrl}/customer/bulkshare/${(user === "v") ? "v" : "u"}/${bulkshareData?.referalCode}`
            try {
                const textarea = document.createElement('textarea');
                textarea.value = urltocopy;
                textarea.style.position = 'fixed';
                textarea.style.top = 0;
                textarea.style.left = 0;
                document.body.appendChild(textarea);
                textarea.focus();
                textarea.setSelectionRange(0, textarea.value.length);
                document.execCommand('copy');
                document.body.removeChild(textarea);
                toast.success("Link copied to clipboard!")

            } catch (error) {
                console.error('Failed to copy:', error);
            }
        }

    }




    const handleScroll = () => {
        const openDropdowns = document.querySelectorAll('.dropdown.show');
        openDropdowns.forEach((dropdown) => {
            const menu = dropdown.querySelector('.dropdown-menu');
            if (menu) {
                dropdown.classList.remove('show');
                menu.classList.remove('show');
            }
        });
    };

    window.addEventListener('scroll', handleScroll);


    useEffect(() => {

        if (bulkshareData?.expirytime) {
            setIsActive(isDateBeforeToday(bulkshareData?.expirytime));
        }
    }, [bulkshareData]);


    const padWithZero = (value) => {
        return value < 10 ? `0${value}` : value;
    };

    const renderer = ({ days, hours, minutes, seconds }) => {
        const formattedDays = padWithZero(days);
        const formattedHours = padWithZero(hours);
        const formattedMinutes = padWithZero(minutes);
        const formattedSeconds = padWithZero(seconds);

        return `${formattedDays}D:${formattedHours}H:${formattedMinutes}M:${formattedSeconds}S`;
    };

    const makeUserLogin = () => {

        navigate(`/customer/login?redirect=${"bulkshare/" + user + "/" + refercode}`)

    }

    const [isShowing, setShowing] = useState(false)
    const [backgroundOverlay, setBackgroundOverlay] = useState(false);

    const popupRef = useRef(null);

    useEffect(() => {
        if (isUserName == "false") {
            setShowing(true)
        }

    }, [isUserName])

    useEffect(() => {
        if (!isShowing) {
            setBackgroundOverlay(false)
        } else {
            setBackgroundOverlay(true)

        }
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowing(false);
            }
        };

        if (isShowing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isShowing]);





    return (
        <div className={`bulksharehome ${backgroundOverlay ? 'background-overlay' : ''}`}>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            <div className='topbulkshare'>

                <div className='navbarr'>
                    <div style={{ cursor: 'pointer' }} data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><img src={menu} alt={menu} /></div>
                    <div className='drop222'>
                        <div className='dropdown001 dropstart'><img style={{ cursor: 'pointer' }} src={profile} alt="" id="dropdownMenuButton1" data-bs-toggle="dropdown" />
                            <ProfileDropDown />

                        </div>
                    </div>
                </div>

                <div className='campignbar pt-5'>
                    <div className='text-center'>
                        <h3 className='headbar1'>Campign time left</h3>
                        {bulkshareData?.expirytime ? <p className='headbar2'><Countdown date={bulkshareData?.expirytime} renderer={renderer} /></p> : <p></p>}
                    </div>

                </div>

                <div className='progressbarr'>

                    <h3 className='username2 text-center'>{bulkshareData?.userName?.toLowerCase()}</h3>

                    <div className='progressbarsize'>
                        <div className='progress234'>
                            <div className='customerbulkshare334'>
                                <div className='customerbulkshare335' >
                                    <ProgressBar2 setPercentage={setPercentage} currentstatus={user === "v" ? 0 : refercode ? ((Number(bulkshareData?.currentstatus)) === Number(bulkshareData?.maxlevel) ? 0 : (Number(bulkshareData?.currentstatus))) : (Number(bulkshareData?.currentstatus))} imageUrl={imageUrl} maxDiscount={bulkshareData?.maxpercentage} maxLimit={bulkshareData?.maxlevel} key={''} />
                                </div>

                            </div>
                        </div>

                    </div>
                    <div>

                    </div>

                    <div className='d-flex justify-content-center'>
                        <div className="progress">
                            <div className="progress-bar bg-danger" role="progressbar" style={{ "width": `${progresspending}%` }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                        </div>

                    </div>
                    <div className='progressdesciption'>
                        <p className='progressdesciption2'>{bulkshareData?.remaningquantity ? bulkshareData?.remaningquantity : 0} Products left !</p>

                        {campaignid ? <div className='text-center mt-4 mb-5 progressdesciption3'>
                            <div>You are now eligible for {percentage ? percentage : 0}% off</div>
                        </div> : null}
                    </div>




                </div>



                <div className='claimbtnicon'>
                    {campaignid ? null :
                        <div>
                            {token ? <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" className='btnforclaim submitt_btn_claim'>Claim</div> :
                                <div onClick={makeUserLogin} className='btnforclaim submitt_btn_claim'>Claim</div>
                            }
                        </div>
                    }

                    {/* Manual Claim Disabled */}
                    {/* {campaignid ?
                        <div><button data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" className='btnforclaim'>Redeem</button></div> : null
                    } */}


                    <div><div onClick={sharedetails} className='btnforclaim submitt_btn_claim'>Share</div></div>
                </div>


                {/* Terms and Conditions */}
                {campaignid ? null : <div className='termsandpolicy'>

                    <h3 className='termsheading'>How this works</h3>
                    <ul className='termsorderlist'>
                        {
                            bulkshareData?.termsAndCondition?.map((item) => (
                                <li>{item}</li>
                            ))}
                    </ul>
                </div>}

                {/* Timeline People Claimed */}

                {(Number(bulkshareData?.currentstatus)) == 0 || user === "v" ? null : <div className='mt-5 pt-3'> <div className='claimedusertextlist mt-5'>People who Claimed</div>

                    <div className='d-flex justify-content-center'>
                        <div className='timelinebar'>
                            <PeopleClaimedTimeline userClaimedlist={referreduserlist} />
                        </div>

                    </div></div>}



                {/* Pop Up for coupon claim */}
                <div className="offcanvas offcanvas-bottom offcanaseditpage" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                    {!campaignid ?
                        <ClaimCouponsOffers pageData={{ bulkshareData, autoclaimstatus }} /> :
                        <ClaimCouponAfterCampaingn pageData={{ bulkshareData, percentage }} />
                    }
                </div>

                {isShowing && (<div ref={popupRef} className={`qwsedrfgbhnjm ${isShowing ? '' : 'hide'}`} >
                    <ClaimCouponsOffers pageData={{ bulkshareData, autoclaimstatus }} />
                </div>)}


            </div>



            <div className="offcanvas offcanvas-start cssidebar001 " data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <CustomerSidebar pageData={sidebarData} />
            </div>




        </div>
    )
}

export default Bulkshare