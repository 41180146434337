import React, { useEffect, useState } from 'react';
import "./OrderDetails.css"
import food from "../../Images/5d085e0b3f7ceb3454946e693f137ac2.jpg"
import { useLocation, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import api from '../ApiConfigration/ApiConfigration';


function OrderCartComponents({ updateCartData , updateCurrencyData }) {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const storeId = queryParams.get('id');
    const [cartdata, setCartData] = useState([]);
    const [currency, setCurrency] = useState("")

    useEffect(() => {

        const getCartData = async () => {

            await api.get(`/store/getcartdatabystoreid?id=${storeId}`).then((res) => {
                if (res) {
                    console.log(res?.data?.message?.message)
                    setCartData(res?.data?.message?.message)
                    sessionStorage.setItem("currency", res?.data?.message?.currency)
                    setCurrency(res?.data?.message?.currency)
                    updateCartData(res?.data?.message?.message)
                    updateCurrencyData(res?.data?.message?.currency)

                } else {
                    toast.error("Error fetching Data")
                }
            }).catch((err) => {
                console.log(err)
                toast.error(err?.response?.data?.errors?.body[0] ? err?.response?.data?.errors?.body[0] : err?.message)
            })

        }

        getCartData();

    }, [])

    const findOptions = (ids) => {
        var data = ids?.find(itm => itm.type === "choice")
        if (data) {
            return data.choice.map(({ value }) => value);

        } else {
            return [];
        }
    }

    const redirectBaCK = () => {
        navigate(-1)
    }

    const changeProductQuantity = async (action, uid) => {

        const productIndex = cartdata.findIndex(item => item.id === uid);
        if (productIndex !== -1) {
            const updatedCartData = [...cartdata];
            let currentQuantity = updatedCartData[productIndex].quantity;
            if (action === "add") {
                currentQuantity++;
                api.get(`/store/update-quantity-by-id?id=${uid}&type=add`)
            } else if (action === "sub" && currentQuantity > 1) {
                currentQuantity--;
                api.get(`/store/update-quantity-by-id?id=${uid}&type=sub`)

            }
            updatedCartData[productIndex].quantity = currentQuantity;
            setCartData(updatedCartData);
            updateCartData(updatedCartData)

        }

    }

    return (
        <div className='ps-0 px-0 ms-0 mx-0'>
            <Toaster
                position="top-center"
                reverseOrder={false}
            />

            {cartdata?.map((item, k) =>
                <div className='d-flex mb-3'>
                    <div><img className='odercart001' src={item?.product?.productImage} alt="" /></div>
                    <div className='ms-3'>
                        <p className='odercart002'>{item?.isvariant ? item?.variants?.name : item?.product?.productName}</p>
                        {findOptions(item?.options)?.length > 0 ? <p className='odercart003'>Add: {findOptions(item?.options)}</p> : null}
                        <div className='d-flex justify-content-between odercart006 '>
                            <div style={{ paddingTop: "2px" }}>
                                <span className='odercart004'>{currency ? currency : "BD"} {item?.isVarient === true ? item?.variants?.price : item?.product?.price ? item?.product?.price : "0"}</span>
                                <span className='ms-3 odercart005'>{currency ? currency : "BD"} {item?.isVarient === true ? item?.variants?.originalPrice : item?.product?.originalPrice ? item?.product?.originalPrice : "0"}</span>
                            </div>
                            <span>
                                <div className='d-flex mx-3'>
                                    <div onClick={() => { changeProductQuantity("sub", item?.id) }} className='mx-2 ms-0 odercart008'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                                            <path d="M10.7933 1.33325H5.20666C2.77999 1.33325 1.33333 2.77992 1.33333 5.20659V10.7866C1.33333 13.2199 2.77999 14.6666 5.20666 14.6666H10.7867C13.2133 14.6666 14.66 13.2199 14.66 10.7933V5.20659C14.6667 2.77992 13.22 1.33325 10.7933 1.33325ZM12 8.49992H3.99999C3.72666 8.49992 3.49999 8.27325 3.49999 7.99992C3.49999 7.72659 3.72666 7.49992 3.99999 7.49992H12C12.2733 7.49992 12.5 7.72659 12.5 7.99992C12.5 8.27325 12.2733 8.49992 12 8.49992Z" fill="white" />
                                        </svg>

                                    </div>
                                    <div className='mx-2 ms-0 odercart007'>{item?.quantity?.toString().padStart(2, '0')}</div>
                                    <div onClick={() => { changeProductQuantity("add", item?.id) }} className='odercart008'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                                            <path d="M10.7933 1.33325H5.20666C2.77999 1.33325 1.33333 2.77992 1.33333 5.20659V10.7866C1.33333 13.2199 2.77999 14.6666 5.20666 14.6666H10.7867C13.2133 14.6666 14.66 13.2199 14.66 10.7933V5.20659C14.6667 2.77992 13.22 1.33325 10.7933 1.33325ZM12 8.49992H8.5V11.9999C8.5 12.2733 8.27333 12.4999 8 12.4999C7.72666 12.4999 7.5 12.2733 7.5 11.9999V8.49992H3.99999C3.72666 8.49992 3.49999 8.27325 3.49999 7.99992C3.49999 7.72659 3.72666 7.49992 3.99999 7.49992H7.5V3.99992C7.5 3.72659 7.72666 3.49992 8 3.49992C8.27333 3.49992 8.5 3.72659 8.5 3.99992V7.49992H12C12.2733 7.49992 12.5 7.72659 12.5 7.99992C12.5 8.27325 12.2733 8.49992 12 8.49992Z" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </span></div>
                    </div>
                </div>
            )}

            <div>
                <p onClick={redirectBaCK} style={{ cursor: "pointer" }} className='odercart009'>+ Add more items</p>
            </div>


        </div>
    )
}

export default OrderCartComponents